import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService, { branchId, companyId, countryId, isInfossNo, userCode, username } from '../../../../services/ApiService'
import { forwardRef } from 'react';
import { Box, CardContent, Grid, Card } from '@mui/material'
import ButtonCreateEditBankAdvSettlement from './Buttons/ButtonCreateEditBankAdvSettlement'
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import BankAdvSettlementHeader from './Components/BankAdvSettlementHeader';
import BankAdvSettlementDetail from './Components/BankAdvSettlementDetail';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal, convertToNumeric, newWindowConfig } from '../../../../utils/masks.jsx';
import CustomToastContainer, { showToast } from '../../../../components/ui';
import { PrintPDF } from '../../../../utils/reusableFunction.jsx';

const CreateEditBankAdvSettlement = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataBAS"));

    const { BankAdvSetId } = useParams()
    const history = useNavigate()
    const paramBankAdvSettlement = 'bankAdvanceSettlement/bankAdvanceSettlement'
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const [BankAdvanceId, setBankAdvanceId] = useState(0);
    const [Reference, setReference] = useState('');
    const [EmployeeId, setEmployeeId] = useState(0);
    const [SettlementBankAdvanceNo, setSettlementBankAdvanceNo] = useState(0);
    const [SettlementBankAdvanceNo2, setSettlementBankAdvanceNo2] = useState('');
    const [TotalUSD, setTotalUSD] = useState(0);
    const [TotalIDR, setTotalIDR] = useState(0);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateDate, setExRateDate] = useState('');
    const [ExRateId, setExRateId] = useState(0);
    const [Approved, setApproved] = useState(false);
    const [Printing, setPrinting] = useState(0);
    const [PrintedOn, setPrintedOn] = useState('');
    const [Deleted, setDeleted] = useState(false);
    const [DeletedOn, setDeletedOn] = useState('');

    const [BankAdvanceNo, setBankAdvanceNo] = useState('');
    const [EmployeeCode, setEmployeeCode] = useState('');
    const [EmployeeName, setEmployeeName] = useState('');
    const [EmployeeNickname, setEmployeeNickname] = useState('');
    const [BankId, setBankId] = useState(0);
    const [BankCode, setBankCode] = useState('');
    const [BankIntName, setBankIntName] = useState('');
    const [BankName, setBankName] = useState('');
    const [ACNumber, setACNumber] = useState('');
    const [BalanceBAIDR, setBalanceBAIDR] = useState(0);
    const [BalanceBAUSD, setBalanceBAUSD] = useState(0);
    const [BalanceSettlementIDR, setBalanceSettlementIDR] = useState(0);
    const [BalanceSettlementUSD, setBalanceSettlementUSD] = useState(0);
    const [BalanceIDR, setBalanceIDR] = useState(0);
    const [BalanceUSD, setBalanceUSD] = useState(0);

    const [BalanceBAIDRReal, setBalanceBAIDRReal] = useState(0);
    const [BalanceBAUSDReal, setBalanceBAUSDReal] = useState(0);
    const [BalanceSettlementIDRReal, setBalanceSettlementIDRReal] = useState(0);
    const [BalanceSettlementUSDReal, setBalanceSettlementUSDReal] = useState(0);
    const [BalanceIDRReal, setBalanceIDRReal] = useState(0);
    const [BalanceUSDReal, setBalanceUSDReal] = useState(0);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Bank Advance Settlement', link: '/Majura/BankAdvanceSettlement', style: '' },
        { label: 'Create Bank Advance Settlement', link: '/Majura/BankAdvanceSettlement/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Bank Advance Settlement', link: '/Majura/BankAdvanceSettlement', style: '' },
        { label: 'Detail Bank Advance Settlement', link: '/Majura/BankAdvanceSettlement/' + BankAdvSetId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Bank Advance Settlement - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BankAdvSetId])

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus.trim() !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const saveLocalData = () => {
        let id = BankAdvSetId ? BankAdvSetId : 0;
        let datePrint = updateDateFormat(PrintedOn, true);
        let dateDelete = updateDateFormat(DeletedOn, true);

        const payload = {
            "bankAdvanceSettlement": {
                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "bankAdvanceId": BankAdvanceId,
                "reference": Reference,
                "employeeId": EmployeeId,
                "bankAdvanceNo": BankAdvanceNo,
                "settlementBankAdvanceNo": SettlementBankAdvanceNo,
                "settlementBankAdvanceNo2": SettlementBankAdvanceNo2,
                "totalUSD": parseFloat(TotalUSD),
                "totalIDR": parseFloat(TotalIDR),
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "exRateId": ExRateId,
                "approved": Approved,
                "printing": Printing,
                "printedOn": datePrint,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "idLama": 0,
                "userCode": userCode,

                "employeeCode": EmployeeCode,
                "employeeName": EmployeeName,
                "bankId": BankId,
                "bankCode": BankCode,
                "bankIntName": BankIntName,
                "bankName": BankName,
                "bankAccNum": ACNumber,
                "balanceBAIDR": BalanceBAIDR,
                "balanceBAUSD": BalanceBAUSD,
                "balanceBASIDR": BalanceSettlementIDR,
                "balanceBASUSD": BalanceSettlementUSD,
                "balanceIDR": BalanceIDR,
                "balanceUSD": BalanceUSD,
            },
        };

        localStorage.setItem(username + "-DataBAS", JSON.stringify(payload));
    }

    const getLocalData = () => {
        let bankAdvanceSettlementData = dataLocal.bankAdvanceSettlement;

        if (BankAdvSetId) {
            setIsEdit(true);
        }
        setInfossNo(bankAdvanceSettlementData.noInfoss);
        setBankAdvanceId(bankAdvanceSettlementData.bankAdvanceId);
        setReference(bankAdvanceSettlementData.reference);
        setEmployeeId(bankAdvanceSettlementData.employeeId);
        setSettlementBankAdvanceNo(bankAdvanceSettlementData.settlementBankAdvanceNo);
        setSettlementBankAdvanceNo2(bankAdvanceSettlementData.settlementBankAdvanceNo2);
        setTotalUSD(bankAdvanceSettlementData.totalUSD);
        setTotalIDR(bankAdvanceSettlementData.totalIDR);
        setRate(convertToNominal(bankAdvanceSettlementData.rate));
        setRateReal(bankAdvanceSettlementData.rate);
        setExRateId(bankAdvanceSettlementData.exRateId);
        setApproved(bankAdvanceSettlementData.approved);
        setPrinting(bankAdvanceSettlementData.printing);
        setDeleted(bankAdvanceSettlementData.deleted);
        handleDocumentStatus(bankAdvanceSettlementData.rowStatus, bankAdvanceSettlementData.isApproved)
        setDateFormat(bankAdvanceSettlementData.exRateDate, setExRateDate);
        setDateFormat(bankAdvanceSettlementData.printedOn, setPrintedOn);
        setDateFormat(bankAdvanceSettlementData.deletedOn, setDeletedOn);

        setBankAdvanceNo(bankAdvanceSettlementData.bankAdvanceNo);
        setEmployeeCode(bankAdvanceSettlementData.employeeCode);
        setEmployeeName(bankAdvanceSettlementData.employeeName);
        setBankId(bankAdvanceSettlementData.bankId);
        setBankCode(bankAdvanceSettlementData.bankCode);
        setBankIntName(bankAdvanceSettlementData.bankIntName);
        setBankName(bankAdvanceSettlementData.bankName);
        setACNumber(bankAdvanceSettlementData.bankAccNum);

        setBalanceBAIDR(convertToNominal(bankAdvanceSettlementData.balanceBAIDR));
        setBalanceBAUSD(convertToNominal(bankAdvanceSettlementData.balanceBAUSD));
        setBalanceSettlementIDR(convertToNominal(bankAdvanceSettlementData.balanceBASIDR));
        setBalanceSettlementUSD(convertToNominal(bankAdvanceSettlementData.balanceBASUSD));
        setBalanceIDR(convertToNominal(bankAdvanceSettlementData.balanceIDR));
        setBalanceUSD(convertToNominal(bankAdvanceSettlementData.balanceUSD));

        setBalanceBAIDRReal(bankAdvanceSettlementData.balanceBAIDR);
        setBalanceBAUSDReal(bankAdvanceSettlementData.balanceBAUSD);
        setBalanceSettlementIDRReal(bankAdvanceSettlementData.balanceBASIDR);
        setBalanceSettlementUSDReal(bankAdvanceSettlementData.balanceBASUSD);
        setBalanceIDRReal(bankAdvanceSettlementData.balanceIDR);
        setBalanceUSDReal(bankAdvanceSettlementData.balanceUSD);

        calculateBalance(0, 0, bankAdvanceSettlementData.balanceCAIDR, bankAdvanceSettlementData.balanceCAUSD, bankAdvanceSettlementData.balanceCASIDR, bankAdvanceSettlementData.balanceCASUSD);

        localStorage.removeItem(username + '-DataBAS');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = () => {
        ApiService.FinAccPostById(paramBankAdvSettlement, BankAdvSetId)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    let bankAdvanceSettlementData = response.data.data.bankAdvanceSettlement;
                    setInfossNo(bankAdvanceSettlementData.noInfoss);
                    setBankAdvanceId(bankAdvanceSettlementData.bankAdvanceId);
                    setReference(bankAdvanceSettlementData.reference);
                    setEmployeeId(bankAdvanceSettlementData.employeeId);
                    setSettlementBankAdvanceNo(bankAdvanceSettlementData.settlementBankAdvanceNo);
                    setSettlementBankAdvanceNo2(bankAdvanceSettlementData.settlementBankAdvanceNo2);
                    setRate(convertToNominal(bankAdvanceSettlementData.rate));
                    setRateReal(bankAdvanceSettlementData.rate);
                    setExRateId(bankAdvanceSettlementData.exRateId);
                    setApproved(bankAdvanceSettlementData.approved);
                    setPrinting(bankAdvanceSettlementData.printing);
                    setDeleted(bankAdvanceSettlementData.deleted);
                    handleDocumentStatus(bankAdvanceSettlementData.rowStatus, bankAdvanceSettlementData.isApproved)
                    setDateFormat(bankAdvanceSettlementData.exRateDate, setExRateDate);
                    setDateFormat(bankAdvanceSettlementData.printedOn, setPrintedOn);
                    setDateFormat(bankAdvanceSettlementData.deletedOn, setDeletedOn);

                    setBankAdvanceNo(bankAdvanceSettlementData.bankAdvanceNo);
                    setEmployeeCode(bankAdvanceSettlementData.employeeCode);
                    setEmployeeName(bankAdvanceSettlementData.employeeName);
                    setBankId(bankAdvanceSettlementData.bankId);
                    setBankCode(bankAdvanceSettlementData.bankCode);
                    setBankIntName(bankAdvanceSettlementData.bankIntName);
                    setBankName(bankAdvanceSettlementData.bankName);
                    setACNumber(bankAdvanceSettlementData.bankAccNum);

                    setBalanceSettlementIDR(convertToNominal(bankAdvanceSettlementData.balanceBASIDR));
                    setBalanceSettlementUSD(convertToNominal(bankAdvanceSettlementData.balanceBASUSD));
                    setBalanceBAIDR(convertToNominal(bankAdvanceSettlementData.balanceBAIDR));
                    setBalanceBAUSD(convertToNominal(bankAdvanceSettlementData.balanceBAUSD));
                    setBalanceIDR(convertToNominal(bankAdvanceSettlementData.balanceIDR));
                    setBalanceUSD(convertToNominal(bankAdvanceSettlementData.balanceUSD));
                    setTotalUSD(bankAdvanceSettlementData.totalUSD);
                    setTotalIDR(bankAdvanceSettlementData.totalIDR);

                    setBalanceBAIDRReal(bankAdvanceSettlementData.balanceBAIDR);
                    setBalanceBAUSDReal(bankAdvanceSettlementData.balanceBAUSD);
                    setBalanceIDRReal(bankAdvanceSettlementData.balanceIDR);
                    setBalanceUSDReal(bankAdvanceSettlementData.balanceUSD);
                    setBalanceSettlementIDRReal((bankAdvanceSettlementData.balanceBASIDR - bankAdvanceSettlementData.totalIDR));
                    setBalanceSettlementUSDReal((bankAdvanceSettlementData.balanceBASUSD - bankAdvanceSettlementData.totalUSD));

                    calculateBalance(bankAdvanceSettlementData.totalIDR, bankAdvanceSettlementData.totalUSD, bankAdvanceSettlementData.balanceBAIDR, bankAdvanceSettlementData.balanceBAUSD, (bankAdvanceSettlementData.balanceBASIDR - bankAdvanceSettlementData.totalIDR), (bankAdvanceSettlementData.balanceBASUSD - bankAdvanceSettlementData.totalUSD));

                    if (!dataLocal) {
                        setIsLoadingBackDrop(false);
                    }
                }
                else {
                    handleBack();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FBAD')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        if (dataLocal) {
                            setIsLoadingBackDrop(true);
                            setTimeout(function () { getLocalData(); }, 2000);
                        } else {
                            if (!BankAdvSetId) {
                                getDateExchRate();
                                handleDocumentStatus();
                            } else {
                                getData();
                            }
                        }
                    }
                    else {
                        handleBack();
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            if (!dataLocal) {
                setIsLoadingBackDrop(false);
            }

            history('/Majura');
        }
    }

    const handleSave = (isPrint = false) => {
        setIsLoadingBackDrop(true);
        let id = BankAdvSetId ? BankAdvSetId : 0;
        let datePrint = updateDateFormat(PrintedOn, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let balanceBAIDR = convertToNumeric(BalanceBAIDR);
        let balanceBAUSD = convertToNumeric(BalanceBAUSD);
        let balanceBASIDR = convertToNumeric(BalanceSettlementIDR);
        let balanceBASUSD = convertToNumeric(BalanceSettlementUSD);

        const payload = {
            "bankAdvanceSettlement": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                'noInfoss': infossNo,
                "bankAdvanceId": BankAdvanceId,
                "bankAdvanceNo": BankAdvanceNo,
                "settlementBankAdvanceNo": SettlementBankAdvanceNo,
                "settlementBankAdvanceNo2": SettlementBankAdvanceNo2,
                "reference": Reference,
                "employeeId": EmployeeId,

                "totalUSD": parseFloat(TotalUSD),
                "totalIDR": parseFloat(TotalIDR),
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "exRateId": ExRateId,
                "approved": Approved,
                "printing": Printing,
                "printedOn": datePrint,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "idLama": 0,
                "userCode": userCode,

                "employeeCode": EmployeeCode,
                "employeeName": EmployeeName,
                "bankId": BankId,
                "bankCode": BankCode,
                "bankIntName": BankIntName,
                "bankName": BankName,
                "bankAccNum": ACNumber,
                "balanceBAIDR": balanceBAIDR,
                "balanceBAUSD": balanceBAUSD,
                "balanceBASIDR": balanceBASIDR,
                "balanceBASUSD": balanceBASUSD,
                "balanceIDR": BalanceIDRReal,
                "balanceUSD": BalanceUSDReal,
            },
        }

        let errorMessage = undefined;
        if (payload.bankAdvanceSettlement.bankAdvanceId === 0) {
            errorMessage = 'Please Select The Cash Advance Data!';
        }
        else if (payload.bankAdvanceSettlement.totalUSD === 0 && payload.bankAdvanceSettlement.totalIDR === 0) {
            errorMessage = 'Please Input Total Settlement!';
        }
        else if (payload.bankAdvanceSettlement.balanceIDR < 0 || payload.bankAdvanceSettlement.balanceUSD < 0) {
            errorMessage = 'The Balance Need to Be Positive!';
        }
        else if (payload.bankAdvanceSettlement.noInfoss === '' && isInfossNo) {
            errorMessage = 'Please Fill Infoss Number!';
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(payload, paramBankAdvSettlement, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (!BankAdvSetId) {
                            ApiService.CreateFinAcc(paramBankAdvSettlement, payload).then((res) => {
                                if (res.data.code === 200) {
                                    message = 'Create Data Success';
                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            setIsLoadingBackDrop(false);
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Majura/BankAdvanceSettlement/' + JSON.parse(localStorage.getItem("tempId")));
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramBankAdvSettlement, payload).then((res) => {
                                if (res.data.code === 200) {
                                    if (!isPrint) {
                                        message = 'Update Data Success';
                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                setIsLoadingBackDrop(false);
                                                history('/Majura/BankAdvanceSettlement/' + BankAdvSetId);
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                    else {
                                        Print();
                                    }
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data,' + res.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    const handleBack = () => {
        history('/Majura/BankAdvanceSettlement');
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationBAS() {
        if (titleConfirmation === 'Print') {
            checkDataCondition('Print');
        }
        setIsModalConfirmation(false);
    }

    const checkDataCondition = async (isAction) => {
        if (!BankAdvSetId && isAction !== 'Save') {
            showToast({ type: 'error', message: 'Create The Data First!' });
            return;
        }

        try {
            setIsLoadingBackDrop(true);

            if (isAction === 'Save' && !BankAdvSetId) {
                handleSave(false);
                return;
            }

            const response = BankAdvSetId ? await ApiService.FinAccPostById(paramBankAdvSettlement, BankAdvSetId) : null;

            if (response && response.data.code !== 200) {
                throw new Error('Failed to fetch data');
            }

            const data = response ? response.data.data.bankAdvanceSettlement : null;

            const showErrorToast = (message) => {
                showToast({ type: 'error', message });
            };

            const handlePrintPDF = () => {
                getData();
                setTitleConfirmation('Print');
                setTitleModalConfirmation('Bank Advance Settlement');
                handleClickConfirmation();
            };

            switch (isAction) {
                case 'Save':
                    if (data && data.printing > 0) {
                        getData();
                        showErrorToast('Data Already Printed, Cannot Be Edited!');
                    } else {
                        handleSave(false);
                    }
                    break;
                case 'Print':
                    getData();
                    data?.printing > 0 ? Print() : handleSave(true);
                    break;
                case 'PrintPDF':
                    handlePrintPDF();
                    break;
                default:
                    showErrorToast('Invalid Action!');
                    break;
            }
        } catch (error) {
            console.error('Error when fetching data:', error);
            showToast({ type: 'error', message: 'Error When Fetching Data, Please Contact The Administrator!' });
        }
    };

    const Print = () => {
        ApiService.FinAccUpdateStatusPrint(paramBankAdvSettlement, BankAdvSetId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code !== 200) {
                showToast({ type: 'error', message: message });
            }
            else {
                setIsLoadingBackDrop(true);
                let reportLink = 'Transactions/Finance/BankAdvanceSettlementReport/BankAdvanceSettlementReport/PrintBankAdvanceSettlement?Id=' + BankAdvSetId;
                PrintPDF(setIsLoadingBackDrop, 'Bank Advance Settlement', reportLink, [], true);
            }
        });
    }

    const AddNew = () => {
        window.open('/Majura/BankAdvanceSettlement/Create', newWindowConfig);
    }

    const getDateExchRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);

                    GetFormatCurrency();
                }
                else {
                    handleBack();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const GetFormatCurrency = () => {
        setBalanceBAIDR(convertToNominal(0));
        setBalanceBAUSD(convertToNominal(0));
        setBalanceSettlementIDR(convertToNominal(0));
        setBalanceSettlementUSD(convertToNominal(0));
        setBalanceIDR(convertToNominal(0));
        setBalanceUSD(convertToNominal(0));

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    function calculateBalance(TotalIDR, TotalUSD, BalanceBAIDR, BalanceBAUSD, BalanceSetIDR, BalanceSetUSD) {
        let balanceIDR = 0;
        let balanceUSD = 0;
        balanceIDR = parseFloat(BalanceBAIDR) - parseFloat(BalanceSetIDR) - parseFloat(TotalIDR);
        balanceUSD = parseFloat(BalanceBAUSD) - parseFloat(BalanceSetUSD) - parseFloat(TotalUSD);

        setBalanceIDRReal(balanceIDR);
        setBalanceUSDReal(balanceUSD);
        setBalanceIDR(convertToNominal(balanceIDR));
        setBalanceUSD(convertToNominal(balanceUSD));
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditBankAdvSettlement
                Save={() => checkDataCondition('Save')}
                Back={handleBack}
                Print={() => checkDataCondition('PrintPDF')}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                AddNew={AddNew}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            BankAdvSetId ?
                                'Bank Advance Settlement (' + Reference + ')'
                                :
                                'Bank Advance Settlement ( New Document )'
                        }
                        BreadcrumbsItems={BankAdvSetId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={handleBack}
                        documentStatus={documentStatus}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <BankAdvSettlementHeader
                                isEdit={isEdit}
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                BankAdvanceId={BankAdvanceId}
                                setBankAdvanceId={setBankAdvanceId}
                                Reference={Reference}
                                setReference={setReference}
                                EmployeeId={EmployeeId}
                                setEmployeeId={setEmployeeId}
                                SettlementBankAdvanceNo={SettlementBankAdvanceNo}
                                setSettlementBankAdvanceNo={setSettlementBankAdvanceNo}
                                SettlementBankAdvanceNo2={SettlementBankAdvanceNo2}
                                setSettlementBankAdvanceNo2={setSettlementBankAdvanceNo2}
                                Rate={Rate}
                                setRate={setRate}
                                ExRateDate={ExRateDate}
                                setExRateDate={setExRateDate}
                                ExRateId={ExRateId}
                                setExRateId={setExRateId}
                                Approved={Approved}
                                setApproved={setApproved}
                                Printing={Printing}
                                setPrinting={setPrinting}
                                PrintedOn={PrintedOn}
                                setPrintedOn={setPrintedOn}

                                BankAdvanceNo={BankAdvanceNo}
                                setBankAdvanceNo={setBankAdvanceNo}
                                BankId={BankId}
                                setBankId={setBankId}
                                BankCode={BankCode}
                                setBankCode={setBankCode}
                                BankIntName={BankIntName}
                                setBankIntName={setBankIntName}
                                BankName={BankName}
                                setBankName={setBankName}
                                ACNumber={ACNumber}
                                setACNumber={setACNumber}
                                EmployeeCode={EmployeeCode}
                                setEmployeeCode={setEmployeeCode}
                                EmployeeName={EmployeeName}
                                setEmployeeName={setEmployeeName}
                                EmployeeNickname={EmployeeNickname}
                                setEmployeeNickname={setEmployeeNickname}
                                TotalUSD={TotalUSD}
                                setTotalUSD={setTotalUSD}
                                TotalIDR={TotalIDR}
                                setTotalIDR={setTotalIDR}

                                setBalanceBAIDR={setBalanceBAIDR}
                                setBalanceBAUSD={setBalanceBAUSD}
                                setBalanceBAIDRReal={setBalanceBAIDRReal}
                                setBalanceBAUSDReal={setBalanceBAUSDReal}
                                setBalanceSettlementIDR={setBalanceSettlementIDR}
                                setBalanceSettlementUSD={setBalanceSettlementUSD}
                                setBalanceSettlementIDRReal={setBalanceSettlementIDRReal}
                                setBalanceSettlementUSDReal={setBalanceSettlementUSDReal}
                                setBalanceIDR={setBalanceIDR}
                                setBalanceUSD={setBalanceUSD}

                                calculateBalance={calculateBalance}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <BankAdvSettlementDetail
                                EmployeeId={EmployeeId}
                                TotalUSD={TotalUSD}
                                setTotalUSD={setTotalUSD}
                                TotalIDR={TotalIDR}
                                setTotalIDR={setTotalIDR}

                                BankAdvanceId={BankAdvanceId}
                                BalanceBAIDR={BalanceBAIDR}
                                setBalanceBAIDR={setBalanceBAIDR}
                                BalanceBAUSD={BalanceBAUSD}
                                setBalanceBAUSD={setBalanceBAUSD}
                                BalanceSettlementIDR={BalanceSettlementIDR}
                                setBalanceSettlementIDR={setBalanceSettlementIDR}
                                BalanceSettlementIDRReal={BalanceSettlementIDRReal}
                                BalanceSettlementUSDReal={BalanceSettlementUSDReal}
                                BalanceBAIDRReal={BalanceBAIDRReal}
                                BalanceBAUSDReal={BalanceBAUSDReal}
                                BalanceSettlementUSD={BalanceSettlementUSD}
                                setBalanceSettlementUSD={setBalanceSettlementUSD}
                                BalanceIDR={BalanceIDR}
                                setBalanceIDR={setBalanceIDR}
                                BalanceUSD={BalanceUSD}
                                setBalanceUSD={setBalanceUSD}
                                setBalanceSettlementIDRReal={setBalanceSettlementIDRReal}
                                setBalanceSettlementUSDReal={setBalanceSettlementUSDReal}

                                calculateBalance={calculateBalance}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationBAS}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditBankAdvSettlement