import { Grid, IconButton, Stack, InputAdornment } from '@mui/material'
import React, { useState } from 'react';

import ModalPv from '../Modal/ModalPv';
import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import { Icon, Input } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const PaymentPPh23Header = (props) => {
    const [isModalPv, setIsModalPv] = useState(false);
    const [titleModalPv, setTitleModalPv] = useState("");

    const paramPv = "paymentVoucher/paymentVoucher";
    const [isLoadingPv, setIsLoadingPv] = useState(false);
    const [dataPv, setDataPv] = useState([]);
    const [dataMapPv, setDataMapPv] = useState([]);
    const [columnDataPv, setColumnDataPv] = useState([]);
    const [SelectedDataPv, setSelectedDataPv] = useState({});
    const [totalRowsPv, setTotalRowsPv] = useState(50);

    const getDataPv = (pageNumber, pageSize, filters) => {
        setIsLoadingPv(true);
        let params = [{ 'attr': 'isPPh23', 'value': 'true' }]
        ApiService.FinAccPostByPage(paramPv, "SEARCH", pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataPv({});
                    setDataPv(response.data.data.paymentVoucher);

                    let temp = response.data.data.paymentVoucher;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        };
                        return indexedTemp;
                    });

                    setDataMapPv(indexed);
                    setColumnDataPv(response.data.data.columns.headerColumns);
                    setTotalRowsPv(response.data.totalRowCount);

                    setIsLoadingPv(false);
                }
                else {
                    setIsLoadingPv(false);
                }
            })
            .catch(function (error) {
                setIsLoadingPv(false);
                console.error("error saat fetch", error);
            });
    };

    const searchPV = (data) => {
        if (data === 'search') {
            if (isModalPv) {
                setIsModalPv(false);
            }
            else {
                setIsModalPv(true);
                setTitleModalPv("Payment Voucher");
                getDataPv(1, 50);
            }
        }
        else if (data === 'delete') {
            props.setPvId(0);
            props.setPvNo('');
            props.setPvRef('');
            props.setNameWP('');
            props.setNpwp('');
            props.setPph23Amount(convertToNominal(0));
            props.setTotalPph23Amount(0);
            props.setJenisPenghasilan('D');
            props.setJumlahPenghasilan(convertToNominal(0));
            props.setTarifTambahan(0);
            props.setTarif(15);
            props.setPPhPotongan(convertToNominal(0));
            props.setJasaLain('');
            props.setJasaLainId(0);
            props.setJasaLainAmount(0);
        }
    };

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Infoss Number"
                                    value={props.infossNo}
                                    onChange={(e) => props.setInfossNo(e.target.value)}
                                    hidden={!isInfossNo ? true : props.isAdjustment}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginBottom: '16px !important'
                                        }
                                    }}
                                    message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Payment PPh23 No"
                                    value={props.paymentNo}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Reference"
                                    value={props.reference}
                                    onChange={(e) => props.setReference(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label="PV No"
                                        value={props.pvNo}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%',
                                                marginTop: '24px'
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.pvRef}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchPV('delete')}
                                                        hidden={props.isEdit ? true : props.pvNo ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => searchPV('search')}
                                                        hidden={props.isEdit}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>

                                <Input
                                    label="PPh23 Amount"
                                    value={props.pph23Amount}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Customer"
                                    value={props.nameWP}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Stack spacing={'16px'} direction="row">
                                    <Input
                                        label="NPWP"
                                        value={props.npwp}
                                        disabled
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />

                                    {/* <Link
                                        component="button"
                                        variant="body2"
                                        width={150}
                                        onClick={() => {
                                        console.info("I'm a button.");
                                        }}
                                    >
                                        Update NPWP
                                    </Link> */}
                                </Stack>

                                <Input
                                    label={'Address'}
                                    value={props.address}
                                    onChange={(e) => props.setAddress(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={props.isEdit}
                                    inputBaseProps={{
                                        rows: 5,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>

            {
                isModalPv && (
                    <ModalPv
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                        isModal={isModalPv}
                        handleClick={searchPV}
                        titleModal={titleModalPv}
                        setIsModal={setIsModalPv}

                        isLoading={isLoadingPv}
                        data={dataPv}
                        dataMap={dataMapPv}
                        columnData={columnDataPv}
                        SelectedData={SelectedDataPv}
                        totalRows={totalRowsPv}
                        setSelectedData={setSelectedDataPv}
                        getData={getDataPv}

                        setPvId={props.setPvId}
                        setPvNo={props.setPvNo}
                        setPvRef={props.setPvRef}
                        setJasaLain={props.setJasaLain}
                        setJasaLainId={props.setJasaLainId}
                        setJasaLainAmount={props.setJasaLainAmount}
                        setNameWP={props.setNameWP}
                        setNpwp={props.setNpwp}
                        setPrintedOn={props.setPrintedOn}
                        setPph23Amount={props.setPph23Amount}
                        setTotalPph23Amount={props.setTotalPph23Amount}
                        setJenisPenghasilan={props.setJenisPenghasilan}
                        setJumlahPenghasilan={props.setJumlahPenghasilan}
                        setTarifTambahan={props.setTarifTambahan}
                        setTarif={props.setTarif}
                        setPPhPotongan={props.setPPhPotongan}
                    />
                )
            }
        </div>
    )
}

export default PaymentPPh23Header