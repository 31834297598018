import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableZ from '../../../../components/Tables/TableZ';
import { Grid, Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ModalDelete from '../../../../components/Modals/ModalDelete';

import ButtonMemorial from './Buttons/ButtonMemorial';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import TableCheck from '../../../../components/Tables/TableCheck';
import TableX from '../../../../components/Tables/TableX';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { updateDateFormat } from '../../../../utils/date';
import { newWindowConfig } from '../../../../utils/masks.jsx';
import ModalRePostGL from '../../../../components/Modals/ModalRePostGL.jsx';
import { handleFailedGL, handleRePost } from '../../../../utils/constantVariable.jsx';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction.jsx';

const MemorialList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramMemorial = 'memorial/memorial';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(0);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const [DropDownTitle, setDropDownTitle] = useState('All');
    const [isDraft, setIsDraft] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [isUnApprove, setIsUnApprove] = useState(false);
    const [dataCheck, setDataCheck] = useState([]);

    const [accessLog, setAccessLog] = useState(false);

    const [isModalRePost, setIsModalRePost] = useState(false);
    const [failedData, setFailedData] = useState({});

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Memorial', link: '/Majura/Memorial', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const dropDownList = [
        { label: 'All', icon: 'search-doc' },
        { label: 'Active', icon: 'documents' },
        { label: 'Posted', icon: 'posted' },
        { label: 'Deleted', icon: 'status-deleted' },
    ]

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        localStorage.setItem('isUnApprove', JSON.stringify(false));
        localStorage.setItem('isApprove', JSON.stringify(false));
        document.title = 'Memorial - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let check = 0;
        let params = [];
        let filter = [];

        if (JSON.parse(localStorage.getItem('isApprove')) === true) {
            check = 1;
            params = [...params,
            {
                "attr": "printing",
                "value": "1",
                "mathSign": ">="
            },
            {
                "attr": "verifyAcc",
                "value": "false",
            }
            ]
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
        }
        else if (JSON.parse(localStorage.getItem('isUnApprove')) === true) {
            check = 1;
            params = [...params,
            {
                "attr": "verifyAcc",
                "value": "true",
            }
            ]
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
        }
        else {
            check = 0;
        }


        if (check === 0) {
            if (data) {
                if (data === 'All') {
                    filter = [...filters]
                }
                else if (data === 'Deleted') {
                    filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
                }
                else if (data === 'Active') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'false' },
                    ]
                }
                else if (data === 'Posted') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'true' },
                    ]
                }
            }
            else {
                if (DropDownTitle === 'All') {
                    filter = [...filters]
                }
                else if (DropDownTitle === 'Deleted') {
                    filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
                }
                else if (DropDownTitle === 'Active') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'false' },
                    ]
                }
                else if (DropDownTitle === 'Posted') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'true' },
                    ]
                }
            }
        }

        ApiService.ListFinAccPostByPage(paramMemorial, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.memorial
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    localStorage.setItem('finAccTemp', JSON.stringify(indexed));
                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    // addToast('error', response.data.message);
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FMEM')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.userAccess;
                        setDataRegUserAccess(response.data.data.userAccess);

                        data.forEach(element => {
                            if (element.accessCode === 'LOG') {
                                setAccessLog(true);
                            }
                        });

                        getData(0, 50, []);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false)
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleReset = () => {
        emptyState();
        getData(0, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
            setIsDraft(false);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationME() {
        if (titleConfirmation === 'Print') {
            if (isDraft === false) {
                Print();
            }
            else {
                let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + dataSelected.id + '&isDraft=' + isDraft + '&typeReport=pdf';
                PrintPDF(setIsLoadingBackDrop, 'Memorial', reportLink);
            }
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Print Excel') {
            setIsLoadingBackDrop(true);
            let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + dataSelected.id + '&isDraft=false&typeReport=xls';
            PrintExcel(setIsLoadingBackDrop, 'Memorial', reportLink);
        }
        else if (titleConfirmation === 'Un-Approve') {
            UnApprove();
        }
        setIsModalConfirmation(false);
    }

    const AddM = () => {
        window.open('/Majura/Memorial/Create', newWindowConfig);
    }

    const EditM = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: "Please Select Data!" });
        }
        else {
            window.open('/Majura/Memorial/' + SelectedData.id, newWindowConfig);
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: "Reload Data" });
        emptyState();
        getData(0, 50, []);
    }

    const DeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: "Please Select Data!" });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.verifyAcc) {
                    showToast({ type: 'error', message: 'Data Already Approved!' });
                }
                else {
                    setTitleModalDelete('Memorial');
                    handleClick();
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted' });
            }
        }
    }

    const DeleteM = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccDelete(paramMemorial, SelectedData.id).then((res) => {
            if (res.data.code === 200) {
                setIsLoadingBackDrop(false);
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Delete Data Success' });
                setIsModalDelete(false);
            }
            else {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Delete Data Failed, ' + res.data.message });
                setIsModalDelete(false);
            }
        });
    }

    const OpenLogData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.isPosted === true) {
                setIsLoadingBackDrop(true);

                ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, updateDateFormat(SelectedData.printedOn))
                    .then((response) => {
                        let data = response.data.data.closingPeriod;
                        history('/Majura/TransactionLogs/Memorial/' + SelectedData.id + '/' + data.period + '/' + data.yearPeriod);
                    })
                    .catch(function (error) {
                        setIsLoadingBackDrop(false);
                    })
            }
            else {
                showToast({ type: 'error', message: 'Please Approve This Memorial First!' });
            }
        }
    }

    const PrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.difference === 0) {
                    setTitleConfirmation('Print')
                    setTitleModalConfirmation('Memorial');
                    handleClickConfirmation();
                }
                else {
                    showToast({ type: 'error', message: 'Can\'t Print Memorial Because Data Not Balance!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const Print = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusPrint(paramMemorial, dataSelected.id).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + dataSelected.id + '&isDraft=' + isDraft + '&typeReport=pdf';
                PrintPDF(setIsLoadingBackDrop, 'Memorial', reportLink);

                getData(numPage, rowsCount, filter);
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    const handleApprove = () => {
        if (isApprove === false) {
            document.title = 'Approve Memorial - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isApprove', JSON.stringify(true));
            setIsApprove(true);
        }
        else {
            document.title = 'Memorial - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isApprove', JSON.stringify(false));
            setIsApprove(false);
        }

        handleReset();
    }

    const ApproveData = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Approve')
            setTitleModalConfirmation('Memorial');
            handleClickConfirmation();
        }
    }

    const Approve = () => {
        let data = '';
        setIsLoadingBackDrop(true);
        dataCheck.forEach(el => {
            if (el.id === dataCheck[dataCheck.length - 1].id) {
                data = data + 'ME:' + el.id;
            }
            else {
                data = data + 'ME:' + el.id + ',';
            }
        });

        const payload = { "generalLedger": { "processedData": data } };
        ApiService.FinAccCustomPost(paramGL + '/MultiVerify', payload)
            .then((res) => {
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: 'Approve Memorial Success!' });

                    setTimeout(
                        function () {
                            setDataCheck([]);
                            setIsLoadingBackDrop(false);
                            getData(numPage, rowsCount, filter);
                        },
                        1250);
                }
                else {
                    setDataCheck([]);
                    setIsLoadingBackDrop(false);
                    getData(numPage, rowsCount, filter);
                    showToast({ type: 'error', message: res.data.message });
                }
            })
            .catch((err) => {
                setDataCheck([]);
                setIsLoadingBackDrop(false);
                getData(numPage, rowsCount, filter);
                showToast({ type: 'error', message: 'Can\'t Approve Memorial!, ' + err });
            })
    }

    const handleUnApprove = () => {
        if (isUnApprove === false) {
            document.title = 'Un-Approve Memorial - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isUnApprove', JSON.stringify(true));
            setIsUnApprove(true);
        }
        else {
            document.title = 'Memorial - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isUnApprove', JSON.stringify(false));
            setIsUnApprove(false);
        }

        handleReset();
    }

    const UnApproveData = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Un-Approve')
            setTitleModalConfirmation('Memorial');
            handleClickConfirmation();
        }
    }

    const UnApprove = () => {
        let check = 0;
        setIsLoadingBackDrop(true);
        dataCheck.forEach(element => {
            if (check === 0) {
                ApiService.FinAccUpdateStatusUnApprovedAcc(paramMemorial, element.id)
                    .then((res) => {
                        let message = '';
                        message = res.data.message;
                        if (res.data.code === 200) {
                            // CreateGL(res.data.data.ContraId);

                            if (check === 0 && dataCheck[dataCheck.length - 1].id === element.id) {
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'success', message: 'Un-Approve Memorial Success!' });
                                getData(numPage, rowsCount, filter);
                            }
                        }
                        else {
                            check = 1;
                            setDataCheck([]);
                            setIsLoadingBackDrop(false);
                            getData(numPage, rowsCount, filter);
                            showToast({ type: 'error', message: message });
                        }
                    })
                    .catch(error => {
                        check = 1;
                        setDataCheck([]);
                        setIsLoadingBackDrop(false);
                        getData(numPage, rowsCount, filter);
                        showToast({ type: 'error', message: 'Can\'t Un-Approve Memorial, ' + error.toString() });
                    });
            }
        });
    }

    // const CreateGL = (id) => {
    //     var data = {
    //         "generalLedger": {
    //             "source": "ME",
    //             "refId": id,
    //             "methodPost": "AUTO",
    //             "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
    //         }
    //     }

    //     ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //         let message = '';
    //         message = res.data.message;
    //         if (res.data.code !== 200) {
    //             showToast({ type: 'error', message: message });
    //         }
    //     });
    // }

    const PrintExcelData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.difference === 0) {
                    setTitleConfirmation('Print Excel')
                    setTitleModalConfirmation('Memorial');
                    handleClickConfirmation();
                }
                else {
                    showToast({ type: 'error', message: 'Can\'t Print Excel Memorial Because Data Not Balance!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted' });
            }
        }
    }

    const handleClickRePost = () => {
        if (isModalRePost === false) {
            setIsModalRePost(true);
        }
        else {
            setIsModalRePost(false);
        }
    }

    const handleGL = (data) => {
        handleFailedGL({
            id: data.id,
            glStatusCode: data.glStatusCode,
            setIsLoadingBackDrop: setIsLoadingBackDrop,
            paramGL: paramGL,
            setFailedData: setFailedData,
            handleClickRePost: handleClickRePost,
            source: 'ME',
        });
    }

    const handlePost = () => {
        handleRePost({
            handleClickRePost: handleClickRePost,
            setIsLoadingBackDrop: setIsLoadingBackDrop,
            failedData: failedData,
            paramGL: paramGL,
            getData: getData,
            numPage: numPage,
            rowsCount: rowsCount,
            filter: filter,
        })
    }

    const emptyState = () => {
        setNumPage(0);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setDataCheck([]);
    };

    function CellDoubleClick(row) {
        EditM();
    };

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(numPage, rowsCount, filter, sortArr, data);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(0);
        setRowsCount(value);
        getData(0, value, filter);
    }

    const ButtonComponents = () => {
        const buttonProps = {
            AddM,
            EditM,
            isApprove,
            ReloadData,
            DeleteData,
            OpenLogData,
            isUnApprove,
            HandleStatus,
            handleApprove,
            DropDownTitle,
            handleUnApprove,
            Print: PrintData,
            DataRegUserAccess,
            Approve: ApproveData,
            UnApprove: UnApproveData,
            DropDownList: dropDownList,
            PrintExcel: PrintExcelData,
        };

        return <ButtonMemorial {...buttonProps} />;
    };

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    isJobType={false}
                    btnPageRef={btnPageRef}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                    title={!isApprove && !isUnApprove ? 'Memorial' : isApprove ? 'Approve Memorial' : 'Un-Approve Memorial'}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2',
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        {
                                            !isApprove && !isUnApprove ?
                                                <>
                                                    {
                                                        accessLog ?
                                                            <TableZ
                                                                goTo={goTo}
                                                                source={'ME'}
                                                                stateX={stateX}
                                                                getData={getData}
                                                                sortArr={sortArr}
                                                                setGoTo={setGoTo}
                                                                dataMap={dataMap}
                                                                rowsCount={rowsCount}
                                                                setFilter={setFilter}
                                                                setStateX={setStateX}
                                                                setNumPage={setNumPage}
                                                                columnData={columnData}
                                                                setDataMap={setDataMap}
                                                                setSortArr={setSortArr}
                                                                localVar={'finAccTemp'}
                                                                setFilterBy={setFilterBy}
                                                                setRowsCount={setRowsCount}
                                                                SelectedData={SelectedData}
                                                                handleFailedGL={handleGL}
                                                                setSelectedData={setSelectedData}
                                                                CellDoubleClick={CellDoubleClick}
                                                                isLoadingBackDrop={isLoadingBackDrop}
                                                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                                            />
                                                            :
                                                            <TableX
                                                                goTo={goTo}
                                                                stateX={stateX}
                                                                uniqueKey={'id'}
                                                                getData={getData}
                                                                dataMap={dataMap}
                                                                sortArr={sortArr}
                                                                setGoTo={setGoTo}
                                                                rowsCount={rowsCount}
                                                                setFilter={setFilter}
                                                                setStateX={setStateX}
                                                                setNumPage={setNumPage}
                                                                setSortArr={setSortArr}
                                                                columnData={columnData}
                                                                setFilterBy={setFilterBy}
                                                                setRowsCount={setRowsCount}
                                                                SelectedData={SelectedData}
                                                                handleFailedGL={handleGL}
                                                                setSelectedData={setSelectedData}
                                                                CellDoubleClick={CellDoubleClick}
                                                            />
                                                    }
                                                </>
                                                :
                                                <TableCheck
                                                    stateX={stateX}
                                                    uniqueKey={'id'}
                                                    getData={getData}
                                                    dataMap={dataMap}
                                                    sortArr={sortArr}
                                                    setGoTo={setGoTo}
                                                    rowsCount={rowsCount}
                                                    setFilter={setFilter}
                                                    dataCheck={dataCheck}
                                                    setStateX={setStateX}
                                                    setNumPage={setNumPage}
                                                    columnData={columnData}
                                                    setSortArr={setSortArr}
                                                    setFilterBy={setFilterBy}
                                                    setRowsCount={setRowsCount}
                                                    setDataCheck={setDataCheck}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                />
                                        }
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        open={isLoadingBackDrop}
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalDelete && (
                    <ModalDelete
                        DeleteData={DeleteM}
                        isModal={isModalDelete}
                        handleClick={handleClick}
                        titleModal={titleModalDelete}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isDraft={isDraft}
                        setIsDraft={setIsDraft}
                        title={titleConfirmation}
                        isModal={isModalConfirmation}
                        ConfirmationData={ConfirmationME}
                        titleModal={titleModalConfirmation}
                        handleClick={handleClickConfirmation}
                    />
                )
            }

            {
                isModalRePost && (
                    <ModalRePostGL
                        isModal={isModalRePost}
                        handleRePost={handlePost}
                        transactionData={failedData}
                        handleClick={handleClickRePost}
                    />
                )
            }
        </Box>
    )
})

export default MemorialList