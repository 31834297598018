import React, { forwardRef, useImperativeHandle } from 'react';
import { branchId, companyId, countryId, userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { CustomDatePicker, Input, showToast } from '../../../components/ui';
import { defaultDate } from '../../../utils/date';

const CreateEditSeriFP = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "seriFP": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataSeriFP", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const convertDate = (dt) => {
        if (!dt) return

        const newDate = new Date(dt)
        const formattedDate = newDate.toLocaleDateString("sv-se").split('T')[0]

        return formattedDate
    }

    const validationSchema = Yup.object().shape({
        sfpHeader: Yup.string().trim().required('This field is required.'),
        sfpStartedOn: Yup.date().required('This field is required.'),
        sfpNoFrom: Yup.number().min(1, 'Must be greater than 0.').required('This field is required.'),
        sfpNoTo: Yup.number().min(1, 'Must be greater than 0.').required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            sfpStartedOn: selectedData?.sfpStartedOn ? convertDate(selectedData.sfpStartedOn) : ''
        } : {
            sfpHeader: '',
            sfpStartedOn: '',
            sfpNoFrom: '',
            sfpNoTo: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.sfpHeader === '') {
            return showToast({ type: 'error', message: 'Header is Required!' });
        }

        if (payload?.sfpStartedOn === '') {
            return showToast({ type: 'error', message: 'Started On is Required!' });
        }

        if (payload?.sfpNoFrom === '') {
            return showToast({ type: 'error', message: 'No From is Required!' });
        }

        if (payload?.sfpNoTo === '') {
            return showToast({ type: 'error', message: 'No To is Required!' });
        }

        const data =
        {
            "seriFP": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": payload?.id,
                "sfpHeader": payload?.sfpHeader,
                "sfpStartedOn": payload?.sfpStartedOn,
                "sfpNoFrom": payload?.sfpNoFrom,
                "sfpNoTo": payload?.sfpNoTo,
                "deleted": false,
                "deletedBy": 0,
                "deletedOn": defaultDate,
                "userCode": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="sfpHeader"
                label="Header"
                value={formik.values.sfpHeader}
                onBlur={formik.handleBlur}
                error={formik.touched.sfpHeader && Boolean(formik.errors.sfpHeader)}
                helperText={formik.touched.sfpHeader && formik.errors.sfpHeader}
                onChange={(e) => formik.setFieldValue('sfpHeader', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <CustomDatePicker
                type={'date'}
                name="sfpStartedOn"
                label="Started On"
                value={formik.values.sfpStartedOn}
                onBlur={formik.handleBlur}
                error={formik.touched.sfpStartedOn && Boolean(formik.errors.sfpStartedOn)}
                helperText={formik.touched.sfpStartedOn && formik.errors.sfpStartedOn}
                onChange={(e) => formik.setFieldValue('sfpStartedOn', e)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                type={'number'}
                name="sfpNoFrom"
                label="No From"
                value={formik.values.sfpNoFrom}
                onBlur={formik.handleBlur}
                error={formik.touched.sfpNoFrom && Boolean(formik.errors.sfpNoFrom)}
                helperText={formik.touched.sfpNoFrom && formik.errors.sfpNoFrom}
                onChange={(e) => formik.setFieldValue('sfpNoFrom', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }
                }}
            />

            <Input
                type={'number'}
                name="sfpNoTo"
                label="No To"
                value={formik.values.sfpNoTo}
                onBlur={formik.handleBlur}
                error={formik.touched.sfpNoTo && Boolean(formik.errors.sfpNoTo)}
                helperText={formik.touched.sfpNoTo && formik.errors.sfpNoTo}
                onChange={(e) => formik.setFieldValue('sfpNoTo', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditSeriFP