import { Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataCOA from '../Selects/SelectDataCOA';
import { CustomDatePicker, Icon, Input, PopUp, Selection, showToast } from '../../../../../components/ui';
import { defaultDate } from '../../../../../utils/date';
import { convertToNumeric } from '../../../../../utils/masks.jsx';

const ModalBank = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramO = "regChartOfAccount/regChartOfAccount";
    const [isLoadingO, setIsLoadingO] = useState(false);
    const [dataO, setDataO] = useState([]);
    const [dataMapO, setDataMapO] = useState([]);
    const [columnDataO, setColumnDataO] = useState([]);
    const [SelectedDataO, setSelectedDataO] = useState({});
    const [totalRowsO, setTotalRowsO] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountCode('');
        props.setAccountName('');
        props.setCheckBG('');
        props.setRemarks('');
        props.setCurrency(1);
        props.setAmount(0);
        props.setDueDate('');
        props.setIsDueDate(false);
    };

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const handleSave = () => {
        let errMessage = undefined;

        if (!props.AccountCode) {
            errMessage = 'Please Select Chart of Account!';
        }
        else if (props.Amount <= 0) {
            errMessage = 'Please Input Amount!';
        }
        else if (props.CheckBG === '') {
            errMessage = 'Please Input Check BG!';
        }

        if (errMessage !== undefined) {
            showToast({ type: 'error', message: errMessage });
        }
        else {
            let sequence = 1;
            let tpId = 0;
            let dueDate = defaultDate;

            if (props.detailEdit) {
                sequence = props.selectedDetail.sequence;
            }
            else {
                if (props.detailSequence > 0) sequence = props.detailSequence + 1;
            }

            if (props.temporaryPaymentId !== undefined || props.temporaryPaymentId !== null) {
                tpId = props.temporaryPaymentId;

                if (tpId === undefined) {
                    tpId = 0;
                }
            }
            else {
                tpId = 0;
            }

            if (props.DueDate !== '') {
                dueDate = props.DueDate;
            }

            let data = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "tpId": tpId,
                "tpJobId": props.selectedShipmentOrder.id,
                "shipmentOrderId": props.selectedShipmentOrder.shipmentOrderId,
                "shipmentNo": props.selectedShipmentOrder.shipmentNo,
                "sequence": sequence,
                "coaCode": props.AccountCode,
                "coaName": props.AccountName,
                "checkBG": props.CheckBG,
                "remarks": props.Remarks,
                "amountCrr": parseInt(props.Currency),
                "amount": convertToNumeric(props.Amount),
                "dueDate": dueDate,
                "statusDueDate": props.StatusDueDate,

                "idLama": 0,
            }

            if (!props.detailEdit) {
                props.setBankData([...props.BankData, data]);

                const newArray = props.ShipmentOrderList.slice();
                newArray.forEach(el => {
                    let tempCountIDR = 0;
                    let tempCountUSD = 0;

                    if (el.shipmentOrderId === props.selectedShipmentOrder.shipmentOrderId) {
                        el.temporaryPaymentJobBanks = [...props.BankData, data];
                    }

                    el.temporaryPaymentJobBanks.forEach(element => {
                        if (element.amountCrr === 0) {
                            tempCountUSD = parseFloat(tempCountUSD) + parseFloat(element.amount);
                        }
                        else if (element.amountCrr === 1) {
                            tempCountIDR = parseFloat(tempCountIDR) + parseFloat(element.amount);
                        }
                    });

                    el.bankUSD = tempCountUSD;
                    el.bankIDR = tempCountIDR;
                });
                props.setShipmentOrderList(newArray);

                props.calculateTotalBank(newArray);
            }
            else {
                const newArr = props.BankData.slice();
                newArr.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.coaCode = data.coaCode;
                        el.coaName = data.coaName;
                        el.checkBG = data.checkBG;
                        el.remarks = data.remarks;
                        el.amountCrr = data.amountCrr;
                        el.amount = data.amount;
                        el.dueDate = data.dueDate;
                    }
                })
                props.setBankData(newArr);

                const newArray = props.ShipmentOrderList.slice();
                newArray.forEach(el => {
                    let tempCountIDR = 0;
                    let tempCountUSD = 0;

                    if (el.shipmentOrderId === props.selectedShipmentOrder.shipmentOrderId) {
                        el.temporaryPaymentJobBanks = newArr;
                    }

                    el.temporaryPaymentJobBanks.forEach(element => {
                        if (element.amountCrr === 0) {
                            tempCountUSD = parseFloat(tempCountUSD) + parseFloat(element.amount);
                        }
                        else if (element.amountCrr === 1) {
                            tempCountIDR = parseFloat(tempCountIDR) + parseFloat(element.amount);
                        }
                    });

                    el.bankUSD = tempCountUSD;
                    el.bankIDR = tempCountIDR;
                });
                props.setShipmentOrderList(newArray);

                props.calculateTotalBank(newArray);
            }

            emptyState();
            handleClose();
            props.setDetailEdit(false);
            props.setSelectedDetail({});
        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingO(true);
        let params = [];
        ApiService.COAPostBySearch(paramO, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataO({})
                    setDataO(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapO(indexed)
                    setColumnDataO(response.data.data.columns)
                    setTotalRowsO(response.data.totalRowCount)

                    setIsLoadingO(false)
                }
                setIsLoadingO(false)
            })
            .catch(function (error) {
                setIsLoadingO(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'16px'}>
                        <Input
                            label={'Account Code'}
                            value={props.AccountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '50%',
                                }
                            }}
                        />

                        <Input
                            value={props.AccountName}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchAccount('delete')}
                                            hidden={props.isApprovedAcc ? true : props.AccountName ? false : true}
                                            disabled={props.isApprovedAcc}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchAccount('search')}
                                            hidden={props.isApprovedAcc}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Check BG"
                        value={props.CheckBG}
                        onChange={(e) => props.setCheckBG(e.target.value)}
                        disabled={props.isApprovedAcc}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                label={'Remarks'}
                value={props.Remarks}
                onChange={(e) => props.setRemarks(e.target.value)}
                disabled={props.isApprovedAcc}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Selection
                        id='0'
                        label={'Currency'}
                        fullWidth={true}
                        placeholder={'Select Currency'}
                        value={props.Currency}
                        onChange={(e) => props.setCurrency(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px !important'
                            }
                        }}
                        disabled={props.isApprovedAcc}
                    >
                        {
                            BankTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Amount"
                        textAlign='right'
                        fullWidth
                        value={props.Amount}
                        onChange={(e) => props.setAmount(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        disabled={props.isApprovedAcc}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <CustomDatePicker
                        label={'Due Date'}
                        type={'date'}
                        value={props.DueDate}
                        onChange={props.setDueDate}
                        disabled={props.isApprovedAcc}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.isApprovedAcc)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingO}
                        totalRows={totalRowsO}
                        setTotalRows={setTotalRowsO}
                        SelectedData={SelectedDataO}
                        setSelectedData={setSelectedDataO}
                        data={dataO}
                        columnData={columnDataO}
                        dataMap={dataMapO}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </>
    )
}

export default ModalBank;