import React, { useState } from 'react'
import Draggable from 'react-draggable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import PropTypes from 'prop-types'

const shrinkStyle = {
  position: 'fixed',
  bottom: 0,
  right: 0,
  maxWidth: 400,
  transform: 'translate(0, 0) !important',
}

const PaperComponent = React.forwardRef((props, ref) => {
  const { id, draggable, isShrink } = props?.customProps;
  const paperProps = { ...props };

  delete paperProps.customProps;

  return (
    <Draggable
      disabled={!draggable || isShrink}
      handle={`#${id}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={ref} sx={{ borderRadius: '12px', ...(paperProps?.sx || {}) }} {...paperProps} />
    </Draggable>
  );
});

function IconButtonWithTooltip({ title, onClick, icon, color }) {
  return (
    <Tooltip title={title}>
      <IconButton aria-label={title.toLowerCase()} color={color} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

const ShrinkableComponent = ({ isShrink, handleShrink }) => (
  <IconButtonWithTooltip
    title={isShrink ? 'Maximize' : 'Minimize'}
    onClick={() => handleShrink(!isShrink)}
    icon={isShrink ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
    color="info"
  />
);

const CloseIconButton = ({ onClose }) => (
  <IconButtonWithTooltip
    title="Close"
    onClick={onClose}
    icon={<CloseIcon />}
    color="error"
  />
);

const PopUp = ({
  // *** Component Props
  id,
  title,
  isOpen,
  onClose,
  fullWidth,
  topButton = false,
  maxWidth,
  disableScrollLock,

  // *** Custom Props
  icon,
  onEscapeKeyDown,
  onBackdropClick,
  draggable = false,
  shrinkable = false,
  children,
  actions,

  // *** MUI Base Props
  baseProps,
}) => {
  const [isShrink, setIsShrink] = useState(false)

  const handleClose = (e, reason) => {
    if (!onEscapeKeyDown || !onBackdropClick) e.preventDefault()
    if (reason === 'backdropClick' && onBackdropClick) onBackdropClick()
    if (reason === 'escapeKeyDown' && onEscapeKeyDown) onEscapeKeyDown()
  }

  const handleShrink = (shrinking) => {
    setIsShrink(shrinking)
    if (shrinking) {
      document.body.style.overflow = 'auto'
    } else if (!disableScrollLock) {
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason) => handleClose(e, reason)}
      PaperComponent={PaperComponent}
      PaperProps={{
        customProps: { id, isShrink, draggable },
        sx: isShrink ? { ...shrinkStyle } : { overflowY: 'unset !important' },
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: isShrink ? 'rgba(0,0,0,0)' : undefined,
          }
        }
      }}
      aria-labelledby={id}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableScrollLock={disableScrollLock}
      {...baseProps}
    >
      <DialogTitle
        id={id}
        sx={{
          cursor: (draggable && !isShrink) ? 'move' : 'default',
          padding: isShrink ? '20px 32px 20px' : '20px 32px 2px'
        }}
      >
        <Grid2 container spacing={1} alignItems={'center'}>
          <Grid2 xs={!topButton ? 8 : 12} display={'flex'} gap={1.5} alignItems={'center'}>
            {icon}
            <Tooltip title={title}>
              <Typography
                sx={{
                  color: 'var(--Blue-Primary-1, #083A50)',
                  fontFamily: 'Nunito',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '24px',
                  marginTop: '3px !important',
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          </Grid2>
          <Grid2 xs={4} textAlign={'end'} hidden={topButton}>
            {shrinkable && <ShrinkableComponent isShrink={isShrink} handleShrink={handleShrink} />}
            <CloseIconButton onClose={onClose} />
          </Grid2>
        </Grid2>
      </DialogTitle>

      {(children && !isShrink) && (
        <DialogContent>
          {children}
        </DialogContent>
      )}

      {(actions && !isShrink) && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}

PopUp.propTypes = {
  // *** Base Props
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    PropTypes.string
  ]),
  disableScrollLock: PropTypes.bool,

  // *** Custom Props
  icon: PropTypes.node,
  shrinkable: PropTypes.bool,
  draggable: PropTypes.bool,
  children: PropTypes.node,
  actions: PropTypes.node,
  topButton: PropTypes.bool,
  onEscapeKeyDown: PropTypes.func,
  onBackdropClick: PropTypes.func,

  // *** MUI Dialog Base Props
  baseProps: PropTypes.shape({
    'aria-describedby': PropTypes.string,
    BackdropComponent: PropTypes.elementType,
    BackdropProps: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
    disableEscapeKeyDown: PropTypes.bool,
    fullScreen: PropTypes.bool,
    onBackdropClick: PropTypes.func,
    PaperProps: PropTypes.object,
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object
    ]),
    TransitionComponent: PropTypes.elementType,
    transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number
    })]),
    TransitionProps: PropTypes.object
  })
}

export default PopUp