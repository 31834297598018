import React, { useState, useEffect, forwardRef } from 'react';
import "jspdf-autotable";
import CreateEditBank from './CreateEditBank';
import ApiService, { username } from '../../../services/ApiService';
import 'react-toastify/dist/ReactToastify.css';
import TempListMaster from '../TempListMaster';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListBank = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Bank';
    const [isModal, setIsModal] = useState(false);
    const param = "bank/bank";
    const [isLoading, setIsLoading] = useState(false);
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [stateX, setStateX] = useState({});
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataBank"));
    const [sortArr, setSortArr] = useState([])

    const [id, setId] = useState(0);
    const [intName, setIntName] = useState('');
    const [masterCode, setMasterCode] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [coaId, setCOAId] = useState(0);
    const [coaCode, setCOACode] = useState('');
    const [coaName, setCOAName] = useState('');
    const [acNumber, setACNumber] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [amountCrr, setAmountCrr] = useState(0);
    const [postalCode, setPostalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [faxNumber, setFaxNumber] = useState('');
    const [email, setEmail] = useState('');
    const [cityId, setCityId] = useState(0);
    const [cityName, setCityName] = useState('');
    const [isActive, setIsActive] = useState(0);

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data = {
                rowStatus: isActive === 0 ? 'ACT' : 'DEL',
                id: props.id,
                intName: props.intName,
                name: props.name,
                address: props.address,
                contactPerson: props.contactPerson,
                eMail: props.email,
                fax: props.faxNumber,
                phone: props.phoneNumber,
                zipCode: props.postalCode,
                cityId: props.cityId,
                cityName: props.cityName,
                coaId: props.coaId,
                coaCode: props.coaCode,
                coaName: props.coaName,
                accountNo: props.acNumber,
                amountCRR: parseInt(props.amountCrr),
                "titleModal": titleModal
            };
            localStorage.setItem(username + "-DataBank", JSON.stringify(data));

        }
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal()
        }
    }));

    const fetchDataLocal = () => {
        setIsModal(true)
        var result = dataLocal;
        setIntName(result.intName);
        setMasterCode(result.masterCode);
        setName(result.name);
        setAddress(result.address);
        setCOAId(result.coaId);
        setCOACode(result.coaCode);
        setCOAName(result.coaName.trim());
        setACNumber(result.accountNo);
        setContactPerson(result.contactPerson);
        setAmountCrr(result.amountCRR);
        setPostalCode(result.zipCode);
        setPhoneNumber(result.phone);
        setFaxNumber(result.fax);
        setEmail(result.eMail);
        setCityId(result.cityId);
        setCityName(result.cityName)
        setIsActive(result.rowStatus === 'ACT' ? 0 : 1);
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DataBank')
    }

    const toggleAdd = () => {
        setId(0);
        setIntName('');
        setMasterCode('');
        setName('');
        setAddress('');
        handleClick();
        setTitleModal('Add');
        setCOAId(0);
        setCOACode('');
        setCOAName('');
        setACNumber('');
        setContactPerson('');
        setAmountCrr(0);
        setPostalCode('');
        setPhoneNumber('');
        setFaxNumber('');
        setEmail('');
        setCityId(0);
        setCityName('')
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            ApiService.PostById(param, SelectedData.id).then((res) => {
                if (res !== null) {
                    var result = res.data.data.bank;
                    setIntName(result.intName);
                    setMasterCode(result.masterCode);
                    setName(result.name);
                    setAddress(result.address);
                    setCOAId(result.coaId);
                    setCOACode(result.coaCode);
                    setCOAName(result.coaName.trim());
                    setACNumber(result.accountNo);
                    setContactPerson(result.contactPerson);
                    setAmountCrr(result.amountCRR);
                    setPostalCode(result.zipCode);
                    setPhoneNumber(result.phone);
                    setFaxNumber(result.fax);
                    setEmail(result.eMail);
                    setCityId(result.cityId);
                    setCityName(result.cityName)
                    setIsActive(result.rowStatus === 'ACT' ? 0 : 1);
                }
            });
            setId(SelectedData.id);
            setIsModal(true);
            setTitleModal('Edit');
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.bank);

                    let temp = response.data.data.bank
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MBANK'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditBank
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}

                        id={id}
                        intName={intName}
                        setIntName={setIntName}
                        masterCode={masterCode}
                        setMasterCode={setMasterCode}
                        name={name}
                        setName={setName}
                        address={address}
                        setAddress={setAddress}
                        coaId={coaId}
                        setCOAId={setCOAId}
                        coaCode={coaCode}
                        setCOACode={setCOACode}
                        coaName={coaName}
                        setCOAName={setCOAName}
                        acNumber={acNumber}
                        setACNumber={setACNumber}
                        contactPerson={contactPerson}
                        setContactPerson={setContactPerson}
                        amountCrr={amountCrr}
                        setAmountCrr={setAmountCrr}
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        faxNumber={faxNumber}
                        setFaxNumber={setFaxNumber}
                        email={email}
                        setEmail={setEmail}
                        cityId={cityId}
                        setCityId={setCityId}
                        cityName={cityName}
                        setCityName={setCityName}
                        isActive={isActive}
                        setIsActive={setIsActive}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListBank