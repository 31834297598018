import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import TableX from '../../../../components/Tables/TableX';
import TableCheck from '../../../../components/Tables/TableCheck';
import ButtonGeneralLedger from './Buttons/ButtonGeneralLedger';
import freezeContainer from '../../../../Styles/freezeContainer';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import ModalRePostGL from '../../../../components/Modals/ModalRePostGL';

const GeneralLedgerList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramGL = 'generalLedger/generalLedger';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [dataCheck, setDataCheck] = useState([]);

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isRePost, setIsRePost] = useState(false);
    const [isGLPosting, setIsGLPosting] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Ledger', link: '/Majura/GeneralLedger', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        localStorage.setItem('isRePost', JSON.stringify(false));
        document.title = 'General Ledger - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }
        let params = [];

        if (JSON.parse(localStorage.getItem('isRePost')) === false) {
            ApiService.FinAccPostByPage(paramGL, 'PAGE', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedData({})

                        let temp = response.data.data.generalLedger
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMap(indexed)
                        setColumnData(response.data.data.columns)
                        setTotalRows(response.data.totalRowCount)

                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(function (error) {
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }

                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
        else {
            ApiService.FinAccCustomPostByPage('PostByGLFail', paramGL, 'PAGE', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedData({})

                        let temp = response.data.data.generalLedgerFail
                        let id = 1;
                        temp.forEach(element => {
                            element.id = id;
                            id++;
                        });
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMap(indexed)
                        setColumnData(response.data.data.columns)
                        setTotalRows(response.data.totalRowCount)
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(function (error) {
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }

                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FGLED')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        getData(0, 50);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(0, 50, []);
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setDataCheck([]);
    };

    const handleReset = () => {
        emptyState();
        getData(0, 50, []);
    };

    function CellDoubleClick(row) {

    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const handleRePosting = () => {
        if (isRePost === false) {
            document.title = 'Re-Posting General Ledger - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isRePost', JSON.stringify(true));
            setIsRePost(true);
        }
        else {
            document.title = 'General Ledger - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isRePost', JSON.stringify(false));
            setIsRePost(false);
        }

        handleReset();
    }

    const handleClickRePost = () => {
        if (!isGLPosting) {
            setIsGLPosting(true);
        }
        else {
            setIsGLPosting(false);
        }
    }

    const handlePostingData = () => {
        if (dataCheck.length > 0) {
            if (dataCheck.length <= 5) {
                handleClickRePost();
            }
            else {
                showToast({ type: 'error', message: 'Max Selected Data is 5!' });
            }

        }
        else {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
    }

    const RePostingData = () => {
        handleClickRePost();
        setIsLoadingBackDrop(true);
        dataCheck.forEach(element => {
            setIsLoadingBackDrop(true);
            var data = {
                "generalLedger": {
                    "messageCode": element.messageCode,
                    "refId": element.refId,
                    "source": element.source,
                }
            }

            ApiService.FinAccCustomPost(paramGL + '/ReCreate', data).then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code !== 200) {
                    showToast({ type: 'error', message: message });
                }
                else {
                    if (dataCheck[dataCheck.length - 1].id === element.id) {
                        setIsLoadingBackDrop(false);
                        getData(numPage, rowsCount, filter);
                        showToast({ type: 'success', message: 'Re-Posting Data Success' });
                    }
                }
            });
        });
    }

    const ButtonComponents = () => {
        return (
            <ButtonGeneralLedger
                ReloadData={ReloadData}
                DataRegUserAccess={DataRegUserAccess}
                handleRePosting={handleRePosting}
                isRePost={isRePost}
                handlePostingData={handlePostingData}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={isRePost ? 'Failed General Ledger Posting' : 'General Ledger'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {ButtonComponent.RefreshButton(sortArr, rowsCount, columnData, setGoTo, handleReset, setFilterBy,
                            filterBy, setFilter, setNumPage, setRowsCount, getData
                            , stateX, setStateX)}
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        {
                                            isRePost ?
                                                <TableCheck
                                                    uniqueKey={'id'}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={getData}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    dataCheck={dataCheck}
                                                    setDataCheck={setDataCheck}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                />
                                                :
                                                <TableX
                                                    uniqueKey={'id'}
                                                    goTo={goTo}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={getData}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    CellDoubleClick={CellDoubleClick}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                />
                                        }
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isGLPosting && (
                    <ModalRePostGL
                        isModal={isGLPosting}
                        handleRePost={RePostingData}
                        isBulk={true}
                        dataCheck={dataCheck}
                        handleClick={handlePostingData}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default GeneralLedgerList;