import { Grid } from '@mui/material';
import * as React from 'react';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

const GeneralButton = ({ reloadData, exportData, findData }) => {
  return (
    <div className="row" spacing={'12px'}>
      <div className='col-12' spacing={'12px'}>
        <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
          {ButtonComponent.ReloadButton(reloadData)}
          {ButtonComponent.FindButton(findData)}
          {ButtonComponent.PrintButton(() => exportData('pdf'), 'PDF', false)}
          {ButtonComponent.ExcelButton(() => exportData('xls'), 'Print Excel', false, false)}
        </Grid>
      </div>
    </div>
  )
}

export default GeneralButton