import { MenuItem, Grid, Typography, Card, CardContent, Tabs, Tab, Stack, IconButton, Collapse } from '@mui/material';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, Selection, TickBox } from '../../../../components/ui';
import { useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';

const ModalBLReport = (props) => {
    const [tabValue, setTabValue] = useState('Agent');
    const [isCarrier, setIsCarrier] = useState(false);
    const [isBoL, setIsBoL] = useState(false);
    const [isLocal, setIsLocal] = useState(false);
    const [isDestination, setIsDestination] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [isDetail, setIsDetail] = useState(false);

    const DocumentTypeData = [
        { id: 1, value: '1', name: 'Our Template (Draft)' },
        { id: 2, value: '2', name: 'Our Template (Fixed)' },
        { id: 3, value: '3', name: 'Agent Template (Draft)' },
        { id: 4, value: '4', name: 'Agent Template (Fixed)' },
    ];

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setDocumentType('1');
        props.setCarrier('0');
        props.setBolIssue('0');
        props.setChangeDes('0');
        props.setDesCollect('0');
    };

    const handleSave = () => {
        props.setIsLoadingBackDrop(true);
        props.SaveData();

        emptyState();
        handleClose();
    }

    const handleChange = (name, isChecked) => {
        if (name === 'carrier') {
            if (isChecked === true) {
                props.setCarrier('0');
                setIsCarrier(false);
            }
            else {
                props.setCarrier('1');
                setIsCarrier(true);
            }
        }
        else if (name === 'BoL') {
            if (isChecked === true) {
                props.setBolIssue('0');
                setIsBoL(false);
            }
            else {
                props.setBolIssue('1');
                setIsBoL(true);
            }
        }
        else if (name === 'local') {
            if (isChecked === true) {
                props.setChangeDes('0');
                setIsLocal(false);
            }
            else {
                props.setChangeDes('1');
                setIsLocal(true);
            }
        }
        else if (name === 'destination') {
            if (isChecked === true) {
                props.setDesCollect('0');
                setIsDestination(false);
            }
            else {
                props.setDesCollect('1');
                setIsDestination(true);
            }
        }
    };

    const handleChangeType = (event, newValue) => {
        setTabValue(newValue);
    };

    const mainContainer = (
        <>
            <Card
                component="div"
                className='p-0'
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px 0px',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '12px',
                    background: '#FAFAFA',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            marginBottom: '16px'
                        }}
                    >
                        Document Detail
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label="BL Number"
                                value={props.BLNumber}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label="Reference"
                                value={props.Reference}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label="BL Name"
                                value={props.BLName}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Selection
                                id='0'
                                label={'Document Type'}
                                fullWidth={true}
                                placeholder={'Select Document Type'}
                                value={props.DocumentType}
                                onChange={(e) => props.setDocumentType(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        marginTop: '16px !important'
                                    }
                                }}
                                hidden={props.TabType === 'Inc Agent' || props.TabType === 'Cost Agent' ? true : false}
                                disabled={props.isViewOnly}
                            >
                                {
                                    DocumentTypeData.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.name}</MenuItem>
                                    ))
                                }
                            </Selection>
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: '24px' }}>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                marginTop: '8px'
                            }}
                        >
                            Do You want to include Text :
                        </Typography>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <div style={{ marginTop: '8px' }}>
                                    <TickBox
                                        size={'small'}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                "As Carrier ..."
                                            </Typography>
                                        }
                                        checked={isCarrier}
                                        onChange={() => handleChange('carrier', isCarrier)}
                                    />
                                </div>

                                <div style={{ marginTop: '8px' }}>
                                    <TickBox
                                        size={'small'}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                "This Bill of lading is issued ..."
                                            </Typography>
                                        }
                                        checked={isBoL}
                                        onChange={() => handleChange('BoL', isBoL)}
                                    />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <div style={{ marginTop: '8px' }}>
                                    <TickBox
                                        size={'small'}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                "Local Charges at Destination ..."
                                            </Typography>
                                        }
                                        checked={isLocal}
                                        onChange={() => handleChange('local', isLocal)}
                                    />
                                </div>

                                <div style={{ marginTop: '8px' }}>
                                    <TickBox
                                        size={'small'}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                "THC DESTINATION COLLECT ..."
                                            </Typography>
                                        }
                                        checked={isDestination}
                                        onChange={() => handleChange('destination', isDestination)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>

            <Card
                sx={{
                    marginTop: '16px',
                    display: 'flex',
                    width: '100%',
                    padding: '8px 0px',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '12px',
                    background: '#FAFAFA',
                }}
                component="div"
                className='p-0'
            >
                <CardContent sx={{ padding: isContact ? '8px 16px 24px !important' : '8px 16px 8px !important' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        disableFocusRipple={true}
                        disableRipple={true}
                        sx={{
                            alignItems: 'center',
                            marginBottom: isContact ? '16px !important' : '0px !important'
                        }}
                        onClick={() => setIsContact(!isContact)}
                    >
                        <Stack direction='row' spacing={'12px'} justifyContent="center" alignItems="center">
                            <Icon
                                iconName={isContact ? 'arrow-up' : 'arrow-down'}
                                svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                        height: '12px',
                                        width: '12px'
                                    }
                                }}
                            />
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                }}
                            >
                                Preview Contact Detail
                            </Typography>
                        </Stack>
                    </IconButton>

                    <Collapse in={isContact} timeout="auto" unmountOnExit>
                        <TabContext value={tabValue} className='p-0'>
                            <Tabs
                                sx={{ width: '100%' }}
                                value={tabValue}
                                onChange={handleChangeType}
                                className='p-0'
                                textColor="primary"
                                aria-label="full width tabs example"
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="Agent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: tabValue === "Agent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="Shipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: tabValue === "Shipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="Consignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: tabValue === "Consignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="NParty"
                                    label={
                                        <Typography
                                            sx={{
                                                color: tabValue === "NParty" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            N'Party
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value='2NParty'
                                    label={
                                        <Typography
                                            sx={{
                                                color: tabValue === "2NParty" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Second N'Party
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {
                                tabValue === 'Agent' && (
                                    <TabPanel value="Agent" className='p-0'>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={5}
                                                lg={5}
                                                sm={5}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Name"
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={props.AgentName}
                                                    onChange={(e) => props.setAgentName(e.target.value)}
                                                    type='text'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={7}
                                                lg={7}
                                                sm={7}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Address"
                                                    value={props.AgentAddress}
                                                    onChange={(e) => props.setAgentAddress(e.target.value)}
                                                    multiline
                                                    fullWidth
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        rows: 4,
                                                        sx: {
                                                            padding: '8px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                )
                            }

                            {
                                tabValue === 'Shipper' && (
                                    <TabPanel value="Shipper" className='p-0'>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={5}
                                                lg={5}
                                                sm={5}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Name"
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={props.ShipperName}
                                                    onChange={(e) => props.setShipperName(e.target.value)}
                                                    type='text'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={7}
                                                lg={7}
                                                sm={7}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Address"
                                                    value={props.ShipperAddress}
                                                    onChange={(e) => props.setShipperAddress(e.target.value)}
                                                    multiline
                                                    fullWidth
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        rows: 4,
                                                        sx: {
                                                            padding: '8px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                )
                            }

                            {
                                tabValue === 'Consignee' && (
                                    <TabPanel value="Consignee" className='p-0'>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={5}
                                                lg={5}
                                                sm={5}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Name"
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={props.ConsigneeName}
                                                    onChange={(e) => props.setConsigneeName(e.target.value)}
                                                    type='text'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={7}
                                                lg={7}
                                                sm={7}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Address"
                                                    value={props.ConsigneeAddress}
                                                    onChange={(e) => props.setConsigneeAddress(e.target.value)}
                                                    multiline
                                                    fullWidth
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        rows: 4,
                                                        sx: {
                                                            padding: '8px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                )
                            }

                            {
                                tabValue === 'NParty' && (
                                    <TabPanel value="NParty" className='p-0'>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={5}
                                                lg={5}
                                                sm={5}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Name"
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={props.NPartyName}
                                                    onChange={(e) => props.setNPartyName(e.target.value)}
                                                    type='text'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={7}
                                                lg={7}
                                                sm={7}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Address"
                                                    value={props.NPartyAddress}
                                                    onChange={(e) => props.setNPartyAddress(e.target.value)}
                                                    multiline
                                                    fullWidth
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        rows: 4,
                                                        sx: {
                                                            padding: '8px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                )
                            }

                            {
                                tabValue === '2NParty' && (
                                    <TabPanel value="2NParty" className='p-0'>
                                        <Stack spacing={'16px'} direction="column" sx={{ marginTop: '16px' }}>
                                            <Input
                                                label="Name"
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={props.SNPartyName}
                                                onChange={(e) => props.setSNPartyName(e.target.value)}
                                                type='text'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                            />

                                            <Input
                                                label="Address"
                                                value={props.SNPartyAddress}
                                                onChange={(e) => props.setSNPartyAddress(e.target.value)}
                                                multiline
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    rows: 4,
                                                    sx: {
                                                        padding: '8px',
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </TabPanel>
                                )
                            }
                        </TabContext>
                    </Collapse>
                </CardContent>
            </Card>

            <Card
                sx={{
                    marginTop: '16px'
                }}
                component="div"
                className='p-0'
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px 0px',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '12px',
                    background: '#FAFAFA',
                }}
            >
                <CardContent sx={{ padding: isDetail ? '8px 16px 24px !important' : '8px 16px 8px !important' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        disableFocusRipple={true}
                        disableRipple={true}
                        sx={{
                            alignItems: 'center',
                            marginBottom: isDetail ? '16px !important' : '0px !important'
                        }}
                        onClick={() => setIsDetail(!isDetail)}
                    >
                        <Stack direction='row' spacing={'12px'} justifyContent="center" alignItems="center">
                            <Icon
                                iconName={isDetail ? 'arrow-up' : 'arrow-down'}
                                svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                        height: '12px',
                                        width: '12px'
                                    }
                                }}
                            />
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                }}
                            >
                                Preview Shipment Detail
                            </Typography>
                        </Stack>
                    </IconButton>

                    <Collapse in={isDetail} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label={'Place of Receipt'}
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.ReceiptPlaceName}
                                    type='text'
                                    onChange={(e) => props.setReceiptPlaceName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label={"Port of Discharge"}
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.DischargePortName}
                                    type='text'
                                    onChange={(e) => props.setDischargePortName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label={"Port of Loading"}
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.LoadingPortName}
                                    type='text'
                                    onChange={(e) => props.setLoadingPortName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label={"Place of Delivery"}
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.DeliveryPlaceName}
                                    type='text'
                                    onChange={(e) => props.setDeliveryPlaceName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </CardContent>
            </Card>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'printer'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalBLReport;