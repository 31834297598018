import {
    Grid, IconButton, MenuItem, InputAdornment
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService, { branchId, companyId, countryId, userId, userCode, formatCurrency } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';

import SelectDataAccount from '../Selects/SelectDataAccount';
import { CustomDatePicker, Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';
import { defaultDate } from '../../../../utils/date';
import { convertToNumeric, maxAmountIDR, maxAmountUSD } from '../../../../utils/masks.jsx';
import ModalConfirmationNominal from '../../../../components/Modals/ModalConfirmationNominal.jsx';

const ModalPaymentRequest = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const param = "account/account";
    const [data, setData] = useState([]);
    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [totalRows, setTotalRows] = useState(50);
    const [SelectedData, setSelectedData] = useState({});
    let countErr = 1;

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [currency, setCurrency] = useState('IDR');

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getData(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Account');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountId(0);
            props.setAccountCode('');
            props.setAccountName('');
            props.setCOACode('');
            props.setCOAName('');
            props.setDescription('');
            props.setIsContainer(false);
            props.setContainerFormat('All');
            props.setContainerType('All');
            props.setQuantity(0);
            props.setAmount(0);
            props.setUnitCost(0);
            props.setIsOthers(false);
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountId(0);
        props.setAccountCode('');
        props.setAccountName('');
        props.setIsPaid(false);
        props.setContainerType('All');
        props.setDescription('');
        props.setCostTrucking(false);
        props.setCostToCost(false);
        props.setQuantity(0);
        props.setUnitCost(0);
        props.setAmount(0);
        props.setIsPPN(false);
        props.setIsContainer(false);
        props.setPercentVAT(0);
        props.setOriginalRate(0);
        props.setOriginalUSD(0);
        props.setFakturNo('');
        props.setFakturDate('');
        props.setCOACode('');
        props.setCOAName('');

        props.setContainerTypeId(0);
        props.setContainerTypeCode('');
        props.setContainerTypeName('');
        props.setContainerFormat('All');
        props.setIsOthers(false);
    };

    const PaidStatusData = [
        { id: 1, code: true, name: 'Paid' },
        { id: 2, code: false, name: 'Not Paid' },
    ];

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const CostToCostData = [
        { id: 1, code: false, name: 'No' },
        { id: 2, code: true, name: 'Yes' },
    ];

    const handleSave = () => {
        let errMsg = undefined;

        if (!props.AccountCode) {
            errMsg = 'Please Select Account!';
        }
        else if (props.Quantity <= 0) {
            errMsg = 'Please Input Quantity Unit!';
        }
        else if (!props.Amount) {
            errMsg = 'Please Input Amount!';
        }
        else if (props.FakturNo !== '' && props.FakturDate === '') {
            errMsg = 'Please Input Faktur Date!';
        }

        if (errMsg === undefined) {
            let Sequence = 1;
            let payReqId = 0;
            let percentPPN = 0;
            let paidDate = defaultDate;
            let fakturDate = defaultDate;

            percentPPN = parseFloat(props.PercentVAT);

            if (props.detailEdit) {
                Sequence = props.selectedDetail.sequence;
            }
            else {
                if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
            }

            if (props.invoiceId !== undefined || props.invoiceId !== null) {
                payReqId = props.invoiceId;

                if (payReqId === undefined) {
                    payReqId = 0;
                }
            }
            else {
                payReqId = 0;
            }

            if (props.isPaid === true) {
                paidDate = props.PaidDate;
            }

            if (props.FakturNo !== '' && props.FakturDate !== '') {
                fakturDate = props.FakturDate;
            }

            let data = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "sequence": Sequence,
                "prId": payReqId,
                "debetCredit": 'C',
                "accountId": props.AccountId,
                "accountCode": props.AccountCode,
                "accountName": props.AccountName,
                "coaCode": props.COACode,
                "coaName": props.COAName,
                "description": props.Description,
                "type": '',
                "containerSize": props.ContainerType,
                "formatContainer": props.ContainerFormat,
                "containerTypeId": props.containerTypeId,
                "containerTypeCode": props.containerTypeCode,
                "containerTypeName": props.containerTypeName,
                "isContainer": props.isContainer,
                "codingQuantity": false,
                "quantity": props.Quantity ? parseFloat(props.Quantity) : parseFloat(0),
                "perQty": convertToNumeric(props.UnitCost),
                "amount": convertToNumeric(props.Amount),
                "amountCrr": parseInt(props.AmountCrr),
                "paid": props.isPaid,
                "paidOn": paidDate,
                "paidPV": false,
                "eplDetailId": props.eplDetailId,
                "statusMemo": false,
                "memoNo": 0,
                "isCostToCost": props.CostToCost,
                "isPpn": props.isPPN,
                "persenPpn": percentPPN,
                "fakturNo": props.FakturNo,
                "fakturDate": fakturDate,
                "isCostTrucking": props.CostTrucking,
                "kendaraanId": 0,
                "kendaraanNopol": '',
                "employeeId": 0,
                "employeeName": '',
                "mrgId": 0,
                "deliveryDate": defaultDate,
                "originalUsd": convertToNumeric(props.OriginalUSD),
                "originalRate": convertToNumeric(props.OriginalRate),

                "idLama": 0,
            }

            let check = 0;

            if (check === 0 && props.PaymentRequestDetail.length > 0) {
                let hasCTC = false;
                let hasPPN = false;

                props.PaymentRequestDetail.forEach(element => {
                    if (element.sequence !== data.sequence) {
                        if (element.isCostToCost) {
                            hasCTC = true;
                        }
                        if (element.isPpn) {
                            hasPPN = true;
                        }
                    }
                });

                if (data.isCostToCost) {
                    if (hasPPN) {
                        check = 3;
                    }
                } else if (data.isPpn) {
                    if (hasCTC) {
                        check = 2;
                    }
                }
            }

            if (check === 0) {
                if (!props.detailEdit) {
                    let newArray = props.PaymentRequestDetail;

                    newArray = [...newArray, data];
                    props.setPaymentRequestDetail(newArray);

                    props.calculateTotal(newArray);
                }
                else {
                    const newArr = props.PaymentRequestDetail.slice();
                    newArr.forEach(el => {
                        if (el.sequence === data.sequence) {
                            el.accountId = data.accountId;
                            el.accountCode = data.accountCode;
                            el.accountName = data.accountName;
                            el.coaCode = data.coaCode;
                            el.coaName = data.coaName;
                            el.description = data.description;
                            el.type = data.type;
                            el.paid = data.paid;
                            el.containerSize = data.containerSize;
                            el.formatContainer = data.formatContainer;
                            el.containerTypeId = data.containerTypeId;
                            el.containerTypeCode = data.containerTypeCode;
                            el.containerTypeName = data.containerTypeName;
                            el.isContainer = data.isContainer;
                            el.paidOn = data.paidOn;
                            el.quantity = data.quantity;
                            el.perQty = data.perQty;
                            el.amountCrr = data.amountCrr;
                            el.amount = data.amount;
                            el.isPpn = data.isPpn;
                            el.persenPpn = data.persenPpn;
                            el.fakturNo = data.fakturNo;
                            el.fakturDate = data.fakturDate;
                            el.isCostToCost = data.isCostToCost;
                            el.isCostTrucking = data.isCostTrucking;
                            el.originalUsd = data.originalUsd;
                            el.originalRate = data.originalRate;
                        }
                    })

                    if (props.isAcc && props.isViewOnly) {
                        props.setIsLoadingBackDrop(true);

                        let subData = {
                            "paymentRequest": {
                            },
                            "paymentRequestDetails": [
                                {
                                    "id": props.selectedDetail.id,
                                    "fakturNo": data.fakturNo,
                                    "fakturDate": data.fakturDate
                                }
                            ]
                        }
                        ApiService.OperationCustomPut('/paymentRequest/paymentRequest/UpdateSubItemSFPNo', subData)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    showToast({ type: 'success', message: 'Update No Faktur Success!' });
                                }
                                else {
                                    showToast({ type: 'error', message: 'Failed to Update Data, ' + response.data.message });
                                }
                                props.setIsLoadingBackDrop(false);
                            })
                            .catch(function (error) {
                                console.log(error)
                                showToast({ type: 'error', message: 'Failed to Update Data, ' + error });
                                props.setIsLoadingBackDrop(false);
                            })
                    }

                    props.setPaymentRequestDetail(newArr);
                    props.calculateTotal(newArr);
                }

                emptyState();
                handleClose();
                props.setDetailEdit(false);
                props.setSelectedDetail({});
            }
            else {
                if (check === 1) {
                    showToast({ type: 'error', message: 'Data with Cost to Cost Cannot Include VAT!' });
                }
                else if (check === 2) {
                    showToast({ type: 'error', message: 'Cannot Mix the Cost to Cost Data with VAT Data!' });
                }
                else if (check === 3) {
                    showToast({ type: 'error', message: 'Cannot Mix the VAT Data with Cost to Cost Data!' });
                }
            }
        }
        else {
            showToast({ type: 'error', message: errMsg });
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    const handleCheckNominal = () => {
        if (props.detailEdit) {
            let Sequence = props.selectedDetail.sequence;
            let data = props.PaymentRequestDetail.filter((el) => el.sequence === Sequence)
            let dataAmount = data[0].amount;
            if (convertToNumeric(dataAmount) === convertToNumeric(props.Amount)) {
                handleSave();
            }
            else {
                handleValidationCurrency();
            }
        }
        else {
            handleValidationCurrency();
        }
    }

    const handleValidationCurrency = () => {
        if (props.AmountCrr === 0) {
            setCurrency('USD');
            if (parseFloat(convertToNumeric(props.Amount)) >= maxAmountUSD) {
                handleClickConfirmation();
            }
            else {
                handleSave();
            }
        }
        else {
            setCurrency('IDR');
            if (parseFloat(convertToNumeric(props.Amount)) >= maxAmountIDR) {
                handleClickConfirmation();
            }
            else {
                handleSave();
            }
        }
    }

    const handleConfirmation = () => {
        handleSave();
        handleClickConfirmation();
    }

    function handleCostToCost(val) {
        props.setCostToCost(val);

        if (val === true) {
            props.setPercentVAT(0);
            props.setIsPPN(false);
            props.setFakturDate('');
        }
    }

    function handleVAT(val) {
        props.setPercentVAT(val);
        if (val === 0) {
            props.setIsPPN(false);
            props.setFakturDate('');
        }
        else {
            props.setIsPPN(true);
            // props.getDateNow();
        }
        props.setFakturNo('');
    };

    const getData = (pageNumber, pageSize, filters) => {
        setIsLoading(true);
        let params = [];
        if (props.isGeneralPR === true) {
            if (props.portType === 'SEA') {
                params = [...params, { 'attr': 'Type', 'value': 'SEA' }];
            }
            else if (props.portType === 'AIR') {
                params = [...params, { 'attr': 'Type', 'value': 'AIR' }];
            }
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedData({})
                        setData(response.data.data.account);

                        let temp = response.data.data.account
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMap(indexed)
                        setColumnData(response.data.data.columns)
                        setTotalRows(response.data.totalRowCount)

                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false);
                    countErr++
                    if (countErr < 3) {
                        getData(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data' });
                        setIsModal(false);
                    }
                })
        }
        else {
            let params = [];
            params = [
                ...params,
                { 'attr': 'eplId', 'value': '' + props.EPLId },
                { 'attr': 'customerId', 'value': '' + props.CustomerId },
                { 'attr': 'isIncome', 'value': '' + false },
                { 'attr': 'refId', 'value': '' + 0 },
                { 'attr': 'isCostToCost', 'value': '' + props.isCostToCost },
            ];
            ApiService.OperationPostByDetail('EstimateProfitLoss/EstimateProfitLoss', 'SEAACC', 1, 200, params)
                .then((response) => {
                    if (response.data.code === 200) {
                        const tempId = new Set(props.PaymentRequestDetail.map(data => data.eplDetailId));
                        let dataArray = response.data.data.estimateProfitLossDetail.filter(item => !tempId.has(item.id));
                        setData(dataArray);

                        let temp = dataArray
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMap(indexed)
                        setColumnData(response.data.data.columns.detailColumns)
                        setTotalRows(response.data.totalRowCount)

                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false);
                    countErr++
                    if (countErr < 3) {
                        getData(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                        setIsModal(false);
                    }
                })
        }
    };

    function handleOriginalUSD(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setOriginalUSD(value);

            props.setUnitCost(parseFloat((convertToNumeric(value) * convertToNumeric(props.OriginalRate)).toFixed(5)));
            props.setAmount(parseFloat((parseFloat((convertToNumeric(value) * convertToNumeric(props.OriginalRate)).toFixed(5)) * convertToNumeric(props.Quantity)).toFixed(5)));
        }
    }

    function handleOriginalRate(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setOriginalRate(value);

            props.setUnitCost(parseFloat((convertToNumeric(value) * convertToNumeric(props.OriginalUSD)).toFixed(5)));
            props.setAmount(parseFloat((parseFloat((convertToNumeric(value) * convertToNumeric(props.OriginalUSD)).toFixed(5)) * convertToNumeric(props.Quantity)).toFixed(5)));
        }
    }

    function handleQuantityUnit(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setQuantity(value);

            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.UnitCost)).toFixed(5)));
        }
    }

    function handleUnitCost(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setUnitCost(value);

            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.Quantity)).toFixed(5)));
        }
    }

    function handleContainerSize(value) {
        props.setContainerFormat(value);
        if (props.isContainer) {
            props.containerSizeData.forEach(element => {
                if (element.formatContainer === value) {
                    props.setContainerType(element.containerSize);
                    props.setContainerTypeId(element.containerTypeId);
                    props.setContainerTypeCode(element.containerTypeCode);
                    props.setContainerTypeName(element.containerTypeName);
                    handleQuantityUnit(element.totalContainer);
                }
            });
        }
        else {
            props.setContainerType('All');
            props.setContainerTypeId(0);
            props.setContainerTypeCode('');
            props.setContainerTypeName('');
            handleQuantityUnit(0);
        }
    }

    const mainContainer = (
        <div style={{ padding: '12px 8px 0px' }}>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Account Code'}
                        value={props.AccountCode}
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchAccount('delete')}
                                        hidden={props.AccountCode && !props.isViewOnly ? false : true}
                                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => searchAccount('search')}
                                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                                        hidden={props.isViewOnly}
                                    >
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Paid Status'}
                        fullWidth={true}
                        placeholder={'Select Paid Status'}
                        value={props.isPaid}
                        onChange={(e) => props.setIsPaid(e.target.value)}
                        disabled={(props.isContra ? true : props.isViewOnly === true ? true : false)}
                    >
                        {
                            PaidStatusData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    {
                        props.isContra || props.isViewOnly === true ?
                            <Selection
                                id='0'
                                label={'Container Size'}
                                fullWidth={true}
                                placeholder={'Select Container Size'}
                                value={props.ContainerFormat}
                                onChange={(e) => handleContainerSize(e.target.value)}
                                disabled
                            >
                                <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                {
                                    props.isContainer ?
                                        props.containerSizeData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                        ))
                                        :
                                        <span></span>
                                }
                            </Selection>
                            :
                            <Selection
                                id='0'
                                label={'Container Size'}
                                fullWidth={true}
                                placeholder={'Select Container Size'}
                                value={props.ContainerFormat}
                                onChange={(e) => handleContainerSize(e.target.value)}
                                disabled={!props.isContainer}
                            >
                                <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                {
                                    props.isContainer ?
                                        props.containerSizeData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                        ))
                                        :
                                        <span></span>
                                }
                            </Selection>
                    }
                </Grid>
            </Grid>

            <Input
                label={'Account Name'}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={props.AccountName}
                type='text'
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
            />

            <Input
                label={'Description'}
                value={props.Description}
                onChange={(e) => props.setDescription(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                disabled={props.isContra || props.isViewOnly === true ? true : false}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Currency'}
                        fullWidth={true}
                        placeholder={'Select Currency'}
                        value={props.AmountCrr}
                        onChange={(e) => props.setAmountCrr(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                    >
                        {
                            BankTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Cost Trucking'}
                        fullWidth={true}
                        placeholder={'Select Cost Trucking'}
                        value={props.CostTrucking}
                        onChange={(e) => props.setCostTrucking(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                    >
                        {
                            CostToCostData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Cost To Cost'}
                        fullWidth={true}
                        placeholder={'Select Cost To Cost'}
                        value={props.CostToCost}
                        onChange={(e) => handleCostToCost(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                        disabled={(props.isContra || props.isViewOnly === true ? true : props.isCostToCost ? false : true)}
                    >
                        {
                            CostToCostData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Quantity Unit'}
                        textAlign='right'
                        fullWidth
                        value={props.Quantity}
                        onChange={(e) => handleQuantityUnit(e.target.value)}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        label={'Per Unit Cost'}
                        textAlign='right'
                        fullWidth
                        value={props.UnitCost}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                        onChange={(e) => handleUnitCost(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        label={'Amount'}
                        textAlign='right'
                        fullWidth
                        value={props.Amount}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                        onChange={(e) => props.setAmount(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Original Rate'}
                        textAlign='right'
                        fullWidth
                        value={props.OriginalRate}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                        onChange={(e) => handleOriginalRate(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        label={'Original USD'}
                        textAlign='right'
                        fullWidth
                        value={props.OriginalUSD}
                        disabled={(props.isContra || props.isViewOnly === true ? true : false)}
                        onChange={(e) => handleOriginalUSD(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        label={'Different Cost'}
                        textAlign='right'
                        fullWidth
                        value={props.DifferentCost}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    {props.selectVATData(props.PercentVAT, handleVAT, (props.isContra || props.isViewOnly === true ? true : props.CostToCost ? true : false))}
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Faktur No.'}
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.FakturNo}
                        onChange={(e) => props.setFakturNo(e.target.value)}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        disabled={!props.isPPN ? !props.isPPN : props.isAcc ? false : props.isContra || props.isViewOnly === true ? true : false}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <CustomDatePicker
                        label={'Faktur Date'}
                        value={props.FakturDate}
                        type={'date'}
                        onChange={props.setFakturDate}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        disabled={!props.isPPN ? !props.isPPN : props.isAcc ? false : props.isContra || props.isViewOnly === true ? true : false}
                    />
                </Grid>
            </Grid>
        </div>
    )

    const buttonComponent = (
        <div style={{ padding: '6px 24px 12px' }}>
            {CustomizeButtonComponent.SaveCancelModalButton(handleCheckNominal, false, (props.isAcc ? false : props.isContra || props.isViewOnly === true ? true : false))}
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
                children={mainContainer}
                actions={buttonComponent}
            />

            {
                isModal && (
                    <SelectDataAccount
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getData}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        data={data}
                        setData={setData}
                        dataMap={dataMap}
                        setDataMap={setDataMap}
                        columnData={columnData}
                        setColumnData={setColumnData}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        totalRows={totalRows}
                        setTotalRows={setTotalRows}

                        setAccountId={props.setAccountId}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                        setCOACode={props.setCOACode}
                        setCOAName={props.setCOAName}
                        setIsContainer={props.setIsContainer}
                        setDescription={props.setDescription}
                        setContainerType={props.setContainerType}
                        detailEdit={props.detailEdit}
                        setQuantity={props.setQuantity}
                        setContainerTypeId={props.setContainerTypeId}
                        setContainerTypeCode={props.setContainerTypeCode}
                        setContainerTypeName={props.setContainerTypeName}
                        setContainerFormat={props.setContainerFormat}
                        containerSizeData={props.containerSizeData}
                        handleQuantityUnit={handleQuantityUnit}
                        setIsOthers={props.setIsOthers}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                        setUnitCost={props.setUnitCost}
                        setCostToCost={props.setCostToCost}
                        setOriginalRate={props.setOriginalRate}
                        setOriginalUSD={props.setOriginalUSD}
                        setAmountCrr={props.setAmountCrr}
                        setAmount={props.setAmount}
                        setEPLDetailId={props.setEPLDetailId}
                        isGeneralPR={props.isGeneralPR}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmationNominal
                        isModal={isModalConfirmation}
                        handleClick={handleClickConfirmation}

                        nominal={props.Amount}
                        currency={currency}
                        ConfirmationData={handleConfirmation}
                    />
                )
            }
        </>
    )
}

export default ModalPaymentRequest;