import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'

import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { CustomDatePicker, Icon, PopUp } from '../../../../../components/ui';

const ConfirmModal = ({ isModal, activeModal, handleClick, handleConfirm }) => {
  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]
  const [selectedDate, setSelectedDate] = useState(dateString)

  const handleApprove = () => {
    handleConfirm(true, activeModal === 'Verify' ? selectedDate : undefined)
  }

  const mainContainer = (
    <>
      {activeModal === 'Verify' && (
        <Grid item xs={12}>
          <CustomDatePicker
            type='date'
            variant="standard"
            label="Upload Date"
            value={selectedDate}
            InputLabelProps={{ shrink: true }}
            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
            onChange={(e) => setSelectedDate(e)}
          />
        </Grid>
      )}
    </>
  )

  return (
    <PopUp
      id='modal-id'
      icon={
        <Icon
          iconName={'documents'}
          svgIconProps={{
            htmlColor: '#0087C2',
            sx: {
              height: '24px',
              width: '24px'
            }
          }}
        />
      }
      title={'Confirmation To ' + activeModal}
      isOpen={isModal}
      onClose={handleClick}
      draggable={false}
      shrinkable={false}
      disableScrollLock={false}
      maxWidth={'sm'}
      fullWidth={true}
      topButton={true}
    >
      <div style={{ padding: '24px 6px 16px' }}>
        <Box display="flex">
          <Typography
            sx={{
              fontFamily: 'Nunito',
              marginTop: '3px !important',
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: '20px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            Are You Sure You Want To {activeModal} Selected Record?
          </Typography>
        </Box>

        {mainContainer}
      </div>

      <div style={{ padding: '24px 6px 0px' }}>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          {ButtonComponent.ApproveButton(handleApprove, 'Yes', true)}
          {ButtonComponent.UnVerifyButton(handleClick, 'No', true)}
        </Stack>
      </div>
    </PopUp>
  )
}

export default ConfirmModal