import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { MenuItem, Grid, Card, CardContent, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import TabEPL from './ContentEPL/TabEPL';
import TotalEpl from './ContentEPL/TotalEpl';
import EplHeader from './ContentEPL/EplHeader';
import CreateEditEplButton from './ComponentEPL/CreateEditEplButton';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, isInfossNo, userCode, userId, username } from '../../../services/ApiService';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../components/ui';
import ButtonInfo from '../../../components/Components/ButtonInfo';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import { defaultDate, getDefaultDateFormat, nullDate, setDateFormat } from '../../../utils/date';
import { convertToNominal } from '../../../utils/masks.jsx';
import { PrintPDF } from '../../../utils/reusableFunction.jsx';

const CreateEditEpl = forwardRef((props, ref) => {
    const { eplId } = useParams()
    const { jobId } = useParams()
    const { isToDo } = useParams()
    const { shipmentId } = useParams()
    const history = useNavigate()
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramShipmentOrder = 'shipmentOrder/shipmentOrder';
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataEpl"));

    const [check, setCheck] = useState(0);

    const [jobOwnerId, setJobOwnerId] = useState(0)
    const [etd, setEtd] = useState('')
    const [shipperId, setShipperId] = useState(0)
    const [shipperName, setShipperName] = useState('')
    const [agentId, setAgentId] = useState(0)
    const [agentName, setAgentName] = useState(' ')
    const [shipmentStatus, setShipmentStatus] = useState(2)
    const [closeEPL, setCloseEPL] = useState(false)
    const [ticketId, setTicketId] = useState(0)
    const [eplNo, setEplNo] = useState(0)
    const [ShipmentId, setShipmentId] = useState(0)
    const [marketId, setMarketId] = useState(0)
    const [marketName, setMarketName] = useState('')
    const [marketCompanyId, setMarketCompanyId] = useState(0)
    const [rate, setRate] = useState(0)
    const [rateReal, setRateReal] = useState(0)
    const [exRateDate, setExRateDate] = useState('')
    const [exRateId, setExRateId] = useState(0)
    const [isModal, setIsModal] = useState(false);
    const [shipmentNo, setShipmentNo] = useState('');
    const [dataDetail, setDataDetail] = useState([])
    const [jobTypeId, setJobTypeId] = useState(0)
    const [jobTypeName, setJobTypeName] = useState('')
    const [selectedDetail, setSelectedDetail] = useState({});
    const [requester, setRequester] = useState('')
    const [jobTypeCode, setJobTypeCode] = useState('')
    const [jobNumber, setJobNumber] = useState(0)
    const [subJobNo, setSubJobNo] = useState(0)
    const [shipperAddress, setShipperAddress] = useState('')
    const [consigneeId, setConsigneeId] = useState(0)
    const [consigneeName, setConsigneeName] = useState('')
    const [consigneeAddress, setConsigneeAddress] = useState('')
    const [agentAddress, setAgentAddress] = useState('')
    const [eta, setEta] = useState('')
    const [jobEntryDate, setJobEntryDate] = useState('')
    const [period, setPeriod] = useState(0)
    const [yearPeriod, setYearPeriod] = useState(0)
    const [portType, setPortType] = useState('');
    const [serviceType, setServiceType] = useState('');

    const [estIncUSDShipper, setEstIncUSDShipper] = useState(0)
    const [estIncIDRShipper, setEstIncIDRShipper] = useState(0)
    const [estIncUSDConsignee, setEstIncUSDConsignee] = useState(0)
    const [estIncIDRConsignee, setEstIncIDRConsignee] = useState(0)
    const [estIncUSDAgent, setEstIncUSDAgent] = useState(0)
    const [estIncIDRAgent, setEstIncIDRAgent] = useState(0)
    const [estCostUSDSSLine, setEstCostUSDSSLine] = useState(0)
    const [estCostIDRSSLine, setEstCostIDRSSLine] = useState(0)
    const [estCostUSDIATA, setEstCostUSDIATA] = useState(0)
    const [estCostIDRIATA, setEstCostIDRIATA] = useState(0)
    const [estCostUSDEMKL, setEstCostUSDEMKL] = useState(0)
    const [estCostIDREMKL, setEstCostIDREMKL] = useState(0)
    const [estCostUSDDiscountShipper, setEstCostUSDDiscountShipper] = useState(0)
    const [estCostIDRDiscountShipper, setEstCostIDRDiscountShipper] = useState(0)
    const [estCostUSDDiscountConsignee, setEstCostUSDDiscountConsignee] = useState(0)
    const [estCostIDRDiscountConsignee, setEstCostIDRDiscountConsignee] = useState(0)
    const [estCostUSDAgent, setEstCostUSDAgent] = useState(0)
    const [estCostIDRAgent, setEstCostIDRAgent] = useState(0)
    const [estCostUSDDepo, setEstCostUSDDepo] = useState(0)
    const [estCostIDRDepo, setEstCostIDRDepo] = useState(0)
    const [estCostUSDTrucking, setEstCostUSDTrucking] = useState(0)
    const [estCostIDRTrucking, setEstCostIDRTrucking] = useState(0)
    const [estTotalUSD, setEstTotalUSD] = useState(0)
    const [estTotalIDR, setEstTotalIDR] = useState(0)
    const [estIncTotalUSD, setEstIncTotalUSD] = useState(0)
    const [estIncTotalIDR, setEstIncTotalIDR] = useState(0)
    const [estCostTotalUSD, setEstCostTotalUSD] = useState(0)
    const [estCostTotalIDR, setEstCostTotalIDR] = useState(0)
    const [estIncUSDShipCons, setEstIncUSDShipCons] = useState(0)
    const [estIncIDRShipCons, setEstIncIDRShipCons] = useState(0)
    const [estCostUSDDiscount, setEstCostUSDDiscount] = useState(0)
    const [estCostIDRDiscount, setEstCostIDRDiscount] = useState(0)
    const [estCostUSDSSLineIATA, setEstCostUSDSSLineIATA] = useState(0)
    const [estCostIDRSSLineIATA, setEstCostIDRSSLineIATA] = useState(0)

    const [estIncUSDShipperReal, setEstIncUSDShipperReal] = useState(0)
    const [estIncIDRShipperReal, setEstIncIDRShipperReal] = useState(0)
    const [estIncUSDConsigneeReal, setEstIncUSDConsigneeReal] = useState(0)
    const [estIncIDRConsigneeReal, setEstIncIDRConsigneeReal] = useState(0)
    const [estIncUSDAgentReal, setEstIncUSDAgentReal] = useState(0)
    const [estIncIDRAgentReal, setEstIncIDRAgentReal] = useState(0)
    const [estCostUSDSSLineReal, setEstCostUSDSSLineReal] = useState(0)
    const [estCostIDRSSLineReal, setEstCostIDRSSLineReal] = useState(0)
    const [estCostUSDIATAReal, setEstCostUSDIATAReal] = useState(0)
    const [estCostIDRIATAReal, setEstCostIDRIATAReal] = useState(0)
    const [estCostUSDEMKLReal, setEstCostUSDEMKLReal] = useState(0)
    const [estCostIDREMKLReal, setEstCostIDREMKLReal] = useState(0)
    const [estCostUSDDiscountShipperReal, setEstCostUSDDiscountShipperReal] = useState(0)
    const [estCostIDRDiscountShipperReal, setEstCostIDRDiscountShipperReal] = useState(0)
    const [estCostUSDDiscountConsigneeReal, setEstCostUSDDiscountConsigneeReal] = useState(0)
    const [estCostIDRDiscountConsigneeReal, setEstCostIDRDiscountConsigneeReal] = useState(0)
    const [estCostUSDAgentReal, setEstCostUSDAgentReal] = useState(0)
    const [estCostIDRAgentReal, setEstCostIDRAgentReal] = useState(0)
    const [estCostUSDDepoReal, setEstCostUSDDepoReal] = useState(0)
    const [estCostIDRDepoReal, setEstCostIDRDepoReal] = useState(0)
    const [estCostUSDTruckingReal, setEstCostUSDTruckingReal] = useState(0)
    const [estCostIDRTruckingReal, setEstCostIDRTruckingReal] = useState(0)
    const [estTotalUSDReal, setEstTotalUSDReal] = useState(0)
    const [estTotalIDRReal, setEstTotalIDRReal] = useState(0)
    const [estIncTotalUSDReal, setEstIncTotalUSDReal] = useState(0)
    const [estIncTotalIDRReal, setEstIncTotalIDRReal] = useState(0)
    const [estCostTotalUSDReal, setEstCostTotalUSDReal] = useState(0)
    const [estCostTotalIDRReal, setEstCostTotalIDRReal] = useState(0)
    const [estIncUSDShipConsReal, setEstIncUSDShipConsReal] = useState(0)
    const [estIncIDRShipConsReal, setEstIncIDRShipConsReal] = useState(0)
    const [estCostUSDDiscountReal, setEstCostUSDDiscountReal] = useState(0)
    const [estCostIDRDiscountReal, setEstCostIDRDiscountReal] = useState(0)
    const [estCostUSDSSLineIATAReal, setEstCostUSDSSLineIATAReal] = useState(0)
    const [estCostIDRSSLineIATAReal, setEstCostIDRSSLineIATAReal] = useState(0)
    const [estUSDShipCons, setEstUSDShipCons] = useState(0);
    const [estIDRShipCons, setEstIDRShipCons] = useState(0);
    const [estUSDAgent, setEstUSDAgent] = useState(0);
    const [estIDRAgent, setEstIDRAgent] = useState(0);

    const [volume20, setVolume20] = useState(0);
    const [volume40, setVolume40] = useState(0);
    const [volume45, setVolume45] = useState(0);
    const [volumeCBM, setVolumeCBM] = useState(0);
    const [isVolume20, setIsVolume20] = useState(false);
    const [isVolume40, setIsVolume40] = useState(false);
    const [isVolume45, setIsVolume45] = useState(false);
    const [isVolumeCBM, setIsVolumeCBM] = useState(false);

    const [selectedDetailIncShipper, setSelectedDetailIncShipper] = useState({});
    const [selectedDetailIncConsignee, setSelectedDetailIncConsignee] = useState({});
    const [selectedDetailIncAgent, setSelectedDetailIncAgent] = useState({});
    const [selectedDetailCostSSLine, setSelectedDetailCostSSLine] = useState({});
    const [selectedDetailCostIATA, setSelectedDetailCostIATA] = useState({});
    const [selectedDetailCostEMKL, setSelectedDetailCostEMKL] = useState({});
    const [selectedDetailCostDiscountShipper, setSelectedDetailCostDiscountShipper] = useState({});
    const [selectedDetailCostDiscountConsignee, setSelectedDetailCostDiscountConsignee] = useState({});
    const [selectedDetailCostAgent, setSelectedDetailCostAgent] = useState({});
    const [selectedDetailCostDepo, setSelectedDetailCostDepo] = useState({});
    const [selectedDetailCostTrucking, setSelectedDetailCostTrucking] = useState({});

    const [IncShipperList, setIncShipperList] = useState([]);
    const [IncConsigneeList, setIncConsigneeList] = useState([]);
    const [IncAgentList, setIncAgentList] = useState([]);
    const [CostSSLineList, setCostSSLineList] = useState([]);
    const [CostEMKLList, setCostEMKLList] = useState([]);
    const [CostIATAList, setCostIATAList] = useState([]);
    const [CostDiscountShipperList, setCostDiscountShipperList] = useState([]);
    const [CostDiscountConsigneeList, setCostDiscountConsigneeList] = useState([]);
    const [CostAgentList, setCostAgentList] = useState([]);
    const [CostDepoList, setCostDepoList] = useState([]);
    const [CostTruckingList, setCostTruckingList] = useState([]);

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);

    const paramShipmentStatus = 'shipmentStatus/shipmentStatus';
    const [shipmentStatusData, setShipmentStatusData] = useState([]);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [ignoreAccess, setIgnoreAccess] = useState(false);

    const [tabValue, setTabValue] = useState('IncShipper');

    const [totalIncSShipper, setTotalIncSShipper] = useState(0);
    const [totalIncNShipper, setTotalIncNShipper] = useState(0);

    const [totalIncSConsignee, setTotalIncSConsignee] = useState(0);
    const [totalIncNConsignee, setTotalIncNConsignee] = useState(0);

    const [totalIncSAgent, setTotalIncSAgent] = useState(0);
    const [totalIncNAgent, setTotalIncNAgent] = useState(0);

    const [totalCostSSSLine, setTotalCostSSSLine] = useState(0);
    const [totalCostNSSLine, setTotalCostNSSLine] = useState(0);

    const [totalCostSIATA, setTotalCostSIATA] = useState(0);
    const [totalCostNIATA, setTotalCostNIATA] = useState(0);

    const [totalCostSEMKL, setTotalCostSEMKL] = useState(0);
    const [totalCostNEMKL, setTotalCostNEMKL] = useState(0);

    const [totalCostSDepo, setTotalCostSDepo] = useState(0);
    const [totalCostNDepo, setTotalCostNDepo] = useState(0);

    const [totalCostSTrucking, setTotalCostSTrucking] = useState(0);
    const [totalCostNTrucking, setTotalCostNTrucking] = useState(0);

    const [totalCostSDiscountShipper, setTotalCostSDiscountShipper] = useState(0);
    const [totalCostNDiscountShipper, setTotalCostNDiscountShipper] = useState(0);

    const [totalCostSDiscountConsignee, setTotalCostSDiscountConsignee] = useState(0);
    const [totalCostNDiscountConsignee, setTotalCostNDiscountConsignee] = useState(0);

    const [totalCostSAgent, setTotalCostSAgent] = useState(0);
    const [totalCostNAgent, setTotalCostNAgent] = useState(0);

    const [containerSize, setContainerSize] = useState('All');
    const [isContainer, setIsContainer] = useState(false);

    const [bookingConfirmationId, setBookingConfirmationId] = useState(0);
    const [bookingConfirmationNumber, setBookingConfirmationNumber] = useState('');

    const [containerSizeData, setContainerSizeData] = useState([]);

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Estimate Profit & Loss', link: '/Majura/EstimateProfitLoss', style: '' },
        { label: 'Create Estimate Profit & Loss', link: '/Majura/EstimateProfitLoss/Create/' + jobId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Estimate Profit & Loss', link: '/Majura/EstimateProfitLoss', style: '' },
        { label: 'Detail Estimate Profit & Loss', link: '/Majura/EstimateProfitLoss/' + jobId + '/' + eplId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        let idEpl = '0'
        if (eplId !== undefined) {
            idEpl = eplId
        }
        const payload =
        {
            "estimateProfitLoss": {
                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "isReadOnly": isReadOnly,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": parseInt(idEpl),
                "ticketId": ticketId,
                "requester": requester,
                "eplNo": eplNo,
                "shipmentId": ShipmentId,
                "shipmentNo": shipmentNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "jobTypeId": parseInt(jobTypeId),
                "jobTypeCode": jobTypeCode,
                "jobOwnerId": jobOwnerId,
                "shipmentStatusId": shipmentStatus,
                "jobNumber": jobNumber,
                "subJobNo": subJobNo,
                "shipperId": shipperId,
                "shipperName": shipperName,
                "shipperAddress": shipperAddress,
                "consigneeId": consigneeId,
                "consigneeName": consigneeName,
                "consigneeAddress": consigneeAddress,
                "agentId": agentId,
                "agentName": agentName,
                "agentAddress": agentAddress,
                "etd": etd,
                "eta": eta,
                "marketId": marketId,
                "marketName": marketName,
                "marketCompanyId": marketCompanyId,
                "jobEntryOn": jobEntryDate,
                "rate": rateReal,
                "exRateDate": exRateDate,
                "estIncUSDShipper": estIncUSDShipperReal,
                "estIncIDRShipper": estIncIDRShipperReal,
                "estIncUSDConsignee": estIncUSDConsigneeReal,
                "estIncIDRConsignee": estIncIDRConsigneeReal,
                "estIncUSDAgent": estIncUSDAgentReal,
                "estIncIDRAgent": estIncIDRAgentReal,
                "estCostUSDSSLine": estCostUSDSSLineReal,
                "estCostIDRSSLine": estCostIDRSSLineReal,
                "estCostUSDIATA": estCostUSDIATAReal,
                "estCostIDRIATA": estCostIDRIATAReal,
                "estCostUSDEMKL": estCostUSDEMKLReal,
                "estCostIDREMKL": estCostIDREMKLReal,
                "estCostUSDDiscountShipper": estCostUSDDiscountShipperReal,
                "estCostIDRDiscountShipper": estCostIDRDiscountShipperReal,
                "estCostUSDDiscountConsignee": estCostUSDDiscountConsigneeReal,
                "estCostIDRDiscountConsignee": estCostIDRDiscountConsigneeReal,
                "estCostUSDAgent": estCostUSDAgentReal,
                "estCostIDRAgent": estCostIDRAgentReal,
                "estCostUSDDepo": estCostUSDDepoReal,
                "estCostIDRDepo": estCostIDRDepoReal,
                "estCostUSDTrucking": estCostUSDTruckingReal,
                "estCostIDRTrucking": estCostIDRTruckingReal,
                "estTotalUSD": estTotalUSDReal,
                "estTotalIDR": estTotalIDRReal,
                "estIncTotalUSD": estIncTotalUSDReal,
                "estIncTotalIDR": estIncTotalIDRReal,
                "estCostTotalUSD": estCostTotalUSDReal,
                "estCostTotalIDR": estCostTotalIDRReal,
                "estUSDShipCons": estUSDShipCons,
                "estIDRShipCons": estIDRShipCons,
                "estUSDAgent": estUSDAgent,
                "estIDRAgent": estIDRAgent,
                "timeCloseEPL": 0,
                "closeEPL": closeEPL,
                "closedEPLOn": defaultDate,
                "period": period,
                "yearPeriod": yearPeriod,
                "exRateId": exRateId,
                "idLama": 0,
                "printing": 0,
                "printedOn": defaultDate,
                "deleted": 0,
                "deletedOn": defaultDate,
                "invoiceId": 0,
                "prId": 0,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "wfComplete": 0,
                "userId": userId,
                "userCode": userCode,
            },
            "estimateProfitLossDetails": dataDetail
        }
        localStorage.setItem(username + "-DataEpl", JSON.stringify(payload));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal()
        }
    }));

    useEffect(() => {
        document.title = 'Estimate Profit and Loss - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleDocumentStatus(rowStatus, isClosed = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isClosed) {
            setDocumentStatus('CLOSED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getDataLocal = () => {
        let tempData = dataLocal.estimateProfitLossDetails
        setDataDetail(tempData)
        let dataEpl = dataLocal.estimateProfitLoss

        if (eplId) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }

        handleDocumentStatus(dataEpl.rowStatus, dataEpl.isReadOnly)
        setIsReadOnly(dataEpl.isReadOnly)
        setJobOwnerId(dataEpl.jobOwnerId)
        setBookingConfirmationId(dataEpl.bookingId);
        setBookingConfirmationNumber(dataEpl.bookingNo);
        setShipperId(dataEpl.shipperId)
        setShipperName(dataEpl.shipperName)
        setAgentId(dataEpl.agentId)
        setInfossNo(dataEpl.noInfoss)
        setAgentName(dataEpl.agentName)
        setShipmentStatus(dataEpl.shipmentStatusId)
        setCloseEPL(dataEpl.closeEPL)
        setTicketId(dataEpl.ticketId)
        setPeriod(dataEpl.period)
        setYearPeriod(dataEpl.yearPeriod)
        setEplNo(dataEpl.eplNo)
        setShipmentId(dataEpl.shipmentId)
        setMarketId(dataEpl.marketId)
        setMarketName(dataEpl.marketName)
        setMarketCompanyId(dataEpl.marketCompanyId)
        setRate(convertToNominal(dataEpl.rate))
        setRateReal(dataEpl.rate)
        setExRateId(dataEpl.exRateId)
        setShipmentNo(dataEpl.shipmentNo)
        setRequester(dataEpl.requester)
        setJobTypeCode(dataEpl.jobTypeCode)
        setJobNumber(dataEpl.jobNumber)
        setSubJobNo(dataEpl.subJobNo)
        setShipperAddress(dataEpl.shipperAddress)
        setConsigneeId(dataEpl.consigneeId)
        setConsigneeName(dataEpl.consigneeName)
        setConsigneeAddress(dataEpl.consigneeAddress)
        setAgentAddress(dataEpl.agentAddress)
        getJobTypeName(dataEpl.jobTypeId)

        setVolume20(dataEpl.shipVol20Amount);
        if (dataEpl.shipmentVolume20 > 0) {
            setIsVolume20(true);
        }

        setVolume40(dataEpl.shipVol40Amount);
        if (dataEpl.shipmentVolume40 > 0) {
            setIsVolume40(true);
        }

        setVolume45(dataEpl.shipVol45Amount);
        if (dataEpl.shipmentVolume45 > 0) {
            setIsVolume45(true);
        }
        setVolumeCBM(dataEpl.shipVolCBMAmount);
        if (dataEpl.shipmentVolumeCBM > 0) {
            setIsVolumeCBM(true);
        }

        setDateFormat(dataEpl.etd, setEtd);
        setDateFormat(dataEpl.eta, setEta);
        setDateFormat(dataEpl.exRateDate, setExRateDate);
        setDateFormat(dataEpl.jobEntryOn, setJobEntryDate);

        setIncAgentList(tempData.filter(dt => dt.customerTypeId === 1 && dt.isIncome === true))
        setCostAgentList(tempData.filter(dt => dt.customerTypeId === 1 && dt.isIncome === false))
        setIncShipperList(tempData.filter(dt => dt.customerTypeId === 2 && dt.isIncome === true))
        setIncConsigneeList(tempData.filter(dt => dt.customerTypeId === 3 && dt.isIncome === true))
        setCostSSLineList(tempData.filter(dt => dt.customerTypeId === 4))
        setCostIATAList(tempData.filter(dt => dt.customerTypeId === 5))
        setCostEMKLList(tempData.filter(dt => dt.customerTypeId === 6))
        setCostDepoList(tempData.filter(dt => dt.customerTypeId === 7))
        setCostDiscountShipperList(tempData.filter(dt => (dt.customerTypeId === 2 || dt.customerTypeId === 8) && dt.isIncome === false))
        setCostDiscountConsigneeList(tempData.filter(dt => (dt.customerTypeId === 3 || dt.customerTypeId === 9) && dt.isIncome === false))
        setCostTruckingList(tempData.filter(dt => dt.customerTypeId === 10))
        countSum(tempData)
        countTotalData(tempData)
        GetContainerSizeData(1, 100, dataEpl.shipmentId)
        localStorage.removeItem(username + '-DataEpl')
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getJobTypeName = (id) => {
        ApiService.PostById('regJobType/regJobType', id).then((res) => {
            if (res.data.code === 200) {
                setJobTypeName(res.data.data.jobType.name)
                setJobTypeId('' + res.data.data.jobType.jobTypeId)
                setServiceType(res.data.data.jobType.serviceType);
                setPortType(res.data.data.jobType.portType);
                if (res.data.data.jobType.serviceType !== 'Import') {
                    setTabValue('IncShipper');
                }
                else {
                    setTabValue('IncConsignee');
                }

                if (!dataLocal) {
                    if (!shipmentId) {
                        getData();
                    }
                }
            }
            else {
                Back();
            }
        }).catch(function (error) {
            console.error('error saat fetch', error)
            showToast('error', 'Error When Fetch Data, Please Contact The Administrator!');
        })
    }

    const GetJobOwnerData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobOwnerData(response.data.data.jobOwner);

                    GetShipmentStatusData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast('error', 'Error When Fetch Data, Please Contact The Administrator!');
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                value={jobOwnerId}
                fullWidth={true}
                placeholder={'Select Principle By'}
                onChange={(e) => setJobOwnerId(e.target.value)}
                disabled
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let checked = 0;
        let tempArray = []
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OEPLO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }

                            if (element.accessCode === 'IGN') {
                                setIgnoreAccess(true);
                            }
                        });

                        setCheck(checked);

                        if (hasAccess) {
                            GetJobOwnerData(1, 20);
                        }
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false)
                    console.error('error saat fetch', error)
                    showToast('error', 'Error When Fetch Data, Please Contact The Administrator!');
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetShipmentStatusData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramShipmentStatus, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setShipmentStatusData(response.data.data.shipmentStatus);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        if (!shipmentId) {
                            formatCurrency();
                            getJobTypeName(jobId)
                        }
                        else {
                            getDataShipment(shipmentId);
                            handleDocumentStatus('ACT');
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast('error', 'Error When Fetch Data, Please Contact The Administrator!');
            })
    };

    const selectShipmentStatus = (
        <>
            <Selection
                id='0'
                label={'Shipment Status'}
                value={shipmentStatus}
                fullWidth
                placeholder={'Select Shipment Status'}
                onChange={(e) => setShipmentStatus(e.target.value)}
                disabled
            >
                {
                    shipmentStatusData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetContainerSizeData = (pageNumber, pageSize, shipmentId, filter) => {
        let params = [];
        params = [
            {
                'attr': 'shipmentId',
                'value': '' + shipmentId,
                'isDistinct': true
            },
            {
                'attr': 'containerTypeId',
                'value': '',
                'isDistinct': true
            },
            {
                'attr': 'containerSize',
                'value': '',
                'isDistinct': true
            }
        ]
        ApiService.ShipmentSeaContainerPostBySearch(paramShipmentOrder, pageNumber, pageSize, params, filter)
            .then((response) => {
                if (response.data.code === 200) {
                    setContainerSizeData(response.data.data.shipmentOrderSeaContainer);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast('error', 'Error When Fetch Data, Please Contact The Administrator!');
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
        }
    };

    const getData = () => {
        if (eplId !== undefined) {
            ApiService.OperationPostById('estimateProfitLoss/estimateProfitLoss', eplId)
                .then((res) => {
                    if (res.data.code === 200) {
                        let dataEpl = res.data.data.estimateProfitLoss;
                        let tempData = dataEpl.estimateProfitLossDetails;
                        setIsEdit(eplId ? true : false);
                        setDataDetail(tempData)
                        setCloseEPL(dataEpl.closeEPL);
                        setJobOwnerId(dataEpl.jobOwnerId)
                        setBookingConfirmationId(dataEpl.bookingId);
                        setBookingConfirmationNumber(dataEpl.bookingNo);
                        setPeriod(dataEpl.period)
                        setYearPeriod(dataEpl.yearPeriod)
                        setShipperId(dataEpl.shipperId)
                        setShipperName(dataEpl.shipperName)
                        setAgentId(dataEpl.agentId)
                        setAgentName(dataEpl.agentName)
                        setShipmentStatus(dataEpl.shipmentStatusId)
                        setInfossNo(dataEpl.noInfoss)
                        setTicketId(dataEpl.ticketId)
                        setEplNo(dataEpl.eplNo)
                        setShipmentId(dataEpl.shipmentId)
                        setMarketId(dataEpl.marketId)
                        setMarketName(dataEpl.marketName)
                        setMarketCompanyId(dataEpl.marketCompanyId)
                        setRate(convertToNominal(dataEpl.rate))
                        setRateReal(dataEpl.rate)
                        setExRateId(dataEpl.exRateId)
                        setShipmentNo(dataEpl.shipmentNo)
                        setRequester(dataEpl.requester)
                        setJobTypeCode(dataEpl.jobTypeCode)
                        setJobNumber(dataEpl.jobNumber)
                        setSubJobNo(dataEpl.subJobNo)
                        setShipperAddress(dataEpl.shipperAddress)
                        setConsigneeId(dataEpl.consigneeId)
                        setConsigneeName(dataEpl.consigneeName)
                        setConsigneeAddress(dataEpl.consigneeAddress)
                        setAgentAddress(dataEpl.agentAddress)

                        setVolume20(dataEpl.shipVol20Amount);
                        if (dataEpl.shipVol20Amount > 0) {
                            setIsVolume20(true);
                        }

                        setVolume40(dataEpl.shipVol40Amount);
                        if (dataEpl.shipVol40Amount > 0) {
                            setIsVolume40(true);
                        }

                        setVolume45(dataEpl.shipVol45Amount);
                        if (dataEpl.shipVol45Amount > 0) {
                            setIsVolume45(true);
                        }
                        setVolumeCBM(dataEpl.shipVolCBMAmount);
                        if (dataEpl.shipVolCBMAmount > 0) {
                            setIsVolumeCBM(true);
                        }

                        setDateFormat(dataEpl.etd, setEtd);
                        setDateFormat(dataEpl.eta, setEta);
                        setDateFormat(dataEpl.exRateDate, setExRateDate);
                        setDateFormat(dataEpl.jobEntryOn, setJobEntryDate);

                        setIncAgentList(tempData.filter(dt => dt.customerTypeId === 1 && dt.isIncome === true))
                        setCostAgentList(tempData.filter(dt => dt.customerTypeId === 1 && dt.isIncome === false))
                        setIncShipperList(tempData.filter(dt => dt.customerTypeId === 2 && dt.isIncome === true))
                        setIncConsigneeList(tempData.filter(dt => dt.customerTypeId === 3 && dt.isIncome === true))
                        setCostSSLineList(tempData.filter(dt => dt.customerTypeId === 4))
                        setCostIATAList(tempData.filter(dt => dt.customerTypeId === 5))
                        setCostEMKLList(tempData.filter(dt => dt.customerTypeId === 6))
                        setCostDepoList(tempData.filter(dt => dt.customerTypeId === 7))
                        setCostDiscountShipperList(tempData.filter(dt => dt.customerTypeId === 8 && dt.isIncome === false))
                        setCostDiscountConsigneeList(tempData.filter(dt => dt.customerTypeId === 9 && dt.isIncome === false))
                        setCostTruckingList(tempData.filter(dt => dt.customerTypeId === 10))
                        countSum(tempData)
                        GetContainerSizeData(1, 100, dataEpl.shipmentId)
                        countTotalData(tempData)

                        if (!dataLocal && isToDo === undefined) {
                            setIsLoadingBackDrop(false)
                        }

                        localStorage.setItem('isClosedEPL', dataEpl.closeEPL);
                        setIsReadOnly(dataEpl.closeEPL);
                        handleDocumentStatus(dataEpl.rowStatus, dataEpl.closeEPL)
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    showToast('error', 'Error', 'Error When Fetch Data, Please Contact The Administrator!');
                })
        }
        else {
            if (!dataLocal && isToDo === undefined) {
                setIsLoadingBackDrop(false)
            }
        }
    }

    const getDataShipment = (id) => {
        setIsLoadingBackDrop(true);
        let isDummy = false;
        ApiService.OperationPostById(paramShipmentOrder, id)
            .then((res) => {
                if (res.data.code === 200) {
                    let containerData = res.data.data.shipmentOrder.shipmentOrderSeaContainers;
                    GetContainerSizeData(1, 100, res.data.data.shipmentOrder.id);
                    setShipmentId(res.data.data.shipmentOrder.id)
                    setTicketId(res.data.data.shipmentOrder.ticketId)
                    setMarketId(res.data.data.shipmentOrder.marketId)
                    setMarketName(res.data.data.shipmentOrder.marketName)
                    setMarketCompanyId(res.data.data.shipmentOrder.marketCompanyId)
                    setShipperId(res.data.data.shipmentOrder.shipperId)
                    setShipperName(res.data.data.shipmentOrder.shipperName)
                    setAgentId(res.data.data.shipmentOrder.agentId)
                    if (res.data.data.shipmentOrder.agentName !== '') {
                        setAgentName(res.data.data.shipmentOrder.agentName)
                    }
                    setShipmentStatus(res.data.data.shipmentOrder.shipmentStatusId)
                    setShipmentNo(res.data.data.shipmentOrder.shipmentNo)
                    setRequester(res.data.data.shipmentOrder.requester)
                    setJobTypeCode(res.data.data.shipmentOrder.jobTypeCode)
                    setJobNumber(res.data.data.shipmentOrder.jobNumber)
                    setSubJobNo(res.data.data.shipmentOrder.subJobNo)
                    setShipperAddress(res.data.data.shipmentOrder.shipperAddress)
                    setConsigneeId(res.data.data.shipmentOrder.consigneeId)
                    setConsigneeName(res.data.data.shipmentOrder.consigneeName)
                    setConsigneeAddress(res.data.data.shipmentOrder.consigneeAddress)
                    setAgentAddress(res.data.data.shipmentOrder.agentAddress)
                    getJobTypeName(res.data.data.shipmentOrder.jobTypeId)
                    setJobOwnerId(res.data.data.shipmentOrder.jobOwnerId)

                    setDateFormat(res.data.data.shipmentOrder.createdOn, setJobEntryDate);
                    setDateFormat(res.data.data.shipmentOrder.etd, setEtd);
                    setDateFormat(res.data.data.shipmentOrder.eta, setEta);

                    setBookingConfirmationId(res.data.data.shipmentOrder.bookingConfirmationId);
                    setBookingConfirmationNumber(res.data.data.shipmentOrder.bookingNo);

                    containerData.forEach(element => {
                        if (element.containerNo.includes('DUMY') === true) {
                            isDummy = true;
                        }
                    });

                    let bookingId = res.data.data.shipmentOrder.bookingConfirmationId
                    ApiService.OperationPostById('bookingConfirmation/bookingConfirmation', bookingId)
                        .then((res) => {
                            if (res.data.code === 200) {
                                let data = res.data.data.bookingConfirmation.bookingConfirmationDetails
                                let data1 = []
                                let Sequence = 0

                                if (res.data.data.bookingConfirmation.shipmentVolume === 'FCL' || res.data.data.bookingConfirmation.shipmentVolume === 'Others') {
                                    setVolume20(res.data.data.bookingConfirmation.shipmentVolume20);
                                    if (res.data.data.bookingConfirmation.shipmentVolume20 > 0) {
                                        setIsVolume20(true);
                                    }

                                    setVolume40(res.data.data.bookingConfirmation.shipmentVolume40);
                                    if (res.data.data.bookingConfirmation.shipmentVolume40 > 0) {
                                        setIsVolume40(true);
                                    }

                                    setVolume45(res.data.data.bookingConfirmation.shipmentVolume45);
                                    if (res.data.data.bookingConfirmation.shipmentVolume45 > 0) {
                                        setIsVolume45(true);
                                    }

                                    if (res.data.data.bookingConfirmation.shipmentVolume === 'Others') {
                                        setVolumeCBM(res.data.data.bookingConfirmation.shipmentVolumeCBM);
                                        if (res.data.data.bookingConfirmation.shipmentVolumeCBM > 0) {
                                            setIsVolumeCBM(true);
                                        }
                                    }
                                }
                                else if (res.data.data.bookingConfirmation.shipmentVolume === 'LCL') {
                                    setVolumeCBM(res.data.data.bookingConfirmation.shipmentVolumeCBM);
                                    if (res.data.data.bookingConfirmation.shipmentVolumeCBM > 0) {
                                        setIsVolumeCBM(true);
                                    }
                                }

                                data.forEach(el => {
                                    Sequence++
                                    let Ausd = 0
                                    let Aidr = 0
                                    let isIgnored = false;
                                    if (el.amountCrr === 0) {
                                        Ausd = parseFloat(el.amount)
                                    }
                                    else {
                                        Aidr = parseFloat(el.amount)
                                    }

                                    const payload = {
                                        "rowStatus": "ACT",
                                        "id": 0,
                                        "eplId": 0,
                                        "countryId": el.countryId,
                                        "companyId": el.companyId,
                                        "branchId": el.branchId,
                                        "sequence": Sequence,
                                        "isIncome": el.isIncome,
                                        "customerId": el.contactId,
                                        "customerTypeId": el.customerTypeId,
                                        "customerName": el.contactName,
                                        "accountId": el.accountId,
                                        "accountCode": el.accountCode,
                                        "accountName": el.accountName,
                                        "coaCode": el.coaCode,
                                        "coaName": el.coaName,
                                        "description": el.description,
                                        "isContainer": el.isContainer,
                                        "formatContainer": el.formatContainer,
                                        "containerSize": el.containerSize,
                                        "containerTypeId": el.containerTypeId,
                                        "containerTypeCode": el.containerTypeCode,
                                        "containerTypeName": el.containerTypeName,
                                        "codingQuantity": false,
                                        "type": '0',
                                        "quantity": parseFloat(el.quantity),
                                        "perQty": parseFloat(el.perQty),
                                        "amountCrr": parseInt(el.amountCrr),
                                        "amountUSD": parseFloat(Ausd),
                                        "amountIDR": parseFloat(Aidr),
                                        "sign": el.sign,
                                        "dataFrom": false,
                                        "memoNo": 0,
                                        "statusMemo": false,
                                        "createdMemoOn": defaultDate,
                                        "paidPR": false,
                                        "isSplitIncCost": false,
                                        "idLama": 0,
                                        "isCostToCost": el.isCostToCost,
                                        "truck_detail_id": 0,
                                        "isCostTrucking": false,
                                        "isAdditional": false,
                                        "isIgnoreItem": isIgnored,
                                        "kendaraanId": 0,
                                        "kendaraanNopol": "",
                                        "employeeId": 0,
                                        "employeeName": "",
                                        "refId": 0,
                                        "mrgId": 0,
                                        "deliveryDate": defaultDate,
                                        "originalUsd": parseFloat(el.originalUsd),
                                        "originalRate": parseFloat(el.originalRate),
                                        "bookingDetailId": el.bookingConfirmationId,
                                        "user": "",
                                        "estimateProfitLossDetailStorages": []
                                    }
                                    data1 = [...data1, payload]
                                });
                                setIncShipperList(data1.filter(dt => dt.customerTypeId === 2 && dt.isIncome === true))
                                setIncConsigneeList(data1.filter(dt => dt.customerTypeId === 3 && dt.isIncome === true))
                                setIncAgentList(data1.filter(dt => dt.customerTypeId === 1 && dt.isIncome === true))
                                setCostSSLineList(data1.filter(dt => dt.customerTypeId === 4 && dt.isIncome === false))
                                setCostIATAList(data1.filter(dt => dt.customerTypeId === 5 && dt.isIncome === false))
                                setCostEMKLList(data1.filter(dt => dt.customerTypeId === 6 && dt.isIncome === false))
                                setCostDiscountShipperList(data1.filter(dt => dt.customerTypeId === 8 && dt.isIncome === false))
                                setCostDiscountConsigneeList(data1.filter(dt => dt.customerTypeId === 9 && dt.isIncome === false))
                                setCostAgentList(data1.filter(dt => dt.customerTypeId === 1 && dt.isIncome === false))
                                setCostDepoList(data1.filter(dt => dt.customerTypeId === 7 && dt.isIncome === false))
                                setCostTruckingList(data1.filter(dt => dt.customerTypeId === 10 && dt.isIncome === false))
                                setDataDetail(data1)
                                countSum(data1)
                                countTotalData(data1)
                            }
                            setIsLoadingBackDrop(false);
                        })
                    getDateExchRate()

                    if (isDummy === true) {
                        showToast('warning', 'There Still Dummy Container On Shipment Order! Please Update The Container!');
                    }

                    setIsLoadingBackDrop(true);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                showToast('error', 'Error When Fetch Data, Please Contact The Administrator!');
                setIsModal(false);
            })
    }

    const Back = () => {
        if (!isToDo) {
            history('/Majura/EstimateProfitLoss');
        }
        else {
            history('/Majura');
            window.location.reload();
        }
    }

    const checkDataCondition = (isAction) => {
        if (eplId) {
            setIsLoadingBackDrop(true);
            ApiService.OperationPostById('estimateProfitLoss/estimateProfitLoss', eplId)
                .then((response) => {
                    if (response.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        let data = response.data.data.estimateProfitLoss;

                        if (isAction === 'Save') {
                            if (data.closeEPL) {
                                getData();
                                showToast({ type: 'error', message: 'Data Already Closed!' });
                            }
                            else {
                                Save(false);
                            }
                        }
                        else if (isAction === 'PrintPDF') {
                            setTitleConfirmation('Print')
                            setTitleModalConfirmation('Estimate Profit Loss');
                            handleClickConfirmation();
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Error When Read Data!, ' + response.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Read Data!, ' + error });
                })
        }
        else if (isAction === 'Save') {
            Save(false);
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const Save = (isPrint = false) => {
        if (isLoadingBackDrop) return
        setIsLoadingBackDrop(true);

        let idEpl = eplId !== undefined ? parseInt(eplId) : 0;

        let dataEPLDetail = dataDetail.map((el) => {
            return {
                ...el,
                rowStatus: el.isIgnoreItem === true ? 'DEL' : 'ACT'
            }
        })

        dataEPLDetail.forEach(element => {
            if (getDefaultDateFormat(element.deliveryDate) === nullDate) {
                element.deliveryDate = defaultDate;
            }

            if (getDefaultDateFormat(element.createdMemoOn) === nullDate) {
                element.createdMemoOn = defaultDate;
            }
        });

        const payload = {
            "estimateProfitLoss": {
                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": parseInt(idEpl),
                "ticketId": ticketId,
                "requester": requester,
                "eplNo": eplNo,
                "shipmentId": ShipmentId,
                "shipmentNo": shipmentNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "jobTypeId": parseInt(jobTypeId),
                "jobTypeCode": jobTypeCode,
                "jobOwnerId": jobOwnerId,
                "shipmentStatusId": shipmentStatus,
                "jobNumber": jobNumber,
                "subJobNo": subJobNo,
                "shipperId": shipperId,
                "shipperName": shipperName,
                "shipperAddress": shipperAddress,
                "consigneeId": consigneeId,
                "consigneeName": consigneeName,
                "consigneeAddress": consigneeAddress,
                "agentId": agentId,
                "agentName": agentName,
                "agentAddress": agentAddress,
                "etd": etd,
                "eta": eta,
                "marketId": marketId,
                "marketName": marketName,
                "marketCompanyId": marketCompanyId,
                "jobEntryOn": jobEntryDate,
                "rate": rateReal,
                "exRateDate": exRateDate,
                "estIncUSDShipper": estIncUSDShipperReal,
                "estIncIDRShipper": estIncIDRShipperReal,
                "estIncUSDConsignee": estIncUSDConsigneeReal,
                "estIncIDRConsignee": estIncIDRConsigneeReal,
                "estIncUSDAgent": estIncUSDAgentReal,
                "estIncIDRAgent": estIncIDRAgentReal,
                "estCostUSDSSLine": estCostUSDSSLineReal,
                "estCostIDRSSLine": estCostIDRSSLineReal,
                "estCostUSDIATA": estCostUSDIATAReal,
                "estCostIDRIATA": estCostIDRIATAReal,
                "estCostUSDEMKL": estCostUSDEMKLReal,
                "estCostIDREMKL": estCostIDREMKLReal,
                "estCostUSDDiscountShipper": estCostUSDDiscountShipperReal,
                "estCostIDRDiscountShipper": estCostIDRDiscountShipperReal,
                "estCostUSDDiscountConsignee": estCostUSDDiscountConsigneeReal,
                "estCostIDRDiscountConsignee": estCostIDRDiscountConsigneeReal,
                "estCostUSDAgent": estCostUSDAgentReal,
                "estCostIDRAgent": estCostIDRAgentReal,
                "estCostUSDDepo": estCostUSDDepoReal,
                "estCostIDRDepo": estCostIDRDepoReal,
                "estCostUSDTrucking": estCostUSDTruckingReal,
                "estCostIDRTrucking": estCostIDRTruckingReal,
                "estTotalUSD": estTotalUSDReal,
                "estTotalIDR": estTotalIDRReal,
                "estIncTotalUSD": estIncTotalUSDReal,
                "estIncTotalIDR": estIncTotalIDRReal,
                "estCostTotalUSD": estCostTotalUSDReal,
                "estCostTotalIDR": estCostTotalIDRReal,
                "estUSDShipCons": estUSDShipCons,
                "estIDRShipCons": estIDRShipCons,
                "estUSDAgent": estUSDAgent,
                "estIDRAgent": estIDRAgent,
                "timeCloseEPL": 0,
                "closeEPL": closeEPL,
                "closedEPLOn": defaultDate,
                "period": period,
                "yearPeriod": yearPeriod,
                "exRateId": exRateId,
                "idLama": 0,
                "printing": 0,
                "printedOn": defaultDate,
                "deleted": 0,
                "deletedOn": defaultDate,
                "invoiceId": 0,
                "prId": 0,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "wfComplete": 0,
                "userId": userId,
                "userCode": userCode,
            },
            "estimateProfitLossDetails": dataEPLDetail
        }

        let errorMessage = undefined;
        let amountCTC = (parseFloat(estIncTotalIDRReal) + (parseFloat(estIncTotalUSDReal) * parseFloat(rateReal))) - (parseFloat(estCostTotalIDRReal) + (parseFloat(estCostTotalUSDReal) * parseFloat(rateReal)));
        if (payload.estimateProfitLossDetails.length === 0) {
            errorMessage = 'Please Add Income or Cost Data!';
        }
        else if (amountCTC !== 0) {
            errorMessage = 'Cost To Cost Value Not Balance!';
        }
        else if (payload.estimateProfitLoss.noInfoss === '' && isInfossNo) {
            errorMessage = 'Please Fill Infoss Number!';
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(payload, 'estimateProfitLoss/estimateProfitLoss', idEpl)
                .then((res) => {
                    if (res.data.code === 200) {
                        if (payload.estimateProfitLoss.id === 0) {
                            ApiService.CreateOperation('estimateProfitLoss/estimateProfitLoss', payload)
                                .then((response) => {
                                    if (response.data.code === 200) {
                                        showToast({ type: 'success', message: 'Create Data Success!' });

                                        setTimeout(
                                            function () {
                                                setIsLoadingBackDrop(false);
                                                localStorage.setItem('tempId', JSON.stringify(response.data.data.Id));
                                                history('/Majura/EstimateProfitLoss/' + jobId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                window.location.reload();
                                                localStorage.removeItem('tempId');
                                            },
                                            1250);

                                    }
                                    else {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: response.data.message });
                                    }
                                })
                                .catch(function (error) {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Something Went Wrong!, ' + error });
                                })
                        }
                        else {
                            ApiService.EditOperation('estimateProfitLoss/estimateProfitLoss', payload)
                                .then((response) => {
                                    if (response.data.code === 200) {
                                        if (!isPrint) {
                                            showToast({ type: 'success', message: 'Update Data Success!' });

                                            setTimeout(
                                                function () {
                                                    setIsLoadingBackDrop(false);
                                                    history('/Majura/EstimateProfitLoss/' + jobId + '/' + eplId, { replace: true });
                                                    window.location.reload();
                                                },
                                                1250);
                                        }
                                        else {
                                            Print();
                                        }
                                    }
                                    else {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: response.data.message });
                                    }
                                })
                                .catch(function (error) {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Something Went Wrong!, ' + error });
                                })
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationBC() {
        if (titleConfirmation === 'Print') {
            Save(true);
        }
        setIsModalConfirmation(false);
    }

    const Print = () => {
        setIsLoadingBackDrop(true);
        let reportLink = 'Transactions/Operation/EstimateProfitLossReport/EstimateProfitLossReport/PrintEstimateProfitLoss?Id=' + eplId;
        PrintPDF(setIsLoadingBackDrop, 'Estimate Profit Loss', reportLink, [], true);
    }

    const getDateExchRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    function countTotalData(data) {
        let sISh = 0;
        let nISh = 0;

        let sICo = 0;
        let nICo = 0;

        let sIAg = 0;
        let nIAg = 0;

        let sCSl = 0;
        let nCSl = 0;

        let sCIa = 0;
        let nCIa = 0;

        let sCEm = 0;
        let nCEm = 0;

        let sCSh = 0;
        let nCSh = 0;

        let sCCo = 0;
        let nCCo = 0;

        let sCAg = 0;
        let nCAg = 0;

        let sCDo = 0;
        let nCDo = 0;

        let sCTr = 0;
        let nCTr = 0;

        data.forEach(dt => {
            if (dt.customerTypeId === 1 && dt.isIncome === true) {
                if (dt.bookingDetailId !== 0) {
                    sIAg = parseInt(sIAg) + parseInt(1);
                }
                else {
                    nIAg = parseInt(nIAg) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 2 && dt.isIncome === true) {
                if (dt.bookingDetailId !== 0) {
                    sISh = parseInt(sISh) + parseInt(1);
                }
                else {
                    nISh = parseInt(nISh) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 3 && dt.isIncome === true) {
                if (dt.bookingDetailId !== 0) {
                    sICo = parseInt(sICo) + parseInt(1);
                }
                else {
                    nICo = parseInt(nICo) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 4 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCSl = parseInt(sCSl) + parseInt(1);
                }
                else {
                    nCSl = parseInt(nCSl) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 5 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCIa = parseInt(sCIa) + parseInt(1);
                }
                else {
                    nCIa = parseInt(nCIa) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 6 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCEm = parseInt(sCEm) + parseInt(1);
                }
                else {
                    nCEm = parseInt(nCEm) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 7 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCDo = parseInt(sCDo) + parseInt(1);
                }
                else {
                    nCDo = parseInt(nCDo) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 8 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCSh = parseInt(sCSh) + parseInt(1);
                }
                else {
                    nCSh = parseInt(nCSh) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 9 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCCo = parseInt(sCCo) + parseInt(1);
                }
                else {
                    nCCo = parseInt(nCCo) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 10 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCTr = parseInt(sCTr) + parseInt(1);
                }
                else {
                    nCTr = parseInt(nCTr) + parseInt(1);
                }
            }
            if (dt.customerTypeId === 1 && dt.isIncome === false) {
                if (dt.bookingDetailId !== 0) {
                    sCAg = parseInt(sCAg) + parseInt(1);
                }
                else {
                    nCAg = parseInt(nCAg) + parseInt(1);
                }
            }
        });

        setTotalIncSShipper(sISh);
        setTotalIncNShipper(nISh);

        setTotalIncSConsignee(sICo);
        setTotalIncNConsignee(nICo);

        setTotalIncSAgent(sIAg);
        setTotalIncNAgent(nIAg);

        setTotalCostSSSLine(sCSl);
        setTotalCostNSSLine(nCSl);

        setTotalCostSIATA(sCIa);
        setTotalCostNIATA(nCIa);

        setTotalCostSEMKL(sCEm);
        setTotalCostNEMKL(nCEm);

        setTotalCostSDepo(sCDo);
        setTotalCostNDepo(nCDo);

        setTotalCostSTrucking(sCTr);
        setTotalCostNTrucking(nCTr);

        setTotalCostSDiscountShipper(sCSh);
        setTotalCostNDiscountShipper(nCSh);

        setTotalCostSDiscountConsignee(sCCo);
        setTotalCostNDiscountConsignee(nCCo);

        setTotalCostSAgent(sCAg);
        setTotalCostNAgent(nCAg);
    }

    const countSum = (data) => {
        let serviceType = '';

        ApiService.PostById('regJobType/regJobType', jobId).then((res) => {
            if (res.data.code === 200) {
                serviceType = res.data.data.jobType.serviceType;

                let sumIncUsdShipper = 0
                let sumIncIdrShipper = 0
                let sumIncUsdConsignee = 0
                let sumIncIdrConsignee = 0
                let sumIncUsdAgent = 0
                let sumIncIdrAgent = 0
                let sumCostUsdSSLine = 0
                let sumCostIdrSSLine = 0
                let sumCostUsdIATA = 0
                let sumCostIdrIATA = 0
                let sumCostUsdEMKL = 0
                let sumCostIdrEMKL = 0
                let sumCostUsdDiscountShipper = 0
                let sumCostIdrDiscountShipper = 0
                let sumCostUsdDiscountConsignee = 0
                let sumCostIdrDiscountConsignee = 0
                let sumCostUsdAgent = 0
                let sumCostIdrAgent = 0
                let sumCostUsdDepo = 0
                let sumCostIdrDepo = 0
                let sumCostUsdTrucking = 0
                let sumCostIdrTrucking = 0
                let sumTotalUsd = 0
                let sumTotalIdr = 0
                let sumIncTotalUsd = 0
                let sumIncTotalIdr = 0
                let sumCostTotalUsd = 0
                let sumCostTotalIdr = 0
                let sumCTCIncIdr = 0
                let sumCTCIncUsd = 0
                let sumCTCCostIdr = 0
                let sumCTCCostUsd = 0
                let estUSDShipCons = 0;
                let estIDRShipCons = 0;
                let estUSDAgent = 0;
                let estIDRAgent = 0;

                data.forEach((dt) => {
                    if (dt.customerTypeId === 1 && dt.isIncome === true && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumIncUsdAgent += dt.amountUSD
                            sumIncIdrAgent += dt.amountIDR
                        }
                        else {
                            sumIncUsdAgent -= dt.amountUSD
                            sumIncIdrAgent -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCIncIdr += dt.amountIDR
                                sumCTCIncUsd += dt.amountUSD
                            }
                            else {
                                sumCTCIncIdr -= dt.amountIDR
                                sumCTCIncUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 2 && dt.isIncome === true && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumIncUsdShipper += dt.amountUSD
                            sumIncIdrShipper += dt.amountIDR
                        }
                        else {
                            sumIncUsdShipper -= dt.amountUSD
                            sumIncIdrShipper -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCIncIdr += dt.amountIDR
                                sumCTCIncUsd += dt.amountUSD
                            }
                            else {
                                sumCTCIncIdr -= dt.amountIDR
                                sumCTCIncUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 3 && dt.isIncome === true && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumIncUsdConsignee += dt.amountUSD
                            sumIncIdrConsignee += dt.amountIDR
                        }
                        else {
                            sumIncUsdConsignee -= dt.amountUSD
                            sumIncIdrConsignee -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCIncIdr += dt.amountIDR
                                sumCTCIncUsd += dt.amountUSD
                            }
                            else {
                                sumCTCIncIdr -= dt.amountIDR
                                sumCTCIncUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 4 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdSSLine += dt.amountUSD
                            sumCostIdrSSLine += dt.amountIDR
                        }
                        else {
                            sumCostUsdSSLine -= dt.amountUSD
                            sumCostIdrSSLine -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 5 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdIATA += dt.amountUSD
                            sumCostIdrIATA += dt.amountIDR
                        }
                        else {
                            sumCostUsdIATA -= dt.amountUSD
                            sumCostIdrIATA -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 6 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdEMKL += dt.amountUSD
                            sumCostIdrEMKL += dt.amountIDR
                        }
                        else {
                            sumCostUsdEMKL -= dt.amountUSD
                            sumCostIdrEMKL -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 7 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdDepo += dt.amountUSD
                            sumCostIdrDepo += dt.amountIDR
                        }
                        else {
                            sumCostUsdDepo -= dt.amountUSD
                            sumCostIdrDepo -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 8 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdDiscountShipper += dt.amountUSD
                            sumCostIdrDiscountShipper += dt.amountIDR
                        }
                        else {
                            sumCostUsdDiscountShipper -= dt.amountUSD
                            sumCostIdrDiscountShipper -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 9 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdDiscountConsignee += dt.amountUSD
                            sumCostIdrDiscountConsignee += dt.amountIDR
                        }
                        else {
                            sumCostUsdDiscountConsignee -= dt.amountUSD
                            sumCostIdrDiscountConsignee -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 10 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdTrucking += dt.amountUSD
                            sumCostIdrTrucking += dt.amountIDR
                        }
                        else {
                            sumCostUsdTrucking -= dt.amountUSD
                            sumCostIdrTrucking -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                    if (dt.customerTypeId === 1 && dt.isIncome === false && dt.isIgnoreItem === false) {
                        if (dt.sign === true) {
                            sumCostUsdAgent += dt.amountUSD
                            sumCostIdrAgent += dt.amountIDR
                        }
                        else {
                            sumCostUsdAgent -= dt.amountUSD
                            sumCostIdrAgent -= dt.amountIDR
                        }

                        if (dt.isCostToCost) {
                            if (dt.sign === true) {
                                sumCTCCostIdr += dt.amountIDR
                                sumCTCCostUsd += dt.amountUSD
                            }
                            else {
                                sumCTCCostIdr -= dt.amountIDR
                                sumCTCCostUsd -= dt.amountUSD
                            }
                        }
                    }
                })
                sumIncTotalUsd = sumIncUsdShipper + sumIncUsdConsignee + sumIncUsdAgent
                sumIncTotalIdr = sumIncIdrShipper + sumIncIdrConsignee + sumIncIdrAgent
                sumCostTotalUsd = sumCostUsdSSLine + sumCostUsdIATA + sumCostUsdEMKL + sumCostUsdDiscountShipper
                    + sumCostUsdDiscountConsignee + sumCostUsdAgent + sumCostUsdDepo + sumCostUsdTrucking
                sumCostTotalIdr = sumCostIdrSSLine + sumCostIdrIATA + sumCostIdrEMKL + sumCostIdrDiscountShipper
                    + sumCostIdrDiscountConsignee + sumCostIdrAgent + sumCostIdrDepo + sumCostIdrTrucking
                sumTotalUsd = sumIncTotalUsd - sumCostTotalUsd
                sumTotalIdr = sumIncTotalIdr - sumCostTotalIdr

                if (serviceType === 'Export') {
                    estUSDShipCons = sumIncUsdShipper - sumCostUsdDiscountShipper;
                    estIDRShipCons = sumIncIdrShipper - sumCostIdrDiscountShipper;
                }
                else if (serviceType === 'Import') {
                    estUSDShipCons = sumIncUsdConsignee - sumCostUsdDiscountConsignee;
                    estIDRShipCons = sumIncIdrConsignee - sumCostIdrDiscountConsignee;
                }

                estUSDAgent = sumIncUsdAgent - sumCostUsdAgent;
                estIDRAgent = sumIncIdrAgent - sumCostIdrAgent;

                setEstUSDShipCons(estUSDShipCons);
                setEstIDRShipCons(estIDRShipCons);
                setEstUSDAgent(estUSDAgent);
                setEstIDRAgent(estIDRAgent);

                setEstIncUSDShipperReal(sumIncUsdShipper)
                setEstIncIDRShipperReal(sumIncIdrShipper)
                setEstIncUSDConsigneeReal(sumIncUsdConsignee)
                setEstIncIDRConsigneeReal(sumIncIdrConsignee)
                setEstIncUSDAgentReal(sumIncUsdAgent)
                setEstIncIDRAgentReal(sumIncIdrAgent)
                setEstCostUSDSSLineReal(sumCostUsdSSLine)
                setEstCostIDRSSLineReal(sumCostIdrSSLine)
                setEstCostUSDIATAReal(sumCostUsdIATA)
                setEstCostIDRIATAReal(sumCostIdrIATA)
                setEstCostUSDEMKLReal(sumCostUsdEMKL)
                setEstCostIDREMKLReal(sumCostIdrEMKL)
                setEstCostUSDDiscountShipperReal(sumCostUsdDiscountShipper)
                setEstCostIDRDiscountShipperReal(sumCostIdrDiscountShipper)
                setEstCostUSDDiscountConsigneeReal(sumCostUsdDiscountConsignee)
                setEstCostIDRDiscountConsigneeReal(sumCostIdrDiscountConsignee)
                setEstCostUSDAgentReal(sumCostUsdAgent)
                setEstCostIDRAgentReal(sumCostIdrAgent)
                setEstCostUSDDepoReal(sumCostUsdDepo)
                setEstCostIDRDepoReal(sumCostIdrDepo)
                setEstCostUSDTruckingReal(sumCostUsdTrucking)
                setEstCostIDRTruckingReal(sumCostIdrTrucking)
                setEstTotalUSDReal(sumTotalUsd)
                setEstTotalIDRReal(sumTotalIdr)
                setEstIncTotalUSDReal(sumCTCIncUsd)
                setEstIncTotalIDRReal(sumCTCIncIdr)
                setEstCostTotalUSDReal(sumCTCCostUsd)
                setEstCostTotalIDRReal(sumCTCCostIdr)
                setEstIncUSDShipConsReal(sumIncUsdShipper + sumIncUsdConsignee)
                setEstIncIDRShipConsReal(sumIncIdrShipper + sumIncIdrConsignee)
                setEstCostUSDDiscountReal(sumCostUsdDiscountShipper + sumCostUsdDiscountConsignee)
                setEstCostIDRDiscountReal(sumCostIdrDiscountShipper + sumCostIdrDiscountConsignee)
                setEstCostUSDSSLineIATAReal(sumCostUsdSSLine + sumCostUsdIATA)
                setEstCostIDRSSLineIATAReal(sumCostIdrSSLine + sumCostIdrIATA)

                setEstIncUSDShipper(convertToNominal(sumIncUsdShipper));
                setEstIncIDRShipper(convertToNominal(sumIncIdrShipper));
                setEstIncUSDConsignee(convertToNominal(sumIncUsdConsignee));
                setEstIncIDRConsignee(convertToNominal(sumIncIdrConsignee));
                setEstIncUSDAgent(convertToNominal(sumIncUsdAgent));
                setEstIncIDRAgent(convertToNominal(sumIncIdrAgent));
                setEstCostUSDSSLine(convertToNominal(sumCostUsdSSLine));
                setEstCostIDRSSLine(convertToNominal(sumCostIdrSSLine));
                setEstCostUSDIATA(convertToNominal(sumCostUsdIATA));
                setEstCostIDRIATA(convertToNominal(sumCostIdrIATA));
                setEstCostUSDEMKL(convertToNominal(sumCostUsdEMKL));
                setEstCostIDREMKL(convertToNominal(sumCostIdrEMKL));
                setEstCostUSDDiscountShipper(convertToNominal(sumCostUsdDiscountShipper));
                setEstCostIDRDiscountShipper(convertToNominal(sumCostIdrDiscountShipper));
                setEstCostUSDDiscountConsignee(convertToNominal(sumCostUsdDiscountConsignee));
                setEstCostIDRDiscountConsignee(convertToNominal(sumCostIdrDiscountConsignee));
                setEstCostUSDAgent(convertToNominal(sumCostUsdAgent));
                setEstCostIDRAgent(convertToNominal(sumCostIdrAgent));
                setEstCostUSDDepo(convertToNominal(sumCostUsdDepo));
                setEstCostIDRDepo(convertToNominal(sumCostIdrDepo));
                setEstCostUSDTrucking(convertToNominal(sumCostUsdTrucking));
                setEstCostIDRTrucking(convertToNominal(sumCostIdrTrucking));
                setEstTotalUSD(convertToNominal(sumTotalUsd));
                setEstTotalIDR(convertToNominal(sumTotalIdr));
                setEstIncTotalUSD(convertToNominal(sumCTCIncUsd));
                setEstIncTotalIDR(convertToNominal(sumCTCIncIdr));
                setEstCostTotalUSD(convertToNominal(sumCTCCostUsd));
                setEstCostTotalIDR(convertToNominal(sumCTCCostIdr));
                setEstIncUSDShipCons(convertToNominal(sumIncUsdShipper + sumIncUsdConsignee));
                setEstIncIDRShipCons(convertToNominal(sumIncIdrShipper + sumIncIdrConsignee));
                setEstCostUSDDiscount(convertToNominal(sumCostUsdDiscountShipper + sumCostUsdDiscountConsignee));
                setEstCostIDRDiscount(convertToNominal(sumCostIdrDiscountShipper + sumCostIdrDiscountConsignee));
                setEstCostUSDSSLineIATA(convertToNominal(sumCostUsdSSLine + sumCostUsdIATA));
                setEstCostIDRSSLineIATA(convertToNominal(sumCostIdrSSLine + sumCostIdrIATA));
            }
            else {
                Back();
            }
        }).catch(function (error) {
            console.error('error saat fetch', error)
            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
        })
    }

    const formatCurrency = () => {
        setEstIncUSDShipper(convertToNominal(0));
        setRate(convertToNominal(0));
        setEstIncIDRShipper(convertToNominal(0));
        setEstIncUSDConsignee(convertToNominal(0));
        setEstIncIDRConsignee(convertToNominal(0));
        setEstIncUSDAgent(convertToNominal(0));
        setEstIncIDRAgent(convertToNominal(0));
        setEstCostUSDSSLine(convertToNominal(0));
        setEstCostIDRSSLine(convertToNominal(0));
        setEstCostUSDIATA(convertToNominal(0));
        setEstCostIDRIATA(convertToNominal(0));
        setEstCostUSDEMKL(convertToNominal(0));
        setEstCostIDREMKL(convertToNominal(0));
        setEstCostUSDDiscountShipper(convertToNominal(0));
        setEstCostIDRDiscountShipper(convertToNominal(0));
        setEstCostUSDDiscountConsignee(convertToNominal(0));
        setEstCostIDRDiscountConsignee(convertToNominal(0));
        setEstCostUSDAgent(convertToNominal(0));
        setEstCostIDRAgent(convertToNominal(0));
        setEstCostUSDDepo(convertToNominal(0));
        setEstCostIDRDepo(convertToNominal(0));
        setEstCostUSDTrucking(convertToNominal(0));
        setEstCostIDRTrucking(convertToNominal(0));
        setEstTotalUSD(convertToNominal(0));
        setEstTotalIDR(convertToNominal(0));
        setEstIncTotalUSD(convertToNominal(0));
        setEstIncTotalIDR(convertToNominal(0));
        setEstCostTotalUSD(convertToNominal(0));
        setEstCostTotalIDR(convertToNominal(0));
        setEstIncUSDShipCons(convertToNominal(0));
        setEstIncIDRShipCons(convertToNominal(0));
        setEstCostUSDDiscount(convertToNominal(0));
        setEstCostIDRDiscount(convertToNominal(0));
        setEstCostUSDSSLineIATA(convertToNominal(0));
        setEstCostIDRSSLineIATA(convertToNominal(0));
    }

    const ButtonComponents = () => {
        return (
            <CreateEditEplButton
                isReadOnly={isReadOnly}
                Back={Back}
                Save={() => checkDataCondition('Save')}
                Print={() => checkDataCondition('PrintPDF')}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            eplId ?
                                'Estimate Profit & Loss - ' + jobTypeName + ' (' + shipmentNo + ')'
                                :
                                'Estimate Profit & Loss - ' + jobTypeName + ' (New Document)'
                        }
                        BreadcrumbsItems={eplId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={ticketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <Box component='div'>
                        <Card component="div" style={{ marginTop: '24px', 'borderRadius': '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <EplHeader
                                    infossNo={infossNo}
                                    setInfossNo={setInfossNo}
                                    isReadOnly={isReadOnly}
                                    isEdit={isEdit}
                                    check={check}
                                    Back={Back}
                                    Save={Save}
                                    Print={Print}

                                    isLoadingBackDrop={isLoadingBackDrop}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    DataRegUserAccess={DataRegUserAccess}
                                    selectPrincipleBy={selectPrincipleBy}
                                    selectShipmentStatus={selectShipmentStatus}
                                    shipmentOrderId={shipmentId}
                                    setJobOwnerId={setJobOwnerId}
                                    jobOwnerId={jobOwnerId}
                                    getDateExchRate={getDateExchRate}
                                    exRateDate={exRateDate}
                                    setShipmentId={setShipmentId}
                                    shipmentId={ShipmentId}
                                    setMarketId={setMarketId}
                                    setMarketName={setMarketName}
                                    setMarketCompanyId={setMarketCompanyId}
                                    closeEPL={closeEPL}
                                    setShipperId={setShipperId}
                                    shipperId={shipperId}
                                    shipperName={shipperName}
                                    setShipperName={setShipperName}
                                    consigneeId={consigneeId}
                                    consigneeName={consigneeName}
                                    setAgentId={setAgentId}
                                    agentId={agentId}
                                    agentName={agentName}
                                    setAgentName={setAgentName}
                                    setShipmentStatus={setShipmentStatus}
                                    shipmentStatus={shipmentStatus}
                                    shipmentNo={shipmentNo}
                                    setShipmentNo={setShipmentNo}
                                    handleClick={handleClick}
                                    isModal={isModal}
                                    setIsModal={setIsModal}
                                    jobId={jobTypeId}
                                    setEtd={setEtd}
                                    etd={etd}
                                    setCloseEPL={setCloseEPL}
                                    setTicketId={setTicketId}
                                    setEplNo={setEplNo}
                                    setCostTruckingList={setCostTruckingList}
                                    setIncShipperList={setIncShipperList}
                                    setIncConsigneeList={setIncConsigneeList}
                                    setIncAgentList={setIncAgentList}
                                    setCostSSLineList={setCostSSLineList}
                                    setCostIATAList={setCostIATAList}
                                    setCostEMKLList={setCostEMKLList}
                                    setCostDiscountShipperList={setCostDiscountShipperList}
                                    setCostDiscountConsigneeList={setCostDiscountConsigneeList}
                                    setCostAgentList={setCostAgentList}
                                    setCostDepoList={setCostDepoList}
                                    jobEntryDate={jobEntryDate}
                                    setJobEntryDate={setJobEntryDate}
                                    setRequester={setRequester}
                                    setJobTypeCode={setJobTypeCode}
                                    setJobNumber={setJobNumber}
                                    setSubJobNo={setSubJobNo}
                                    setShipperAddress={setShipperAddress}
                                    setConsigneeId={setConsigneeId}
                                    setConsigneeName={setConsigneeName}
                                    setConsigneeAddress={setConsigneeAddress}
                                    setAgentAddress={setAgentAddress}
                                    eta={eta}
                                    setEta={setEta}
                                    rate={rate}
                                    countSum={countSum}
                                    setDataDetail={setDataDetail}
                                    countTotalData={countTotalData}

                                    volume20={volume20}
                                    setVolume20={setVolume20}
                                    isVolume20={isVolume20}
                                    setIsVolume20={setIsVolume20}
                                    volume40={volume40}
                                    setVolume40={setVolume40}
                                    isVolume40={isVolume40}
                                    setIsVolume40={setIsVolume40}
                                    volume45={volume45}
                                    setVolume45={setVolume45}
                                    isVolume45={isVolume45}
                                    setIsVolume45={setIsVolume45}
                                    volumeCBM={volumeCBM}
                                    setVolumeCBM={setVolumeCBM}
                                    isVolumeCBM={isVolumeCBM}
                                    setIsVolumeCBM={setIsVolumeCBM}
                                    containerSize={containerSize}
                                    setContainerSize={setContainerSize}
                                    isContainer={isContainer}
                                    setIsContainer={setIsContainer}

                                    bookingConfirmationId={bookingConfirmationId}
                                    setBookingConfirmationId={setBookingConfirmationId}
                                    getDataShipment={getDataShipment}
                                    bookingConfirmationNumber={bookingConfirmationNumber}
                                    setBookingConfirmationNumber={setBookingConfirmationNumber}
                                />

                                <TabEPL
                                    isReadOnly={isReadOnly}
                                    serviceType={serviceType}
                                    portType={portType}
                                    jobId={jobTypeId}
                                    agentId={agentId}
                                    agentName={agentName}
                                    shipperId={shipperId}
                                    shipperName={shipperName}
                                    consigneeId={consigneeId}
                                    consigneeName={consigneeName}
                                    setSelectedDetail={setSelectedDetail}
                                    selectedDetail={selectedDetail}
                                    IncShipperList={IncShipperList}
                                    setIncShipperList={setIncShipperList}
                                    IncConsigneeList={IncConsigneeList}
                                    setIncConsigneeList={setIncConsigneeList}
                                    IncAgentList={IncAgentList}
                                    setIncAgentList={setIncAgentList}
                                    CostSSLineList={CostSSLineList}
                                    setCostSSLineList={setCostSSLineList}
                                    CostIATAList={CostIATAList}
                                    setCostIATAList={setCostIATAList}
                                    CostEMKLList={CostEMKLList}
                                    setCostEMKLList={setCostEMKLList}
                                    CostDiscountShipperList={CostDiscountShipperList}
                                    setCostDiscountShipperList={setCostDiscountShipperList}
                                    CostDiscountConsigneeList={CostDiscountConsigneeList}
                                    setCostDiscountConsigneeList={setCostDiscountConsigneeList}
                                    CostAgentList={CostAgentList}
                                    setCostAgentList={setCostAgentList}
                                    CostDepoList={CostDepoList}
                                    setCostDepoList={setCostDepoList}
                                    CostTruckingList={CostTruckingList}
                                    setCostTruckingList={setCostTruckingList}
                                    setDataDetail={setDataDetail}
                                    dataDetail={dataDetail}
                                    rate={rate}
                                    ignoreAccess={ignoreAccess}
                                    ShipmentId={ShipmentId}
                                    shipmentNo={shipmentNo}
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                    containerSizeData={containerSizeData}

                                    selectedDetailIncShipper={selectedDetailIncShipper}
                                    setSelectedDetailIncShipper={setSelectedDetailIncShipper}
                                    selectedDetailIncConsignee={selectedDetailIncConsignee}
                                    setSelectedDetailIncConsignee={setSelectedDetailIncConsignee}
                                    selectedDetailIncAgent={selectedDetailIncAgent}
                                    setSelectedDetailIncAgent={setSelectedDetailIncAgent}
                                    selectedDetailCostSSLine={selectedDetailCostSSLine}
                                    setSelectedDetailCostSSLine={setSelectedDetailCostSSLine}
                                    selectedDetailCostIATA={selectedDetailCostIATA}
                                    setSelectedDetailCostIATA={setSelectedDetailCostIATA}
                                    selectedDetailCostEMKL={selectedDetailCostEMKL}
                                    setSelectedDetailCostEMKL={setSelectedDetailCostEMKL}
                                    selectedDetailCostDiscountShipper={selectedDetailCostDiscountShipper}
                                    setSelectedDetailCostDiscountShipper={setSelectedDetailCostDiscountShipper}
                                    selectedDetailCostDiscountConsignee={selectedDetailCostDiscountConsignee}
                                    setSelectedDetailCostDiscountConsignee={setSelectedDetailCostDiscountConsignee}
                                    selectedDetailCostAgent={selectedDetailCostAgent}
                                    setSelectedDetailCostAgent={setSelectedDetailCostAgent}
                                    selectedDetailCostDepo={selectedDetailCostDepo}
                                    setSelectedDetailCostDepo={setSelectedDetailCostDepo}
                                    selectedDetailCostTrucking={selectedDetailCostTrucking}
                                    setSelectedDetailCostTrucking={setSelectedDetailCostTrucking}

                                    countSum={countSum}
                                    countTotalData={countTotalData}

                                    estIncUSDShipper={estIncUSDShipper}
                                    estIncIDRShipper={estIncIDRShipper}
                                    estIncUSDConsignee={estIncUSDConsignee}
                                    estIncIDRConsignee={estIncIDRConsignee}
                                    estIncUSDAgent={estIncUSDAgent}
                                    estIncIDRAgent={estIncIDRAgent}
                                    estCostUSDSSLine={estCostUSDSSLine}
                                    estCostIDRSSLine={estCostIDRSSLine}
                                    estCostUSDIATA={estCostUSDIATA}
                                    estCostIDRIATA={estCostIDRIATA}
                                    estCostUSDEMKL={estCostUSDEMKL}
                                    estCostIDREMKL={estCostIDREMKL}
                                    estCostUSDDiscountShipper={estCostUSDDiscountShipper}
                                    estCostIDRDiscountShipper={estCostIDRDiscountShipper}
                                    estCostUSDDiscountConsignee={estCostUSDDiscountConsignee}
                                    estCostIDRDiscountConsignee={estCostIDRDiscountConsignee}
                                    estCostUSDAgent={estCostUSDAgent}
                                    estCostIDRAgent={estCostIDRAgent}
                                    estCostUSDDepo={estCostUSDDepo}
                                    estCostIDRDepo={estCostIDRDepo}
                                    estCostUSDTrucking={estCostUSDTrucking}
                                    estCostIDRTrucking={estCostIDRTrucking}

                                    totalIncSShipper={totalIncSShipper}
                                    totalIncNShipper={totalIncNShipper}
                                    totalIncSConsignee={totalIncSConsignee}
                                    totalIncNConsignee={totalIncNConsignee}
                                    totalIncSAgent={totalIncSAgent}
                                    totalIncNAgent={totalIncNAgent}
                                    totalCostSSSLine={totalCostSSSLine}
                                    totalCostNSSLine={totalCostNSSLine}
                                    totalCostSIATA={totalCostSIATA}
                                    totalCostNIATA={totalCostNIATA}
                                    totalCostSEMKL={totalCostSEMKL}
                                    totalCostNEMKL={totalCostNEMKL}
                                    totalCostSDepo={totalCostSDepo}
                                    totalCostNDepo={totalCostNDepo}
                                    totalCostSTrucking={totalCostSTrucking}
                                    totalCostNTrucking={totalCostNTrucking}
                                    totalCostSDiscountShipper={totalCostSDiscountShipper}
                                    totalCostNDiscountShipper={totalCostNDiscountShipper}
                                    totalCostSDiscountConsignee={totalCostSDiscountConsignee}
                                    totalCostNDiscountConsignee={totalCostNDiscountConsignee}
                                    totalCostSAgent={totalCostSAgent}
                                    totalCostNAgent={totalCostNAgent}

                                    containerSize={containerSize}
                                    setContainerSize={setContainerSize}
                                    isContainer={isContainer}
                                    setIsContainer={setIsContainer}
                                    volume20={volume20}
                                    setVolume20={setVolume20}
                                    isVolume20={isVolume20}
                                    setIsVolume20={setIsVolume20}
                                    volume40={volume40}
                                    setVolume40={setVolume40}
                                    isVolume40={isVolume40}
                                    setIsVolume40={setIsVolume40}
                                    volume45={volume45}
                                    setVolume45={setVolume45}
                                    isVolume45={isVolume45}
                                    setIsVolume45={setIsVolume45}
                                    volumeCBM={volumeCBM}
                                    setVolumeCBM={setVolumeCBM}
                                    isVolumeCBM={isVolumeCBM}
                                    setIsVolumeCBM={setIsVolumeCBM}

                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                />
                            </CardContent>
                        </Card>

                        <Card component="div" style={{ marginTop: '24px', 'borderRadius': '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <TotalEpl
                                    isReadOnly={isReadOnly}
                                    jobId={jobTypeId}
                                    estIncUSDShipCons={estIncUSDShipCons}
                                    estIncIDRShipCons={estIncIDRShipCons}
                                    estCostUSDDiscount={estCostUSDDiscount}
                                    estCostIDRDiscount={estCostIDRDiscount}
                                    estCostUSDSSLineIATA={estCostUSDSSLineIATA}
                                    estCostIDRSSLineIATA={estCostIDRSSLineIATA}

                                    estIncUSDShipper={estIncUSDShipper}
                                    estIncIDRShipper={estIncIDRShipper}
                                    estIncUSDConsignee={estIncUSDConsignee}
                                    estIncIDRConsignee={estIncIDRConsignee}
                                    estIncUSDAgent={estIncUSDAgent}
                                    estIncIDRAgent={estIncIDRAgent}

                                    estCostUSDSSLine={estCostUSDSSLine}
                                    estCostIDRSSLine={estCostIDRSSLine}
                                    estCostUSDIATA={estCostUSDIATA}
                                    estCostIDRIATA={estCostIDRIATA}
                                    estCostUSDEMKL={estCostUSDEMKL}
                                    estCostIDREMKL={estCostIDREMKL}
                                    estCostUSDDiscountShipper={estCostUSDDiscountShipper}
                                    estCostIDRDiscountShipper={estCostIDRDiscountShipper}
                                    estCostUSDDiscountConsignee={estCostUSDDiscountConsignee}
                                    estCostIDRDiscountConsignee={estCostIDRDiscountConsignee}
                                    estCostUSDAgent={estCostUSDAgent}
                                    estCostIDRAgent={estCostIDRAgent}
                                    estCostUSDDepo={estCostUSDDepo}
                                    estCostIDRDepo={estCostIDRDepo}
                                    estCostUSDTrucking={estCostUSDTrucking}
                                    estCostIDRTrucking={estCostIDRTrucking}

                                    estTotalUSD={estTotalUSD}
                                    estTotalIDR={estTotalIDR}
                                    estIncTotalUSD={estIncTotalUSD}
                                    estIncTotalIDR={estIncTotalIDR}
                                    estCostTotalUSD={estCostTotalUSD}
                                    estCostTotalIDR={estCostTotalIDR}

                                    estIncUSDShipConsReal={estIncUSDShipConsReal}
                                    setEstIncUSDShipConsReal={setEstIncUSDShipConsReal}
                                    estIncIDRShipConsReal={estIncIDRShipConsReal}
                                    setEstIncIDRShipConsReal={setEstIncIDRShipConsReal}
                                    estCostUSDDiscountReal={estCostUSDDiscountReal}
                                    setEstCostUSDDiscountReal={setEstCostUSDDiscountReal}
                                    estCostIDRDiscountReal={estCostIDRDiscountReal}
                                    setEstCostIDRDiscountReal={setEstCostIDRDiscountReal}
                                    estCostUSDSSLineIATAReal={estCostUSDSSLineIATAReal}
                                    setEstCostUSDSSLineIATAReal={setEstCostUSDSSLineIATAReal}
                                    estCostIDRSSLineIATAReal={estCostIDRSSLineIATAReal}
                                    setEstCostIDRSSLineIATAReal={setEstCostIDRSSLineIATAReal}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationBC}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditEpl