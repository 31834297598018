import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import ModalAllPPh23 from '../Modals/ModalAllPPh23';
import { showToast } from '../../../../../components/ui';
import ModalPaymentDetail from '../Modals/ModalPaymentDetail';
import { convertToNominal } from '../../../../../utils/masks.jsx';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { defaultDate, setDateFormat, updateDateFormat } from '../../../../../utils/date.jsx';

const PaymentVoucherDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [size, setSize] = useState('86%');
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [RefId, setRefId] = useState(0);
    const [RefNo, setRefNo] = useState('');
    const [ShipmentId, setShipmentId] = useState(0);
    const [ShipmentNo, setShipmentNo] = useState('');
    const [ExRateId, setExRateId] = useState(0);
    const [RefRate, setRefRate] = useState(0);
    const [RefAccountId, setRefAccountId] = useState(0);

    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [Description, setDescription] = useState('');
    const [DCNote, setDCNote] = useState('D');
    const [AmountUSD, setAmountUSD] = useState(0);
    const [AmountIDR, setAmountIDR] = useState(0);
    const [AmountTaxUSD, setAmountTaxUSD] = useState(0);
    const [AmountTaxIDR, setAmountTaxIDR] = useState(0);
    const [DifferentUSD, setDifferentUSD] = useState(0);
    const [DifferentIDR, setDifferentIDR] = useState(0);

    const [isDisabled, setIsDisabled] = useState(false);
    const [isAccount, setIsAccount] = useState(false);

    const [isAmountCrr0, setIsAmountCrr0] = useState(false);
    const [isAmountCrr1, setIsAmountCrr1] = useState(false);

    const [isModalPPh, setIsModalPPh] = useState(false);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddPaymentDetail = () => {
        let check = props.checkInput();

        if (check === 0) {
            emptyState();
            if (props.PaymentBy === 'CU' || props.PaymentBy === 'BU') {
                setIsAmountCrr0(false);
                setIsAmountCrr1(true);
            }
            else {
                setIsAmountCrr0(true);
                setIsAmountCrr1(false);
            }

            if (props.SettlementTo === 'G') {
                setRefRate(props.Rate);
            }

            setIsDisabled(false);
            handleBoxSize();
            setTitleModal('Payment Voucher Detail');
            setDetailEdit(false);
            setExRateId(props.ExRateId);
            if (props.DataPaymentDetail.length > 0) {
                let data = props.DataPaymentDetail.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            handleClick();
        }
    };

    const ShowEditPaymentDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                if (props.PaymentBy === 'CU' || props.PaymentBy === 'BU') {
                    setIsAmountCrr0(false);
                    setIsAmountCrr1(true);
                }
                else {
                    setIsAmountCrr0(true);
                    setIsAmountCrr1(false);
                }

                setTitleModal('Payment Voucher Detail');
                setDetailEdit(true);

                setRefId(selectedDetail.refId);
                if (selectedDetail.refId === 0 || selectedDetail.refId === undefined) {
                    setIsDisabled(true);
                }
                else {
                    setIsDisabled(true);
                }
                setRefNo(selectedDetail.refNo);
                setShipmentId(selectedDetail.shipmentOrderId);
                setShipmentNo(selectedDetail.shipmentNo);
                setExRateId(selectedDetail.exRateId);
                setRefRate(selectedDetail.refRate);
                setRefAccountId(selectedDetail.refAccountId);

                setAccountCode(selectedDetail.coaCode);
                if (selectedDetail.accountCode === '' || selectedDetail.accountCode === undefined) {
                    setIsAccount(false);
                }
                else {
                    setIsAccount(true);
                }
                setAccountName(selectedDetail.coaName);
                setDescription(selectedDetail.description);
                setDCNote(selectedDetail.dcNote);
                setAmountUSD(selectedDetail.amountUSD + selectedDetail.amountTaxUSD);
                setAmountIDR(selectedDetail.amountIDR + selectedDetail.amountTaxIDR);
                setAmountTaxUSD(selectedDetail.amountTaxUSD);
                setAmountTaxIDR(selectedDetail.amountTaxIDR);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeletePaymentDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempRefDetailId = props.SettlementTo === 'I' ? selectedDetail.refId : selectedDetail.refDetailId;
            let tempSequence = selectedDetail.sequence;
            let tempIsBack = selectedDetail.isStored;
            let tempPPh23Amount = 0;

            const deleteRefId = (data) => {
                return data !== tempRefDetailId;
            }
            const filteredArray = props.DataPaymentDetailId.filter(deleteRefId);

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }
            let result = props.DataPaymentDetail.filter(deleteFunction);

            if (tempRefDetailId !== 0) {
                const deleteSameSourceFunction = (data) => {
                    return data.refId !== tempRefDetailId;
                }

                result = result.filter(deleteSameSourceFunction);
            }

            tempSequence = 0;

            if (result.length > 0) {
                let dateDueLast = defaultDate;
                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;

                    if (props.SettlementTo !== 'G' && tempIsBack === true) {
                        el.isStored = true;
                    }

                    if (el.paymentVoucherDetailBanks.length > 0) {
                        el.paymentVoucherDetailBanks.forEach(element => {
                            if (new Date(dateDueLast) < new Date(element.dueDate)) {
                                dateDueLast = element.dueDate;
                            }
                        });
                    }
                })

                if (dateDueLast === defaultDate) {
                    dateDueLast = updateDateFormat(new Date());
                }
                props.setDueDateLast(dateDueLast);
            }
            else {
                props.setIsAddValue(false);
                setDateFormat(new Date(), props.setDueDateLast);
            }

            setDetailSequence(tempSequence);

            if (props.paymentBy === 'CU' || props.paymentBy === 'BU') {
                result.forEach(element => {
                    if (element.isPPh23 === true) {
                        tempPPh23Amount = parseFloat(tempPPh23Amount) + (parseFloat(element.amountUSD) * parseFloat(props.PPh23Percent) / parseFloat(100));
                    }
                });
            }
            else {
                result.forEach(element => {
                    if (element.isPPh23 === true) {
                        tempPPh23Amount = parseFloat(tempPPh23Amount) + (parseFloat(element.amountIDR) * parseFloat(props.PPh23Percent) / parseFloat(100));
                    }
                });
            }

            props.setPPh23AmountReal(tempPPh23Amount);
            let countPPH = convertToNominal(tempPPh23Amount);
            props.setPPh23Amount(countPPH);

            props.setDataPaymentDetail(result);
            props.setDataPaymentDetailId(filteredArray);
            props.setBankData([]);
            props.setCashPayment(0);
            props.setSelectedPaymentVoucher({});

            props.calculateTotalDebitCredit(result, props.Rate, props.PaymentBy, tempPPh23Amount);

            setSelectedDetail({});
            emptyState();
        }
    }

    const handleClickPPh = () => {
        if (isModalPPh === false) {
            setIsModalPPh(true);
        }
        else {
            setIsModalPPh(false);
        }
    }

    const PPH23Set = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let newArr = props.DataPaymentDetail;
            let check = 0;

            if (newArr.length > 1) {
                newArr.forEach(element => {
                    if (element.isPPh23 === true) {
                        check = 1;
                    }
                });

                if (check === 0) {
                    handleClickPPh();
                }
                else {
                    SetPPh23('No');
                }
            }
            else {
                SetPPh23('No');
            }
        }
    }

    const SetPPh23 = (value) => {
        if (value === 'No') {
            props.setIsAllPPh(false);
            let newArr = props.DataPaymentDetail;
            let tempAmount = 0;
            let tempPPh23Amount = 0;
            let totalTempAmount = 0;
            let tempIsPPh23 = false;
            let tempCashPayment = 0;

            newArr.forEach((el) => {
                if (el.sequence === selectedDetail.sequence) {
                    tempCashPayment = el.paymentCash;
                    tempIsPPh23 = el.isPPh23;
                    if (el.isPPh23 !== true) {
                        props.setTempIsPPh23(true);
                        props.setTempSeq(el.sequence);
                        if (props.paymentBy === 'CU' || props.paymentBy === 'BU') {
                            tempPPh23Amount = parseFloat(el.amountUSD) * parseFloat(props.PPh23Percent) / parseFloat(100);
                            tempAmount = parseFloat(el.amountUSD) + parseFloat(el.amountTaxUSD) - parseFloat(tempPPh23Amount);
                        }
                        else {
                            tempPPh23Amount = parseFloat(el.amountIDR) * parseFloat(props.PPh23Percent) / parseFloat(100);
                            tempAmount = parseFloat(el.amountIDR) + parseFloat(el.amountTaxIDR) - parseFloat(tempPPh23Amount);
                        }
                    }
                }
            });

            if (tempIsPPh23 === false) {
                if (props.isInput === false) {
                    totalTempAmount = parseFloat(props.PPh23AmountReal) + parseFloat(tempPPh23Amount);
                    props.setTempAmount(tempAmount);
                    props.setPPh23AmountReal(totalTempAmount);
                    let countPPH = convertToNominal(totalTempAmount);
                    props.setPPh23Amount(countPPH);
                    props.setTempMinus(tempPPh23Amount);

                    if (props.PaymentBy === 'CU' || props.PaymentBy === 'CI') {
                        if (tempCashPayment === 0) {
                            props.setCashPayment(tempAmount);
                        }
                    }

                    props.setIsInput(true);

                    props.calculateTotalDebitCredit(props.DataPaymentDetail, props.Rate, props.PaymentBy, totalTempAmount);
                }
            }
        }
        else {
            props.setIsAllPPh(true);
            let newArr = props.DataPaymentDetail;
            let tempAmount = 0;
            let tempPPh23Amount = 0;
            let tempPaymentAmount = 0;
            props.setTempIsPPh23(true);
            newArr.forEach((el) => {
                el.isPPh23 = true;
                if (props.PaymentBy === 'CU' || props.PaymentBy === 'BU') {
                    tempPPh23Amount = parseFloat(tempPPh23Amount) + (parseFloat(el.amountUSD) * parseFloat(props.PPh23Percent) / parseFloat(100));
                    tempAmount = parseFloat(el.amountUSD) + parseFloat(el.amountTaxUSD) + parseFloat(tempAmount);
                }
                else {
                    tempPPh23Amount = parseFloat(tempPPh23Amount) + (parseFloat(el.amountIDR) * parseFloat(props.PPh23Percent) / parseFloat(100));
                    tempAmount = parseFloat(el.amountIDR) + parseFloat(el.amountTaxIDR) + parseFloat(tempAmount);
                }
            });
            tempPaymentAmount = parseFloat(tempAmount) - parseFloat(tempPPh23Amount);
            props.setTempAmount(tempPaymentAmount);
            props.setPPh23AmountReal(tempPPh23Amount);
            let countPPH = convertToNominal(tempPPh23Amount);
            props.setPPh23Amount(countPPH);
            props.setTempMinus(tempPPh23Amount);

            if (props.PaymentBy === 'CU' || props.PaymentBy === 'CI') {
                props.setCashPayment(tempPaymentAmount);
            }

            props.setIsInput(true);
            props.setDataPaymentDetail(newArr);

            props.calculateTotalDebitCredit(newArr, props.Rate, props.PaymentBy, tempPPh23Amount);

            handleClickPPh();
        }
        setIsModalPPh(false);
    }

    const Reset = () => {
        let newArr = props.DataPaymentDetail;
        newArr.forEach((el) => {
            el.isPPh23 = false;
        });

        props.setIsAllPPh(false);
        props.setDataPaymentDetail(newArr);

        props.setTempAmount(0);
        props.setTempIsPPh23(false);
        props.setTempSeq(0);
        let countPPH = convertToNominal(0);
        props.setPPh23Amount(countPPH);
        props.setPPh23AmountReal(0);
        props.setIsInput(false);
        props.setTempMinus(0);
        setSelectedDetail({});
        props.setSelectedPaymentVoucher({});

        if (props.PaymentBy === 'CU' || props.PaymentBy === 'CI') {
            props.setCashPayment(0);
        }

        props.calculateTotalDebitCredit(props.DataPaymentDetail, props.Rate, props.PaymentBy, 0);
    }

    function handleBoxSize() {
        if (props.SettlementTo === 'G') {
            setSize('56%');
        }
        else {
            setSize('80%');
        }
    }

    function handleSelected(val) {
        setSelectedDetail(val);
        props.setTempIsPPh23(false);
        props.setTempSeq(0);
        props.setSelectedPaymentVoucher(val);
        props.setBankData(val.paymentVoucherDetailBanks);
        props.setCashPayment(val.paymentCash);

        if (props.isInput === true) {
            let temp = props.PPh23AmountReal;
            temp = parseFloat(temp) - parseFloat(props.TempMinus);
            props.setTempAmount(0);
            let countPPH = convertToNominal(temp);
            props.setPPh23Amount(countPPH);
            props.setPPh23AmountReal(temp);
            props.setIsInput(false);
            let newArray = props.DataPaymentDetail;

            if (props.isAllPPh === true) {
                newArray.forEach(element => {
                    element.isPPh23 = false;
                });
                props.setDataPaymentDetail(newArray);
            }
            props.setIsAllPPh(false);
            props.calculateTotalDebitCredit(newArray, props.Rate, props.PaymentBy, temp);
        }

        if (val.paymentBy === 'CU' || val.paymentBy === 'CI') {
            props.setIsCash(false);
            props.setIsBank(true);
        }
        else {
            props.setIsCash(true);
            props.setIsBank(false);
        }
    }

    const emptyState = () => {
        setRefId(0);
        setRefNo('');
        setShipmentId(0);
        setShipmentNo('');
        setExRateId(0);
        setRefRate(0);

        setDescription('');
        setDCNote('D');
        setAmountUSD(0);
        setAmountIDR(0);
        setDifferentUSD(0);
        setDifferentIDR(0);
    };

    const handleDoubleClick = () => {
        ShowEditPaymentDetail()
    }

    return (
        <>
            <div>
                <form>
                    <Box>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            Payment Detail
                        </Typography>

                        <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px', marginTop: '16px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Account Code</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell>D/C</StyledTableCell>
                                        {
                                            props.PaymentBy === 'CU' || props.PaymentBy === 'BU' ?
                                                <StyledTableCell sx={{ textAlign: 'right' }}>Amount USD</StyledTableCell>
                                                :
                                                <StyledTableCell sx={{ textAlign: 'right' }}>Amount IDR</StyledTableCell>
                                        }
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Ref No</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Shipment</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>PPh23</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right' }}>Rate</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.DataPaymentDetail.length > 0 ?
                                            props.DataPaymentDetail.map((el) => {
                                                return (
                                                    <TableRow
                                                        key={el.sequence}
                                                        onClick={() => handleSelected(el)}
                                                        onDoubleClick={() => handleDoubleClick()}
                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                        hover={true}
                                                    >
                                                        <StyledTableBodyCell style={{ textAlign: 'center' }}>
                                                            <Tooltip title={el.coaName}>
                                                                <span>{el.coaCode}</span>
                                                            </Tooltip>
                                                        </StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.dcNote === 'C' ? 'Credit' : 'Debit'}</StyledTableBodyCell>
                                                        {
                                                            props.PaymentBy === 'CU' || props.PaymentBy === 'BU' ?
                                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.amountUSD + el.amountTaxUSD)}</StyledTableBodyCell>
                                                                :
                                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.amountIDR + el.amountTaxIDR)}</StyledTableBodyCell>
                                                        }
                                                        <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.refNo}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.shipmentNo !== '' ? el.shipmentNo : '-'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.isPPh23 === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.refRate)}</StyledTableBodyCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{ marginTop: '16px' }}>
                            <Stack spacing={'10px'} direction='row'>
                                {
                                    props.printing === 0 ?
                                        <>
                                            {ButtonComponent.AddButton(ShowAddPaymentDetail)}
                                            {ButtonComponent.EditButton(ShowEditPaymentDetail)}
                                            {ButtonComponent.DeleteButton(DeletePaymentDetail)}
                                            {ButtonComponent.PPH23Button(PPH23Set, !props.IsPPh23)}
                                            {ButtonComponent.ResetButton(Reset, !props.IsPPh23)}
                                        </>
                                        :
                                        <>
                                            {ButtonComponent.AddButton(ShowAddPaymentDetail, '', props.printing > 0)}
                                            {ButtonComponent.EditButton(ShowEditPaymentDetail, '', props.isApprovedAcc)}
                                            {ButtonComponent.DeleteButton(DeletePaymentDetail, '', props.printing > 0)}
                                            {ButtonComponent.PPH23Button(PPH23Set, props.printing > 0)}
                                            {ButtonComponent.ResetButton(Reset, props.printing > 0)}
                                        </>
                                }
                            </Stack>
                        </div>
                    </Box>
                </form>
            </div>

            {
                isModal && (
                    <ModalPaymentDetail
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        PPh23Percent={props.PPh23Percent}
                        printing={props.printing}
                        PaymentBy={props.PaymentBy}
                        selectedDetail={selectedDetail}
                        IsPPh23={props.IsPPh23}
                        setPPh23AmountReal={props.setPPh23AmountReal}
                        SettlementTo={props.SettlementTo}
                        SettlementToName={props.SettlementToName}
                        setCashPayment={props.setCashPayment}
                        setPPh23Amount={props.setPPh23Amount}
                        detailEdit={detailEdit}
                        size={size}
                        paymentVoucherId={props.paymentVoucherId}
                        isAmountCrr0={isAmountCrr0}
                        isAmountCrr1={isAmountCrr1}
                        setDetailEdit={setDetailEdit}
                        setSelectedDetail={setSelectedDetail}
                        PPh23Amount={props.PPh23AmountReal}
                        CustomerTo={props.CustomerTo}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        Rate={props.Rate}
                        ContactId={props.ContactId}
                        isPosted={props.isPosted}
                        DataPaymentDetail={props.DataPaymentDetail}
                        setDataPaymentDetail={props.setDataPaymentDetail}
                        isApprovedAcc={props.isApprovedAcc}
                        DataPaymentDetailId={props.DataPaymentDetailId}
                        setDataPaymentDetailId={props.setDataPaymentDetailId}
                        RateReal={props.RateReal}
                        RefId={RefId}
                        setRefId={setRefId}
                        RefNo={RefNo}
                        setRefNo={setRefNo}
                        ShipmentId={ShipmentId}
                        setShipmentId={setShipmentId}
                        ShipmentNo={ShipmentNo}
                        setShipmentNo={setShipmentNo}
                        ExRateId={ExRateId}
                        setExRateId={setExRateId}
                        RefRate={RefRate}
                        setRefRate={setRefRate}
                        RefAccountId={RefAccountId}
                        setRefAccountId={setRefAccountId}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        Description={Description}
                        setDescription={setDescription}
                        DCNote={DCNote}
                        setDCNote={setDCNote}
                        AmountUSD={AmountUSD}
                        setAmountUSD={setAmountUSD}
                        AmountIDR={AmountIDR}
                        setAmountIDR={setAmountIDR}
                        AmountTaxUSD={AmountTaxUSD}
                        setAmountTaxUSD={setAmountTaxUSD}
                        AmountTaxIDR={AmountTaxIDR}
                        setAmountTaxIDR={setAmountTaxIDR}
                        DifferentUSD={DifferentUSD}
                        setDifferentUSD={setDifferentUSD}
                        DifferentIDR={DifferentIDR}
                        setDifferentIDR={setDifferentIDR}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                        isAccount={isAccount}
                        setIsAccount={setIsAccount}
                        calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                    />
                )
            }

            {
                isModalPPh && (
                    <ModalAllPPh23
                        isModal={isModalPPh}
                        handleClick={handleClickPPh}
                        SetPPh23={SetPPh23}
                    />
                )
            }
        </>
    )
}

export default PaymentVoucherDetail;