import { Backdrop, Box, Grid, MenuItem, Card, CardContent, Stack, InputAdornment, IconButton } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import SelectDataPeriod from './Selects/SelectDataPeriod';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonEstimateIncomeStatementView from './Buttons/ButtonEstimateIncomeStatementView';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const EstimateIncomeStatementView = forwardRef((props, ref) => {
    const [isLoadingPrint, setIsLoadingPrint] = useState(true);
    const [isPerClosingPeriod, setIsPerClosingPeriod] = useState(true);
    const [bPeriod, setBPeriod] = useState('');
    const [bYearPeriod, setBYearPeriod] = useState('');
    const [bStartPeriod, setBStartPeriod] = useState('');
    const [bEndPeriod, setBEndPeriod] = useState('');

    const paramP = "regClosingPeriod/regClosingPeriod";
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [isModalP, setIsModalP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [titleModalP, setTitleModalP] = useState('');
    const [columnDataP, setColumnDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [dataMapP, setDataMapP] = useState([]);

    const closingTypeList = [
        { id: 1, code: true, name: 'Per Closing Period' },
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Estimate Income Statement Report', link: '/Majura/EstimateIncomeStatementReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Estimate Income Statement - ' + JSON.parse(localStorage.getItem('branchName'));
        setTimeout(function () { setIsLoadingPrint(false); }, 2000);
    }, []);

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationEIS() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls")
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (bPeriod === '') {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Period!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('Estimate Income Statement Report');
            handleClickConfirmation();
        }
    };

    const PrintExcelData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Estimate Income Statement Report');
            handleClickConfirmation();
        }
    };

    const handleClickPeriod = () => {
        if (isModalP === false) {
            setTitleModalP('Closing Period');
            getDataPeriod(1, 50);
            setIsModalP(true);
        }
        else {
            setIsModalP(false);
        }
    };

    const EmptyPeriod = () => {
        setBPeriod('');
        setBYearPeriod('');
        setBStartPeriod('');
        setBEndPeriod('');
    }

    const getDataPeriod = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.closingPeriod);
                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)
                    setIsLoadingP(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingP(false)
            })
    }

    const PrintReport = (code) => {
        setIsLoadingPrint(true)
        let params = [
            {
                "attr": "Month",
                "value": '' + bPeriod
            },
            {
                "attr": "Year",
                "value": '' + bYearPeriod
            }
        ]

        let reportLink = 'Reports/Finance/Print/FinanceReport/EstIncStatementReport/EstIncStatementReport/PrintEstimateIncomeStatement?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(setIsLoadingPrint, 'Estimate Income Statement', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'Estimate Income Statement', reportLink, params);
        }
    };

    const ButtonComponents = () => {
        return (
            <ButtonEstimateIncomeStatementView
                PrintData={PrintData}
                PrintExcel={PrintExcelData}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Estimate Income Statement Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            value={isPerClosingPeriod}
                                            label="Custom Period"
                                            fullWidth={true}
                                            placeholder={'Select Custom Period'}
                                            onChange={(e) => setIsPerClosingPeriod(e.target.value)}
                                        >
                                            {
                                                closingTypeList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                label={'Period'}
                                                value={bPeriod}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                }}
                                                disabled
                                            />

                                            <Input
                                                label="Year Period"
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={bYearPeriod}
                                                type='text'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                disabled
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyPeriod('Beginning')}
                                                                size='small'
                                                                hidden={bPeriod ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleClickPeriod('Beginning')}
                                                                size='small'
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label="Beginning Period"
                                                    value={bStartPeriod}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label="Ending Period"
                                                    value={bEndPeriod}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalP && (
                    <SelectDataPeriod
                        isModal={isModalP}
                        setIsModal={setIsModalP}
                        titleModal={titleModalP}
                        handleClick={handleClickPeriod}
                        isLoading={isLoadingP}
                        data={dataP}
                        getData={getDataPeriod}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}

                        setBPeriod={setBPeriod}
                        setBYearPeriod={setBYearPeriod}
                        setBStartPeriod={setBStartPeriod}
                        setBEndPeriod={setBEndPeriod}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationEIS}
                    />
                )
            }
        </Box>
    )
}
)
export default EstimateIncomeStatementView