import React, { forwardRef, useState, useEffect, useRef } from 'react'
import { Backdrop, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router';

import CustomerServiceReport from './CustomerServiceReport';
import GeneralButton from './components/GeneralButton';
import FilterSection from './components/FilterSection';

import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import TableX from '../../../components/Tables/TableX';
import ApiService from '../../../services/ApiService';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { updateDateFormat } from '../../../utils/date';
import { PrintExcel, PrintPDF } from '../../../utils/reusableFunction';

const CustomerServiceList = forwardRef((props, ref) => {
  const date = updateDateFormat(new Date());
  const history = useNavigate();
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true)
  const [isLoadingModal, setIsLoadingModal] = useState(true)
  const [isOpenReport, setIsOpenReport] = useState(false)

  const [dataTable, setDataTable] = useState([])
  const [columnData, setColumnData] = useState([])
  const [selectedData, setSelectedData] = useState({});

  // Filter
  const [goTo, setGoTo] = useState(1)
  const [stateX, setStateX] = useState({})
  const [filter, setFilter] = useState([])
  const [numPage, setNumPage] = useState(0)
  const [sortArr, setSortArr] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [rowsCount, setRowsCount] = useState(50)
  const [totalRows, setTotalRows] = useState(0)

  const [jobTypeList, setJobTypeList] = useState([])
  const [principleList, setPrincipleList] = useState([])

  const localJobTypeId = localStorage.getItem("jobTypeId")

  const [shipmentData, setShipmentData] = useState()
  const [bookingConfirmationData, setBookingConfirmationData] = useState()
  const [eplData, setEplData] = useState()
  const [paymentRequestData, setPaymentRequestData] = useState([])
  const [temporaryReceiptData, setTemporaryReceiptData] = useState([])
  const [invoiceData, setInvoiceData] = useState([])
  const [paymentVoucherData, setPaymentVoucherData] = useState([])
  const [officialReceiptData, setOfficialReceiptData] = useState([])

  const [tableINV, setTableINV] = useState({ data: [], columns: [], totalRows: 50 })
  const [tableOR, setTableOR] = useState({ data: [], columns: [], totalRows: 50 })
  const [tablePR, setTablePR] = useState({ data: [], columns: [], totalRows: 50 })
  const [tablePV, setTablePV] = useState({ data: [], columns: [], totalRows: 50 })
  const [tableTR, setTableTR] = useState({ data: [], columns: [], totalRows: 50 })

  const [filterData, setFilterData] = useState({
    jobType: localJobTypeId || 1,
    principle: 'all',
    range: 'all',
    start: date,
    end: date,
  })

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Customer Service', link: '/Majura/CustomerService', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Customer Service - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', 'PAGE', 1, 100, params, [])
        .then((res) => {
          if (res.data.code === 200) {
            setDataRegUserAccess(res.data.data.userAccess);
            getData(0, 50, [], [], filterData)
            getAdditionalData()
          } else if (res.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + res.data.message })
            setIsLoading(false);
          }
        }).catch((err) => {
          console.error('Error: ', err)
          history('/Majura')
        })
    } else {
      history('/Majura');
    }
  }

  const getData = (pageNumber, pageSize, filters = filter, orderBy = sortArr, headerFilter = filterData) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true)
    }
    let params = [];
    let filter = [];

    if (filters.length > 0) {
      filter = [...filters];
    }

    if (headerFilter.jobType !== 'all') {
      params.push({ 'attr': 'jobTypeId', 'value': '' + headerFilter.jobType });
    }
    if (headerFilter.principle !== 'all') {
      params.push({ 'attr': 'jobOwnerId', 'value': '' + headerFilter.principle });
    }
    if (headerFilter.range !== 'all') {
      params.push({ 'attr': 'start', 'value': '' + headerFilter.start });
      params.push({ 'attr': 'end', 'value': '' + headerFilter.end });
    }

    ApiService.ListOperationDynamicPostByPage('shipmentOrder/shipmentOrder', 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.shipmentOrder
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataTable(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (pageNumber === 0) {
            setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
        }

        setIsLoading(false)
        setIsLoadingBackDrop(false);
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false)
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const getAdditionalData = async () => {
    try {
      const [resJT, resJO] = await Promise.all([
        ApiService.PostByPage('regJobType/regJobType', 1, 999, []),
        ApiService.PostByPage('jobOwner/jobOwner', 1, 999, []),
      ])

      if (resJT?.data?.code === 200) {
        const tempData = resJT.data.data.jobType
        const mapData = tempData.map((jt) => {
          return { value: jt.id, label: jt.name }
        })

        setJobTypeList(mapData)
      } else if (resJT.data.code === 500) {
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + resJT.data.message })
      } else {
        errorToast('job type')
      }

      if (resJO?.data?.code === 200) {
        const tempData = resJO.data.data.jobOwner
        const mapData = tempData.map((jo) => {
          return { value: jo.id, label: jo.name }
        })

        setPrincipleList(mapData)
      } else if (resJO.data.code === 500) {
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + resJO.data.message })
      } else {
        errorToast('principal')
      }

    } catch (error) {
      errorToast('additional')
    }
  }

  const changeFilter = (key, value) => {
    const rangeFilter = {}
    if (key === 'range') {
      rangeFilter.start = date
      rangeFilter.end = date
    }

    const mapFilter = {
      ...filterData,
      ...rangeFilter,
      [key]: value,
    }

    setFilterData(mapFilter)

    if (['jobType', 'principle'].includes(key)) {
      getData(0, rowsCount, filter, sortArr, mapFilter)
    }
  }

  const exportData = (fileType) => {
    if (selectedData?.id !== undefined) {
      setIsOpenReport(false);
      setIsLoadingBackDrop(true);
      setIsLoadingModal(true);
      let reportLink = 'Transactions/Operation/CustomerServiceReport/CustomerServiceReport/PrintCustomerService?Id=' + selectedData?.id
        + '&typeReport=' + fileType;
      if (fileType === 'pdf') {
        PrintPDF(setIsLoadingBackDrop, 'Customer Service', reportLink);
      }
      else {
        PrintExcel(setIsLoadingBackDrop, 'Customer Service', reportLink);
      }
    }
    else {
      showToast({ type: 'error', message: 'Please Select Data First!' })
    }
  }

  const reloadData = () => {
    setSelectedData({})
    setNumPage(0)
    setGoTo(1)
    getData(0, rowsCount, [], [], filterData)
  }

  const resetData = () => {
    setFilterData({
      jobType: localJobTypeId || 1,
      principle: 'all',
      range: 'all',
      start: date,
      end: date,
    })

    setGoTo(1);
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(0);
    setFilter([]);
    setFilterBy([]);
    setStateX({})
    setSortArr([])
    setSelectedData({})

    getData(0, 50, [], [], filterData)
  }

  const cellDoubleClick = (row) => {
    setIsLoadingBackDrop(true)
    getDataShipment();
  }

  const handleModal = () => {
    setIsOpenReport(!isOpenReport)
  }

  const getDataShipment = () => {
    setIsLoadingModal(true)
    ApiService.OperationPostById('shipmentOrder/shipmentOrder', selectedData?.id)
      .then(res => {
        if (res.data.code === 200) {
          const shipment = res.data.data.shipmentOrder
          setShipmentData(shipment)
          getAdditionalDetail(shipment)
        } else {
          errorToast('Shipment')
          handleModal()
        }
      })
      .catch(() => {
        errorToast('Shipment')
        handleModal()
      })
  }

  const getPayloadParams = (withOrder, withInv, withJob) => {
    let params = []
    if (withOrder) {
      params.push({ "attr": "shipmentOrderId", "value": String(selectedData?.id) })
    } else {
      params.push({ "attr": "shipmentId", "value": String(selectedData?.id) })
    }

    if (withInv) params.push({ "attr": "IsWithInv", "value": "true" })
    if (withJob) params.push({ "attr": "IsWithJob", "value": 'true' })

    return params
  }

  const getAdditionalDetail = (shipment) => {
    const paramPV = [
      {
        "attr": "pvNo2",
        "value": "",
        "isDistinct": true
      },
      {
        "attr": "shipmentOrderId",
        "value": String(selectedData?.id)
      }
    ]
    getDataBC(shipment);
    getDataPV(paramPV);
  }

  const getDataBC = (shipment) => {
    ApiService.OperationPostById('bookingConfirmation/bookingConfirmation', shipment?.bookingConfirmationId)
      .then(res => {
        if (res?.data?.code === 200) {
          const booking = res.data.data.bookingConfirmation
          setBookingConfirmationData(booking)
          getDataEPL();
        }
      })
      .catch(() => {
        errorToast('Booking Confirmation')
      })
  }

  const getDataEPL = () => {
    ApiService.OperationDynamicPostByPage('estimateProfitLoss/estimateProfitLoss', 'PAGE', 1, 50, getPayloadParams())
      .then(res => {
        if (res?.data?.code === 200) {
          const epl = res.data.data.estimateProfitLoss
          if (epl.length) {
            setEplData(epl[0])
          }
          getDataTR();
        } else {
          errorToast('Estimate Profit Loss')
        }
      })
  }

  const getDataTR = () => {
    ApiService.FinAccPostByPage('temporaryReceipt/temporaryReceipt', 'SEARCH', 1, 50, getPayloadParams(true, false, true))
      .then(res => {
        if (res?.data?.code === 200) {
          const data = res.data.data.temporaryReceipts
          const columns = res.data.data.columns.headerColumns
          const totalRows = res.data.totalRowCount

          setTemporaryReceiptData(data)
          setTableTR({ data, columns, totalRows })
          getDataPR();
        } else {
          errorToast('Temporary Receipt')
        }
      })
  }

  const getDataPR = () => {
    ApiService.OperationDynamicPostByPage('paymentRequest/paymentRequest', 'SEARCH', 1, 50, getPayloadParams())
      .then(res => {
        if (res?.data?.code === 200) {
          const data = res.data.data.paymentRequests
          const columns = res.data.data.columns.headerColumns
          const totalRows = res.data.totalRowCount

          setPaymentRequestData(data)
          setTablePR({ data, columns, totalRows })
          getDataInv();
        } else {
          errorToast('Payment Request')
        }
      })
  }

  const getDataInv = () => {
    ApiService.OperationDynamicPostByPage('invoice/invoice', 'SEARCH', 1, 50, getPayloadParams())
      .then(res => {
        if (res?.data?.code === 200) {
          const data = res.data.data.invoices
          const columns = res.data.data.columns.headerColumns
          const totalRows = res.data.totalRowCount

          setInvoiceData(data)
          setTableINV({
            data: data,
            columns: columns,
            totalRows: totalRows
          })
          getDataOR();
        } else {
          errorToast('Invoice')
        }
      })
  }

  const getDataPV = (paramPV) => {
    ApiService.FinAccPostByPage('paymentVoucher/paymentVoucher', 'CSTMS', 1, 50, paramPV)
      .then(res => {
        if (res?.data?.code === 200) {
          const data = res.data.data.paymentVoucher
          const columns = res.data.data.columns.headerColumns
          const totalRows = res.data.totalRowCount

          setPaymentVoucherData(data)
          setTablePV({ data, columns, totalRows })
        } else {
          errorToast('Payment Voucher')
        }
      })
  }

  const getDataOR = () => {
    ApiService.FinAccPostByPage('officialReceipt/officialReceipt', 'SEARCH', 1, 50, getPayloadParams(false, true))
      .then(res => {
        if (res?.data?.code === 200) {
          const data = res.data.data.officialReceipt
          const columns = res.data.data.columns.headerColumns
          const totalRows = res.data.totalRowCount

          setOfficialReceiptData(data)
          setTableOR({ data, columns, totalRows })

          setIsLoadingModal(false)
          setIsLoadingBackDrop(false)
          setIsOpenReport(!isOpenReport)
        } else {
          errorToast('Official Receipt')
        }
      })
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 144
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const ButtonComponents = () => {
    return (
      <>
        <GeneralButton
          reloadData={reloadData}
          exportData={exportData}
          findData={cellDoubleClick}
          dataRegUserAccess={dataRegUserAccess}
        />

        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <FilterSection
            filterData={filterData}
            changeFilter={changeFilter}
            jobTypeList={jobTypeList}
            principleList={principleList}
          />
        </Grid>
      </>
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr, filterData);
  }

  const onChangeLimit = (value) => {
    setNumPage(0);
    setRowsCount(value);
    getData(0, value, filter, sortArr, filterData);
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo btnPageRef={btnPageRef}
            title={'Customer Service'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
            <div ref={btnTableRef}>
              {
                ButtonComponent.RefreshButton(
                  sortArr,
                  rowsCount,
                  columnData,
                  setGoTo,
                  resetData,
                  setFilterBy,
                  filterBy,
                  setFilter,
                  setNumPage,
                  setRowsCount,
                  getData,
                  stateX,
                  setStateX
                )
              }
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '16px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              {
                isLoading ?
                  <LoadingSpinner />
                  :
                  <TableX
                    uniqueKey={'id'}
                    goTo={goTo}
                    rowsCount={rowsCount}
                    setNumPage={setNumPage}
                    setRowsCount={setRowsCount}
                    getData={getData}
                    columnData={columnData}
                    dataMap={dataTable}
                    SelectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setFilter={setFilter}
                    setFilterBy={setFilterBy}
                    CellDoubleClick={cellDoubleClick}
                    stateX={stateX}
                    setStateX={setStateX}
                    sortArr={sortArr}
                    setSortArr={setSortArr}
                    setGoTo={setGoTo}
                  />
              }
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>
        </Grid>

        {
          isLoadingBackDrop && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoadingBackDrop}
            >
              <LoadingSpinner color="inherit" />
            </Backdrop>
          )
        }
      </Box>

      {isOpenReport && (
        <CustomerServiceReport
          isOpen={isOpenReport}
          closeModal={handleModal}
          selectedData={selectedData}
          exportData={exportData}
          errorToast={errorToast}
          jobTypeList={jobTypeList}
          setIsLoading={setIsLoadingModal}
          isLoading={isLoadingModal}

          shipmentData={shipmentData}
          setShipmentData={setShipmentData}
          bookingConfirmationData={bookingConfirmationData}
          setBookingConfirmationData={setBookingConfirmationData}
          eplData={eplData}
          setEplData={setEplData}
          paymentRequestData={paymentRequestData}
          setPaymentRequestData={setPaymentRequestData}
          temporaryReceiptData={temporaryReceiptData}
          setTemporaryReceiptData={setTemporaryReceiptData}
          invoiceData={invoiceData}
          setInvoiceData={setInvoiceData}
          paymentVoucherData={paymentVoucherData}
          setPaymentVoucherData={setPaymentVoucherData}
          officialReceiptData={officialReceiptData}
          setOfficialReceiptData={setOfficialReceiptData}

          tableINV={tableINV}
          setTableINV={setTableINV}
          tableOR={tableOR}
          setTableOR={setTableOR}
          tablePR={tablePR}
          setTablePR={setTablePR}
          tablePV={tablePV}
          setTablePV={setTablePV}
          tableTR={tableTR}
          setTableTR={setTableTR}
        />
      )}
    </>
  )
})

export default CustomerServiceList