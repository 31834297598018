import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Grid, Stack, IconButton, Backdrop, CardContent, Card, InputAdornment, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ModalSearchCustomer from './components/ModalSearchCustomer';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, showToast, TickBox } from '../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const TransferSOAAgentReport = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(true)

  const [activeModal, setActiveModal] = useState('')
  const [selectedAgent, setSelectedAgent] = useState({})

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Transfer SOA Agent Report', link: '/Majura/TransferSOAAgentReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Transfer SOA Agent Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    } else {
      history('/Majura');
    }
  };

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resCT] = await Promise.all([
        ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 999, [])
      ])

      if (resCT.data.code === 200) {
        let data = resCT.data.data.contactType
        data = data.find((el) => el?.name?.toUpperCase() === 'AGENT')
        formik.setFieldValue('contactTypeId', data?.id)
      } else {
        errorToast('contact type')
      }
    } catch (error) {
      errorToast('additional')
      console.log(error)
    }
    setIsLoading(false)
  }

  const handlePrint = (fileType) => {
    const payload = formik.values
    if (!payload.isAllAgent && !selectedAgent?.contactId) {
      return showToast({ type: 'error', message: 'Please Select Agent!' })
    }
    if (!payload?.fromPeriod || !payload?.toPeriod) {
      return showToast({ type: 'error', message: 'Please select period.' })
    }

    setIsLoading(true);
    let agentId = 0;

    if (payload.isAllAgent === true) {
      agentId = 0;
    }
    else {
      agentId = selectedAgent.contactId;
    }

    let params = [
      {
        "attr": "AgentId",
        "value": '' + agentId
      },
      {
        "attr": "StartDate",
        "value": '' + payload.fromPeriod
      },
      {
        "attr": "EndDate",
        "value": '' + payload.toPeriod
      }
    ];

    let reportLink = 'Reports/Finance/Print/Other/TransferSOAAgentReport/TransferSOAAgentReport/PrintTransferSOAAgent?typeReport=' + fileType;
    if (fileType === 'pdf') {
      PrintPDF(setIsLoading, 'Transfer SOA Agent', reportLink, params);
    }
    else {
      PrintExcel(setIsLoading, 'Transfer SOA Agent', reportLink, params);
    }
  }

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const formik = useFormik({
    initialValues: {
      contactTypeId: 0,
      isAllAgent: false,
      fromPeriod: dateString,
      toPeriod: dateString,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {/* {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf'))} */}
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Print Excel')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  function handleChange(name, value) {
    formik.setFieldValue(name, !value);
    setSelectedAgent({});
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Transfer SOA Agent Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={7}
                  lg={7}
                  sm={7}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack direction={'row'} spacing={'16px'} alignItems={'center'}>
                        <Stack spacing={'16px'} direction="row" sx={{ width: '75%' }}>
                          <Input
                            readOnly
                            fullWidth
                            label='Agent'
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={selectedAgent?.id ? selectedAgent?.code : 0}
                          />

                          <Input
                            fullWidth
                            label=' '
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            formControlProps={{ sx: { width: '100%', } }}
                            value={selectedAgent?.id ? `${selectedAgent?.code || 0} - ${selectedAgent?.contactName}` : ''}
                            inputBaseProps={{
                              readOnly: true,
                              sx: { marginTop: '23px !important' },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setSelectedAgent({})}
                                    hidden={selectedAgent?.contactName ? false : true}
                                  >
                                    <Icon
                                      iconName={'close'}
                                      svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                          height: '12px',
                                          width: '12px'
                                        }
                                      }}
                                    />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => toggleModal('agent')}
                                    hidden={formik.values.isAllAgent}
                                  >
                                    <Icon
                                      iconName={'search'}
                                      svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                          height: '12px',
                                          width: '12px'
                                        }
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Stack>

                        <TickBox
                          size={'small'}
                          label={
                            <Typography
                              sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px !important',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                              }}
                            >
                              All Agents
                            </Typography>
                          }
                          name={'isAllAgent'}
                          checked={formik.values.isAllAgent}
                          baseLabelProps={{ sx: { marginTop: '26px !important' } }}
                          onChange={() => handleChange('isAllAgent', formik.values.isAllAgent)}
                        />
                      </Stack>

                      <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                        <CustomDatePicker
                          fullWidth
                          type='date'
                          variant="standard"
                          label="Period From"
                          value={formik.values.fromPeriod}
                          InputLabelProps={{ shrink: true }}
                          formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                          onChange={(e) => formik.setFieldValue('fromPeriod', e)}
                        />

                        <CustomDatePicker
                          fullWidth
                          type='date'
                          variant="standard"
                          label="Period To"
                          value={formik.values.toPeriod}
                          InputLabelProps={{ shrink: true }}
                          formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                          onChange={(e) => formik.setFieldValue('toPeriod', e)}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal === 'agent' && (
        <ModalSearchCustomer
          isModal={activeModal === 'agent'}
          toggleModal={toggleModal}
          dataSelected={selectedAgent}
          onSelect={setSelectedAgent}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </>
  )
})

export default TransferSOAAgentReport