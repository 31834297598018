import { Table } from 'react-bootstrap';
import { Typography } from '@mui/material'
import React from 'react';
import { defaultDate } from '../../utils/date';
import { isInfossNoList } from '../../services/ApiService';

const TableNoFilter = (props) => {
    function handleKeyDown(e) {
        let currIndex = 0
        if (e.keyCode === 38 && props.SelectedData.index > 0) {
            //UP ARROW
            currIndex = props.SelectedData.index
            currIndex -= 1
        } else if (e.keyCode === 40 && props.SelectedData.index < props.dataMap.length - 1) {
            //DOWN ARROW
            currIndex = props.SelectedData.index
            currIndex += 1
        } else if (e.keyCode === 13 && props.SelectedData.index > 0) {
            //PRESS ENTER
            //THEN DO FILTER
            props.setNumPage(1)
            props.setRowsCount(50)
            props.getData(1, props.rowsCount)
        }

        const copyArr = [...props.dataMap]
        const changeSelectedData = (data) => {
            return data.index === currIndex
        }
        const result = copyArr.filter(changeSelectedData)
        props.setSelectedData(...result);
        if (e.keyCode === 13 && props.SelectedData.index !== undefined) {
            handleDoubleClick(...result)
        }
    }

    const handleDoubleClick = (el) => {
        if (props.CellDoubleClick === undefined) {
            props.GoTo(el)
        }
        else {
            props.CellDoubleClick();
        }
    }

    return (
        <Table hover={!props?.static} className='table table-sm' style={{ '--bs-table-bg': '#F2F2F2' }} onKeyDown={handleKeyDown}>
            <thead style={{ position: 'sticky', top: '-4px', 'color': '#F2F2F2', background: '#F2F2F2', zIndex: 1 }}>
                <tr>
                    {
                        props.columnData.map((el, index) => {
                            return (
                                <th
                                    key={index}
                                    hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                                    style={{
                                        textAlign: el.textAlign,
                                        "whiteSpace": "nowrap",
                                        fontWeight: 'normal',
                                        maxWidth: 'fit-content',
                                        cursor: 'pointer',
                                        padding: '2px 8px',
                                        background: '#F2F2F2',
                                        fontSize: '12px !important'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            fontSize: '11px !important',
                                            fontWeight: '700',
                                            lineHeight: '16px',
                                            textAlign: 'left',
                                            color: '#083A50',
                                            maxWidth: 'fit-content',
                                        }}
                                    >
                                        {el.text}
                                    </Typography>
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody className="text-muted">
                {
                    props.dataMap.length > 0 ?
                        props.dataMap.map((el, index) => {
                            let tempStyle = 'text-dark'
                            // if (props.SelectedData.id === el.id) {
                            //     tempStyle = "bg-infoss text-white"
                            // } 
                            // else 
                            if (el.rowStatus === 'DEL') {
                                tempStyle = "text-danger"
                            }
                            else if (el.printing > 0) {
                                tempStyle = "text-secondary"
                            }

                            return (

                                <tr
                                    key={index}
                                    onClick={() => { props.setSelectedData(el); }}
                                    className={tempStyle}
                                    onDoubleClick={() => handleDoubleClick(el)}
                                    style={{ fontSize: '14px', background: '#F2F2F2', padding: '24px 8px !important', alignItems: 'center', height: '27.09px' }}
                                >
                                    {
                                        props.columnData.map((headersEl, indexHeaders) => {
                                            let temp = el[headersEl.column]
                                            // let optionsDate = {
                                            //     year: "numeric",
                                            //     month: "short",
                                            //     day: "2-digit",
                                            // }

                                            if (headersEl.dataType === 'datetime') {
                                                if (el[headersEl.column] !== "0001-01-01T00:00:00" && new Date(el[headersEl.column]).getFullYear() !== new Date(defaultDate).getFullYear()) {
                                                    let tempDate = new Date(el[headersEl.column]);
                                                    var dateTemp = tempDate.toLocaleDateString("en-US", { day: "2-digit" });
                                                    var monthTemp = tempDate.toLocaleDateString("en-US", { month: "short" });
                                                    var yearTemp = tempDate.toLocaleDateString("en-US", { year: "numeric" });
                                                    temp = dateTemp + ' ' + monthTemp + ' ' + yearTemp;
                                                } else {
                                                    temp = '-'
                                                }
                                            }

                                            if (headersEl.dataType === 'currency') {
                                                let tempNumber = new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(`${el[headersEl.column]}`);
                                                temp = tempNumber;
                                            }

                                            if (el[headersEl.column] === true) {
                                                temp = 'Yes';
                                            }
                                            else if (el[headersEl.column] === false) {
                                                temp = 'No';
                                            }

                                            if (el[headersEl.column] === '') {
                                                temp = '-';
                                            }

                                            return (
                                                <td
                                                    key={indexHeaders}
                                                    hidden={headersEl.column === 'noInfoss' && !isInfossNoList ? true : headersEl.hidden}
                                                    align={headersEl.textAlign}
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        padding: '0px 10px',
                                                        // maxWidth: '150px !important',
                                                        "whiteSpace": "nowrap",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '11px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                            maxWidth: 'fit-content'
                                                        }}
                                                    >
                                                        {temp}
                                                    </Typography>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={20} className="text-center py-3 text-muted">
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontstyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal'
                                    }}
                                >
                                    Data Empty
                                </Typography>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}
export default TableNoFilter