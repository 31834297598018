import { Grid } from '@mui/material';
import React, { useState } from 'react'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const SelectDataContact = (props) => {
    const param1 = "regContact/regContact";
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    const getDataContact = (id, typeId) => {
        let params = [
            { 'attr': 'contactId', 'value': '' + id },
            { 'attr': 'contactTypeId', 'value': '' + typeId }
        ];
        ApiService.DynamicPostByPage(param1, 'SEARCH', 1, 1, params, [])
            .then((response) => {
                if (response.data.code === 200) {
                    if (props.CustomerType === 1) {
                        props.setCustomerId(response.data.data.contact[0].contactId);
                        props.setCustomerName(response.data.data.contact[0].contactName);
                        props.setCustomerAddress(response.data.data.contact[0].contactAddress);

                        if (props.type !== '1') {
                            props.setCustomerSCId(response.data.data.contact[0].contactId);
                            props.setCustomerSCName(response.data.data.contact[0].contactName);
                            props.setCustomerSCAddress(response.data.data.contact[0].contactAddress);
                            props.setDataPayment([]);
                            props.setTempDataPayment([]);
                            props.setDataPaymentSC([]);
                        }
                        else {
                            props.setCustomerAId(response.data.data.contact[0].contactId);
                            props.setCustomerAName(response.data.data.contact[0].contactName);
                            props.setCustomerAAddress(response.data.data.contact[0].contactAddress);
                            props.setDataPayment([]);
                            props.setTempDataPayment([]);
                            props.setDataPaymentA([]);
                        }

                        props.setBillToId(0);
                        props.setBillToName('');
                        props.setBillToAddress('');
                    }
                    else {
                        if (props.CustomerId !== response.data.data.contact[0].contactId) {
                            props.setBillToId(response.data.data.contact[0].contactId);
                            props.setBillToName(response.data.data.contact[0].contactName);
                            props.setBillToAddress(response.data.data.contact[0].contactAddress);
                        }
                        else {
                            showToast({ type: 'error', message: 'Contact Already Been Chosen As Customer!' });
                        }
                    }

                    if (props.PaymentType === 'C') {
                        props.getDataDetail(props.EPLId, id, props.isCostToCost, false);
                    }
                    else {
                        props.getDataDetail(props.EPLId, id, props.isCostToCost, true);
                    }
                }

                props.setIsLoadingBackDrop(false);
            })
            .catch(function (error) {
                props.setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (props.PaymentFrom === 0) {
                props.setVatValue(0);
                props.setCurrency('All');
                props.setIsLoadingBackDrop(true);

                if (props.CustomerType === 1) {
                    getDataContact(props.SelectedData.customerId, props.SelectedData.customerTypeId);

                    emptyState();
                    props.handleClick();

                    props.setIsModal(false);
                }
                else {
                    if (props.CustomerId !== props.SelectedData.customerId) {
                        getDataContact(props.SelectedData.customerId, props.SelectedData.customerTypeId);
                        emptyState();
                        props.handleClick();

                        props.setIsModal(false);
                    }
                    else {
                        props.setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Contact Has Been Selected!' });
                    }
                }
            }
            else {
                if (props.CustomerType === 1) {
                    props.setCustomerId(props.SelectedData.contactId);
                    props.setCustomerName(props.SelectedData.contactName);
                    props.setCustomerAddress(props.SelectedData.contactAddress);

                    props.setBillToId(0);
                    props.setBillToName('');
                    props.setBillToAddress('');
                }
                else {
                    if (props.CustomerId !== props.SelectedData.contactId) {
                        props.setBillToId(props.SelectedData.contactId);
                        props.setBillToName(props.SelectedData.contactName);
                        props.setBillToAddress(props.SelectedData.contactAddress);
                    }
                    else {
                        showToast({ type: 'error', message: 'Contact Already Been Chosen As Customer!' });
                    }
                }

                Cancel();
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
        setStateX({})
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataContact;