import { FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, InputAdornment, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CustomDatePicker, Icon, Input } from '../../../../../components/ui';
import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import ModalListPersonal from '../../BankAdvance/ModalBankAdv/ModalListPersonal';

const CashAdvHeader = (props) => {
    const [isModalEmployee, setIsModalEmployee] = useState(false);
    const [titleModalEmployee, setTitleModalEmployee] = useState('Employee');

    const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);
    const [dataEmployee, setDataEmployee] = useState([]);
    const [dataMapEmployee, setDataMapEmployee] = useState([])
    const [columnDataEmployee, setColumnDataEmployee] = useState([])
    const [SelectedDataEmployee, setSelectedDataEmployee] = useState({});
    const [totalRowsEmployee, setTotalRowsEmployee] = useState(50)

    const paramEmployee = "employee/employee";

    const SearchPersonal = (data) => {
        if (data === 'search') {
            if (isModalEmployee === false) {
                setIsModalEmployee(true);
                setTitleModalEmployee('Personal')
                getDataEmployee(1, 50)
            } else {
                setIsModalEmployee(false);
            }
        }
        else if (data === 'delete') {
            props.setEmployeeId(0);
            props.setEmployeeCode('');
            props.setEmployeeName('');
            props.setDataDetail([]);
        }
    }

    const getDataEmployee = (pageNumber, pageSize, filters) => {
        setIsLoadingEmployee(true);
        ApiService.DynamicPostByPage(paramEmployee, 'PAGE', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataEmployee({})
                    setDataEmployee(response.data.data.employees);

                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapEmployee(indexed)
                    setColumnDataEmployee(response.data.data.columns)
                    setTotalRowsEmployee(response.data.totalRowCount)

                    setIsLoadingEmployee(false)
                }
                setIsLoadingEmployee(false)
            })
            .catch(function (error) {
                setIsLoadingEmployee(false)
                console.error('error saat fetch', error)
            })
    }

    function handleChange(event) {
        props.setCashAdvanceTo(event.target.value);
        props.setDataDetail([]);
    }

    return (
        <>
            <Grid
                container
                spacing={'22px'}
            >
                <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={3}
                    xs={12}
                >
                    <Input
                        label={'Infoss Number'}
                        value={props.infossNo}
                        onChange={(e) => props.setInfossNo(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginBottom: '16px !important'
                            }
                        }}
                        disabled={props.isApprove > 0}
                        hidden={!isInfossNo}
                        message={props.infossNo !== '' ? '' : 'This Field is Required'}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={'32px'}>
                <Grid item xs={6}>
                    <Stack spacing={'16px'} direction="row" hidden={!props.isEdit}>
                        <Input
                            label="Cash Advance No"
                            value={props.cashAdvanceNo}
                            textAlign='right'
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />

                        <Input
                            label="Reference"
                            value={props.reference}
                            textAlign='right'
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />
                    </Stack>

                    <div style={{ marginTop: props.isEdit ? '16px' : '0px' }}>
                        <Stack spacing={'16px'} direction="row">
                            <Input
                                label="Personal Code"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.employeeCode}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => SearchPersonal('delete')}
                                                hidden={props.printing > 0 ? true : props.employeeCode ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => SearchPersonal('search')}
                                                hidden={props.printing > 0 ? true : false}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />

                            <Input
                                label="Personal Name"
                                value={props.employeeName}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Stack>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <Stack spacing={'16px'} direction="row" hidden={!props.isEdit}>
                        <CustomDatePicker
                            label="Print Date"
                            value={props.printedOn}
                            type={'date'}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />

                        <CustomDatePicker
                            label="Date Paid"
                            value={props.paidOn}
                            type={'date'}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />
                    </Stack>

                    <div style={{ marginTop: props.isEdit ? '16px' : '0px' }}>
                        <Stack spacing={'16px'} direction="row">
                            <Input
                                label='Rate'
                                value={props.rate}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <CustomDatePicker
                                label="Date Rate"
                                value={props.exRateDate}
                                type={'date'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Stack>
                    </div>
                </Grid>
            </Grid>

            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={12}>
                <Grid item xs={8}>
                    <div style={{ marginTop: '16px' }}>
                        <Typography
                            sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '19.6px',
                            }}
                        >
                            Cash Advance To
                        </Typography>
                        <RadioGroup
                            row
                            sx={{
                                gap: '20px'
                            }}
                            aria-labelledby="variant-option"
                            name="variant"
                            value={props.cashAdvanceTo}
                            onChange={(e) => handleChange(e)}
                            disabled={props.printing > 0 ? true : false}
                        >
                            <FormControlLabel
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                                value={'O'}
                                key={0}
                                control={<Radio size='small' />}
                                label={
                                    <Typography
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '12px !important',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Operational
                                    </Typography>
                                }
                                disabled={props.printing > 0}
                            />

                            <FormControlLabel
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                                value={'T'}
                                key={1}
                                control={<Radio size='small' />}
                                label={
                                    <Typography
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '12px !important',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Traveling
                                    </Typography>
                                }
                                disabled={props.printing > 0}
                            />

                            <FormControlLabel
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                                value={'P'}
                                key={2}
                                control={<Radio size='small' />}
                                label={
                                    <Typography
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '12px !important',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Others
                                    </Typography>
                                }
                                disabled={props.printing > 0}
                            />
                        </RadioGroup>
                    </div>
                </Grid>
            </Grid>

            {
                isModalEmployee && (
                    <ModalListPersonal
                        setEmployeeId={props.setEmployeeId}
                        setEmployeeCode={props.setEmployeeCode}
                        setEmployeeName={props.setEmployeeName}

                        isModal={isModalEmployee}
                        handleClick={SearchPersonal}
                        titleModal={titleModalEmployee}
                        setIsModal={setIsModalEmployee}

                        isLoading={isLoadingEmployee}
                        data={dataEmployee}
                        dataMap={dataMapEmployee}
                        columnData={columnDataEmployee}
                        SelectedData={SelectedDataEmployee}
                        totalRows={totalRowsEmployee}
                        setSelectedData={setSelectedDataEmployee}
                        getData={getDataEmployee}
                    />
                )
            }
        </>
    )
}
export default CashAdvHeader