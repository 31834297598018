import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ButtonBankAdvanceSettlement from './Buttons/ButtonBankAdvanceSettlement';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import { Grid, Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import { forwardRef } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks.jsx';
import { PrintPDF } from '../../../../utils/reusableFunction.jsx';

const BankAdvanceSettlementList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramBASettlement = 'bankAdvanceSettlement/bankAdvanceSettlement';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(0);
    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const [DropDownTitle, setDropDownTitle] = useState('All');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Bank Advance Settlement', link: '/Majura/BankAdvanceSettlement', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Bank Advance Settlement - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let filter = [];
        if (data) {
            if (data === 'All') {
                filter = [...filters]
            }
            else if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (data === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'false' },
                ];
            }
        }
        else {
            if (DropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (DropDownTitle === 'Deleted') {
                filter = [{ 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (DropDownTitle === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'false' },
                ];
            }
        }

        ApiService.ListFinAccPostByPage(paramBASettlement, 'PAGE', pageNumber, pageSize, [], filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.bankAdvanceSettlement
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

                    if (isLoadingBackDrop === false) {
                        setIsLoading(true);
                    }
                    else {
                        setIsLoadingBackDrop(true);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false)
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                setSelectedData({})
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false)
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FBAD')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getData(0, 50, []);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

                        if (isLoadingBackDrop === false) {
                            setIsLoading(true);
                        }
                        else {
                            setIsLoadingBackDrop(true);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleReset = () => {
        emptyState();
        getData(0, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationBAS() {
        if (titleConfirmation === 'Add') {
            AddData();
        }
        else if (titleConfirmation === 'Edit') {
            EditData();
        }
        else if (titleConfirmation === 'Print') {
            Print();
        }
        else if (titleConfirmation === 'Reprint') {
            RePrint();
        }
        setIsModalConfirmation(false);
    }

    const AddBAS = () => {
        setTitleConfirmation('Add')
        setTitleModalConfirmation('Bank Advance Settlement');
        handleClickConfirmation();
    }

    const AddData = () => {
        window.open('/Majura/BankAdvanceSettlement/Create', newWindowConfig);
    }

    const EditBAS = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Edit')
            setTitleModalConfirmation('Bank Advance Settlement');
            handleClickConfirmation();
        }
    }

    const EditData = () => {
        window.open('/Majura/BankAdvanceSettlement/' + SelectedData.id, newWindowConfig);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data!' });
        emptyState();
        getData(0, 50, []);
    }

    const DeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.approved) {
                    showToast({ type: 'error', message: 'Can not delete data, already approved!' });
                }
                else {
                    setTitleModalDelete('Bank Advance Settlement');
                    handleClick();
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const DeleteOR = () => {
        ApiService.FinAccDelete(paramBASettlement, SelectedData.id).then((res) => {
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Delete Data Success!' });
                setIsModalDelete(false);
            }
            else {
                showToast({ type: 'error', message: 'Delete Data Failed!, ' + res.data.message });
                setIsModalDelete(false);
            }
        });
    }

    const PrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Print')
                setTitleModalConfirmation('Bank Advance Settlement');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const Print = () => {
        if (dataSelected.rePrintApproved === false) {
            showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
            setIsModalConfirmation(false);
        }
        else {
            setIsLoadingBackDrop(true);
            ApiService.FinAccUpdateStatusPrint(paramBASettlement, dataSelected.id).then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    getData(numPage, rowsCount, filter);
                    let reportLink = 'Transactions/Finance/BankAdvanceSettlementReport/BankAdvanceSettlementReport/PrintBankAdvanceSettlement?Id=' + dataSelected.id;
                    PrintPDF(setIsLoadingBackDrop, 'Bank Advance Settlement', reportLink);
                }
                else {
                    showToast({ type: 'error', message: message });
                }
            });
        }
    };

    const RePrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Reprint')
                setTitleModalConfirmation('Bank Advance Settlement');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const RePrint = () => {
        ApiService.FinAccUpdateStatusRePrint(paramBASettlement, dataSelected.id).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Reprint Bank Advance Settlement Success' });
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    const emptyState = () => {
        setNumPage(0);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    function CellDoubleClick(row) {
        EditBAS();
    };

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(numPage, rowsCount, filter, sortArr, data);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(0);
        setRowsCount(value);
        getData(0, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <ButtonBankAdvanceSettlement
                AddBAS={AddBAS}
                EditBAS={EditBAS}
                ReloadData={ReloadData}
                DeleteData={DeleteData}
                Print={PrintData}
                RePrint={RePrintData}
                DataRegUserAccess={DataRegUserAccess}
                DropDownTitle={DropDownTitle}
                HandleStatus={HandleStatus}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={'Bank Advance Settlement'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClick}
                        DeleteData={DeleteOR}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationBAS}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default BankAdvanceSettlementList