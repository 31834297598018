import React, { forwardRef } from 'react';
import CustomizeButtonComponent from '../../components/Buttons/CustomizeButtonComponent';

import { Icon, PopUp } from '../../components/ui';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../components/Components/LoadingSpinner';

const CreateEditModalMaster = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, mainContainer, handleSave, width = 'sm', isLoading = false, code } = props

    const ButtonComponent = (
        <div style={{ padding: '0px 32px 6px' }}>
            {CustomizeButtonComponent.SaveCancelMasterModal(handleSave, false)}
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={titleModal === 'Edit' ? titleModal + ' ' + title +  ' - ' + code : titleModal + ' ' + title}
                isOpen={isModal}
                onClose={handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={width}
                fullWidth={true}
                topButton={false}
                actions={ButtonComponent}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}
                </div>
            </PopUp >

            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditModalMaster