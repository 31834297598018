import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Grid, Stack, Backdrop, RadioGroup, FormControlLabel, Radio, IconButton, Card, CardContent, Typography, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalSearchCustomer from './components/ModalSearchCustomer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, showToast } from '../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const RekapPPnReport = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(true)

  const [activeModal, setActiveModal] = useState('')

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({})

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Rekap PPn Report', link: '/Majura/RekapPPnMasukanReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Rekap PPn Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          setIsLoading(false)
          console.error("error saat fetch", error);
          showToast({ type: 'error', message: 'Can\'t get user access data! please contact Administrator, ' + error })
          history('/Majura');
        });
    } else {
      setIsLoading(false)
      history('/Majura');
      showToast({ type: 'error', message: 'You don`t have access to this page.' })
    }
  };

  const getAdditionalData = async () => {
    try {
      const [resCT] = await Promise.all([
        ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 999, [])
      ])

      if (resCT.data.code === 200) {
        let data = resCT.data.data.contactType
        const includesData = ['SSLINE', 'IATA', 'PPJK', 'EMKL', 'DEPO', 'TRUCKING']
        data = data.filter((el) => includesData.includes(el?.name?.toUpperCase()))
        setContactTypeList(data)

        formik.setFieldValue('customerType', data[0].id)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        errorToast('contact type')
      }
    } catch (error) {
      setIsLoading(false)
      errorToast('additional')
      console.log(error)
    }
  }

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const handlePrint = (fileType) => {
    const payload = formik.values
    if (payload?.isCustom && !selectedCustomer?.id) {
      return showToast({ type: 'error', message: 'Please select customer.' })
    }
    if (!payload?.fromDate || !payload?.toDate) {
      return showToast({ type: 'error', message: 'Please select correct period.' })
    }

    setIsLoading(true);

    let params = [];
    let contactId = 0;

    if (payload.isCustom === true) {
      contactId = selectedCustomer.contactId;
    }

    params = [
      ...params,
      {
        "attr": "StartDate",
        "value": payload.fromDate
      },
      {
        "attr": "EndDate",
        "value": payload.toDate
      },
      {
        "attr": "CustomerId",
        "value": '' + contactId
      }
    ]

    let reportLink = 'Reports/Finance/Print/Other/RekapPPnReport/RekapPPnReport/PrintRekapPpn?typeReport=' + fileType;
    if (fileType === 'pdf') {
      PrintPDF(setIsLoading, 'Rekap PPn Masukan', reportLink, params);
    }
    else {
      PrintExcel(setIsLoading, 'Rekap PPn Masukan', reportLink, params);
    }
  }

  const formik = useFormik({
    initialValues: {
      isCustom: false,
      customerType: 0,
      fromDate: dateString,
      toDate: dateString,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf'))}
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Excel')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Rekap PPn Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <div>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Customer
                        </Typography>

                        <RadioGroup
                          row
                          name="variant"
                          value={formik.values.isCustom}
                          aria-labelledby="variant-option"
                          onChange={(e) => { formik.setFieldValue('isCustom', (e.target.value === 'true')) }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={false}
                            key={1}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                All Customer
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={true}
                            key={2}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Custom Customer
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      {
                        formik.values.isCustom && (
                          <>
                            <div style={{ marginTop: '16px' }}>
                              <Typography
                                sx={{
                                  color: 'var(--Primary-One, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: '19.6px',
                                }}
                              >
                                Custom Customer
                              </Typography>

                              <RadioGroup
                                row
                                name="variant"
                                aria-labelledby="variant-option"
                                value={formik.values.customerType}
                                onChange={(e) => { formik.setFieldValue('customerType', e.target.value) }}
                              >
                                {
                                  contactTypeList.map((elm) => (
                                    <FormControlLabel
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                      }}
                                      key={elm.id}
                                      value={elm.id}
                                      control={<Radio size='small' />}
                                      label={
                                        <Typography
                                          sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '12px !important',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: '20px',
                                          }}
                                        >
                                          {elm.name}
                                        </Typography>
                                      }
                                    />
                                  ))
                                }
                              </RadioGroup>
                            </div>

                            <Input
                              fullWidth
                              variant="standard"
                              label='Search Customer'
                              placeholder='Search Customer'
                              disabled
                              InputLabelProps={{ shrink: true }}
                              formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                              value={selectedCustomer?.id ? `${selectedCustomer?.code || 0} - ${selectedCustomer?.contactName}` : ''}
                              inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => toggleModal('customer')}>
                                      <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                          fontSize: 'small',
                                          htmlColor: '#0087C2',
                                          sx: {
                                            height: '12px',
                                            width: '12px'
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </>
                        )
                      }
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack direction={'row'} spacing={'16px'}>
                        <CustomDatePicker
                          fullWidth
                          type='date'
                          variant="standard"
                          label="Period From"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.fromDate || ''}
                          formControlProps={{ sx: { width: '100%' } }}
                          onChange={(e) => { formik.setFieldValue('fromDate', (e)) }}
                        />

                        <CustomDatePicker
                          fullWidth
                          type='date'
                          label="Period To"
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.toDate || ''}
                          formControlProps={{ sx: { width: '100%' } }}
                          onChange={(e) => { formik.setFieldValue('toDate', (e)) }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal === 'customer' && (
        <ModalSearchCustomer
          isModal={activeModal === 'customer'}
          toggleModal={toggleModal}
          dataSelected={selectedCustomer}
          onSelect={setSelectedCustomer}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </>
  )
})

export default RekapPPnReport