import { Box, Typography, Grid, MenuItem, Button } from '@mui/material';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Icon, Input, PopUp, Selection } from '../ui';

const ModalDelete = React.memo(({
    isPrinting,
    typeDelete,
    setTypeDelete,
    DataEditType,
    isRemarks,
    DeleteData,
    handleClick,
    titleModal,
    isModal
}) => {
    const [DeleteRemarks, setDeleteRemarks] = useState('');

    useEffect(() => {
        setDeleteRemarks('');
    }, [isModal]);

    const memoizedTitle = useMemo(() => (
        <Typography
            sx={{
                fontFamily: 'Nunito',
                marginTop: '3px !important',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
            }}
        >
            Are You Sure Want To Delete This Data?
        </Typography>
    ), []);

    const mainContainer = useMemo(() => (
        <>
            {isPrinting && (
                <Selection
                    id='0'
                    label={'Type'}
                    fullWidth={true}
                    placeholder={'Select Type'}
                    value={typeDelete}
                    onChange={(e) => setTypeDelete(e.target.value)}
                    formControlProps={{
                        sx: {
                            marginTop: '16px !important'
                        }
                    }}
                >
                    {DataEditType ?
                        DataEditType.map((data, index) => (
                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={index} value={data.code}>
                                {data.name}
                            </MenuItem>
                        )) :
                        <></>
                    }
                </Selection>
            )}

            {isRemarks && (
                <Input
                    label={'Delete Reason'}
                    multiline
                    fullWidth
                    value={DeleteRemarks}
                    onChange={(e) => setDeleteRemarks(e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    inputBaseProps={{
                        rows: 5,
                        sx: {
                            padding: '8px',
                        },
                    }}
                />
            )}
        </>
    ), [isPrinting, typeDelete, DataEditType, isRemarks, DeleteRemarks, setTypeDelete, setDeleteRemarks]);

    const handleDelete = useCallback(() => {
        !isRemarks ? DeleteData() : DeleteData(DeleteRemarks);
    }, [DeleteData, isRemarks, DeleteRemarks]);

    const handleCancel = useCallback(() => {
        handleClick();
    }, [handleClick]);

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'unApprove-doc'}
                    svgIconProps={{
                        htmlColor: '#083A50',
                        sx: {
                            height: '20px',
                            width: '20px'
                        }
                    }}
                />
            }
            title={'Confirm Delete Data ' + titleModal}
            isOpen={isModal}
            onClose={handleClick}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'sm'}
            fullWidth={true}
            topButton={true}
        >
            <div style={{ padding: '24px 6px 16px' }}>
                <Box display="flex">
                    {memoizedTitle}
                </Box>

                {mainContainer}
            </div>

            <div style={{ padding: '24px 6px 0px' }}>
                <Box display="flex" alignItems="center">
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '4px',
                                    marginInlineStart: '12px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    borderRadius: '8px',
                                    border: '1px solid var(--Red-Alert, #D20C0C)',
                                    background: '#D20C0C',
                                    '&:hover': {
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: '#D20C0C',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={handleDelete}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#E4E8EC',
                                        sx: {
                                            height: '14px',
                                            width: '14px',
                                            marginTop: '1px'
                                        }
                                    }}
                                    iconName={'delete'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '3px !important',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        color: '#E4E8EC',
                                    }}
                                >
                                    Delete
                                </Typography>
                            </Button>

                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '4px',
                                    marginInlineStart: '12px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    borderRadius: '8px',
                                    border: '1px solid var(--Red-Alert, #D20C0C)',
                                    background: 'rgba(210, 12, 12, 0.25)',
                                    '&:hover': {
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: 'rgba(210, 12, 12, 0.25)',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={handleCancel}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#D20C0C',
                                        sx: {
                                            height: '14px',
                                            width: '14px',
                                            marginTop: '1px'
                                        }
                                    }}
                                    iconName={'close'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '3px !important',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        color: '#D20C0C',
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </PopUp>
    );
});

export default React.memo(ModalDelete);