import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { username } from '../../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import CreateEditRegContact from './CreateEditRegContact';
import TempListMaster2 from '../TempListMaster2';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { defaultDate } from '../../../utils/date';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListRegContact = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Contact'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const history = useNavigate()
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const param = "regContact/regContact";
    const [id, setId] = useState(0);
    const [contactId, setContactId] = useState(0);
    const [code, setCode] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactType, setContactType] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [contactNIK, setContactNIK] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactFax, setContactFax] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactNPWP, setContactNPWP] = useState('');
    const [contactNPPKP, setContactNPPKP] = useState('');
    const [creditTermInDays, setCreditTermInDays] = useState(0);
    const [creditTermInIDR, setCreditTermInIDR] = useState(0);
    const [creditTermInUSD, setCreditTermInUSD] = useState(0);
    const [shipmentStatus, setShipmentStatus] = useState('');
    const [marketId, setMarketId] = useState(0);
    const [marketingCode, setMarketingCode] = useState('');
    const [marketingName, setMarketingName] = useState('');
    const [groupId, setGroupId] = useState(0);
    const [groupCode, setGroupCode] = useState('');
    const [groupName, setGroupName] = useState('');

    const [listContactType, setListContactType] = useState([]);
    const [listAddress, setListAddress] = useState([]);
    const [listJobOwner, setListJobOwner] = useState([]);
    const [listBankAccount, setListBankAccount] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const paramShipmentStatus = 'shipmentStatus/shipmentStatus';
    const [shipmentStatusData, setShipmentStatusData] = useState([]);
    const paramContactType = 'contactType/contactType';
    const [contactTypeData, setContactTypeData] = useState([]);
    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);
    const [dataContact, setDataContact] = useState({});

    const [isSearch, setIsSearch] = useState(false);
    const [isNewReg, setIsNewReg] = useState(false);
    const [isAgent, setIsAgent] = useState(false);

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataRegContact"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "contact": {
                    "rowStatus": 'ACT',
                    "id": id,
                    "contactId": contactId,
                    "code": code,
                    "contactName": contactName,
                    "contactPerson": contactPerson,
                    "contactStatus": '',
                    "nik": contactNIK,
                    "phone": contactPhone,
                    "fax": contactFax,
                    "email": contactEmail,
                    "npwp": contactNPWP,
                    "nppkp": contactNPPKP,
                    "creditTermInDays": creditTermInDays,
                    "creditTermInIDR": creditTermInIDR,
                    "creditTermInUSD": creditTermInUSD,
                    "shipmentStatus": shipmentStatus,
                    "marketId": marketId,
                    "marketingCode": marketingCode,
                    "marketingName": marketingName,
                    "lastShipmentDate": defaultDate,
                    "portId": 0,
                    "portName": "",
                    "airPortId": 0,
                    "airPortName": "",
                    "pic": "",
                    "contactType": contactType,
                    "deleted": false,
                    "contactGroupId": groupId,
                    "contactGroupCode": groupCode,
                    "contactGroupName": groupName,
                    "contactTypes": listContactType,
                    "contactAddresses": listAddress,
                    "contactJobOwners": listJobOwner,
                    "contactBanks": listBankAccount,
                }
            }
            localStorage.setItem(username + "-DataRegContact", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.contact;
        setIsModal(true);
        setTitleModal(result.titleModal);

        setId(result.id);
        setCode(result.code);
        setContactId(result.contactId);
        setContactName(result.contactName);
        setContactPerson(result.contactPerson);
        setContactNIK(result.nik);
        setContactPhone(result.phone);
        setContactFax(result.fax);
        setContactEmail(result.email);
        setContactNPWP(result.npwp);
        setContactNPPKP(result.nppkp);
        setCreditTermInDays(result.creditTermInDays);
        setCreditTermInIDR(result.creditTermInIDR);
        setCreditTermInUSD(result.creditTermInUSD);
        setShipmentStatus(result.shipmentStatus);
        setMarketId(result.marketId);
        setMarketingCode(result.marketingCode);
        setMarketingName(result.marketingName);
        setGroupId(result.contactGroupId);
        setGroupCode(result.contactGroupCode);
        setGroupName(result.contactGroupName);
        setListContactType(result.contactTypes);
        setListAddress(result.contactAddresses);
        setListJobOwner(result.contactJobOwners);
        setContactType(result.contactType);
        setListBankAccount(result.contactBanks);

        result.contactTypes.forEach(element => {
            if (element.name === 'Agent') {
                setIsAgent(true);
            }
        });

        localStorage.removeItem(username + '-DataRegContact')
    }

    const toggleAdd = () => {
        setIsLoadingBackDrop(true);
        emptyState()
        handleClick();
        setTitleModal('Add');
    }

    const emptyState = () => {
        setId(0);
        setIsEdit(false);
        setContactId(0)
        setCode('');
        setContactType('');
        setContactName('');
        setContactPerson('');
        setContactNIK('');
        setContactPhone('');
        setContactFax('');
        setContactEmail('');
        setContactNPWP('');
        setContactNPPKP('');
        setCreditTermInDays(0);
        setCreditTermInIDR(0);
        setCreditTermInUSD(0);
        setShipmentStatus('');
        setMarketId(0);
        setMarketingCode('');
        setMarketingName('');
        setGroupId(0);
        setGroupCode('');
        setGroupName('');
        setListContactType([]);
        setListAddress([]);
        setListJobOwner([]);
        setListBankAccount([]);
        setIsNewReg(false);
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            setIsLoadingBackDrop(true);
            ApiService.PostById(param, SelectedData.contactId)
                .then((res) => {
                    if (res.data.code === 200) {
                        emptyState();
                        setIsSearch(true);
                        setIsNewReg(true);
                        setIsEdit(true);
                        setId(res.data.data.contact.id);
                        setCode(res.data.data.contact.code);
                        setDataContact(res.data.data.contact);
                        setContactId(res.data.data.contact.contactId);
                        setContactName(res.data.data.contact.contactName);
                        setContactPerson(res.data.data.contact.contactPerson);
                        setContactNIK(res.data.data.contact.nik);
                        setContactPhone(res.data.data.contact.phone);
                        setContactFax(res.data.data.contact.fax);
                        setContactEmail(res.data.data.contact.email);
                        let contactNPWP = res.data.data.contact.npwp;
                        setContactNPWP(contactNPWP);
                        setContactNPPKP(res.data.data.contact.nppkp);
                        setCreditTermInDays(res.data.data.contact.creditTermInDays);
                        setCreditTermInIDR(res.data.data.contact.creditTermInIDR);
                        setCreditTermInUSD(res.data.data.contact.creditTermInUSD);
                        if (res.data.data.contact.shipmentStatus === 'F' || res.data.data.contact.shipmentStatus === 'N' || res.data.data.contact.shipmentStatus === 'C') {
                            setShipmentStatus(res.data.data.contact.shipmentStatus);
                        }
                        else {
                            setShipmentStatus(null);
                        }
                        setMarketId(res.data.data.contact.marketId);
                        setMarketingCode(res.data.data.contact.marketingCode);
                        setMarketingName(res.data.data.contact.marketingName);
                        setGroupId(res.data.data.contact.groupId);
                        setGroupCode(res.data.data.contact.groupCode);
                        setGroupName(res.data.data.contact.groupName);
                        setListContactType(res.data.data.contact.contactTypes);
                        setListAddress(res.data.data.contact.contactAddresses);
                        setContactType(res.data.data.contact.contactMaster.contactStatus);
                        let seq = 1;
                        let array = res.data.data.contact.contactJobOwners;
                        array.forEach(element => {
                            element.sequence = seq;
                            seq = seq + 1;
                        });
                        setListJobOwner(array);
                        setListBankAccount(res.data.data.contact.contactBanks);

                        if (res.data.data.contact.contactGroups.length > 0) {
                            setGroupId(res.data.data.contact.contactGroups[0].contactGroupId);
                            setGroupCode(res.data.data.contact.contactGroups[0].contactGroupCode);
                            setGroupName(res.data.data.contact.contactGroups[0].contactGroupName);
                        }

                        res.data.data.contact.contactTypes.forEach(element => {
                            if (element.name === 'Agent') {
                                setIsAgent(true);
                            }
                        });

                        handleClick();
                        setTitleModal('Edit');
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Error When Read Reg Contact Data!' })
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: error.toString() })
                })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === true) {
            setIsLoadingBackDrop(true);
        }
        else {
            setIsLoading(true);
        }

        let params = [];
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.contact);

                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === true) {
                        setIsLoadingBackDrop(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                }
                else {
                    history('/Majura');
                }

                if (isLoadingBackDrop === true) {
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === true) {
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoading(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    }

    const GetShipmentStatusData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramShipmentStatus, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setShipmentStatusData(response.data.data.shipmentStatus);
                }
                else {
                    showToast({ type: 'error', message: 'Failed To Get Shipment Status Data, Please Contact Administrator!, ' + response.data.message })
                    setIsModal(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Failed To Get Contact Type Data, Please Contact Administrator!, ' + error })
            })
    };

    const GetContactTypeData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramContactType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    let contactType = ['SI', 'SE'];
                    let data = response.data.data.contactType.filter(el => !contactType.includes(el.name));
                    data.forEach(element => {
                        if (element.name === 'Discount Shipper' || element.name === 'Discount Consignee') {
                            element.hidden = true;
                        }
                        else {
                            element.hidden = false;
                        }
                    });
                    setContactTypeData(data);
                    setIsLoadingBackDrop(false);
                    setIsModal(true);
                }
                else {
                    showToast({ type: 'error', message: 'Failed To Get Contact Type Data, Please Contact Administrator!, ' + response.data.message })
                    setIsModal(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Failed To Get Contact Type Data, Please Contact Administrator!, ' + error })
            })
    };

    const GetJobOwnerData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobOwnerData(response.data.data.jobOwner);
                    setIsLoadingBackDrop(false);
                    setIsModal(true);
                }
                else {
                    showToast({ type: 'error', message: 'Failed To Get Job Owner Data, Please Contact Administrator!, ' + response.data.message })
                    setIsModal(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Failed To Get Contact Type Data, Please Contact Administrator!, ' + error })
            })
    };

    const handleClick = () => {
        if (isModal === false) {
            GetShipmentStatusData(1, 100);
            GetContactTypeData(1, 100);
            GetJobOwnerData(1, 100);
        }
        else {
            setIsSearch(false);
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster2
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MRCON'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditRegContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        isEdit={isEdit}
                        param={param}
                        getData={getData}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}
                        shipmentStatusData={shipmentStatusData}
                        contactTypeData={contactTypeData}
                        jobOwnerData={jobOwnerData}
                        emptyState={emptyState}

                        id={id}
                        setId={setId}
                        code={code}
                        setCode={setCode}
                        contactId={contactId}
                        setContactId={setContactId}
                        contactName={contactName}
                        setContactName={setContactName}
                        contactPerson={contactPerson}
                        setContactPerson={setContactPerson}
                        contactNIK={contactNIK}
                        setContactNIK={setContactNIK}
                        contactPhone={contactPhone}
                        setContactPhone={setContactPhone}
                        contactFax={contactFax}
                        setContactFax={setContactFax}
                        contactEmail={contactEmail}
                        setContactEmail={setContactEmail}
                        contactNPWP={contactNPWP}
                        setContactNPWP={setContactNPWP}
                        contactNPPKP={contactNPPKP}
                        setContactNPPKP={setContactNPPKP}
                        creditTermInDays={creditTermInDays}
                        setCreditTermInDays={setCreditTermInDays}
                        creditTermInIDR={creditTermInIDR}
                        setCreditTermInIDR={setCreditTermInIDR}
                        creditTermInUSD={creditTermInUSD}
                        setCreditTermInUSD={setCreditTermInUSD}
                        shipmentStatus={shipmentStatus}
                        setShipmentStatus={setShipmentStatus}
                        marketId={marketId}
                        setMarketId={setMarketId}
                        marketingCode={marketingCode}
                        setMarketingCode={setMarketingCode}
                        marketingName={marketingName}
                        setMarketingName={setMarketingName}
                        contactType={contactType}
                        setContactType={setContactType}
                        groupId={groupId}
                        setGroupId={setGroupId}
                        groupCode={groupCode}
                        setGroupCode={setGroupCode}
                        groupName={groupName}
                        setGroupName={setGroupName}
                        listContactType={listContactType}
                        setListContactType={setListContactType}
                        listAddress={listAddress}
                        setListAddress={setListAddress}
                        listJobOwner={listJobOwner}
                        setListJobOwner={setListJobOwner}
                        listBankAccount={listBankAccount}
                        setListBankAccount={setListBankAccount}

                        isSearch={isSearch}
                        setIsSearch={setIsSearch}
                        isNewReg={isNewReg}
                        setIsNewReg={setIsNewReg}
                        isAgent={isAgent}
                        setIsAgent={setIsAgent}
                        dataContact={dataContact}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListRegContact