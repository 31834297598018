import React from 'react';
import { Stack, FormControl, Box } from '@mui/material';

import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { CustomDatePicker } from '../../../../../components/ui';

const ButtonBalance = (props) => {
    return (
        <div className="row mb-3">
            <Stack spacing={'8px'} direction="row">
                <div>
                    <Stack spacing={'8px'} direction="row">
                        {
                            props.roleName === 'company' ?
                                <>
                                    <Box>
                                        <FormControl variant="standard" fullWidth>
                                            {props.selectBranchData(props.setBranchId, props.BranchName, props.setBranchName)}
                                        </FormControl>
                                    </Box>
                                </>
                                :
                                <>
                                    {
                                        props.roleName === 'group' ?
                                            <>
                                                <Box>
                                                    <FormControl variant="standard" fullWidth>
                                                        {props.selectCompanyData(props.setCompanyId, props.CompanyName, props.setCompanyName)}
                                                    </FormControl>
                                                </Box>

                                                <Box>
                                                    <FormControl variant="standard" fullWidth>
                                                        {props.selectBranchData(props.setBranchId, props.BranchName, props.setBranchName)}
                                                    </FormControl>
                                                </Box>
                                            </>
                                            :
                                            <>
                                                {
                                                    props.roleName === 'holding' ?
                                                        <>
                                                            <Box>
                                                                <FormControl variant="standard" fullWidth>
                                                                    {props.selectGroupData(props.setGroupId, props.GroupName, props.setGroupName)}
                                                                </FormControl>
                                                            </Box>

                                                            <Box>
                                                                <FormControl variant="standard" fullWidth>
                                                                    {props.selectCompanyData(props.setCompanyId, props.CompanyName, props.setCompanyName)}
                                                                </FormControl>
                                                            </Box>

                                                            <Box>
                                                                <FormControl variant="standard" fullWidth>
                                                                    {props.selectBranchData(props.setBranchId, props.BranchName, props.setBranchName)}
                                                                </FormControl>
                                                            </Box>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </>
                                    }
                                </>
                        }

                        <CustomDatePicker
                            label='Select Period'
                            value={props.Period}
                            onChange={(e) => props.setDataPeriod(e)}
                            type={'month'}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />
                    </Stack>
                </div>

                <div className='mt-4'>
                    {
                        props.roleName !== 'branch' ?
                            <>
                                {ButtonComponent.ArchiveButton(props.OpenLogData, 'Search Log Data')}
                            </>
                            :
                            <>
                                {ButtonComponent.FindButton(props.handleFindData)}
                            </>
                    }
                </div>
            </Stack>
        </div>
    )
}

export default ButtonBalance