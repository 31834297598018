import React from 'react'
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

import Grid from '@mui/material/Grid';
import { Icon } from '../../../../../components/ui';
import { Button, Typography } from '@mui/material';

const ButtonGeneralLedger = ({ ReloadData, isDisabled, isHidden, handleRePosting, isRePost, handlePostingData }) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {
                        isRePost ?
                            <>
                                {ButtonComponent.BackButton(handleRePosting)}
                                {ButtonComponent.ReloadButton(ReloadData)}

                                <Button
                                    size='small'
                                    variant="outlined"
                                    disabled={isDisabled}
                                    onClick={() => handlePostingData()}
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '3px 6px',
                                        gap: '4px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '18px !important',
                                        minHeight: '28px !important',
                                        borderRadius: '8px',
                                        display: isHidden ? 'none !important' : undefined,
                                        '&:hover': {
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                >
                                    <Icon
                                        iconName={'convert'}
                                        svgIconProps={{
                                            sx: {
                                                width: '12px',
                                                height: '12px',
                                            },
                                            htmlColor: 'inherit',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '700',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            letterSpacing: '0em',
                                            fontFamily: 'Nunito',
                                            marginTop: '2px !important',
                                            color: 'inherit',
                                        }}
                                    >
                                        Re-Post
                                    </Typography>
                                </Button>
                            </>
                            :
                            <>
                                {ButtonComponent.ReloadButton(ReloadData)}

                                <Button
                                    size='small'
                                    variant="outlined"
                                    disabled={isDisabled}
                                    onClick={() => handleRePosting()}
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '3px 6px',
                                        gap: '4px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '18px !important',
                                        minHeight: '28px !important',
                                        borderRadius: '8px',
                                        display: isHidden ? 'none !important' : undefined,
                                        '&:hover': {
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                >
                                    <Icon
                                        iconName={'convert'}
                                        svgIconProps={{
                                            sx: {
                                                width: '12px',
                                                height: '12px',
                                            },
                                            htmlColor: isDisabled ? 'inherit' : '#BE2957',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '700',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            letterSpacing: '0em',
                                            fontFamily: 'Nunito',
                                            marginTop: '2px !important',
                                            color: isDisabled ? 'inherit' : '#BE2957',
                                        }}
                                    >
                                        Failed GL Posting
                                    </Typography>
                                </Button>
                            </>
                    }
                </Grid>
            </div>
        </div>
    )
}

export default ButtonGeneralLedger