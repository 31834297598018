import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import {
    Grid, Box, Stack, InputAdornment, IconButton, Card, CardContent,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonDetailMutasi from './Buttons/ButtonDetailMutasi';
import SelectDataMaster from '../../../Master/MasterWarehouse/SelectDataMaster';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';

const WarehouseDetailMutasi = forwardRef((props, ref) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#f0f7ff',
            color: theme.palette.common.black,
        },
    }));

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramProduct = "warehouse/product/product";
    const paramUnit = "warehouse/RegProductUnits/RegProductUnits";
    const paramReport = "reports/DetailMutasi/DetailMutasi";

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalProductCategory, setIsModalProductCategory] = useState(false);
    const [isLoadingModalProductCategory, setIsLoadingModalProductCategory] = useState(false);
    const [dataModalProductCategory, setDataModalProductCategory] = useState([]);
    const [dataMapModalProductCategory, setDataMapModalProductCategory] = useState([]);
    const [columnDataModalProductCategory, setColumnDataModalProductCategory] = useState([]);
    const [SelectedDataModalProductCategory, setSelectedDataModalProductCategory] = useState({});
    const [totalRowsModalProductCategory, setTotalRowsModalProductCategory] = useState(50);

    const [isModalProduct, setIsModalProduct] = useState(false);
    const [isLoadingModalProduct, setIsLoadingModalProduct] = useState(false);
    const [dataModalProduct, setDataModalProduct] = useState([]);
    const [dataMapModalProduct, setDataMapModalProduct] = useState([]);
    const [columnDataModalProduct, setColumnDataModalProduct] = useState([]);
    const [SelectedDataModalProduct, setSelectedDataModalProduct] = useState({});
    const [totalRowsModalProduct, setTotalRowsModalProduct] = useState(50);

    const [isModalUnit, setIsModalUnit] = useState(false);
    const [isLoadingModalUnit, setIsLoadingModalUnit] = useState(false);
    const [dataModalUnit, setDataModalUnit] = useState([]);
    const [dataMapModalUnit, setDataMapModalUnit] = useState([]);
    const [columnDataModalUnit, setColumnDataModalUnit] = useState([]);
    const [SelectedDataModalUnit, setSelectedDataModalUnit] = useState({});
    const [totalRowsModalUnit, setTotalRowsModalUnit] = useState(50);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [dtFrom, setDtFrom] = useState('');
    const [dtTo, setDtTo] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [unitId, setUnitId] = useState('');
    const [unitName, setUnitName] = useState('');
    const [dataDetailMutasi, setDataDetailMutasi] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Detail Mutasi Report', link: '/Majura/ReportDetailMutasi', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Detail Mutasi Report - ' + JSON.parse(localStorage.getItem('branchName'));
        setIsLoadingBackDrop(false);
        getDateNow()
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDtFrom);
        setDateFormat(new Date(), setDtTo);
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'RWHDM')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            getDateNow();
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRB() {
        if (titleConfirmation === 'Print PDF') {
            PrintPDF();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Report InBound');
        handleClickConfirmation();
    };

    const PrintExcel = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Report InBound');
        handleClickConfirmation();
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingBackDrop(true);
        ApiService.DynamicPostByPageWarehouse(paramReport, "PAGE", 1, 99999, getParams(), [], [])
            .then((response) => {
                if (response.data.code === 200) {
                    setDataDetailMutasi(response.data.data.detailMutasi);
                }
                showToast({ type: 'success', message: 'Success fetch data' });
                setIsLoadingBackDrop(false);
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.error("error saat fetch", error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            });
    }

    function PrintPDF() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouse(paramReport, "PDF", [], getParams())
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Buat URL objek untuk blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Buat tautan unduhan
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = 'Report_DetailMutasi.pdf';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();

                // Hapus elemen tautan setelah pengunduhan selesai
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);

                setIsLoadingBackDrop(false);
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Print PDF, Please Contact The Administrator, ' + error });
            });
    }

    function PrintExl() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouse(paramReport, "Excel", [], getParams())
            .then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Buat URL objek untuk blob
                    const blobUrl = window.URL.createObjectURL(blob);

                    // Buat tautan unduhan
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = 'Report_DetailMutasi.xlsx';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();

                    // Hapus elemen tautan setelah pengunduhan selesai
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);

                    setIsLoadingBackDrop(false);
                } else {
                    setIsLoadingBackDrop(false);
                    throw new Error("Failed, " + response.status);
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Print Excel, Please Contact The Administrator, ' + error });
            });
    }

    const handleClickProductCategory = () => {
        if (isModalProductCategory === false) {
            setIsModalProductCategory(true);
            getDataProductCategory(1, 5);
        }
        else {
            setIsModalProductCategory(false);
        }
    };

    const handleClickProduct = () => {
        if (isModalProduct === false) {
            setIsModalProduct(true);
            getDataProduct(1, 5);
        } else {
            setIsModalProduct(false);
        }
    }

    const handleClickUnit = () => {
        if (isModalUnit === false) {
            setIsModalUnit(true);
            getDataUnit(1, 5);
        } else {
            setIsModalUnit(false);
        }
    }

    const getDataProductCategory = (pageNumber, pageSize, filters) => {
        setIsLoadingModalProduct(true);
        ApiService.PostByPage(paramProductCategory, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalProductCategory({})
                    setDataModalProductCategory(response.data.data.productCategory);
                    let temp = response.data.data.productCategory;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalProductCategory(indexed)
                    setColumnDataModalProductCategory(response.data.data.columns)
                    setTotalRowsModalProductCategory(response.data.totalRowCount)

                    setIsLoadingModalProductCategory(false)
                }
                setIsLoadingModalProductCategory(false)
            })
            .catch(function (error) {
                setIsLoadingModalProductCategory(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataProduct = (pageNumber, pageSize, filters) => {
        setIsLoadingModalProduct(true);
        if (productCategoryId !== "") {
            let filter = {
                "field": "productCategoryId",
                "data": productCategoryId.toString()
            };
            if (filters) {
                filters = [...filters, filter];
            } else {
                filters = [filter];
            }
        }
        ApiService.PostByPage(paramProduct, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalProduct({})
                    setDataModalProduct(response.data.data.product);
                    let temp = response.data.data.product;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalProduct(indexed)
                    setColumnDataModalProduct(response.data.data.columns)
                    setTotalRowsModalProduct(response.data.totalRowCount)

                    setIsLoadingModalProduct(false)
                }
                setIsLoadingModalProduct(false)
            })
            .catch(function (error) {
                setIsLoadingModalProduct(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataUnit = (pageNumber, pageSize, filters) => {
        setIsLoadingModalUnit(true);
        if (productId !== "") {
            let filter = {
                "field": "productId",
                "data": productId.toString()
            };
            if (filters) {
                filters = [...filters, filter];
            } else {
                filters = [filter];
            }
        }
        ApiService.PostByPage(paramUnit, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalUnit({})
                    setDataModalUnit(response.data.data.productUnits);
                    let temp = response.data.data.productUnits;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalUnit(indexed)
                    setColumnDataModalUnit(response.data.data.columns)
                    setTotalRowsModalUnit(response.data.totalRowCount)

                    setIsLoadingModalUnit(false)
                }
                setIsLoadingModalUnit(false)
            })
            .catch(function (error) {
                setIsLoadingModalUnit(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectHandlerProductCategory = (selectedData) => {
        if (selectedData.id !== props.productCategoryId) {
            setProductId('');
            setProductName('');
        }
        setProductCategoryId(selectedData.id);
        setProductCategoryName(selectedData.name);
    }

    const selectHandlerUnit = (selectedData) => {
        setUnitId(selectedData.unitId);
        setUnitName(selectedData.unitsName);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    };

    const getParams = () => {
        let params = [
            {
                "attr": "StartDate",
                "value": (new Date(dtFrom)).toLocaleDateString("sv-se").split('T')[0],
                "mathSign": ">="
            },
            {
                "attr": "EndDate",
                "value": (new Date(dtTo)).toLocaleDateString("sv-se").split('T')[0],
                "mathSign": "<="
            }
        ];
        if (productCategoryId !== "") {
            params = [
                ...params,
                {
                    "attr": "productCategoryId",
                    "value": productCategoryId.toString(),
                    "mathSign": "="
                }
            ]
        }
        if (productId !== "") {
            params = [
                ...params,
                {
                    "attr": "productId",
                    "value": productId.toString(),
                    "mathSign": "="
                }
            ]
        }

        if (unitId === "") {
            params = [
                ...params,
                {
                    "attr": "issmallest",
                    "value": "1",
                    "mathSign": "="
                }
            ]
        } else {
            params = [
                ...params,
                {
                    "attr": "unitid",
                    "value": unitId.toString(),
                    "mathSign": "="
                }
            ]
        }
        return params;
    }

    useEffect(() => {
        if (productId === "") {
            setUnitName("");
            setUnitId("");
        }
    }, [productId, productCategoryId])

    const ButtonComponents = () => {
        return (
            <ButtonDetailMutasi
                Reload={getData}
                PrintData={PrintData}
                PrintExcel={PrintExcel}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Detail Mutasi Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Stack spacing={'16px'} direction="row">
                                            <CustomDatePicker
                                                fullWidth
                                                type='date'
                                                label="Period"
                                                value={dtFrom}
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                formControlProps={{ sx: { width: '100%' } }}
                                                onChange={(e) => { setDtFrom(e) }}
                                            />

                                            <CustomDatePicker
                                                fullWidth
                                                label="To"
                                                type='date'
                                                value={dtTo}
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                formControlProps={{ sx: { width: '100%' } }}
                                                onChange={(e) => { setDtTo(e) }}
                                            />
                                        </Stack>

                                        <Input
                                            fullWidth
                                            variant="standard"
                                            label="Product Category"
                                            value={productCategoryName}
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            hidden={productCategoryId ? false : true}
                                                            onClick={() => {
                                                                setProductCategoryId('');
                                                                setProductCategoryName('');
                                                            }}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton onClick={handleClickProductCategory}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Input
                                            fullWidth
                                            label="Product"
                                            variant="standard"
                                            disabled
                                            value={productName}
                                            InputLabelProps={{ shrink: true }}
                                            formControlProps={{ sx: { width: '100%' } }}
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            hidden={productId ? false : true}
                                                            onClick={() => {
                                                                setProductId('');
                                                                setProductName('');
                                                                setUnitId('');
                                                                setUnitName('');
                                                            }}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton onClick={handleClickProduct}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />

                                        <Input
                                            fullWidth
                                            label="Satuan"
                                            value={unitName}
                                            disabled
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            hidden={unitName ? false : true}
                                                            onClick={() => {
                                                                setUnitId('');
                                                                setUnitName('');
                                                            }}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={handleClickUnit}
                                                            hidden={productName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>

                    {
                        dataDetailMutasi.length > 0 &&
                        <Card component="div" className='mt-3 p-3' style={{ borderRadius: '5px', backgroundColor: 'white' }}>
                            <CardContent>
                                {
                                    dataDetailMutasi.map((el) => {
                                        return (
                                            <TableContainer className='mt-2'>
                                                <Table sx={{ minWidth: 650 }} className='mb-5' size='small' aria-label="a dense table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Product:</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>{el.product}</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Beginning Stock:</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', fontSize: 17 }}>{el.beginningStock}</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}></StyledTableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Date</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>NoDoc (InBound No / OutBound No)</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>InBound Category</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Quantity In</StyledTableCell>
                                                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Quantity Out</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            el.detailMutasiDetails.map((elDetail, i) => {
                                                                return (
                                                                    <TableRow key={i} >
                                                                        <TableCell sx={{ textAlign: 'right', }}>{formatDate(elDetail.trxDate)}</TableCell>
                                                                        <TableCell sx={{ textAlign: 'left', }}>{elDetail.noDoc}</TableCell>
                                                                        <TableCell sx={{ textAlign: 'left', }}>{elDetail.inBoundCategoryName}</TableCell>
                                                                        <TableCell sx={{ textAlign: 'right', }}>{elDetail.quantityIn}</TableCell>
                                                                        <TableCell sx={{ textAlign: 'right', }}>{elDetail.quantityOut}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                        <TableRow key={el.detailMutasiDetails.length} >
                                                            <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Unit of Measurement:</TableCell>
                                                            <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>{el.units}</TableCell>
                                                            <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Ending Stock:</TableCell>
                                                            <TableCell sx={{ textAlign: 'right', fontWeight: 'bold', fontSize: 17 }}>{el.endStock}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right', }}></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )
                                    })
                                }
                            </CardContent>
                        </Card>
                    }
                </Grid>
            </Grid>

            {
                isModalProductCategory && (
                    <SelectDataMaster
                        isModal={isModalProductCategory}
                        handleClick={handleClickProductCategory}
                        titleModal="ProductCategory" setIsModal={setIsModalProductCategory}

                        getData={getDataProductCategory}
                        isLoading={isLoadingModalProductCategory}
                        totalRows={totalRowsModalProductCategory}
                        setTotalRows={setTotalRowsModalProductCategory}
                        SelectedData={SelectedDataModalProductCategory}
                        setSelectedData={setSelectedDataModalProductCategory}
                        data={dataModalProductCategory}
                        columnData={columnDataModalProductCategory}
                        dataMap={dataMapModalProductCategory}

                        selectHandler={selectHandlerProductCategory}
                    />
                )
            }

            {
                isModalProduct && (
                    <SelectDataMaster
                        isModal={isModalProduct}
                        handleClick={handleClickProduct}
                        titleModal="Product" setIsModal={setIsModalProduct}

                        getData={getDataProduct}
                        isLoading={isLoadingModalProduct}
                        totalRows={totalRowsModalProduct}
                        setTotalRows={setTotalRowsModalProduct}
                        SelectedData={SelectedDataModalProduct}
                        setSelectedData={setSelectedDataModalProduct}
                        data={dataModalProduct}
                        columnData={columnDataModalProduct}
                        dataMap={dataMapModalProduct}

                        setId={setProductId}
                        setName={setProductName}
                    />
                )
            }

            {
                isModalUnit && (
                    <SelectDataMaster
                        isModal={isModalUnit}
                        handleClick={handleClickUnit}
                        titleModal="Satuan" setIsModal={setIsModalUnit}

                        getData={getDataUnit}
                        isLoading={isLoadingModalUnit}
                        totalRows={totalRowsModalUnit}
                        setTotalRows={setTotalRowsModalUnit}
                        SelectedData={SelectedDataModalUnit}
                        setSelectedData={setSelectedDataModalUnit}
                        data={dataModalUnit}
                        columnData={columnDataModalUnit}
                        dataMap={dataMapModalUnit}

                        selectHandler={selectHandlerUnit}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRB}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default WarehouseDetailMutasi