import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { CustomDatePicker, Input, Selection } from '../../../../components/ui';

const TelexRelease = (props) => {
    const dataFullSet = [
        { id: 1, code: 'O', name: 'Original' },
        { id: 2, code: 'C', name: 'Copy' },
    ];

    const dataReleaseStatus = [
        { id: 1, code: 'T', name: 'Telex Release' },
        { id: 2, code: 'S', name: 'Seawaybill' },
    ];

    return (
        <div>
            <form>
                <Box>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            marginTop: '8px'
                        }}
                    >
                        Telex Release
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={8}
                        >
                            <Selection
                                id='0'
                                label={'Full Set'}
                                fullWidth={true}
                                placeholder={'Select Full Set'}
                                value={props.TRFullSetStatus}
                                onChange={(e) => props.setTRFullSetStatus(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        marginTop: '16px !important'
                                    }
                                }}
                            >
                                {
                                    dataFullSet.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                    ))
                                }
                            </Selection>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={8}
                        >
                            <Selection
                                id='0'
                                label={'Release Status'}
                                fullWidth={true}
                                placeholder={'Select Release Status'}
                                value={props.TRReleaseStatus}
                                onChange={(e) => props.setTRReleaseStatus(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        marginTop: '16px !important'
                                    }
                                }}
                            >
                                {
                                    dataReleaseStatus.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                    ))
                                }
                            </Selection>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={8}
                        >
                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    label="Print"
                                    value={props.TRPrintCount}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <CustomDatePicker
                                    label="Date Print"
                                    value={props.TRPrintDate}
                                    disabled
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    )
}

export default TelexRelease;