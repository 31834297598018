import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, MenuItem, Card, CardContent, Stack, InputAdornment, IconButton, Backdrop, Typography } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonOutstandingTemporaryPaymentView from './Buttons/ButtonOutstandingTemporaryPaymentView';
import SelectDataCOA from './Selects/SelectDataCOA';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast, TickBox } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const OutstandingTemporaryPaymentView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dateNow, setDateNow] = useState('');
    const [isCompany, setIsCompany] = useState('C');
    const [isAllPayment, setIsAllPayment] = useState(true);
    const CustomCompanyData = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Per Regional' },
    ];
    const [accountCode, setAccountCode] = useState('');
    const [accountName, setAccountName] = useState('');

    const paramA = "regChartOfAccountEntry/regChartOfAccountEntry";
    const [isLoadingA, setIsLoadingA] = useState(false);
    const [isModalA, setIsModalA] = useState(false);
    const [dataA, setDataA] = useState([]);
    const [titleModalA, setTitleModalA] = useState('');
    const [columnDataA, setColumnDataA] = useState([]);
    const [SelectedDataA, setSelectedDataA] = useState({});
    const [totalRowsA, setTotalRowsA] = useState(50);
    const [dataMapA, setDataMapA] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Outstanding Temporary Payment', link: '/Majura/OutstandingTemporaryPaymentReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Outstanding Temporary Payment - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDateNow();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCOSC() {
        if (titleConfirmation === 'Print') {
            PrintPDFData();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExcelData();
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (isAllPayment === false && accountCode === '') {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Chart of Account (COA)!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Outstanding Temporary Payment');
            handleClickConfirmation();
        }
    };

    const PrintDataExcel = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Outstanding Temporary Payment');
            handleClickConfirmation();
        }
    }

    function PrintPDFData() {
        setIsLoadingBackDrop(true);
        let params = [
            {
                'attr': 'accountCode',
                'value': '' + accountCode
            },
            {
                'attr': 'date',
                'value': '' + dateNow
            }
        ];

        let reportLink = 'Reports/Finance/Print/ControlReport/OutstandingTPReport/OutstandingTPReport/PrintOutstandingTemporaryPayment?typeReport=pdf';
        PrintPDF(setIsLoadingBackDrop, 'Outstanding Temporary Payment', reportLink, params);
    }

    function PrintExcelData() {
        setIsLoadingBackDrop(true);
        let params = [
            {
                'attr': 'accountCode',
                'value': '' + accountCode
            },
            {
                'attr': 'date',
                'value': '' + dateNow
            }
        ];

        let reportLink = 'Reports/Finance/Print/ControlReport/OutstandingTPReport/OutstandingTPReport/PrintOutstandingTemporaryPayment?typeReport=xls';
        PrintExcel(setIsLoadingBackDrop, 'Outstanding Temporary Payment', reportLink, params);
    }

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDateNow);
    }

    const handleChange = (event) => {
        if (!event === true) {
            setIsAllPayment(true);
            EmptyAccount();
        }
        else {
            setIsAllPayment(false);
        }
    }

    function EmptyAccount() {
        setAccountCode('');
        setAccountName('');
    }

    const handleClickAccount = () => {
        setTitleModalA('Chart Of Account (COA)');
        if (isModalA === false) {
            getDataAccount(1, 50);
            setIsModalA(true);
        }
        else {
            setIsModalA(false);
        }
    }

    const getDataAccount = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingA(true);
        let params = [
            {
                'attr': 'entryFor',
                'value': 'TP'
            }
        ]
        ApiService.DynamicPostByPage(paramA, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataA({})
                    setDataA(response.data.data.chartOfAccountEntry);
                    let temp = response.data.data.chartOfAccountEntry
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapA(indexed)
                    setColumnDataA(response.data.data.columns)
                    setTotalRowsA(response.data.totalRowCount)
                    setIsLoadingA(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingA(false)
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonOutstandingTemporaryPaymentView
                PrintData={PrintData} PrintExcel={PrintDataExcel} DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Outstanding Temporary Payment'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'32px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={isCompany}
                                                    label={'Custom Company'}
                                                    placeholder={'Select Custom Company'}
                                                    onChange={(e) => setIsCompany(e.target.value)}
                                                >
                                                    {
                                                        CustomCompanyData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <TickBox
                                                    size={'small'}
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '12px !important',
                                                                fontStyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: '20px',
                                                            }}
                                                        >
                                                            All Payments
                                                        </Typography>
                                                    }
                                                    checked={isAllPayment}
                                                    baseLabelProps={{ sx: { marginTop: '23px' } }}
                                                    onChange={() => handleChange(isAllPayment)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Stack spacing={'16px'} direction="row" style={{ marginTop: '16px' }}>
                                            <Input
                                                readOnly
                                                value={accountCode}
                                                disabled
                                                label="Payment Type Code"
                                                formControlProps={{
                                                    sx: {
                                                        width: '50%'
                                                    }
                                                }}
                                            />

                                            <Input
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={accountName}
                                                label="Payment Type Name"
                                                type='text'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyAccount()}
                                                                hidden={accountName ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleClickAccount()}
                                                                hidden={isAllPayment}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                disabled
                                            />
                                        </Stack>

                                        <CustomDatePicker
                                            type={'date'}
                                            value={dateNow}
                                            label="As Per Date"
                                            onChange={setDateNow}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalA && (
                    <SelectDataCOA
                        isModal={isModalA}
                        setIsModal={setIsModalA}
                        titleModal={titleModalA}
                        handleClick={handleClickAccount}
                        isLoading={isLoadingA}
                        data={dataA}
                        getData={getDataAccount}
                        columnData={columnDataA}
                        dataMap={dataMapA}
                        totalRows={totalRowsA}
                        setTotalRows={setTotalRowsA}
                        SelectedData={SelectedDataA}
                        setSelectedData={setSelectedDataA}

                        setAccountCode={setAccountCode}
                        setAccountName={setAccountName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCOSC}
                    />
                )
            }
        </Box>
    )
})

export default OutstandingTemporaryPaymentView