import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  alpha,
  styled,
  FormLabel,
  FormHelperText,
} from '@mui/material';

import { Icon } from '../index';

const CustomSelect = styled(MuiSelect)(({ theme }) => ({
  maxHeight: '32px',
  border: '1.5px solid',
  borderColor: '#989898',
  borderRadius: 8,
  fontSize: 12,
  backgroundColor: 'transparent',
  padding: '4px 8px',
  marginTop: '3px !important',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  '&:after, &:before': {
    border: 'none !important',
  },
  '&.Mui-focused': {
    boxShadow: `${alpha('#989898', 0.25)} 0 0 0 0.2rem`,
    borderColor: '#989898',
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    '&.Mui-focused': {
      boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.error.main,
    }
  },
  '&.Mui-readOnly': {
    cursor: 'default',
  },
  '&.Mui-disabled': {
    backgroundColor: '#EAEAEA',
    cursor: 'not-allowed',
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    '&:focus': {
      background: 'inherit',
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.575) !important',
      '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.575) !important'
    },
  },
  '& .MuiSelect-icon': {
    right: '16px'
  },
  'label + &': {
    marginTop: theme.spacing(1),
  },
}));

const DropdownIcon = ({ className, clearable, value, disabled }) => {
  return (
    <Icon
      svgIconProps={{
        className: `${className}`,
        fontSize: '12px',
        sx: {
          color: !disabled ? '#0087C2 !important' : 'inherit',
          visibility: (!clearable || !value?.length || disabled) ? 'visible' : 'hidden',
        }
      }}
      iconName={'arrow-down'}
    />
  )
}

const ClearIcon = ({ onClick, hasLabel }) => {
  return (
    <Icon
      svgIconProps={{
        onClick: onClick,
        fontSize: '12px',
        sx: {
          color: '#0087C2 !important',
          position: 'absolute',
          right: '16px',
          top: `calc(50% + ${hasLabel ? '16px' : '0'})`,
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }
      }}
      iconName={'remove'}
    />
  )
}

const Selection = ({
  // *** Props
  id,
  fullWidth,
  error,
  disabled,
  required,
  hidden,
  clearable = false,
  multiple = false,
  label,
  value = multiple ? [] : '',
  defaultValue = multiple ? [] : '',
  onChange,
  helperText,
  options = [],
  placeholder,
  minWidth = 120,
  children,

  // *** Default MUI Props
  formControlProps,
  formLabelProps,
  selectBaseProps,
  selectInnerStyle,
  menuItemProps,
}) => {
  const handleClear = () => {
    if (onChange) {
      onChange({ target: { value: multiple ? [] : '' } });
    }
  };

  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
      required={required}
      sx={{
        minWidth,
      }}
      {...formControlProps}
    >
      {label && (
        <FormLabel
          id={`label-selection-${id}`}
          htmlFor={id}
          {...formLabelProps}
          hidden={hidden}
          sx={{
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontWeight: '600',
            color: '#083A50 !important',
            textAlign: 'left',
            ...formLabelProps?.sx
          }}
        >
          {label}
        </FormLabel>
      )}

      <CustomSelect
        labelId={`label-selection-${id}`}
        id={id}
        label={label}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        multiple={multiple}
        hidden={hidden}
        displayEmpty
        SelectDisplayProps={{
          style: {
            padding: '0px 32px 0px 0px !important',
            ...selectInnerStyle
          }
        }}
        MenuProps={{
          sx: {
            marginTop: '10px',
            '.MuiMenu-paper': {
              boxShadow: '0px 4px 14px 0px #0000001A',
              borderRadius: '8px',
            },
          },
        }}
        {...selectBaseProps}
        sx={{
          '& .MuiSelect-select .notranslate::after': placeholder ?
            {
              content: `"${placeholder}"`,
              color: '#CACED8'
            }
            :
            {},
          ...selectBaseProps?.sx
        }}
        IconComponent={({ className: iconClassName }) => (
          <DropdownIcon
            className={iconClassName}
            clearable={clearable}
            value={value}
            disabled={disabled}
          />
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            {...menuItemProps}
          >
            {option.label}
          </MenuItem>
        ))}
        {children}
      </CustomSelect>

      {(value?.length > 0 && clearable && !disabled) && (
        <ClearIcon onClick={handleClear} hasLabel={label ?? false} />
      )}

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl >
  );
};

Selection.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    })
  ),
  children: PropTypes.node,
  placeholder: PropTypes.string,
  minWidth: PropTypes.number,
  formControlProps: PropTypes.object,
  formLabelProps: PropTypes.object,
  selectBaseProps: PropTypes.object,
  selectInnerStyle: PropTypes.object,
  menuItemProps: PropTypes.object,
}

export default Selection