import { Backdrop, Box, Grid, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import { Icon, PopUp, Selection } from '../../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../../utils/reusableFunction';

const ModalPrintRekap = (props) => {
    const [period, setPeriod] = useState(new Date());
    const [yearPeriod, setYearPeriod] = useState(new Date());
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);

    useEffect(() => {
        getDefaultMonth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDefaultMonth = () => {
        const date = new Date();
        setPeriod(date.getMonth() + 1);
        setYearPeriod(date.getFullYear());
    }

    const PrintData = () => {
        PrintReport("pdf");
    };

    const PrintXLS = () => {
        PrintReport("xls");
    }

    const PrintReport = (code) => {
        setIsLoadingPrint(true);
        props.handleClick();
        let params = [
            {
                "attr": "period",
                "value": "" + period
            },
            {
                "attr": "yearPeriod",
                "value": "" + yearPeriod
            }
        ];

        let reportLink = 'Transactions/Finance/OfficialReceiptPph23Report/OfficialReceiptPph23Report/PrintRekapOfficialReceiptPph23?typeReport=' + code;
        if (code !== 'xls') {
            PrintPDF(setIsLoadingPrint, 'Receipt PPh 23', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'Receipt PPh 23', reportLink, params);
        }
    };

    const getMonths = () => {
        const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
        return Array.from({ length: 12 }, (_, i) => ({
            name: formatter.format(new Date(2024, i)),
            value: i + 1
        }));
    };

    const mainContainer = (
        <div>
            <Grid container spacing={'16px'} justifyContent="center">
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        id='0'
                        fullWidth={true}
                        label="Period"
                        placeholder={'Select Period'}
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)}
                    >
                        {getMonths().map((elm) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={elm.value} value={elm.value}>{elm.name}</MenuItem>
                        ))}
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        label={'Year Period'}
                        placeholder={'Select Year Period'}
                        value={yearPeriod}
                        onChange={(e) => setYearPeriod(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                MarginTop: '16px !important'
                            }
                        }}
                    >
                        {
                            Array.from({ length: (new Date().getFullYear() - 2020 + 1) }, (_, index) => new Date().getFullYear() - index).map((year) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={year} value={year}>{year}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Print Rekap'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        <Box display="flex" alignItems="center">
                            <Grid
                                item
                                container
                                className='text-end'
                                justifyContent={'flex-end'}
                                spacing={'12px'}
                                direction="row"
                                style={{
                                    'maxWidth': '100vw',
                                    margin: '0px',
                                    columnGap: '8px',
                                    rowGap: '10px'
                                }}
                            >
                                {ButtonComponent.PrintButton(PrintData, 'PDF', true)}
                                {ButtonComponent.ExcelButton(PrintXLS, "Print Excel", false, true)}
                            </Grid>
                        </Box>
                    </div>
                </div>
            </PopUp>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => 99999 }}
                        open={true}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
}

export default ModalPrintRekap