import { Box, MenuItem, Grid, Card, CardContent } from '@mui/material';
import React, { useImperativeHandle } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username, isInfossNo } from '../../../../services/ApiService';

import ButtonCreateEditTemporaryReceipt from './Buttons/ButtonCreateEditTemporaryReceipt';
import TemporaryReceiptHeader from './Components/TemporaryReceiptHeader';
import TemporaryReceiptListJob from './Components/TemporaryReceiptListJob';
import TemporaryReceiptFor from './Components/TemporaryReceiptFor';
import TemporaryReceiptTotalPayment from './Components/TemporaryReceiptTotalPayment';
import { forwardRef } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { defaultDate, getBackDatePeriod, getDefaultDateFormat, nullDate, setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal, newWindowConfig } from '../../../../utils/masks';
import { PrintPDF } from '../../../../utils/reusableFunction.jsx';

const CreateEditTemporaryReceipt = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataTR"));

    const { temporaryReceiptId } = useParams();
    const { sJobTypeId } = useParams();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState([]);

    const paramTemporaryReceipt = 'temporaryReceipt/temporaryReceipt';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    // const paramGL = 'generalLedger/generalLedger';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);

    const history = useNavigate();
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    //Temporary Receipt Variable
    const [RowStatus, setRowStatus] = useState('ACT');
    const [TicketId, setTicketId] = useState(0);
    const [TRNumber, setTRNumber] = useState(0);
    const [CustomerTypeId, setCustomerTypeId] = useState(1);
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerCode, setCustomerCode] = useState('');
    const [CustomerName, setCustomerName] = useState('');
    const [COACode, setCOACode] = useState('');
    const [COAName, setCOAName] = useState('');
    const [JobOwnerId, setJobOwnerId] = useState(0);
    const [DueDate, setDueDate] = useState('');
    const [Printing, setPrinting] = useState(0);
    const [PrintDate, setPrintDate] = useState('');
    const [VerifyAcc, setVerifyAcc] = useState('');
    const [verify, setVerify] = useState('No');
    const [verifyAccOn, setVerifyAccOn] = useState('');
    const [paidPV, setPaidPV] = useState(false);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setyearPeriod] = useState(0);
    const [trNo2, setTRNo2] = useState('');
    const [rePrintApproved, setRePrintApproved] = useState(false);
    const [rePrintApprovedOn, setRePrintApprovedOn] = useState('');
    const [rePrintApprovedBy, setRePrintApprovedBy] = useState('');
    const [deletedRemarks, setDeletedRemarks] = useState('');
    const [isDP, setIsDP] = useState(false);
    const [isDPJob, setIsDPJob] = useState(false);
    const [wfComplete, setWFComplete] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const [deletedOn, setDeletedOn] = useState('');
    const [RateId, setRateId] = useState(0);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [RateDate, setRateDate] = useState('');
    const [isApprovedAcc, setIsApprovedAcc] = useState(false);

    const [ShipmentOrderList, setShipmentOrderList] = useState([]);
    const [ShipmentOrderIdList, setShipmentOrderIdList] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [selectedShipmentOrder, setSelectedShipmentOrder] = useState({});
    const [BankData, setBankData] = useState([]);
    const [CashPaymentUSD, setCashPaymentUSD] = useState(0);
    const [CashPaymentIDR, setCashPaymentIDR] = useState(0);
    const [TotalCashUSD, setTotalCashUSD] = useState(0);
    const [TotalCashIDR, setTotalCashIDR] = useState(0);
    const [TotalBankUSD, setTotalBankUSD] = useState(0);
    const [TotalBankIDR, setTotalBankIDR] = useState(0);

    const [TotalCashUSDReal, setTotalCashUSDReal] = useState(0);
    const [TotalCashIDRReal, setTotalCashIDRReal] = useState(0);
    const [TotalBankUSDReal, setTotalBankUSDReal] = useState(0);
    const [TotalBankIDRReal, setTotalBankIDRReal] = useState(0);

    const [isDueDate, setIsDueDate] = useState(false);
    const [tabValue, setTabValue] = useState('Cash');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isPDueDate, setIsPDueDate] = useState(false);
    const [dateDue, setDateDue] = useState('');
    const [backDate, setBackDate] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');

    const [isUnapproved, setIsUnapproved] = useState(false);

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Temporary Receipt', link: '/Majura/TemporaryReceipt', style: '' },
        { label: 'Create Temporary Receipt', link: '/Majura/TemporaryReceipt/Create/' + sJobTypeId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Temporary Receipt', link: '/Majura/TemporaryReceipt', style: '' },
        { label: 'Detail Temporary Receipt', link: '/Majura/TemporaryReceipt/' + sJobTypeId + '/' + temporaryReceiptId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Temporary Receipt - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let id = temporaryReceiptId ? temporaryReceiptId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(verifyAccOn, true);
        let dateDelete = updateDateFormat(deletedOn, true);
        let datePrintApproved = updateDateFormat(rePrintApprovedOn, true);
        let dateDue = updateDateFormat(DueDate, true);

        var data = {
            "temporaryReceipt": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": TicketId,
                "trNo": TRNumber,
                "jobTypeId": jobType.jobTypeId,
                "contactTypeId": CustomerTypeId,
                "contactId": CustomerId,
                "contactCode": CustomerCode,
                "contactName": CustomerName,
                "totalCashUSD": TotalCashUSDReal,
                "totalCashIDR": TotalCashIDRReal,
                "totalBankUSD": TotalBankUSDReal,
                "totalBankIDR": TotalBankIDRReal,
                "exRateId": RateId,
                "rate": RateReal,
                "exRateDate": RateDate,
                "approvedAccBy": VerifyAcc,
                "printing": Printing,
                "printedOn": datePrint,
                "approvedAccOn": dateVerify,
                "paidPV": paidPV,
                "jobOwnerId": JobOwnerId,
                "period": period,
                "yearPeriod": yearPeriod,
                "trNo2": trNo2,
                "accountCode": COACode,
                "accountName": COAName,
                "trDueDate": dateDue,
                "rePrintApproved": rePrintApproved,
                "rePrintApprovedOn": datePrintApproved,
                "rePrintApprovedBy": rePrintApprovedBy,
                "deletedRemarks": deletedRemarks,
                "isDP": isDP,
                "isDPJob": isDPJob,
                "wfComplete": wfComplete,
                "deleted": deleted,
                "deletedOn": dateDelete,
                "isApprovedAcc": isApprovedAcc,
                "idLama": 0,
            },
            "temporaryReceiptJobs": ShipmentOrderList,
        };

        localStorage.setItem(username + "-DataTR", JSON.stringify(data));
    }

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getLocalData = () => {
        let arrayJob = [];
        let temporaryReceiptData = dataLocal.temporaryReceipt;
        let temporaryReceiptJobData = dataLocal.temporaryReceiptJobs;

        if (temporaryReceiptId) {
            setIsEdit(true);
        }

        setRowStatus(temporaryReceiptData.rowStatus);
        setInfossNo(temporaryReceiptData.noInfoss);
        setTicketId(temporaryReceiptData.ticketId);
        setTRNumber(temporaryReceiptData.trNo);
        setCustomerTypeId(temporaryReceiptData.contactTypeId);
        setCustomerId(temporaryReceiptData.contactId);
        setCustomerCode(temporaryReceiptData.contactCode);
        setCustomerName(temporaryReceiptData.contactName);
        setCOACode(temporaryReceiptData.accountCode);
        setCOAName(temporaryReceiptData.accountName);
        setJobOwnerId(temporaryReceiptData.jobOwnerId);
        setPrinting(temporaryReceiptData.printing);
        setVerifyAcc(temporaryReceiptData.approvedAccBy);
        setVerify(temporaryReceiptData.isApprovedAcc === false ? 'No' : 'Yes');
        handleDocumentStatus(temporaryReceiptData.rowStatus, temporaryReceiptData.isApprovedAcc);
        setPaidPV(temporaryReceiptData.paidPV);
        setPeriod(temporaryReceiptData.period);
        setyearPeriod(temporaryReceiptData.yearPeriod);
        setTRNo2(temporaryReceiptData.trNo2);
        setRePrintApproved(temporaryReceiptData.rePrintApproved);
        setRePrintApprovedBy(temporaryReceiptData.rePrintApprovedBy);
        setDeletedRemarks(temporaryReceiptData.deletedRemarks);
        setIsDP(temporaryReceiptData.isDP);
        setIsDPJob(temporaryReceiptData.isDPJob);
        setWFComplete(temporaryReceiptData.wfComplete);
        setDeleted(temporaryReceiptData.deleted);
        setRateId(temporaryReceiptData.exRateId);
        setRate(convertToNominal(temporaryReceiptData.rate));
        setRateReal(temporaryReceiptData.rate);
        setIsApprovedAcc(temporaryReceiptData.isApprovedAcc);

        setDateFormat(temporaryReceiptData.exRateDate, setRateDate);
        setDateFormat(temporaryReceiptData.deletedOn, setDeletedOn);
        setDateFormat(temporaryReceiptData.rePrintApprovedOn, setRePrintApprovedOn);
        setDateFormat(temporaryReceiptData.approvedAccOn, setVerifyAccOn);
        setDateFormat(temporaryReceiptData.printedOn, setPrintDate);

        let dateDue = updateDateFormat(temporaryReceiptData.trDueDate);
        if (dateDue !== nullDate && dateDue !== getDefaultDateFormat(defaultDate) && dateDue !== '') {
            setDueDate(dateDue);
            setIsDueDate(true);
        }

        setShipmentOrderList(temporaryReceiptJobData);
        arrayJob = temporaryReceiptJobData;
        let tempArrayId = [];
        arrayJob.forEach(element => {
            tempArrayId = [...tempArrayId, element.shipmentOrderId];
        });
        setShipmentOrderIdList(tempArrayId);

        calculateTotalCash(arrayJob);
        calculateTotalBank(arrayJob);

        localStorage.removeItem(username + '-DataTR');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id) => {
        let arrayJob = [];
        ApiService.FinAccPostById(paramTemporaryReceipt, id)
            .then((response) => {
                if (response.data.code === 200) {
                    let temporaryReceiptData = response.data.data.temporaryReceipt;
                    setIsEdit(true);
                    setRowStatus(temporaryReceiptData.rowStatus);
                    setInfossNo(temporaryReceiptData.noInfoss);
                    setTicketId(temporaryReceiptData.ticketId);
                    setTRNumber(temporaryReceiptData.trNo);
                    setCustomerTypeId(temporaryReceiptData.contactTypeId);
                    setCustomerId(temporaryReceiptData.contactId);
                    setCustomerCode(temporaryReceiptData.contactCode);
                    setCustomerName(temporaryReceiptData.contactName);
                    setCOACode(temporaryReceiptData.accountCode);
                    setCOAName(temporaryReceiptData.accountName);
                    setJobOwnerId(temporaryReceiptData.jobOwnerId);
                    setPrinting(temporaryReceiptData.printing);
                    setVerifyAcc(temporaryReceiptData.approvedAccBy);
                    setVerify(temporaryReceiptData.isApprovedAcc === false ? 'No' : 'Yes');
                    handleDocumentStatus(temporaryReceiptData.rowStatus, temporaryReceiptData.isApprovedAcc);
                    setPaidPV(temporaryReceiptData.paidPV);
                    setPeriod(temporaryReceiptData.period);
                    setyearPeriod(temporaryReceiptData.yearPeriod);
                    setTRNo2(temporaryReceiptData.trNo2);
                    setRePrintApproved(temporaryReceiptData.rePrintApproved);
                    setRePrintApprovedBy(temporaryReceiptData.rePrintApprovedBy);
                    setDeletedRemarks(temporaryReceiptData.deletedRemarks);
                    setIsDP(temporaryReceiptData.isDP);
                    setIsDPJob(temporaryReceiptData.isDPJob);
                    setWFComplete(temporaryReceiptData.wfComplete);
                    setDeleted(temporaryReceiptData.deleted);
                    setRateId(temporaryReceiptData.exRateId);
                    setRate(convertToNominal(temporaryReceiptData.rate));
                    setRateReal(temporaryReceiptData.rate);
                    setIsApprovedAcc(temporaryReceiptData.isApprovedAcc);

                    setDateFormat(temporaryReceiptData.exRateDate, setRateDate);
                    setDateFormat(temporaryReceiptData.deletedOn, setDeletedOn);
                    setDateFormat(temporaryReceiptData.rePrintApprovedOn, setRePrintApprovedOn);
                    setDateFormat(temporaryReceiptData.approvedAccOn, setVerifyAccOn);
                    setDateFormat(temporaryReceiptData.printedOn, setPrintDate);

                    let dateDue = updateDateFormat(temporaryReceiptData.trDueDate);
                    if (dateDue !== nullDate && dateDue !== getDefaultDateFormat(defaultDate) && dateDue !== '') {
                        setDueDate(dateDue);
                        setIsDueDate(true);
                    }

                    arrayJob = temporaryReceiptData.temporaryReceiptJobs;
                    let tempArrayId = [];

                    arrayJob.forEach(element => {
                        tempArrayId = [...tempArrayId, element.shipmentOrderId];

                        if (element.temporaryReceiptJobBanks.length > 0) {
                            element.temporaryReceiptJobBanks.forEach(el => {
                                let dateDue = updateDateFormat(el.dueDate);
                                if (dateDue === nullDate || dateDue === '') {
                                    el.dueDate = defaultDate;
                                }
                            });
                        }
                    });

                    setShipmentOrderList(arrayJob);
                    setShipmentOrderIdList(tempArrayId);
                }
                else {
                    Back();
                }
            })
            .then(() => {
                calculateTotalCash(arrayJob);
                calculateTotalBank(arrayJob);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataApproved = () => {
        let data = {};
        ApiService.FinAccCustomPost(paramTemporaryReceipt + '/Create?check=true', data)
            .then((res) => {
                if (res.data.code === 200 && res.data.data !== null) {
                    let data = res.data.data;

                    if (data != null) {
                        setIsUnapproved(true);

                        if (!temporaryReceiptId) {
                            Back();
                        }
                    }
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);

                console.error('error saat fetch', error)
                // Alert.ErrorAlert(error.toString(), true)
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FTER')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        handleDocumentStatus();
                        setCheck(checked);

                        GetJobType();
                        getDataApproved();
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const GetJobType = () => {
        ApiService.PostById(paramRegJobType, sJobTypeId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);

                    GetJobOwnerData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    //Function for Principle By
    const GetJobOwnerData = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setJobOwnerId(response.data.data.jobOwner[0].id);
                    }
                    setJobOwnerData(response.data.data.jobOwner);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        if (temporaryReceiptId) {
                            getData(temporaryReceiptId);
                        }
                        else {
                            setDateFormat(new Date(), GetExRate);
                            GetFormatCurrency();
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={JobOwnerId}
                onChange={(e) => setJobOwnerId(e.target.value)}
                disabled={Printing > 0}
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetExRate = (date) => {
        ApiService.PostByExRateDate(date)
            .then((response) => {
                if (response.data.code === 200) {
                    setRateId(response.data.data.exchangeRate.id);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setRateDate);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetFormatCurrency = () => {
        let totalUSD = 0;
        let totalIDR = 0;
        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);

        setTotalCashUSD(formatUSD);
        setTotalCashIDR(formatIDR);
        setTotalBankUSD(formatUSD);
        setTotalBankIDR(formatIDR);

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    };

    function Save(isDeleted, isPrint = false, result) {
        if (isLoadingBackDrop) return
        if (isDeleted === false) {
            setIsLoadingBackDrop(true);
        }

        let id = temporaryReceiptId ? temporaryReceiptId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(verifyAccOn, true);
        let dateDelete = updateDateFormat(deletedOn, true);
        let datePrintApproved = updateDateFormat(rePrintApprovedOn, true);
        let dateDue = updateDateFormat(DueDate, true);
        let arrayShipment = [];
        let totalCashUSD = 0;
        let totalCashIDR = 0;
        let totalBankUSD = 0;
        let totalBankIDR = 0;

        if (isDeleted === false) {
            arrayShipment = ShipmentOrderList;
            totalCashUSD = TotalCashUSDReal;
            totalCashIDR = TotalCashIDRReal;
            totalBankUSD = TotalBankUSDReal;
            totalBankIDR = TotalBankIDRReal;
        }
        else {
            arrayShipment = result;

            for (let i = 0; i < result.length; i++) {
                totalCashUSD = parseFloat(totalCashUSD) + parseFloat(result[i].cashUSD);
                totalCashIDR = parseFloat(totalCashIDR) + parseFloat(result[i].cashIDR);

                for (let j = 0; j < result[i].temporaryPaymentJobBanks.length; j++) {
                    if (result[i].temporaryPaymentJobBanks[j].amountCrr === 0) {
                        totalBankUSD = parseFloat(totalBankUSD) + parseFloat(result[i].temporaryPaymentJobBanks[j].amount);
                    }
                    else if (result[i].temporaryPaymentJobBanks[j].amountCrr === 1) {
                        totalBankIDR = parseFloat(totalBankIDR) + parseFloat(result[i].temporaryPaymentJobBanks[j].amount);
                    }
                }
            }
        }

        var data = {
            "temporaryReceipt": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": TicketId,
                "trNo": TRNumber,
                "jobTypeId": jobType.jobTypeId,
                "contactTypeId": CustomerTypeId,
                "contactId": CustomerId,
                "contactCode": CustomerCode,
                "contactName": CustomerName,
                "totalCashUSD": totalCashUSD,
                "totalCashIDR": totalCashIDR,
                "totalBankUSD": totalBankUSD,
                "totalBankIDR": totalBankIDR,
                "exRateId": RateId,
                "rate": RateReal,
                "exRateDate": RateDate,
                "approvedAccBy": VerifyAcc,
                "printing": Printing,
                "printedOn": datePrint,
                "approvedAccOn": dateVerify,
                "paidPV": paidPV,
                "jobOwnerId": JobOwnerId,
                "period": period,
                "yearPeriod": yearPeriod,
                "trNo2": trNo2,
                "accountCode": COACode,
                "accountName": COAName,
                "trDueDate": dateDue,
                "rePrintApproved": rePrintApproved,
                "rePrintApprovedOn": datePrintApproved,
                "rePrintApprovedBy": rePrintApprovedBy,
                "deletedRemarks": deletedRemarks,
                "isDP": isDP,
                "isDPJob": isDPJob,
                "wfComplete": wfComplete,
                "deleted": deleted,
                "deletedOn": dateDelete,
                "idLama": 0,
            },
            "temporaryReceiptJobs": arrayShipment,
            "temporaryReceiptJobBanks": []
        };

        let errMsg = undefined;

        if (data.temporaryReceipt.contactId === 0) {
            errMsg = 'Customer Must Be Filled!';
        }
        else if (data.temporaryReceipt.accountCode === '') {
            errMsg = 'Receipt Type Must Be Filled!';
        }
        else if (data.temporaryReceipt.totalCashUSD === 0 && data.temporaryReceipt.totalCashIDR === 0 && data.temporaryReceipt.totalBankUSD === 0 && data.temporaryReceipt.totalBankIDR === 0) {
            errMsg = 'Total Payment Must Be Filled!';
        }
        else if (data.temporaryReceipt.noInfoss === '' && isInfossNo) {
            errMsg = 'Please Fill Infoss Number!';
        }

        if (errMsg === undefined) {
            ApiService.HelpCreateData(data, paramTemporaryReceipt, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (id === 0) {
                            ApiService.CreateFinAcc(paramTemporaryReceipt, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Create Data Success';
                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                history('/Majura/TemporaryReceipt/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                window.location.reload();
                                                localStorage.removeItem('tempId');
                                            },
                                            1250);
                                    }
                                }
                                else {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: res.data.message });
                                    }
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramTemporaryReceipt, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isDeleted === false && isPrint === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Update Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                history('/Majura/TemporaryReceipt/' + sJobTypeId + '/' + temporaryReceiptId, { replace: true });
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                    else if (isPrint) {
                                        Print();
                                    }
                                }
                                else {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: res.data.message });
                                    }
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errMsg });
        }
    }

    function Back() {
        let check = 0;
        if (isEdit === true) {
            if (ShipmentOrderList.length === 0) {
                check = 1;
            }
        }

        if (check === 0) {
            history('/Majura/TemporaryReceipt');
        }
        else {
            showToast({ type: 'error', message: 'Please Add Temporary Receipt Job!' });
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
            setIsPDueDate(false);
        }
    }

    function getDateNowP() {
        setDateFormat(new Date(), setDateDue);
    };

    function ConfirmationTR() {
        if (titleConfirmation === 'Print') {
            CheckDataCondition('PrintPDF');
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Un Approve') {
            UnApprove();
        }
        setIsModalConfirmation(false);
    }

    const CheckDataCondition = async (isAction) => {
        if (!temporaryReceiptId && isAction !== 'Save') {
            showToast({ type: 'error', message: 'Create The Data First!' });
            return;
        }

        try {
            setIsLoadingBackDrop(true);

            if (isAction === 'Save' && !temporaryReceiptId) {
                Save(false);
                return;
            }

            const response = await ApiService.FinAccPostById(paramTemporaryReceipt, temporaryReceiptId);

            if (response.data.code !== 200) {
                throw new Error(response.data.message);
            }

            const data = response.data.data.temporaryReceipt;
            setIsLoadingBackDrop(false);

            const setConfirmationModal = (title) => {
                setTitleConfirmation(title);
                setTitleModalConfirmation('Temporary Receipt');
                handleClickConfirmation();
            };

            const handleSave = () => {
                if (data.printing > 0) {
                    getData(temporaryReceiptId);
                    showToast({ type: 'error', message: 'Data Already Been Printed!' });
                } else {
                    Save(false);
                }
            };

            const handlePrintAction = async () => {
                getData(temporaryReceiptId);
                if (!data.rePrintApproved) {
                    showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
                    setIsModalConfirmation(false);
                    return;
                }

                setIsLoadingBackDrop(true);
                setConfirmationModal('Print');

                if (data.printing === 0) {
                    getDateNowP();
                } else {
                    setDateFormat(data.printedOn, setDateDue);
                }

                if (data.totalCashIDR === 0 && data.totalCashUSD === 0) {
                    setIsPDueDate(true);
                }

                try {
                    const backDate = await getBackDatePeriod();
                    setBackDate(backDate);
                    handleClickConfirmation();
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoadingBackDrop(false);
                }
            };

            switch (isAction) {
                case 'Save':
                    handleSave();
                    break;
                case 'Print':
                    await handlePrintAction();
                    break;
                case 'PrintPDF':
                    if (data.isApprovedAcc) {
                        getData(temporaryReceiptId);
                        Print()
                    } else {
                        Save(true);
                    }
                    break;
                case 'Approve':
                    if (data.printing > 0) {
                        getData(temporaryReceiptId);
                        data.isApprovedAcc ? showToast({ type: 'error', message: 'Data Already Been Approved!' }) : setConfirmationModal('Approve');
                    } else {
                        showToast({ type: 'error', message: 'Please Print The Data First!' });
                    }
                    break;
                case 'Un Approve':
                    if (data.printing > 0) {
                        getData(temporaryReceiptId);
                        data.isApprovedAcc ? setConfirmationModal('Un Approve') : showToast({ type: 'error', message: 'Data Hasn\'t Been Approved Yet!' });
                    } else {
                        showToast({ type: 'error', message: 'Please Print The Data First!' });
                    }
                    break;
                default:
                    showToast({ type: 'error', message: 'Invalid Action!' });
                    break;
            }
        } catch (error) {
            setIsLoadingBackDrop(false);
            console.error('Error fetching data:', error);
            showToast({ type: 'error', message: `Error When Read Data! ${error.message || error}` });
        }
    };

    const Print = () => {
        setIsLoadingBackDrop(true);
        let dueDate = dateDue === '' ? updateDateFormat(new Date()) : dateDue;
        ApiService.FinAccBackDatePrint(paramTemporaryReceipt, temporaryReceiptId, dueDate).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let count = parseInt(Printing) + 1;
                setPrinting(count);

                let reportLink = 'Transactions/Finance/TemporaryReceiptReport/TemporaryReceiptReport/PrintTemporaryReceipt?Id=' + temporaryReceiptId;
                PrintPDF(setIsLoadingBackDrop, 'Temporary Receipt', reportLink, [], true);
                setIsDueDate(false);
            }
            else {
                setIsLoadingBackDrop(false);
                setIsDueDate(false);
                showToast({ type: 'error', message: message });
            }
        });
    };

    // const CreateGL = (id) => {
    //     var data = {
    //         "generalLedger": {
    //             "source": "TR",
    //             "refId": id,
    //             "methodPost": "AUTO",
    //             "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
    //         }
    //     }

    //     ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //         let message = '';
    //         message = res.data.message;
    //         if (res.data.code !== 200) {
    //             showToast({ type: 'error', message: message });
    //         }
    //     });
    // }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusApprovedAcc(paramTemporaryReceipt, temporaryReceiptId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                setIsLoadingBackDrop(false);
                // CreateGL(temporaryReceiptId);
                showToast({ type: 'success', message: message });

                setTimeout(
                    function () {
                        history('/Majura/TemporaryReceipt/' + sJobTypeId + '/' + temporaryReceiptId, { replace: true });
                        window.location.reload();
                    },
                    1250);
            }
            else {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: message });
            }
        })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Approve Temporary Receipt!' });
            });
    }

    const UnApprove = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusUnApprovedAcc(paramTemporaryReceipt, temporaryReceiptId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                setIsLoadingBackDrop(false);
                // CreateGL(res.data.data.ContraId);
                showToast({ type: 'success', message: message });

                setTimeout(
                    function () {
                        history('/Majura/TemporaryReceipt/' + sJobTypeId + '/' + temporaryReceiptId, { replace: true });
                        window.location.reload();
                    },
                    1250);
            }
            else {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: message });
            }
        })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Approve Temporary Receipt!' });
            });
    }

    function AddNew() {
        window.open('/Majura/TemporaryReceipt/Create/' + sJobTypeId, newWindowConfig);
    }

    function calculateTotalCash(result) {
        let totalUSD = 0;
        let totalIDR = 0;

        for (let i = 0; i < result.length; i++) {
            totalUSD = parseFloat(totalUSD) + parseFloat(result[i].cashUSD);
            totalIDR = parseFloat(totalIDR) + parseFloat(result[i].cashIDR);
        }

        setTotalCashUSDReal(totalUSD);
        setTotalCashIDRReal(totalIDR);
        setTotalCashUSD(convertToNominal(totalUSD));
        setTotalCashIDR(convertToNominal(totalIDR));
    }

    function calculateTotalBank(result) {
        let totalUSD = 0;
        let totalIDR = 0;

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].temporaryReceiptJobBanks.length; j++) {
                if (result[i].temporaryReceiptJobBanks[j].amountCrr === 0) {
                    totalUSD = parseFloat(totalUSD) + parseFloat(result[i].temporaryReceiptJobBanks[j].amount);
                }
                else if (result[i].temporaryReceiptJobBanks[j].amountCrr === 1) {
                    totalIDR = parseFloat(totalIDR) + parseFloat(result[i].temporaryReceiptJobBanks[j].amount);
                }
            }
        }

        setTotalBankUSDReal(totalUSD);
        setTotalBankIDRReal(totalIDR);
        setTotalBankUSD(convertToNominal(totalUSD));
        setTotalBankIDR(convertToNominal(totalIDR));
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditTemporaryReceipt
                Save={() => CheckDataCondition('Save')}
                Back={Back}
                Print={() => CheckDataCondition('Print')}
                Approve={() => CheckDataCondition('Approve')}
                UnApprove={() => CheckDataCondition('Un Approve')}
                AddNew={AddNew}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                isApprovedAcc={isApprovedAcc}
                isUnapproved={isUnapproved}
                Printing={Printing}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '0px 32px 24px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            temporaryReceiptId ?
                                'Temporary Receipt - ' + jobType.name + ' (' + trNo2 + ')'
                                :
                                'Temporary Receipt - ' + jobType.name + ' ( New Document )'
                        }
                        BreadcrumbsItems={temporaryReceiptId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={TicketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                        isSticky={true}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryReceiptHeader
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                selectPrincipleBy={selectPrincipleBy}
                                temporaryReceiptId={temporaryReceiptId}
                                TRNumber={trNo2}
                                JobOwnerId={JobOwnerId}
                                verifyAccOn={verifyAccOn}
                                CustomerId={CustomerId}
                                setCustomerId={setCustomerId}
                                CustomerCode={CustomerCode}
                                setCustomerCode={setCustomerCode}
                                CustomerName={CustomerName}
                                setCustomerName={setCustomerName}
                                CustomerTypeId={CustomerTypeId}
                                setCustomerTypeId={setCustomerTypeId}
                                COACode={COACode}
                                setCOACode={setCOACode}
                                getDateNow={getDateNow}
                                COAName={COAName}
                                setCOAName={setCOAName}
                                isApprovedAcc={isApprovedAcc}
                                DueDate={DueDate}
                                setDueDate={setDueDate}
                                isEdit={isEdit}
                                PrintDate={PrintDate}
                                verify={verify}
                                jobType={jobType}
                                GetExRate={GetExRate}
                                calculateTotalBank={calculateTotalBank}
                                calculateTotalCash={calculateTotalCash}
                                setCashPaymentUSD={setCashPaymentUSD}
                                setCashPaymentIDR={setCashPaymentIDR}
                                Rate={Rate}
                                RateDate={RateDate}
                                setBankData={setBankData}
                                setShipmentOrderIdList={setShipmentOrderIdList}
                                setShipmentOrderList={setShipmentOrderList}
                                setSelectedShipmentOrder={setSelectedShipmentOrder}
                                isDueDate={isDueDate}
                                setIsDueDate={setIsDueDate}
                                Printing={Printing}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryReceiptListJob
                                TRNumber={trNo2}
                                Rate={RateReal}
                                RateId={RateId}
                                CustomerTypeId={CustomerTypeId}
                                calculateTotalBank={calculateTotalBank}
                                calculateTotalCash={calculateTotalCash}
                                setSelectedShipmentOrder={setSelectedShipmentOrder}
                                temporaryReceiptId={temporaryReceiptId}
                                CustomerId={CustomerId}
                                jobType={jobType}
                                setBankData={setBankData}
                                isApprovedAcc={isApprovedAcc}
                                setCashPaymentUSD={setCashPaymentUSD}
                                setCashPaymentIDR={setCashPaymentIDR}
                                ShipmentOrderList={ShipmentOrderList}
                                setShipmentOrderList={setShipmentOrderList}
                                Save={Save}
                                ShipmentOrderIdList={ShipmentOrderIdList}
                                setShipmentOrderIdList={setShipmentOrderIdList}
                                Printing={Printing}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryReceiptFor
                                tabValue={tabValue}
                                setTabValue={setTabValue}
                                Rate={RateReal}
                                selectedShipmentOrder={selectedShipmentOrder}
                                temporaryReceiptId={temporaryReceiptId}
                                ShipmentOrderList={ShipmentOrderList}
                                setShipmentOrderList={setShipmentOrderList}
                                BankData={BankData}
                                setBankData={setBankData}
                                isApprovedAcc={isApprovedAcc}
                                calculateTotalCash={calculateTotalCash}
                                calculateTotalBank={calculateTotalBank}
                                CashPaymentUSD={CashPaymentUSD}
                                setCashPaymentUSD={setCashPaymentUSD}
                                CashPaymentIDR={CashPaymentIDR}
                                setCashPaymentIDR={setCashPaymentIDR}
                                TotalCashUSD={TotalCashUSD}
                                setTotalCashUSD={setTotalCashUSD}
                                TotalCashIDR={TotalCashIDR}
                                setTotalCashIDR={setTotalCashIDR}
                                TotalBankUSD={TotalBankUSD}
                                setTotalBankUSD={setTotalBankUSD}
                                TotalBankIDR={TotalBankIDR}
                                setTotalBankIDR={setTotalBankIDR}
                                Printing={Printing}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryReceiptTotalPayment
                                TotalCashUSD={TotalCashUSD}
                                TotalCashIDR={TotalCashIDR}
                                TotalBankUSD={TotalBankUSD}
                                TotalBankIDR={TotalBankIDR}
                                Printing={Printing}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationTR}
                            isDueDate={isPDueDate}
                            setIsDueDate={setIsPDueDate}
                            dueDate={dateDue}
                            backDate={backDate}
                            setDueDate={setDateDue}
                            PrintCount={Printing}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditTemporaryReceipt;