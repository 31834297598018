import { Grid, IconButton, Stack, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';

import SelectDataNonContact from '../Selects/SelectDataNonContact';
import SelectDataTracking from '../Selects/SelectDataTracking';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { CustomDatePicker, Icon, Input, PopUp, showToast } from '../../../../components/ui';

const ModalContainerInfo = (props) => {
    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [tipe, setTipe] = useState(1);

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    let countErrN = 1;

    const [isModal3, setIsModal3] = useState(false);
    const [titleModal3, setTitleModal3] = useState('');

    const [isLoadingT, setIsLoadingT] = useState(false);
    const [dataT, setDataT] = useState([]);
    const [dataMapT, setDataMapT] = useState([]);
    const [columnDataT, setColumnDataT] = useState([]);
    const [SelectedDataT, setSelectedDataT] = useState({});
    const [totalRowsT, setTotalRowsT] = useState(50);
    let countErrT = 1;

    const [statusName, setStatusName] = useState('');

    const handleClick2 = (title) => {
        if (isModal2 === false) {
            setIsModal2(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal2(false);
            localStorage.removeItem("title");
        }
    };

    const handleClick3 = (title) => {
        if (isModal3 === false) {
            setIsModal3(true);
            localStorage.setItem("titleT", JSON.stringify(title));
            getDataTracking(1, 50);
        }
        else {
            setIsModal3(false);
        }
    };

    const emptyState = () => {
        props.setContainerNo('');
        props.setSealNo(0);
        props.setContSizeType('');
        props.setStatusId(1);
        props.setStatus('');
        props.setDescription('');
        props.setPortId(0);
        props.setPortCode('');
        props.setPortName('');
        props.setAirportId(0);
        props.setAirportCode('');
        props.setAirportName('');
        props.setCityId(0);
        props.setCityCode('');
        props.setCityName('');
        props.setCountryName('');
        props.setVessel('');
        props.setFlight('');
        props.setConNoteSequence(0);
        props.setConNote('');
        props.setReceiptName('');
        props.getDateNow();
    };

    const AddCity = (data) => {
        if (data === 'search') {
            setTitleModal2('City');
            setTipe(4);
            handleClick2('City');
        }
        else if (data === 'delete') {
            props.setCityId(0);
            props.setCityCode('');
            props.setCityName('');
            props.setCountryName('');

            props.setPortId(0);
            props.setPortCode('');
            props.setPortName('');

            if (props.jobType.portType === 'AIR') {
                props.setAirportId(0);
                props.setAirportCode('');
                props.setAirportName('');
            }
            else {
                props.setPortId(0);
                props.setPortCode('');
                props.setPortName('');
            }
        }
    };

    const AddVessel = (data) => {
        if (data === 'search') {
            if (props.jobType.portType !== 'AIR') {
                setTitleModal3('Vessel');
            }
            else {
                setTitleModal3('Flight');
            }
            handleClick3('Vessel');
        }
        else if (data === 'delete') {
            if (props.jobType.portType === 'SEA') {
                props.setVessel('');
            }
            else if (props.jobType.portType === 'AIR') {
                props.setFlight('');
            }
        }
    };

    const AddContainer = (data) => {
        if (data === 'search') {
            setTitleModal3('Container');
            handleClick3('Container');
        }
        else if (data === 'delete') {
            props.setSeaContainerSequence('');
            props.setContainerNo('');
            props.setSealNo(0);
            props.setContSizeType('');
        }
    };

    const AddConNote = (data) => {
        if (data === 'search') {
            setTitleModal3('Con Note');
            handleClick3('Con Note');
        }
        else if (data === 'delete') {
            props.setConNoteSequence('');
            props.setConNote('');
        }
    };

    const AddPort = (data) => {
        if (data === 'search') {
            setTitleModal3('Port');
            handleClick3('Port');
        }
        else if (data === 'delete') {
            props.setPortId(0);
            props.setPortCode('');
            props.setPortName('');
        }
    };

    const AddAirport = (data) => {
        if (data === 'search') {
            setTitleModal3('AirPort');
            handleClick3('AirPort');
        }
        else if (data === 'delete') {
            if (props.jobType.portType === 'AIR') {
                props.setAirportId(0);
                props.setAirportCode('');
                props.setAirportName('');
            }
            else {
                props.setPortId(0);
                props.setPortCode('');
                props.setPortName('');
            }
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const handleSaveSeaAir = () => {
        if (!props.vessel && !props.flight) {
            showToast({ type: 'error', message: 'Please Select Vessel!' });
        }
        else {
            if (!props.cityId) {
                showToast({ type: 'error', message: 'Please Select City!' });
            }
            else {
                if (props.jobType.portType === 'SEA' && !props.portId) {
                    showToast({ type: 'error', message: 'Please Select Port!' });
                }
                else if (props.jobType.portType === 'AIR' && !props.airportId) {
                    showToast({ type: 'error', message: 'Please Select AirPort!' });
                }
                else {
                    let Sequence = 1;

                    if (props.detailEdit) {
                        Sequence = props.selectedDetail.sequence;
                    }
                    else {
                        if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                    }

                    let data = {
                        "countryId": countryId,
                        "companyId": companyId,
                        "branchId": branchId,
                        "userId": userId,
                        "userCode": userCode,

                        "rowStatus": "ACT",
                        "shipmentId": props.ShipmentId,
                        "sequence": Sequence,
                        "sequenceSeaContainer": props.seaContainerSequence,
                        "containerNo": props.containerNo,
                        "sealNo": toString(props.sealNo),
                        "sizeType": props.contSizeType,
                        "status": statusName,
                        "description": props.description,
                        "portId": props.portId,
                        "portCode": props.portCode,
                        "portName": props.portName,
                        "airportId": props.airportId,
                        "airportCode": props.airportCode,
                        "airportName": props.airportName,
                        "cityId": props.cityId,
                        "cityCode": props.cityCode,
                        "cityName": props.cityName,
                        "countryName": props.countryName,
                        "sequenceRouting": props.routingSequence,
                        "vessel": props.vessel,
                        "flight": props.flight,
                        "statusUpdateOn": props.statusUpdateOn,
                        "statusId": props.statusId,
                        "sequenceConNote": 0,
                        "conNote": "",
                        "receiptName": "",
                        "idLama": 0,
                    }

                    if (!props.detailEdit) {
                        props.setDataTracking([...props.dataTracking, data]);
                    }
                    else {
                        const newArr = props.dataTracking.slice();
                        newArr.forEach(el => {
                            if (el.sequence === data.sequence) {
                                el.containerNo = data.containerNo;
                                el.sealNo = data.sealNo;
                                el.sizeType = data.sizeType;
                                el.vessel = data.vessel;
                                el.flight = data.flight;
                                el.status = data.status;
                                el.statusId = data.statusId;
                                el.statusUpdateOn = data.statusUpdateOn;
                                el.cityId = data.cityId;
                                el.cityCode = data.cityCode;
                                el.cityName = data.cityName;
                                el.countryName = data.countryName;
                                el.description = data.description;
                                el.portId = data.portId;
                                el.portCode = data.portCode;
                                el.portName = data.portName;
                                el.airportId = data.airportId;
                                el.airportCode = data.airportCode;
                                el.airportName = data.airportName;
                                el.conNote = data.conNote;
                                el.receiptName = data.receiptName;

                                el.sequenceConNote = data.sequenceConNote;
                                el.sequenceSeaContainer = data.sequenceSeaContainer;
                                el.sequenceRouting = data.sequenceRouting;
                            }
                        })
                        props.setDataTracking(newArr);
                    }

                    emptyState();
                    handleClose();
                    props.setDetailEdit(false);
                    props.setSelectedDetail({});
                }
            }
        }
    }

    const handleSaveDomestic = () => {
        if (!props.setConNoteSequence) {
            showToast({ type: 'error', message: 'Please Select Con Note!' });
        }
        else {
            if (!props.receiptName) {
                showToast({ type: 'error', message: 'Please Input Receipt Name!' });
            }
            else {
                if (!props.cityId) {
                    showToast({ type: 'error', message: 'Please Select City!' });
                }
                else {
                    if (!props.portId) {
                        showToast({ type: 'error', message: 'Please Select Port!' });
                    }
                    else {
                        let Sequence = 1;
                        let vName = '';
                        let fName = '';

                        if (props.VesselFlight === 'V') {
                            vName = props.vessel;
                        }
                        else if (props.VesselFlight === 'F') {
                            fName = props.flight;
                        }

                        if (props.detailEdit) {
                            Sequence = props.selectedDetail.sequence;
                        }
                        else {
                            if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                        }

                        let data = {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "user": userCode,

                            "rowStatus": "ACT",
                            "shipmentId": props.ShipmentId,
                            "sequence": Sequence,
                            "sequenceSeaContainer": 0,
                            "containerNo": '',
                            "sealNo": '',
                            "sizeType": '',
                            "status": statusName,
                            "description": props.description,
                            "portId": props.portId,
                            "portCode": props.portCode,
                            "portName": props.portName,
                            "airportId": 0,
                            "airportCode": '',
                            "airportName": '',
                            "cityId": props.cityId,
                            "cityCode": props.cityCode,
                            "cityName": props.cityName,
                            "countryName": props.countryName,
                            "sequenceRouting": props.routingSequence,
                            "vessel": vName,
                            "flight": fName,
                            "statusUpdateOn": props.statusUpdateOn,
                            "statusId": props.statusId,
                            "sequenceConNote": props.conNoteSequence,
                            "conNote": props.conNote,
                            "receiptName": props.receiptName,
                            "idLama": 0,
                        }

                        if (!props.detailEdit) {
                            props.setDataTracking([...props.dataTracking, data]);
                        }
                        else {
                            const newArr = props.dataTracking.slice();
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.containerNo = data.containerNo;
                                    el.sealNo = data.sealNo;
                                    el.sizeType = data.sizeType;
                                    el.vessel = data.vessel;
                                    el.flight = data.flight;
                                    el.status = data.status;
                                    el.statusId = data.statusId;
                                    el.statusUpdateOn = data.statusUpdateOn;
                                    el.cityId = data.cityId;
                                    el.cityCode = data.cityCode;
                                    el.cityName = data.cityName;
                                    el.countryName = data.countryName;
                                    el.description = data.description;
                                    el.portId = data.portId;
                                    el.portCode = data.portCode;
                                    el.portName = data.portName;
                                    el.airportId = data.airportId;
                                    el.airportCode = data.airportCode;
                                    el.airportName = data.airportName;
                                    el.conNote = data.conNote;
                                    el.receiptName = data.receiptName;

                                    el.sequenceConNote = data.sequenceConNote;
                                    el.sequenceSeaContainer = data.sequenceSeaContainer;
                                    el.sequenceRouting = data.sequenceRouting;
                                }
                            })
                            props.setDataTracking(newArr);
                        }

                        emptyState();
                        handleClose();
                        props.setDetailEdit(false);
                        props.setSelectedDetail({});
                    }
                }
            }
        }
    }

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        let title = JSON.parse(localStorage.getItem("title"));

        if (
            title === 'Place Of Receipt' ||
            title === 'Place Of Delivery' ||
            title === 'City' ||
            title === 'Freight Collect'
        ) {
            param = 'city/city';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (title) {
                            case 'Vessel':
                                setDataN(response.data.data.vessel);
                                temp = response.data.data.vessel;
                                break;
                            case 'AirPort':
                                setDataN(response.data.data.airPort);
                                temp = response.data.data.airPort;
                                break;
                            case 'Place Of Receipt':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;
                            case 'Place Of Delivery':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;
                            case 'City':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;
                            case 'Freight Collect':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;
                            case 'Port':
                                setDataN(response.data.data.seaPort);
                                temp = response.data.data.seaPort;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    // console.error('error saat fetch', error);

                    countErrN++
                    if (countErrN < 3) {
                        getDataNonContact(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                        setIsModal2(false);
                    }
                })
        }
    };

    const getDataTracking = (pageNumber, pageSize, filters) => {
        setIsLoadingT(true);
        let param = '';
        let title = JSON.parse(localStorage.getItem("titleT"));

        if (title === 'AirPort') {
            param = 'airPort/airPort';
            let params = [{ 'attr': 'cityId', 'value': '' + props.cityId }];
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataT({})
                        setDataT(response.data.data.airPort);

                        let temp = response.data.data.airPort
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapT(indexed)
                        setColumnDataT(response.data.data.columns)
                        setTotalRowsT(response.data.totalRowCount)

                        setIsLoadingT(false)
                    }
                    setIsLoadingT(false)
                })
                .catch(function (error) {
                    setIsLoadingT(false);
                    // console.error('error saat fetch', error);

                    countErrT++
                    if (countErrT < 3) {
                        getDataTracking(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                        setIsModal3(false);
                    }
                })
        }

        else if (title === 'Port') {
            param = 'seaport/seaport';
            let params = [{ 'attr': 'cityId', 'value': '' + props.cityId }];
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataT({})
                        setDataT(response.data.data.seaPort);

                        let temp = response.data.data.seaPort
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapT(indexed)
                        setColumnDataT(response.data.data.columns)
                        setTotalRowsT(response.data.totalRowCount)

                        setIsLoadingT(false)
                    }
                    setIsLoadingT(false)
                })
                .catch(function (error) {
                    setIsLoadingT(false);
                    // console.error('error saat fetch', error);

                    countErrT++
                    if (countErrT < 3) {
                        getDataTracking(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                        setIsModal3(false);
                    }
                })
        }
    };

    const mainContainer = (
        <>
            {
                props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label="Container No"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.containerNo}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddContainer('delete')}
                                                hidden={props.containerNo ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton onClick={() => AddContainer('search')}>
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />

                            <Input
                                label="Seal No"
                                value={props.sealNo}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label="Size / Type"
                                value={props.contSizeType}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label="Quantity"
                                textAlign='right'
                                type={'number'}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            sm={12}
                            xs={24}
                        >
                            <Input
                                label="Con. Note"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.conNote}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddConNote('delete')}
                                                hidden={props.conNote ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton onClick={() => AddConNote('search')}>
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />
                        </Grid>
                    </Grid>
            }

            <Typography
                sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    marginTop: '24px'
                }}
            >
                Status
            </Typography>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={8}
                    lg={8}
                    sm={8}
                    xs={18}
                >
                    <div style={{ marginTop: '13px' }}>
                        {props.selectTrackingStatus(props.statusId, props.setStatusId, setStatusName)}
                    </div>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={6}
                >
                    <CustomDatePicker
                        type={'date'}
                        value={props.statusUpdateOn}
                        onChange={props.setStatusUpdateOn}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            sx: {
                                marginTop: '16px !important',
                            },
                        }}
                    />
                </Grid>
            </Grid>

            {
                props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                        <Input
                            label={props.jobType.portType === 'SEA' ? "Vessel" : 'Flight'}
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.jobType.portType === 'SEA' ? props.vessel : props.flight}
                            type='text'
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => AddVessel('delete')}
                                            hidden={props.jobType.portType === 'SEA' ? (props.vessel ? false : true) : (props.flight ? false : true)}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => AddVessel('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            disabled
                        />
                    </Stack>
                    :
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label="Receipt By"
                                value={props.receiptName}
                                onChange={(e) => props.setReceiptName(e.target.value)}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label={props.VesselFlight === 'V' ? "Vessel" : 'Flight'}
                                value={props.VesselFlight === 'V' ? props.vessel : props.flight}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
            }

            <div style={{ marginTop: '24px' }}>
                <Stack spacing={'32px'} direction="row" sx={{ marginTop: '16px' }} alignItems={'center'}>
                    <Typography
                        sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            marginTop: '16px !important'
                        }}
                    >
                        Location
                    </Typography>

                    <div style={{ width: '100%' }}>
                        <Stack spacing={'16px'} direction="row">
                            <Input
                                label="City"
                                value={props.cityCode}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px',
                                        width: '50%'
                                    }
                                }}
                            />

                            <Input
                                value={props.cityName}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px',
                                        width: '100%'
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.countryName}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddCity('delete')}
                                                hidden={props.countryName ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton onClick={() => AddCity('search')}>
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }} hidden={props.cityId !== 0 ? false : true}>
                            {
                                (props.jobType.portType === 'SEA' || props.jobType.portType === '') || props.VesselFlight === 'V' ?
                                    <>
                                        <Input
                                            label="AirPort / Port"
                                            value={props.portCode}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '24px',
                                                    width: '50%'
                                                }
                                            }}
                                        />

                                        <Input
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={props.portName}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                            inputBaseProps={{
                                                sx: {
                                                    marginTop: '23px !important',
                                                },
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => AddPort('delete')}
                                                            hidden={props.cityId !== 0 && props.portName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton onClick={() => AddPort('search')} hidden={props.cityId !== 0 ? false : true}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            disabled
                                        />
                                    </>
                                    :
                                    <>
                                        <Input
                                            label="AirPort / Port"
                                            value={props.airportCode}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '24px',
                                                    width: '50%'
                                                }
                                            }}
                                        />

                                        <Input
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={props.portName}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                            inputBaseProps={{
                                                sx: {
                                                    marginTop: '23px !important',
                                                },
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => AddAirport('delete')}
                                                            hidden={props.cityId !== 0 && props.airportName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton onClick={() => AddAirport('search')} hidden={props.cityId !== 0 ? false : true}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            disabled
                                        />
                                    </>
                            }
                        </Stack>
                    </div>
                </Stack>
            </div>

            <Input
                label="Description"
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '24px !important'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ? handleSaveSeaAir : handleSaveDomestic, false, false)}
                    </div>
                </div>
            </PopUp>

            {
                isModal2 && (
                    <SelectDataNonContact
                        jobType={props.jobType}
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        tipe={tipe}
                        setTitleModal={setTitleModal2}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setCityId={props.setCityId}
                        setCityCode={props.setCityCode}
                        setCityName={props.setCityName}
                        setCountryName={props.setCountryName}
                    />
                )
            }

            {
                isModal3 && (
                    <SelectDataTracking
                        jobType={props.jobType}
                        ShipmentId={props.ShipmentId}
                        cityId={props.cityId}
                        isModal={isModal3}
                        handleClick={handleClick3}
                        titleModal={titleModal3}
                        setIsModal={setIsModal3}
                        containerData={props.containerData}
                        vesselData={props.vesselData}
                        flightData={props.flightData}
                        ConNoteData={props.ConNoteData}

                        getData={getDataTracking}
                        isLoading={isLoadingT}
                        setIsLoading={setIsLoadingT}
                        data={dataT}
                        setData={setDataT}
                        dataMap={dataMapT}
                        setDataMap={setDataMapT}
                        columnData={columnDataT}
                        setColumnData={setColumnDataT}
                        SelectedData={SelectedDataT}
                        setSelectedData={setSelectedDataT}
                        totalRows={totalRowsT}
                        setTotalRows={setTotalRowsT}

                        setRoutingSequence={props.setRoutingSequence}
                        setVessel={props.setVessel}
                        setFlight={props.setFlight}
                        setSeaContainerSequence={props.setSeaContainerSequence}
                        setContainerNo={props.setContainerNo}
                        setSealNo={props.setSealNo}
                        setContSizeType={props.setContSizeType}
                        setConNoteSequence={props.setConNoteSequence}
                        setConNote={props.setConNote}
                        setPortId={props.setPortId}
                        setPortCode={props.setPortCode}
                        setPortName={props.setPortName}
                        setAirportId={props.setAirportId}
                        setAirportCode={props.setAirportCode}
                        setAirportName={props.setAirportName}
                    />
                )
            }
        </>
    )
}

export default ModalContainerInfo;