import { Grid, Typography } from '@mui/material';
import { useState } from 'react';

import TabsPaymentMethod from '../Tabs/TabsPaymentMethod';
import { Input } from '../../../../../components/ui';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput from '../../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../../components/Masks/RupiahInput';

const PaymentVoucherFor = (props) => {
    const [tabValue, setTabValue] = useState('Cash');

    return (
        <div>
            <form>
                <div>
                    <Typography
                        sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Payment Method
                    </Typography>

                    <div style={{ marginTop: '16px' }}>
                        <TabsPaymentMethod
                            tabValue={tabValue} setTabValue={setTabValue} isApprovedAcc={props.isApprovedAcc}
                            paymentVoucherId={props.paymentVoucherId} TempAmount={props.TempAmount} printing={props.printing}
                            PaymentBy={props.PaymentBy} Rate={props.Rate} IsPPh23={props.IsPPh23} isPosted={props.isPosted}
                            isCash={props.isCash} isBank={props.isBank} setIsInput={props.setIsInput}
                            DataPaymentDetail={props.DataPaymentDetail} setDataPaymentDetail={props.setDataPaymentDetail}
                            selectedPaymentVoucher={props.selectedPaymentVoucher} PPh23AmountReal={props.PPh23AmountReal}
                            BankData={props.BankData} setBankData={props.setBankData} BankId={props.BankId}
                            CashPayment={props.CashPayment} setCashPayment={props.setCashPayment}
                            TempIsPPh23={props.TempIsPPh23} setTempIsPPh23={props.setTempIsPPh23}
                            TempSeq={props.TempSeq} setTempSeq={props.setTempSeq} isAllPPh={props.isAllPPh}
                            AccountCode={props.AccountCode} AccountName={props.AccountName} SettlementTo={props.SettlementTo}
                            isAddValue={props.isAddValue} setIsAddValue={props.setIsAddValue}

                            calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                            dueDateLast={props.dueDateLast} setDueDateLast={props.setDueDateLast}
                        />
                    </div>

                    <div style={{ margin: '24px 0px' }}>
                        <hr
                            style={{
                                strokeWidth: '1.5px',
                                borderTop: '3px dashed #7F888C',
                                stroke: 'var(--Disable-Grey-Text-2, #7F888C)',
                            }}
                        />
                    </div>

                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Input
                                    label='Total Debit USD'
                                    value={props.TotalDebitUSD}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label='Total Credit USD'
                                    value={props.TotalCreditUSD}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Input
                                    label='Total Debit IDR'
                                    value={props.TotalDebitIDR}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label='Total Credit IDR'
                                    value={props.TotalCreditIDR}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Input
                                    label='Total Debit In IDR'
                                    value={props.TotalDebitInIDR}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label='Total Credit In IDR'
                                    value={props.TotalCreditInIDR}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label={props.LabelDifference}
                                    value={props.TotalDifference}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PaymentVoucherFor;