import React, { forwardRef, useImperativeHandle } from 'react';
import ButtonService from '../ButtonService';
import 'react-toastify/dist/ReactToastify.css';
import { companyId, formatCurrency, userCode, username } from '../../../services/ApiService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { CustomDatePicker, Input, showToast } from '../../../components/ui';
import DollarInput, { convertMask } from '../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../components/Masks/RupiahInput';
import { defaultDate } from '../../../utils/date';
import { Grid } from '@mui/material';

const CreateEditExchangeRate = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "exchangeRate": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    deletedOn: defaultDate,
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataExchangeRate", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const convertDate = (dt) => {
        if (!dt) return

        const newDate = new Date(dt)
        const formattedDate = newDate.toLocaleDateString("sv-se").split('T')[0]

        return formattedDate
    }

    const validationSchema = Yup.object().shape({
        exRate1: Yup.number().required('This field is required.'),
        exRate2: Yup.number().required('This field is required.'),
        exRate3: Yup.number().required('This field is required.'),
        exRateDate: Yup.date().required('This field is required.'),
        remarks: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            exRateDate: selectedData?.exRateDate ? convertDate(selectedData.exRateDate) : '',
        } : {
            code: 0,
            exRate1: '',
            exRate2: '',
            exRate3: '',
            exRateDate: '',
            remarks: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.exRate1 === '') {
            return showToast({ type: 'error', message: 'High Rate is Required!' });
        }

        if (payload?.exRate2 === '') {
            return showToast({ type: 'error', message: 'Medium Rate is Required!' });
        }

        if (payload?.exRate3 === '') {
            return showToast({ type: 'error', message: 'Low Rate is Required!' });
        }

        if (payload?.exRateDate === '') {
            return showToast({ type: 'error', message: 'Entry Date is Required!' });
        }

        const data =
        {
            "exchangeRate": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "exRateDate": payload?.exRateDate,
                "exRate1": payload?.exRate1,
                "exRate2": payload?.exRate2,
                "exRate3": payload?.exRate3,
                "deleted": false,
                "companyId": companyId,
                "idLama": payload?.idLama || 0,
                "remarks": payload?.remarks,
                "deletedBy": "",
                "deletedOn": defaultDate,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Grid container item direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} rowSpacing={'24px'} columnSpacing={'16px'}>
                <Grid item xs={6} display={'flex'}>
                    <CustomDatePicker
                        type={'date'}
                        name="exRateDate"
                        label="Entry Date"
                        value={formik.values.exRateDate}
                        onBlur={formik.handleBlur}
                        error={formik.touched.exRateDate && Boolean(formik.errors.exRateDate)}
                        helperText={formik.touched.exRateDate && formik.errors.exRateDate}
                        onChange={(e) => formik.setFieldValue('exRateDate', e)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6} display={'flex'} />
            </Grid>

            <Grid container item direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} rowSpacing={'24px'} columnSpacing={'16px'}>
                <Grid item xs={4} display={'flex'}>
                    <Input
                        textAlign='right'
                        name="exRate1"
                        label="High Rate"
                        onBlur={formik.handleBlur}
                        value={formik.values.exRate1}
                        onChange={(e) => formik.setFieldValue('exRate1', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.exRate1 && formik.errors.exRate1}
                        error={formik.touched.exRate1 && Boolean(formik.errors.exRate1)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid item xs={4} display={'flex'}>
                    <Input
                        textAlign='right'
                        name="exRate2"
                        label="Medium Rate"
                        onBlur={formik.handleBlur}
                        value={formik.values.exRate2}
                        onChange={(e) => formik.setFieldValue('exRate2', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.exRate2 && formik.errors.exRate2}
                        error={formik.touched.exRate2 && Boolean(formik.errors.exRate2)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid item xs={4} display={'flex'}>
                    <Input
                        textAlign='right'
                        name="exRate3"
                        label="Low Rate"
                        onBlur={formik.handleBlur}
                        value={formik.values.exRate3}
                        onChange={(e) => formik.setFieldValue('exRate3', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.exRate3 && formik.errors.exRate3}
                        error={formik.touched.exRate3 && Boolean(formik.errors.exRate3)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                id='remarks'
                name="remarks"
                label="Remarks"
                value={formik.values.remarks}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                onChange={(e) => formik.setFieldValue('remarks', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                        code={formik.values.code}
                    />
                )
            }
        </>
    )
})

export default CreateEditExchangeRate