import React, { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { Grid, Box, InputAdornment, IconButton, } from "@mui/material";

import ApiService from "../../../../services/ApiService";
import LoadingSpinner from "../../../../components/Components/LoadingSpinner";
import ModalConfirmation from "../../../../components/Modals/ModalConfirmation";
import ButtonReportInBound from "./Buttons/ButtonReportInBound";
import SelectDataMaster from "../../../Master/MasterWarehouse/SelectDataMaster";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import TableX from "../../../../components/Tables/TableX";
import CustomToastContainer, { CustomDatePicker, Icon, Input, Paginate, showToast } from "../../../../components/ui";
import freezeContainer from "../../../../Styles/freezeContainer";
import HeaderInfo from "../../../../components/Components/HeaderInfo";
import { setDateFormat } from "../../../../utils/date";

const ReportInBound = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramProduct = "warehouse/product/product";
    const paramReport = "reports/TransactionInbound/TransactionInbound";

    let selectParam, selectKey;
    const [selectName, setSelectName] = useState("");

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [titleModal, setTitleModal] = useState("");
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(50);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState("");
    const [titleConfirmation, setTitleConfirmation] = useState("");

    const [dtFrom, setDtFrom] = useState("");
    const [dtTo, setDtTo] = useState("");
    const [productCategoryId, setProductCategoryId] = useState("");
    const [productCategoryName, setProductCategoryName] = useState("");
    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Report In Bound', link: '/Majura/ReportInBound', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() { },
    }));

    useEffect(() => {
        document.title = "Report In Bound - " + JSON.parse(localStorage.getItem("branchName"));
        setIsLoadingBackDrop(false);
        getDateNow();
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDtFrom);
        setDateFormat(new Date(), setDtTo);
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'RWHTI')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            getDateNow();
                        }
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history("/Majura");
                });
        } else {
            history("/Majura");
        }
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        } else {
            setIsModalConfirmation(false);
        }
    };

    function ConfirmationDRB() {
        if (titleConfirmation === "Print PDF") {
            PrintPDF();
        } else if (titleConfirmation === "Print Excel") {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' })
        emptyState();
        getData(1, 50, []);
    }

    const PrintData = () => {
        setTitleConfirmation("Print PDF");
        setTitleModalConfirmation("Report InBound");
        handleClickConfirmation();
    };

    const PrintExcel = () => {
        setTitleConfirmation("Print Excel");
        setTitleModalConfirmation("Report InBound");
        handleClickConfirmation();
    };

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        ApiService.DynamicPostByPageWarehouse(paramReport, 'PAGE', pageNumber, pageSize, getParams(), filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});

                    let temp = response.data.data.transactionInbound;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index,
                        };
                        return indexedTemp;
                    });

                    setDataMap(indexed);
                    setColumnData(response.data.data.columns);
                    setTotalRows(response.data.totalRowCount);

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    setIsLoadingBackDrop(false);
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
                console.error("error saat fetch", error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });
            });
    };

    function PrintPDF() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouse(paramReport, "PDF", [], getParams())
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Buat URL objek untuk blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Buat tautan unduhan
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = 'Report_InBound.pdf';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();

                // Hapus elemen tautan setelah pengunduhan selesai
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);

                setIsLoadingBackDrop(false);
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Print PDF, Please Contact The Administrator, ' + error.toString() });
            });
    }

    function PrintExl() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouse(paramReport, "Excel", [], getParams())
            .then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Buat URL objek untuk blob
                    const blobUrl = window.URL.createObjectURL(blob);

                    // Buat tautan unduhan
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = 'Report_InBound.xlsx';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();

                    // Hapus elemen tautan setelah pengunduhan selesai
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);

                    setIsLoadingBackDrop(false);
                } else {
                    setIsLoadingBackDrop(false);
                    throw new Error("Failed, " + response.status);
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Print Excel, Please Contact The Administrator, ' + error.toString() });
            });
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataSelect(1, 5);
        } else {
            setIsModal(false);
        }
    };

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({});
        setSortArr([]);
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    const getDataSelect = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(selectParam, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({});
                    setDataModal(response.data.data[selectKey]);
                    let temp = response.data.data[selectKey];
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index,
                        };
                        return indexedTemp;
                    });

                    setDataMapModal(indexed);
                    setColumnDataModal(response.data.data.columns);
                    setTotalRowsModal(response.data.totalRowCount);

                    setIsLoadingModal(false);
                }
                setIsLoadingModal(false);
            })
            .catch(function (error) {
                setIsLoadingModal(false);
                console.error("error saat fetch", error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });
            });
    };

    const selectHandler = (selectedData, name) => {
        if (name === "product") {
            setProductId(selectedData.id);
            setProductName(selectedData.name);
        } else {
            if (selectedData.id !== props.productCategoryId) {
                setProductId("");
                setProductName("");
            }
            setProductCategoryId(selectedData.id);
            setProductCategoryName(selectedData.name);
        }
    };

    const searchProductCategory = () => {
        setTitleModal("Product Category");
        selectParam = paramProductCategory;
        selectKey = "productCategory";
        setSelectName("productCategory");
        handleClick();
    };

    const searchProduct = () => {
        setTitleModal("Product");
        selectParam = paramProduct;
        selectKey = "product";
        setSelectName("product");
        handleClick();
    };

    const CellDoubleClick = () => { };

    const getParams = () => {
        let params = [
            {
                "attr": "StartDate",
                "value": (new Date(dtFrom)).toLocaleDateString("sv-se").split('T')[0],
                "mathSign": ">="
            },
            {
                "attr": "EndDate",
                "value": (new Date(dtTo)).toLocaleDateString("sv-se").split('T')[0],
                "mathSign": "<="
            }
        ];
        if (productCategoryId !== "") {
            params = [
                ...params,
                {
                    "attr": "productCategoryId",
                    "value": productCategoryId.toString(),
                    "mathSign": "="
                }
            ]
        }
        if (productId !== "") {
            params = [
                ...params,
                {
                    "attr": "productId",
                    "value": productId.toString(),
                    "mathSign": "="
                }
            ]
        }

        return params;
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPagination = filter.length > 0 ? 235 : 150
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <>
                <ButtonReportInBound
                    Reload={ReloadData}
                    PrintData={PrintData}
                    PrintExcel={PrintExcel}
                    DataRegUserAccess={DataRegUserAccess}
                />

                <Grid
                    container
                    columnSpacing={{ xs: 0, md: 3 }}
                    rowSpacing={{ xs: 2, md: 0 }}
                >
                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <CustomDatePicker
                            fullWidth
                            type='date'
                            value={dtFrom}
                            label="Period"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setDtFrom(e); }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <CustomDatePicker
                            fullWidth
                            label="To"
                            type='date'
                            value={dtTo}
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setDtTo(e); }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <Input
                            fullWidth
                            variant="standard"
                            label="Product Category"
                            value={productCategoryName}
                            InputLabelProps={{ shrink: true }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            hidden={productCategoryId ? false : true}
                                            onClick={() => {
                                                setProductCategoryId("");
                                                setProductCategoryName("");
                                            }}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchProductCategory()}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <Input
                            fullWidth
                            label="Product"
                            variant="standard"
                            value={productName}
                            InputLabelProps={{ shrink: true }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            hidden={productId ? false : true}
                                            onClick={() => {
                                                setProductId("");
                                                setProductName("");
                                            }}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchProduct()}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3, ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Report In Bound'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1' id="data_result">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataSelect}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}
                        selectHandler={selectHandler}
                        selectName={selectName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRB}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    );
});

export default ReportInBound;
