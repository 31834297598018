import { IconButton, Stack, Grid } from '@mui/material';
import React from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';

const TabShipper = (props) => {
    const searchShipper = (data) => {
        if (data === 'search') {
            if (props.principleBy !== 0) {
                props.setTipe(2);
                props.setContactTypeId(14);
                props.setTitleModal('Shipper');
                props.handleClick(2);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By!' });
            }
        }
        else if (data === 'delete') {
            props.setShipperId(0);
            props.setShipperName('');
            props.setShipperAddress('');

            if (props.billToShipperId === 0) {
                if (props.jobType.serviceType !== 'Import') {
                    props.setCreditTermUSD(0);
                    props.setCreditTermDays(0);
                    props.setCreditTermIDR(0);

                    props.setOutstandingInIDR(0);
                    props.setOutstandingInUSD(0);
                }
            }
        }
        else if (data === 'reset') {
            props.setShipperId(props.RefShipperId);
            props.setShipperName(props.RefShipperName);
            props.setShipperAddress(props.RefShipperAddress);
        }
    };

    const searchCompany = (data) => {
        if (data === 'search') {
            props.setTitleModal2('Company');
            props.handleClick2('Company');
        }
        else if (data === 'delete') {
            props.setSICompanyId(0);
            props.setSICompanyName('');
            props.setSICompanyAddress('');
        }
    }

    return (
        <>
            {
                props.contactType !== 'ShippingInstruction' ?
                    <>
                        {
                            props.contactType === 'MainShipmentOrder' && props.jobType.serviceType === 'Import' ?
                                <>
                                    <Stack spacing={'16px'} direction="column">
                                        <Input
                                            label="Name"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={props.ShipperName}
                                            onChange={(e) => props.setShipperName(e.target.value)}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />

                                        <Input
                                            label="Address"
                                            value={props.ShipperAddress}
                                            onChange={(e) => props.setShipperAddress(e.target.value)}
                                            multiline
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                            inputBaseProps={{
                                                rows: 4,
                                                sx: {
                                                    padding: '8px',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </>
                                :
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={1.5}
                                        lg={1.5}
                                        sm={1.5}
                                        xs={12}
                                    >
                                        <Input
                                            label="Code"
                                            value={props.ShipperId}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={5}
                                        lg={5}
                                        sm={5}
                                        xs={12}
                                    >
                                        <Input
                                            label="Name"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={props.ShipperName}
                                            onChange={(e) => props.setShipperName(e.target.value)}
                                            disabled={props.contactType === 'MainShipmentOrder' ? true : false}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            inputBaseProps={{
                                                readOnly: props.contactType === 'MainShipmentOrder' ? true : false,
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={5.5}
                                        lg={5.5}
                                        sm={5.5}
                                        xs={12}
                                    >
                                        <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                                            <Input
                                                label="Address"
                                                value={props.ShipperAddress}
                                                onChange={(e) => props.setShipperAddress(e.target.value)}
                                                disabled={props.contactType === 'MainShipmentOrder' ? true : false}
                                                multiline
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    readOnly: props.contactType === 'MainShipmentOrder' ? true : false,
                                                    rows: 4,
                                                    sx: {
                                                        padding: '8px',
                                                    },
                                                }}
                                            />

                                            {
                                                props.contactType === 'MainShipmentOrder' ?
                                                    <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                                        <IconButton
                                                            onClick={() => searchShipper('search')}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton onClick={() => searchShipper('delete')}
                                                            hidden={props.ShipperAddress ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                    :
                                                    <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                                        <IconButton onClick={() => searchShipper('reset')}>
                                                            <Icon
                                                                iconName={'refresh'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                        }
                    </>
                    :
                    <Grid
                        container
                        spacing={'16px'}
                    >
                        <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            sm={1.5}
                            xs={12}
                        >
                            <Input
                                label="Code"
                                value={props.SICompanyId}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '16px'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={5}
                            lg={5}
                            sm={5}
                            xs={12}
                        >
                            <Input
                                label="Name"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.SICompanyName}
                                disabled
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={5.5}
                            lg={5.5}
                            sm={5.5}
                            xs={12}
                        >
                            <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                                <Input
                                    label="Address"
                                    value={props.SICompanyAddress}
                                    onChange={(e) => props.setSICompanyAddress(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 4,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />

                                <IconButton onClick={() => searchCompany('search')} sx={{ marginTop: '36px !important' }} hidden={props.isJobClose}>
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '16px',
                                                width: '16px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
            }
        </>
    )
}

export default TabShipper;