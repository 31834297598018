import React from 'react';
import Grid from '@mui/material/Grid';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

const ButtonCheckPaymentRequest = ({
    isApprove,
    isApproveGen,
    isUnApprove,
    DataRegUserAccess,
    ReloadData,
    ApproveAccManager,
    ApproveGenPR,
    UnApproveAccManager
}) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {data.accessCode === 'APP' && ButtonComponent.ApproveButton(ApproveAccManager, 'Approve By Acc Mgr')}
                            {data.accessCode === 'APG' && ButtonComponent.ApproveButton(ApproveGenPR, 'Approve General PR')}
                            {data.accessCode === 'UPP' && ButtonComponent.UnApprovalButton(UnApproveAccManager, 'Open Approval By Acc Mgr')}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonCheckPaymentRequest;