import { Typography, Stack } from '@mui/material';
import { CustomDatePicker, Icon, Input, Selection } from '../ui';
import React, { useCallback, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { defaultDate } from '../../utils/date';
import { isInfossNoList } from '../../services/ApiService';

const TableX = (props) => {
    const [filterJson, setFilterJson] = useState({})
    const [filterArr, setFilterArr] = useState([])

    const filterTable = (key, val, type = 'Standard') => {
        if (type !== 'Stop') {
            let filter = filterJson
            let temp = {
                field: key,
                data: val?.trim()
            }
            let arr = []
            if (!filter[key]) {
                filter[key] = temp
                setFilterJson(filter)
            } else {
                filter[key].data = val
                setFilterJson(filter)
            }

            if (filter[key].data.length === 0) {
                delete filter[key]
                delete props.stateX[key]
                setFilterJson(filter)
            } else {
                props.setStateX({ ...props.stateX, [key]: val })
            }

            for (const key in filter) {
                arr.push(filter[key])
            }

            setFilterArr(arr)

            if (type === 'Immediately' || type === 'Standard') {
                props.setNumPage(1)
                props.setGoTo(1)
                let arr = []
                let arrFilter = []
                let filter = { ...props.stateX, [key]: val }
                for (const [key, value] of Object.entries(filter)) {
                    let realValue = value;
                    const valueMapping = {
                        'true': 'Yes',
                        'false': 'No',
                        'ACT': 'Active',
                        'DEL': 'Deleted'
                    };

                    realValue = valueMapping[realValue] || realValue;

                    const realCol = props.columnData.find(el => el.column === key);

                    if (realCol) {
                        arr.push(`${realCol.text}: ${realValue}`);
                    }

                    arrFilter = [
                        ...arrFilter,
                        {
                            field: key,
                            data: value?.trim() || ''
                        }
                    ];
                }
                props.setFilterBy(arr)
                props.setFilter(arrFilter)
                if (props.id !== undefined) {
                    props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
                }
                else {
                    props.getData(1, props.rowsCount, arrFilter, props.sortArr)
                }
            }
        }
    }

    const handleDoubleClick = useCallback((el) => {
        props.CellDoubleClick(el);
    }, [props]);

    const handleClick = useCallback((el) => {
        if (props?.static) return;

        if (el) {
            props.setSelectedData(el);
        }
        if (props.handleGetData !== undefined) {
            props.handleGetData(el);
        }
    }, [props]);

    const handleKeyDown = (e) => {
        if (props?.preventKeyCapture) return;
        if (props?.static && e.keyCode !== 13) return;

        const keycode = e.keyCode;
        const valid = [38, 40, 13];

        if (!props.isModal && valid.includes(keycode)) {
            let currIndex = 0;

            if (e.keyCode === 38 && props.SelectedData.index > 1) {
                currIndex = props.SelectedData.index;
                currIndex -= 1;
            } else if (e.keyCode === 40 && props.SelectedData.index < props.dataMap.length - 1) {
                currIndex = props.SelectedData.index;
                currIndex += 1;
            } else if (e.keyCode === 13 && filterArr.length >= 0) {
                if (filterArr.length > 0) {
                    FilterData();
                }
            }

            if (![13].includes(e.keyCode)) {
                const copyArr = [...props.dataMap];
                const changeSelectedData = (data) => {
                    return data.index === currIndex;
                };
                const result = copyArr.filter(changeSelectedData);

                if (result.length > 0 && props.SelectedData) {
                    props.setSelectedData(...result);
                    handleClick(...result);
                }
            }
        }
    }

    const FilterData = () => {
        const { setNumPage, setRowsCount, setGoTo, stateX, columnData, setFilterBy, setFilter, getData, rowsCount, sortArr, id } = props;

        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);

        const filter = stateX;
        const arr = [];
        const arrFilter = [];

        const valueMapping = {
            'true': 'Yes',
            'false': 'No',
            'ACT': 'Active',
            'DEL': 'Deleted'
        };

        for (const [key, value] of Object.entries(filter)) {
            const realValue = valueMapping[value] || value;
            const realCol = columnData.find(el => el.column === key);

            if (realCol) {
                arr.push(`${realCol.text}: ${realValue}`);
            }

            arrFilter.push({
                field: key,
                data: value?.trim() || ''
            });
        }

        setFilterBy(arr);
        setFilter(arrFilter);
        if (id !== undefined) {
            getData(1, rowsCount, arrFilter, id, sortArr)
        }
        else {
            getData(1, rowsCount, arrFilter, sortArr)
        }
    };

    const SortData = (key) => {
        if (props.sortArr) {
            let temp = {
                attr: key,
                method: 'asc'
            }
            var arr = []
            var cekArr = props.sortArr
            cekArr = cekArr.filter(el => el.attr === key)

            if (cekArr.length > 0) {
                arr = props.sortArr
                arr.forEach(el => {
                    if (el.attr === key) {
                        if (el.method === 'asc') {
                            el.method = 'desc'
                        } else {
                            arr = arr.filter(el => el.attr !== key)
                        }
                    }
                });
            } else {
                arr = [...props.sortArr, temp]
            }
            props.setSortArr(arr)

            let arrFilter = []
            let filter = props.stateX
            for (const [key, value] of Object.entries(filter)) {
                arrFilter = [...arrFilter, {
                    field: key,
                    data: value?.trim()
                }]
            }
            props.getData(1, props.rowsCount, arrFilter, arr)
            props.setNumPage(1)
            props.setGoTo(1)
            // CekSortStatus(key)
        }
    }

    const CekSortStatus = (key) => {
        if (props.sortArr) {
            const arr = props.sortArr.filter(el => el.attr === key)
            if (arr.length > 0) {
                if (arr[0].method === 'asc') {
                    return (
                        <Stack direction={'column'} spacing={'0px'}>
                            <Icon
                                iconName={'arrow-up'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#0096D8'
                                    }
                                }}
                            />

                            <Icon
                                iconName={'arrow-down'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#D8D8D8'
                                    }
                                }}
                            />
                        </Stack>
                    )
                }
                else {
                    return (
                        <Stack direction={'column'} spacing={'0px'}>
                            <Icon
                                iconName={'arrow-up'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#D8D8D8'
                                    }
                                }}
                            />

                            <Icon
                                iconName={'arrow-down'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#0096D8'
                                    }
                                }}
                            />
                        </Stack>
                    )
                }
            }
            else {
                return (
                    <Stack direction={'column'} spacing={'0px'}>
                        <Icon
                            iconName={'arrow-up'}
                            svgIconProps={{
                                sx: {
                                    width: '8px',
                                    height: '8px',
                                    color: '#D8D8D8'
                                }
                            }}
                        />

                        <Icon
                            iconName={'arrow-down'}
                            svgIconProps={{
                                sx: {
                                    width: '8px',
                                    height: '8px',
                                    color: '#D8D8D8'
                                }
                            }}
                        />
                    </Stack>
                )
            }
        }
    }

    const TableHeaderRow = React.memo(({ columnData, SortData, CekSortStatus }) => {
        const handleSortClick = useCallback((column) => {
            SortData(column);
        }, [SortData]);

        return (
            <tr>
                {
                    columnData.map((el, index) => (
                        <th
                            key={index}
                            hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                            style={{
                                textAlign: el.textAlign,
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal',
                                maxWidth: 'fit-content',
                                cursor: 'pointer',
                                padding: '2px 8px',
                                background: '#F2F2F2',
                                fontSize: '12px !important'
                            }}
                        >
                            <label
                                onClick={() => handleSortClick(el.column)}
                                style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    fontSize: '12px !important',
                                    maxWidth: 'fit-content'
                                }}
                            >
                                <Stack
                                    direction='row'
                                    spacing='6px'
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        maxWidth: 'fit-content'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            fontSize: '11px !important',
                                            fontWeight: '700',
                                            lineHeight: '16px',
                                            textAlign: 'left',
                                            color: '#083A50',
                                            maxWidth: 'fit-content',
                                        }}
                                    >
                                        {el.text}
                                    </Typography>

                                    {CekSortStatus(el.column)}
                                </Stack>
                            </label>
                        </th>
                    ))
                }
            </tr>
        );
    });

    const formatValue = (value, dataType) => {
        if (dataType === 'datetime' || dataType === 'date') {
            if (value !== "0001-01-01T00:00:00" && new Date(value).getFullYear() !== new Date(defaultDate).getFullYear()) {
                const tempDate = new Date(value);
                return tempDate.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
            } else {
                return '-';
            }
        } else if (dataType === 'currency') {
            return new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            }).format(`${value}`);
        } else if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        } else {
            return value;
        }
    };

    const TableBodyRow = React.memo(({ el, index, handleClick, handleDoubleClick, SelectedData, uniqueKey, columnData, handleFailedGL }) => {
        const [isHovered, setIsHovered] = useState(false);
        const tempStyle = useMemo(() => {
            if (SelectedData && el && SelectedData[uniqueKey] === el[uniqueKey]) {
                return "bg-infoss text-white";
            } else if (el.rowStatus === 'DEL') {
                return "text-danger";
            } else if (el.printing > 0) {
                return "text-secondary";
            } else {
                return "text-dark";
            }
        }, [SelectedData, el, uniqueKey]);

        return (
            <tr
                key={index}
                onClick={() => handleClick(el)}
                className={tempStyle}
                onDoubleClick={() => handleDoubleClick(el)}
                style={{ fontSize: '14px', background: '#F2F2F2', padding: '24px 8px !important', alignItems: 'center', height: '27.09px' }}
            >
                {
                    columnData.map((headersEl, indexHeaders) => {
                        const temp = formatValue(el[headersEl.column], headersEl.dataType);

                        return (
                            <td
                                key={indexHeaders}
                                hidden={headersEl.column === 'noInfoss' && !isInfossNoList ? true : headersEl.hidden}
                                align={headersEl.column === 'glStatusName' ? 'center' : headersEl.textAlign}
                                style={{
                                    verticalAlign: 'middle',
                                    padding: '0px 10px',
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {
                                    headersEl.column === 'rowStatus' ?
                                        (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: 'fit-content',
                                                    padding: '4px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '32px',
                                                    background: temp?.trim() === 'ACT' ? 'var(--Green-Button, #168444)' : 'var(--Red-Alert, #D20C0C)',
                                                }}
                                            >
                                                <Icon
                                                    iconName={temp?.trim() === 'ACT' ? 'active' : 'inactive'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#EAEAEA',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            headersEl.column === 'documentStatus' && temp !== '' && temp !== undefined ?
                                                (
                                                    <div
                                                        style={{
                                                            width: '75px',
                                                            padding: '4px 12px 4px',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '32px',
                                                            border: temp === 'Saved' ? '1.5px solid var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '1.5px solid #585858' : '1.5px solid #007330',
                                                            background: temp === 'Saved' ? 'rgba(254, 165, 30, 0.15)' : temp === 'Draft' ? 'rgba(88, 88, 88, 0.15)' : 'rgba(0, 115, 48, 0.15)',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: temp === 'Saved' ? 'var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '#585858' : '#007330',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '10px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '600',
                                                                lineHeight: '12px !important',
                                                            }}
                                                        >
                                                            {temp === 'Saved' ? 'Submitted' : temp}
                                                        </Typography>
                                                    </div>
                                                )
                                                :
                                                headersEl.column === 'glStatusName' ?
                                                    (
                                                        <div
                                                            style={{
                                                                gap: '10px',
                                                                height: '18px',
                                                                padding: '10px',
                                                                display: 'flex',
                                                                borderRadius: '4px',
                                                                alignItems: 'center',
                                                                width: 'fit-content',
                                                                justifyContent: 'center',
                                                                background: temp === 'Failed' ? 'var(--Mjr-blue-4, #BE2957)' : 'transparent',
                                                                boxShadow: isHovered && temp === 'Failed' ? '0px 4px 4px 0px rgba(0, 0, 0, 0.20)' : null,
                                                            }}
                                                            onMouseEnter={() => setIsHovered(true)}
                                                            onMouseLeave={() => setIsHovered(false)}
                                                            disabled={temp === 'Failed' ? false : true}
                                                            onClick={() => temp === 'Failed' ? handleFailedGL(el) : null}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: temp === 'Success' ? '#168444' : temp === 'Failed' ? '#FFFFFF' : '#F68C00',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: '11px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: temp === 'Failed' ? '700' : '400',
                                                                    lineHeight: 'normal',
                                                                    textDecorationLine: temp === 'Failed' ? 'underline' : 'none',
                                                                    cursor: temp === 'Failed' ? 'pointer' : 'default',
                                                                    userSelect: 'none',
                                                                }}
                                                            >
                                                                {temp}
                                                            </Typography>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '11px !important',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                maxWidth: 'fit-content'
                                                            }}
                                                        >
                                                            {temp}
                                                        </Typography>
                                                    )
                                        )
                                }
                            </td>
                        );
                    })
                }
            </tr >
        );
    });

    const memoizedColumnData = useMemo(() => props.columnData, [props.columnData]);

    function handleKeyDownInput(e, el) {
        if (e.key === "Enter") {
            filterTable(el.column, e.target.value, 'Standard');
        }
    }

    return (
        <Table
            hover={!props?.static}
            className='table table-sm'
            style={{ '--bs-table-bg': '#F2F2F2' }}
            onKeyDown={handleKeyDown}
        >
            <thead style={{ position: 'sticky', top: '-4px', 'color': '#F2F2F2', background: '#F2F2F2', zIndex: 1 }}>
                <TableHeaderRow columnData={props.columnData} SortData={SortData} CekSortStatus={CekSortStatus} />
                <tr>
                    {
                        props.columnData.map((el, index) => (
                            <td
                                key={index}
                                hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                                align={el.textAlign}
                                style={{
                                    background: '#F2F2F2',
                                    padding: '24px 8px !important',
                                    maxWidth: '150px !important',
                                }}
                            >
                                {
                                    el.text === 'Data Status' ?
                                        <Selection
                                            // value={props.stateX[el.column]}
                                            onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                            options={
                                                [
                                                    {
                                                        value: 'ACT',
                                                        label: 'Active'
                                                    },
                                                    {
                                                        value: 'DEL',
                                                        label: 'Deleted'
                                                    }
                                                ]
                                            }
                                            formControlProps={{
                                                sx: {
                                                    width: '100% !important',
                                                }
                                            }}
                                            menuItemProps={{
                                                sx: {
                                                    color: '#0087C2',
                                                    fontFamily: 'Nunito',
                                                    fontWeight: '700',
                                                    fontSize: '12px !important'
                                                }
                                            }}
                                            selectBaseProps={{
                                                sx: {
                                                    border: '1px solid #0087C2',
                                                    fontSize: '10px !important',
                                                    borderRadius: '10px',
                                                    height: '27px',
                                                    backgroundColor: 'transparent',
                                                }
                                            }}
                                            selectInnerStyle={{
                                                backgroundColor: 'transparent',
                                            }}
                                        />
                                        : el.dataType === 'boolean' || el.dataType === 'bit' ?
                                            <Selection
                                                // value={props.stateX[el.column]}
                                                onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                                options={[
                                                    {
                                                        value: 'true',
                                                        label: 'Yes'
                                                    },
                                                    {
                                                        value: 'false',
                                                        label: 'No'
                                                    }
                                                ]}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                    }
                                                }}
                                                menuItemProps={{
                                                    sx: {
                                                        color: '#0087C2',
                                                        fontFamily: 'Nunito',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important'
                                                    }
                                                }}
                                                selectBaseProps={{
                                                    sx: {
                                                        border: '1px solid #0087C2',
                                                        fontSize: '10px !important',
                                                        borderRadius: '10px',
                                                        height: '27px',
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}
                                                selectInnerStyle={{
                                                    backgroundColor: 'transparent',
                                                }}
                                            />
                                            : el.column === 'glStatusName' ?
                                                <Selection
                                                    // value={props.stateX[el.column]}
                                                    onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                                    options={[
                                                        {
                                                            value: 'Success',
                                                            label: 'Success'
                                                        },
                                                        {
                                                            value: 'Pending',
                                                            label: 'Pending'
                                                        },
                                                        {
                                                            value: 'Failed',
                                                            label: 'Failed'
                                                        }
                                                    ]}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100% !important',
                                                        }
                                                    }}
                                                    selectBaseProps={{
                                                        sx: {
                                                            // padding: '8px 8px !important', 
                                                            border: '1px solid #0087C2',
                                                            borderRadius: '10px',
                                                            fontSize: '10px !important',
                                                            height: '27px',
                                                            backgroundColor: 'transparent',
                                                        }
                                                    }}
                                                    menuItemProps={{
                                                        sx: {
                                                            color: '#0087C2',
                                                            fontFamily: 'Nunito',
                                                            fontWeight: '700',
                                                            fontSize: '12px !important'
                                                        }
                                                    }}
                                                    selectInnerStyle={{
                                                        backgroundColor: 'transparent',
                                                        // padding: '0px 32px 0px 0px !important',
                                                    }}
                                                />
                                                :
                                                el.dataType === 'datetime' ?
                                                    <CustomDatePicker
                                                        className="form-control col-search-form"
                                                        onChange={(e) => filterTable(el.column, e, 'Immediately')}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100% !important',
                                                                minWidth: '65px !important',
                                                                maxWidth: '100% !important',
                                                            }
                                                        }}
                                                        inputBaseProps={{
                                                            sx: {
                                                                width: '100% !important',
                                                                padding: '5px 10px !important',
                                                                border: '1px solid #0087C2',
                                                                fontSize: '10px !important',
                                                                borderRadius: '10px',
                                                                maxWidth: '100% !important',
                                                                minHeight: '27px !important',
                                                            },
                                                        }}
                                                        customDateProps={{
                                                            toolbarPlaceholder: ''
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        // value={props.stateX[el.column]}
                                                        className="form-control col-search-form"
                                                        onKeyDown={(e) => handleKeyDownInput(e, el)}
                                                        onChange={(e) => filterTable(el.column, e.target.value, 'Stop')}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100% !important',
                                                                minWidth: '65px !important',
                                                                maxWidth: '100% !important',
                                                            }
                                                        }}
                                                        type={'text'}
                                                        inputBaseProps={{
                                                            sx: {
                                                                placeHolder: '',
                                                                width: '100% !important',
                                                                padding: '5px 10px !important',
                                                                border: '1px solid #0087C2',
                                                                fontSize: '10px !important',
                                                                borderRadius: '10px',
                                                                maxWidth: '100% !important',
                                                                minHeight: '27px !important',
                                                            },
                                                        }}
                                                    />
                                }
                            </td>
                        ))
                    }
                </tr>
            </thead>

            <tbody>
                {
                    props.dataMap.length > 0 ?
                        props.dataMap.map((el, index) => (
                            <TableBodyRow
                                el={el}
                                key={index}
                                index={index}
                                handleClick={handleClick}
                                uniqueKey={props.uniqueKey}
                                columnData={memoizedColumnData}
                                SelectedData={props.SelectedData}
                                handleDoubleClick={handleDoubleClick}
                                handleFailedGL={props.handleFailedGL}
                            />
                        ))
                        :
                        <tr>
                            <td colSpan={20} className="text-center py-3 text-muted">
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        fontstyle: 'normal',
                                        lineHeight: 'normal',
                                        fontFamily: 'Nunito',
                                        color: 'var(--Primary-One, #083A50)',
                                    }}
                                >
                                    Data Empty
                                </Typography>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}

export default TableX