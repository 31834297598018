import { IconButton, InputAdornment, } from '@mui/material';
import React, { useState } from 'react';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataMaster from '../../../../Master/MasterWarehouse/SelectDataMaster';
import { CustomDatePicker, Icon, Input, PopUp, showToast } from '../../../../../components/ui';

const ModalAdjustmentDetail = (props) => {
    const today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    const paramProduct = "warehouse/product/product";
    const paramUnit = "warehouse/RegProductUnits/RegProductUnits";

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(5);

    const [titleModalUnit, setTitleModalUnit] = useState('');
    const [isModalUnit, setIsModalUnit] = useState(false);
    const [isLoadingModalUnit, setIsLoadingModalUnit] = useState(false);
    const [dataModalUnit, setDataModalUnit] = useState([]);
    const [dataMapModalUnit, setDataMapModalUnit] = useState([]);
    const [columnDataModalUnit, setColumnDataModalUnit] = useState([]);
    const [SelectedDataModalUnit, setSelectedDataModalUnit] = useState({});
    const [totalRowsModalUnit, setTotalRowsModalUnit] = useState(5);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataProduct(1, 5);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClickUnit = () => {
        if (isModalUnit === false) {
            setIsModalUnit(true);
            getDataUnit(1, 5);
        }
        else {
            setIsModalUnit(false);
        }
    };

    const getDataProduct = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        filters = [{
            "field": "productCategoryId",
            "data": props.productCategoryId.toString()
        }]
        ApiService.PostByPage(paramProduct, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data.product);

                    let temp = response.data.data.product
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataUnit = (pageNumber, pageSize, filters) => {
        setIsLoadingModalUnit(true);
        filters = [{
            "field": "productId",
            "data": props.productId.toString()
        }]
        ApiService.PostByPage(paramUnit, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalUnit({})
                    setDataModalUnit(response.data.data.productUnits);

                    let temp = response.data.data.productUnits
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalUnit(indexed)
                    setColumnDataModalUnit(response.data.data.columns)
                    setTotalRowsModalUnit(response.data.totalRowCount)

                    setIsLoadingModalUnit(false)
                }
                setIsLoadingModalUnit(false)
            })
            .catch(function (error) {
                setIsLoadingModalUnit(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const searchProduct = () => {
        setTitleModal("Product");
        handleClick();
    }

    const searchUnit = () => {
        if (!props.productName) {
            showToast({ type: 'error', message: 'Select Product!' });
            return
        }
        setTitleModalUnit("Unit");
        handleClickUnit();
    }

    const handleClose = () => {
        props.emptyState();
        props.handleClick();
    };

    const handleSave = () => {
        if (props.productId === '' || props.unitId === '' || props.qty === '' || props.qty === '0') {
            showToast({ type: 'error', message: 'Please Fill Form!' });
            return;
        }

        let Sequence = 1
        if (props.detailSequence > 0) {
            Sequence = props.detailSequence + 1;
        }

        if (!props.detailEdit) {
            let data = {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "sequence": Sequence,
                "id": "0",
                "productId": props.productId,
                "productName": props.productName,
                "productCode": props.productCode,
                "unitId": props.unitId,
                "unitName": props.unitName,
                "quantity": props.qty,
                "expiredDate": props.expiredDate,
                "batchNo": props.batchNo,
                "userId": userId,
                "userCode": userCode
            };
            let newArray = props.adjustmentDetailList;
            newArray = [...newArray, data];
            props.setAdjustmentDetailList(newArray);
        } else {
            Sequence = props.selectedDetail.sequence;
            let data = {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "sequence": Sequence,
                "id": "0",
                "productId": props.productId,
                "productName": props.productName,
                "productCode": props.productCode,
                "unitId": props.unitId,
                "unitName": props.unitName,
                "quantity": props.qty,
                "expiredDate": props.expiredDate,
                "batchNo": props.batchNo,
                "userId": userId,
                "userCode": userCode
            };

            const newArr = props.adjustmentDetailList.slice();
            newArr.forEach(el => {
                if (el.sequence === data.sequence) {
                    el.id = data.id;
                    el.productId = data.productId;
                    el.productName = data.productName;
                    el.productCode = data.productCode;
                    el.expiredDate = data.expiredDate;
                    el.batchNo = data.batchNo;
                    el.warehouseLocationName = data.warehouseLocationName;
                    el.warehouseLocationId = data.warehouseLocationId;
                    el.unitId = data.unitId;
                    el.unitName = data.unitName;
                    el.unitCode = data.unitCode;
                    el.quantity = data.quantity;
                }
            })
            props.setAdjustmentDetailList(newArr);
        }
        props.emptyState();
        handleClose();
        props.setSelectedDetail({});
        props.setDetailEdit(false);
    }

    const selectUnit = (selectedData) => {
        props.setUnitId(selectedData.unitId);
        props.setUnitName(selectedData.unitsName);
        props.setUnitCode(selectedData.unitsCode);
    }

    const selectProduct = (selectedData) => {
        props.setProductId(selectedData.id)
        props.setProductName(selectedData.name)
        props.setProductCode(selectedData.code)
    }

    const mainContainer = (
        <form onSubmit={handleSave}>
            <Input
                label="Product"
                value={props.productName}
                disabled={props.approved}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                props.productName && (
                                    <IconButton hidden={props.approved}
                                        onClick={
                                            () => {
                                                props.setProductId(0);
                                                props.setProductCode('');
                                                props.setProductName('');
                                                props.setBatchNo('');
                                                props.setExpiredDate('');
                                                props.setUnitId('');
                                                props.setUnitName('');
                                                props.setQty(0);
                                            }
                                        }
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                )
                            }

                            <IconButton onClick={() => searchProduct()} hidden={props.approved}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <CustomDatePicker
                label={'Expiry Date'}
                type={'date'}
                value={props.expiredDate}
                onChange={(e) => props.setExpiredDate(e)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                customDateProps={{
                    minDate: new Date(today),
                }}
                disabled={props.approved}
            />

            <Input
                label="Batch No"
                value={props.batchNo}
                onChange={(e) => props.setBatchNo(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled={props.approved}
            />

            <Input
                label="Unit of Measurement"
                value={props.unitName}
                disabled={props.approved}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                props.unitName && (
                                    <IconButton hidden={props.approved}
                                        onClick={
                                            () => {
                                                props.setUnitId(0);
                                                props.setUnitName('');
                                                props.setQty(0);
                                            }
                                        }
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                )
                            }

                            <IconButton onClick={() => searchUnit()} hidden={props.approved}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                label="Quantity"
                value={props.qty}
                onChange={(e) => props.setQty(e.target.value)}
                type={'number'}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled={props.approved}
            />
        </form>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.approved)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataProduct}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}

                        selectHandler={selectProduct}
                    />
                )
            }

            {
                isModalUnit && (
                    <SelectDataMaster
                        isModal={isModalUnit}
                        handleClick={handleClickUnit}
                        titleModal={titleModalUnit}
                        setIsModal={setIsModalUnit}

                        getData={getDataUnit}
                        isLoading={isLoadingModalUnit}
                        totalRows={totalRowsModalUnit}
                        setTotalRows={setTotalRowsModalUnit}
                        SelectedData={SelectedDataModalUnit}
                        setSelectedData={setSelectedDataModalUnit}
                        data={dataModalUnit}
                        columnData={columnDataModalUnit}
                        dataMap={dataMapModalUnit}
                        selectHandler={selectUnit}
                    />
                )
            }
        </>
    )
}

export default ModalAdjustmentDetail;