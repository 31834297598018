import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { memo } from "react";

const CustomRadioGroup = memo(({ title, isChecked, setIsChecked, isDisabled = false, isHidden = false, dataGroup = [], isGrid = false }) => {
    return (
        <Stack direction={'column'}>
            <Typography
                sx={{
                    color: 'var(--Primary-One, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '19.6px',
                    marginTop: '1px'
                }}
                hidden={title === '' ? true : false}
            >
                {title}
            </Typography>

            <RadioGroup
                row
                value={isChecked}
                hidden={isHidden}
                disabled={isDisabled}
                sx={{ columnGap: '8px', rowGap: '8px', marginTop: '-1px !important' }}
                onChange={(e) => setIsChecked(e.target.value)}
            >
                <Grid container direction={'row'} spacing={'8px'}>
                    {
                        dataGroup.map((data, index) => (
                            <Grid item xs={isGrid ? 4 : 0}>
                                <FormControl
                                    key={index}
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        height: '32px',
                                        padding: '10px 12px',
                                        marginTop: '6px',
                                        borderRadius: '12px',
                                        border: String(isChecked) === String(data.code) ? '1px solid var(--Mjr-green-2, #77EBFF)' : '1px solid var(--Mjr-white-1, #E9E9EA)',
                                        background: String(isChecked) === String(data.code) ? 'var(--Mjr-green-4, #EBFDFF)' : 'var(--Mjr-white-3, #FAFAFA)',
                                        justifyContent: 'center',
                                        alignItems: 'left',
                                    }}
                                    disabled={isDisabled}
                                >
                                    <FormControlLabel
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontSize: '12px !important',
                                            fontFamily: 'Nunito',
                                            fontStyle: 'normal',
                                            lineHeight: '20px',
                                            fontWeight: '400',
                                        }}
                                        control={
                                            <Radio size='small'
                                                onClick={(e) => {
                                                    setIsChecked(data.code);
                                                }}
                                            />
                                        }
                                        value={data.code}
                                        key={index}
                                        disabled={data.isDisabled}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontSize: '12px !important',
                                                    fontFamily: 'Nunito',
                                                    fontStyle: 'normal',
                                                    lineHeight: '20px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {data.name}
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
        </Stack>
    )
});

export default memo(CustomRadioGroup);