import { Grid, Box, Card, CardContent, MenuItem, Stack } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import ButtonLiftingRegionView from './Buttons/ButtonLiftingRegionView';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomRadioGroup, Input, SelectInput, Selection, showToast, TickBox } from '../../../../components/ui';
import { PrintPDF } from '../../../../utils/reusableFunction';

const LiftingRegionView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const [jobType, setJobType] = useState(1);
    const [isAllCompany, setIsAllCompany] = useState(false);
    const [ascendingYear, setAscendingYear] = useState(2);
    const [startPeriod, setStartPeriod] = useState(new Date().getFullYear() - (ascendingYear - 1));
    const [endPeriod, setEndPeriod] = useState(new Date().getFullYear());
    const [companyId, setCompanyId] = useState(JSON.parse(localStorage.getItem('branchId')) || 0);
    const [companyName, setCompanyName] = useState(JSON.parse(localStorage.getItem('branchName')) || '');

    const [dataJobType, setDataJobType] = useState([]);
    const dataForwarding = [
        { id: 11, code: 11, name: 'Sea Forwarding' },
        { id: 12, code: 12, name: 'Air Forwarding' },
    ]
    const DataAscending = [
        { id: 1, code: 2, name: '2 Years' },
    ]
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Lifting Shipment Performance By Region', link: '/Majura/LiftingRegionReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Lifting Shipment Performance By Region - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    let data = response.data.data.jobType;
                    let array = [];

                    data.forEach(element => {
                        let object = {
                            id: element.id,
                            code: element.id,
                            name: element.name,
                        }

                        array = [...array, object];
                    });

                    setDataJobType(array);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });
            })
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        GetDataJobType();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCM() {
        if (titleConfirmation === 'Print') {
            setIsLoadingBackDrop(true);
            let reportLink = 'Reports/Operation/Print/CargoManifestReport/CargoManifestReport/PrintCargoManifest?id=';
            PrintPDF(setIsLoadingBackDrop, 'Lifting Region', reportLink);
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Shipment Order!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Lifting Region');
            handleClickConfirmation();
        }
    };

    function handleChange() {
        if (isAllCompany === false) {
            setCompanyId(0);
            setCompanyName('');
            setIsAllCompany(true);
        }
        else {
            setCompanyId(JSON.parse(localStorage.getItem('branchId')));
            setCompanyName(JSON.parse(localStorage.getItem('branchName')));
            setIsAllCompany(false);
        }
    }

    function handleChangeEndPeriod(value) {
        setEndPeriod(value);
        setStartPeriod(value - (ascendingYear - 1));
    }

    const ButtonComponents = () => {
        return (
            <ButtonLiftingRegionView
                PrintData={PrintData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Lifting Shipment Performance By Region'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <SelectInput
                                            label={"Company"}
                                            value={companyName}
                                            // onClickSearch={(e) => searchCOA(e)}
                                            hiddenDelete={JSON.parse(localStorage.getItem('parentCompanyId')) !== 0 ? true : companyId === 0}
                                            isDisabled={JSON.parse(localStorage.getItem('parentCompanyId')) !== 0 ? true : isAllCompany}
                                        />

                                        <div style={{ marginTop: '12px' }}>
                                            <TickBox
                                                label={'All Companies'}
                                                checked={isAllCompany}
                                                onChange={() => handleChange()}
                                                disabled={JSON.parse(localStorage.getItem('parentCompanyId')) !== 0}
                                                size={'small'}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card component="div" style={{ borderRadius: '12px', marginTop: '24px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <CustomRadioGroup
                                            title={'Shipment Order'}
                                            isChecked={jobType}
                                            setIsChecked={setJobType}
                                            dataGroup={dataJobType}
                                            isGrid={true}
                                        />

                                        <hr />

                                        <CustomRadioGroup
                                            title={''}
                                            isChecked={jobType}
                                            setIsChecked={setJobType}
                                            dataGroup={dataForwarding}
                                            isGrid={true}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            fullWidth={true}
                                            value={ascendingYear}
                                            label={'Ascending Column (In Year):'}
                                            placeholder={'Select Ascending Year'}
                                            onChange={(e) => setAscendingYear(e.target.value)}
                                        >
                                            {
                                                DataAscending.map((data, index) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Stack direction={'row'} spacing={'24px'} sx={{ marginTop: '12px' }}>
                                            <Input
                                                label="Start Period"
                                                value={startPeriod}
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />

                                            <Input
                                                label="End Period"
                                                value={endPeriod}
                                                onChange={(e) => handleChangeEndPeriod(e.target.value)}
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCM}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default LiftingRegionView