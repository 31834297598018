import { Button, Card, CardContent, Grid, IconButton, Typography, Collapse, Box } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Icon, Paginate, showToast } from "../../../../components/ui";
import ProgressComponent from "./ProgressComponent";
import TableX from "../../../../components/Tables/TableX";
import ApiService, { branchId, companyId, countryId } from "../../../../services/ApiService";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";

const ProcessView = memo(({
    monthName,
    isDisabled = true,
    onProcessComplete,
    data,
    batchStatus = 'NST',
    handleProcessBackward,
    isDisabledBackward,
    setOpenAccess,
    isHidden
}) => {
    const [isInProgress, setIsInProgress] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressPosted, setProgressPosted] = useState(0);
    const [progressCM, setProgressCM] = useState(0);
    const [progressNM, setProgressNM] = useState(0);
    const [progressNY, setProgressNY] = useState(0);
    const [batchId, setBatchId] = useState(0);
    const [postingStatus, setPostingStatus] = useState('');

    const param = 'Help/restorePostingClosing/restorePostingClosing';
    const [rowsCount, setRowsCount] = useState(10)
    const [numPage, setNumPage] = useState(1)
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(10);
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([]);

    useEffect(() => {
        if (postingStatus === 'UCM') return;
        if (!isInProgress) return;

        const interval = setInterval(() => {
            checkProgress(batchId);
        }, 30000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchId, isInProgress]);

    useEffect(() => {
        if (batchStatus !== 'NST') {
            setBatchId(data.batchId);
            localStorage.setItem('batchId', data.batchId);
            getData(numPage, rowsCount, filter, sortArr);
            setPostingStatus(batchStatus);

            if (batchStatus === 'RUN') {
                setIsInProgress(true);
                checkProgress(data.batchId);
            }
            else if (batchStatus === 'SCS' || batchStatus === 'UCM') {
                setIsDone(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStartClick = () => {
        if (!isDisabled && !isInProgress) {
            setIsInProgress(true);
        }
    };

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        let params = [
            { 'attr': 'batchId', 'value': '' + localStorage.getItem('batchId') },
        ]
        ApiService.ListOperationDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((res) => {
                if (res.status === 200) {
                    setSelectedData({})
                    let temp = res.data.data.restorePostingClosing;

                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index,
                            id: index
                        }
                        return indexedTemp;
                    });

                    setDataMap(indexed)
                    setColumnData(res.data.data.columns)
                    setTotalRows(res.data.totalRowCount)
                }
                else {
                    console.log(res)
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + res.data.message });
                }
            })
            .catch((err) => {
                console.log(err)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + err });
            })
    }

    const StartRestore = (isRestart = false) => {
        let params = isRestart ? param + '/ReStart' : param + '/Start';
        let body = {
            "restorePostingClosing": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "batchGroupId": data.batchGroupId,
                "period": data.period,
                "yearPeriod": data.yearPeriod,
                "processedTransactions": ""
            }
        }
        ApiService.OperationCustomPost(params, body)
            .then((res) => {
                if (res.data.code === 200) {
                    if (isRestart) {
                        setIsDone(false);
                        handleProcessBackward();
                    }
                    setOpenAccess(true);
                    setBatchId(res.data.data.BatchId);
                    updateProgress(0, setProgressPosted, 100);
                    updateProgress(0, setProgressCM, 100);
                    updateProgress(0, setProgressNM, 100);
                    updateProgress(0, setProgressNY, 100);
                    updateProgress(0, setProgress, 100);
                    checkProgress(res.data.data.BatchId);
                    localStorage.setItem('batchId', res.data.data.BatchId);
                    handleStartClick();
                }
                else {
                    console.log(res)
                    showToast({ type: 'error', message: res.data.error });
                }
            })
            .catch((err) => {
                console.log(err)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + err });
            })
    }

    const checkProgress = (id) => {
        ApiService.OperationCustomReadId(param + '/CheckProgress?id=' + id)
            .then((res) => {
                if (res.data.code === 200) {
                    let data = res.data.data.restorePostingClosingProgress;
                    setPostingStatus(data.batchStatusCode);
                    if (data.batchStatusCode !== 'UCM') {
                        if (data.total !== 0) {
                            let dataTP = data.totalPosted;
                            let dataCM = data.totalPostToCurrMonth;
                            let dataNM = data.totalPostToNextMonth;
                            let dataNY = data.totalPostToNextYear;

                            updateProgress(dataTP, setProgressPosted, data.total);
                            updateProgress(dataCM, setProgressCM, data.total);
                            updateProgress(dataNM, setProgressNM, data.total);
                            updateProgress(dataNY, setProgressNY, data.total);
                            updateProgress((dataTP + dataCM + dataNM + dataNY), setProgress, (data.total * 4));

                            if (dataTP + dataCM + dataNM + dataNY === data.total * 4) {
                                setIsInProgress(false);
                                setIsDone(true);
                                onProcessComplete();
                            }
                        }
                        getData(numPage, rowsCount, filter, sortArr);
                    }
                    else {
                        setIsInProgress(false);
                        setIsDone(true);
                        showToast({ type: 'error', message: 'There is Error on Posting Process, Please Try Again!' });
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + err });
            })
    }

    const updateProgress = (processedData, setProgress, totalData) => {
        const newProgress = Math.min((processedData / totalData) * 100, 100);
        setProgress(newProgress);
    };

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    const handleReset = () => {
        emptyState();
        getData(0, 50, []);
    };

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const PostingProgress = ({ text, progress }) => {
        return (
            <div style={{ marginTop: '12px' }}>
                <Grid container spacing={0} direction="row" sx={{ alignItems: 'center', paddingBottom: '0px !important' }}>
                    <Grid item xs={1.5} justifyContent={'start'}>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '12px',
                                color: '#001A4F',
                                userSelect: 'none',
                            }}
                        >
                            {text}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={10.5}
                        className="text-start"
                        sx={{ alignItems: 'center' }}
                    >
                        <ProgressComponent progress={progress} />
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <Box className='w-100'>
            <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                <CardContent sx={{ paddingBottom: '14px !important' }}>
                    <Grid container direction="row" sx={{ alignItems: 'center' }}>
                        <Grid item xs={1.5} sx={{ alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '12px',
                                    color: '#001A4F',
                                    userSelect: 'none',
                                }}
                            >
                                {monthName}
                            </Typography>
                        </Grid>

                        <Grid item xs={10} sx={{ alignItems: 'center' }}>
                            <Grid container item direction="row" sx={{ alignItems: 'center' }}>
                                <Grid item xs={1.5} display={'flex'} justifyContent={'center'}>
                                    <Button
                                        size="small"
                                        variant={isDone || isInProgress ? 'text' : "outlined"}
                                        className="d-flex align-items-center"
                                        sx={{
                                            marginTop: '-2px',
                                            padding: '4px 12px !important',
                                            gap: '8px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '28px !important',
                                            minHeight: '28px !important',
                                            borderRadius: '8px',
                                            background: postingStatus === 'UCM' || isDone || isInProgress ? 'transparent' : isDisabled ? '#EAEAEA' : '#0087C2',
                                            '&:hover': {
                                                backgroundColor: postingStatus === 'UCM' || isDone || isInProgress ? 'transparent' : isDisabled ? '#EAEAEA' : '#0087C2 !important',
                                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                            },
                                        }}
                                        onClick={isDone || isInProgress ? null : () => StartRestore(false)}
                                        disabled={isDone || isInProgress ? true : isDisabled}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito',
                                                fontSize: '10px',
                                                fontWeight: '700',
                                                lineHeight: '12px',
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: postingStatus === 'UCM' ? '#D20C0C' : isDone ? '#168444' : isInProgress ? '#FEA51E' : isDisabled ? '#A3A3A3' : '#E4E8EC',
                                            }}
                                        >
                                            {postingStatus === 'UCM' ? 'FAILED' : isDone ? 'DONE' : isInProgress ? 'IN PROGRESS' : 'START'}
                                        </Typography>
                                    </Button>
                                </Grid>

                                <Grid
                                    item
                                    xs={10.5}
                                    className="text-start"
                                    display={'flex'}
                                    justifyContent={'flex-start'}
                                    sx={{ alignItems: 'center' }}
                                >
                                    {
                                        isDone && (
                                            <Button
                                                size="small"
                                                variant={"outlined"}
                                                className="d-flex align-items-center"
                                                sx={{
                                                    marginTop: '-2px',
                                                    padding: '4px 12px !important',
                                                    gap: '8px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '28px !important',
                                                    minHeight: '28px !important',
                                                    borderRadius: '8px',
                                                    display: postingStatus === '' && batchStatus === 'UCM' ? undefined : postingStatus === 'UCM' ? undefined : isHidden ? 'none !important' : undefined,
                                                    background: postingStatus !== 'UCM' && isDisabledBackward ? '#EAEAEA' : '#0087C2',
                                                    '&:hover': {
                                                        backgroundColor: postingStatus !== 'UCM' && isDisabledBackward ? '#EAEAEA' : '#0087C2 !important',
                                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                                    },
                                                }}
                                                onClick={() => StartRestore(true)}
                                                disabled={postingStatus === '' && batchStatus === 'UCM' ? false : postingStatus === 'UCM' ? false : isDisabledBackward}
                                                hidden={postingStatus === '' && batchStatus === 'UCM' ? false : postingStatus === 'UCM' ? false : isHidden}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Nunito',
                                                        fontSize: '10px',
                                                        fontWeight: '700',
                                                        lineHeight: '12px',
                                                        letterSpacing: '0em',
                                                        textAlign: 'center',
                                                        color: postingStatus !== 'UCM' && isDisabledBackward ? '#A3A3A3' : '#E4E8EC',
                                                    }}
                                                >
                                                    RESTART
                                                </Typography>
                                            </Button>
                                        )
                                    }

                                    {isInProgress && (<ProgressComponent progress={progress} />)}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.5} className="text-end" display={'flex'} justifyContent={'flex-end'}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                disableFocusRipple={true}
                                sx={{ alignItems: 'end' }}
                                onClick={() => setIsExpanded(!isExpanded)}
                                value={isExpanded}
                                disabled={isDisabled || (!isInProgress && !isDone)}
                            >
                                <Icon
                                    iconName={isExpanded ? 'arrow-up' : 'arrow-down'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: isDisabled || (!isInProgress && !isDone) ? 'inherit' : '#0087C2',
                                        sx: {
                                            height: '14px',
                                            width: '14px',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        {
                            isInProgress && (
                                <>
                                    <div
                                        style={{
                                            backgroundColor: '#E9E9EA',
                                            width: '100%',
                                            height: '1.75px',
                                            marginTop: '8px',
                                        }}
                                    />

                                    <Grid container spacing={0} sx={{ paddingTop: '2px' }}>
                                        <Grid item xs={1.5} />
                                        <Grid item xs={10}>
                                            <PostingProgress text={'Posting'} progress={progressPosted} />
                                            <PostingProgress text={'Posting Current Month'} progress={progressCM} />
                                            <PostingProgress text={'Posting Next Month'} progress={progressNM} />
                                            <PostingProgress text={'Posting Next Year'} progress={progressNY} />
                                        </Grid>
                                        <Grid item xs={0.5} />
                                    </Grid>

                                </>
                            )
                        }

                        <div
                            style={{
                                backgroundColor: '#E9E9EA',
                                width: '100%',
                                height: '1.75px',
                                marginTop: '16px',
                            }}
                        />

                        <div style={{ marginTop: '24px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    lineHeight: '0px',
                                    color: '#001A4F',
                                    userSelect: 'none',
                                }}
                            >
                                Transaction Detail: #{batchId}
                            </Typography>

                            <Grid container spacing={0} direction="column">
                                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                                    <div style={{ marginTop: '12px' }}>
                                        {
                                            ButtonComponent.RefreshButton(
                                                sortArr,
                                                rowsCount,
                                                columnData,
                                                setGoTo,
                                                handleReset,
                                                setFilterBy,
                                                filterBy,
                                                setFilter,
                                                setNumPage,
                                                setRowsCount,
                                                getData,
                                                stateX,
                                                setStateX,
                                                false
                                            )
                                        }
                                    </div>

                                    <div
                                        className="rounded-10 table-responsive"
                                        style={{
                                            maxHeight: `calc(100vh)`,
                                            marginTop: '16px',
                                            'borderRadius': '12px',
                                            background: '#F2F2F2'
                                        }}
                                    >
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            filterBy={filterBy}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={null}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>

                                    <div style={{ marginTop: '16px' }}>
                                        <Paginate
                                            isCustom={true}
                                            page={numPage}
                                            limit={rowsCount}
                                            totalData={totalRows}
                                            onChange={onChangePaging}
                                            onChangeLimit={onChangeLimit}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Collapse>
                </CardContent>
            </Card>
        </Box>
    );
});

export default memo(ProcessView);