import {
    Box, FormControl, InputAdornment,
    FormControlLabel, Grid,
    IconButton, Radio, RadioGroup, Stack,
    Table, TableBody, TableCell, Typography,
    TableContainer, TableHead, TableRow, MenuItem,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';

const TabDetails = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '6px 16px !important',
            height: '35px !important'
        },
    }));

    const dataContainerSize = [
        { id: 1, name: '20\'' },
        { id: 2, name: '20\'HC' },
        { id: 3, name: '40\'' },
        { id: 4, name: '40\'HC' },
        { id: 5, name: '45\'' },
        { id: 6, name: '45\'HC' },
    ];

    const cariCity1 = (data) => {
        if (data === 'search') {
            props.setTitleModal2('Place of Receipt');
            props.setParamModal('city/city');
            localStorage.setItem("paramModal", 'city/city');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setPlaceOfReceipt('')
            props.setPlaceOfReceiptId(0)
            props.setPlaceOfReceiptCode('')
        }
    }

    const cariCity2 = (data) => {
        if (data === 'search') {
            props.setTitleModal2('Place of Delivery');
            props.setParamModal('city/city');
            localStorage.setItem("paramModal", 'city/city');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setPlaceOfDelivery('')
            props.setPlaceOfDeliveryId(0)
            props.setPlaceOfDeliveryCode('')
        }
    }

    const cariAirport1 = (data) => {
        if (data === 'search') {
            localStorage.setItem("paramModal", 'airport/airport');
            props.setTitleModal2('Airport Departure');
            props.setParamModal('airport/airport');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setAirportDeparture('')
            props.setAirportDepartureId(0)
            props.setAirportDepartureCode('')
        }
    }

    const cariAirport2 = (data) => {
        if (data === 'search') {
            localStorage.setItem("paramModal", 'airport/airport');
            props.setTitleModal2('Airport Destination');
            props.setParamModal('airport/airport');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setAirportDestination('')
            props.setAirportDestinationId(0)
            props.setAirportDestinationCode('')
        }
    }

    const cariSeaport1 = (data) => {
        if (data === 'search') {
            localStorage.setItem("paramModal", 'seaport/seaport');
            props.setTitleModal2('Port of Loading');
            props.setParamModal('seaport/seaport');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setPortOfLoading('')
            props.setPortOfLoadingId(0)
            props.setPortOfLoadingCode('')
        }
    }

    const cariSeaport2 = (data) => {
        if (data === 'search') {
            localStorage.setItem("paramModal", 'seaport/seaport');
            props.setTitleModal2('Port of Discharge');
            props.setParamModal('seaport/seaport');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setPortOfDischarge('')
            props.setPortOfDischargeId(0)
            props.setPortOfDischargeCode('')
        }
    }

    const cariVessel = (data) => {
        if (data === 'search') {
            localStorage.setItem("paramModal", 'vessel/vessel');
            props.setTitleModal2('Vessel');
            props.setParamModal('vessel/vessel');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setVessel('')
            props.setVesselId(0)
            props.setVesselCode('')
        }
    }

    const cariAirline = (data) => {
        if (data === 'search') {
            localStorage.setItem("paramModal", 'airline/airline');
            props.setTitleModal2('Airline');
            props.setParamModal('airline/airline');
            props.handleClick2();
        }
        else if (data === 'delete') {
            props.setAirline('')
            props.setFlightAb('')
            props.setAirlineId(0)
            props.setAirlineCode('')
        }
    }

    const cariAgent = (data) => {
        if (data === 'search') {
            props.setTipe(1);
            props.setTitleModalContact('Agent');
            localStorage.setItem("ContactType", 1);
            props.handleClickContact();
        }
        else if (data === 'delete') {
            props.setIdAgent(0);
            props.setAgentName('');
            props.setAgentAddress('')
        }
    }

    const cariEMKL = (data) => {
        if (data === 'search') {
            props.setTipe(6);
            props.setTitleModalContact('EMKL');
            localStorage.setItem("ContactType", 6);
            props.handleClickContact();
        }
        else if (data === 'delete') {
            props.setIdEMKL(0);
            props.setEMKLName('');
        }
    }

    const cariDepo = (data) => {
        if (data === 'search') {
            props.setTipe(7);
            props.setTitleModalContact('Depo');
            localStorage.setItem("ContactType", 7);
            props.handleClickContact();
        }
        else if (data === 'delete') {
            props.setIdDepo(0);
            props.setDepoName('');
        }
    }

    const cariCarrier = (data) => {
        if (data === 'search') {
            if (props.portType !== 'AIR') {
                props.setTipe(4);
                props.setTitleModalContact('Carrier');
                localStorage.setItem("ContactType", 4);
                props.handleClickContact();
            }
            else {
                props.setTipe(50);
                props.setTitleModalContact('Carrier');
                localStorage.setItem("ContactType", 5);
                props.handleClickContact();
            }
        }
        else if (data === 'delete') {
            props.setIdCarrier(0);
            props.setCarrierName('');
        }
    }

    function calculateVolumeCBM(value) {
        props.setShipmentVolumeCBM(value);
        if (value > 0) {
            props.setIsVolumeCBM(true);
        }
        else {
            props.setIsVolumeCBM(false);
        }
    }

    function handleVolume(value) {
        props.setShipmentVolume(value);
        if (value === 'FCL') {
            props.setShipmentVolumeCBM(0);
            props.setShipmentVolumeKGS(0);
            props.setShipmentVolumeCollie(0);
        }
        else if (value === 'LCL') {
            props.setShipmentVolume20(0);
            props.setShipmentVolume40(0);
            props.setShipmentVolume45(0);
            props.setContainerData([]);
        }
    }

    function handleAddContainer() {
        if (props.jobTypeId) {
            let Sequence = 1;
            let bookId = 0;

            if (props.containerData?.length > 0) {
                let data = props.containerData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                Sequence = data.sequence + 1;
            }

            if (props.bookingId) {
                bookId = parseInt(props.bookingId);
            }

            let data = {
                "rowStatus": "ACT",
                "bookingConfirmationId": bookId,
                "sequence": Sequence,
                "containerSize": '20\'',
                "containerTypeName": 'General Purpose',
                "containerTypeId": 1,
                "amount": 1,
                "containerTypeCode": 'GP',
                "formatContainer": '',
            }

            props.getContainerSizeData([...props.containerData, data]);

            props.setContainerData([...props.containerData, data]);
        }
        else {
            showToast({ type: 'error', message: "Invalid Job Type!" });
        }
    }

    const handleDeleteContainer = (el) => {
        let tempSequence = el.sequence;

        const deleteFunction = (containerData) => {
            return containerData.sequence !== el.sequence
        }

        const result = props.containerData.filter(deleteFunction)

        if (result?.length > 0) {
            tempSequence = 0
            result.forEach((el) => {
                tempSequence += 1
                el.sequence = tempSequence
            })
        }

        props.getContainerSizeData(result);

        props.setContainerData(result);
    }

    const handleChangeContainerSize = (value, el) => {
        let arrayData = [];

        props.containerData.map((data) => {
            if (el.sequence === data.sequence) {
                data.containerSize = value;
            }

            return data;
        });

        arrayData = [...props.containerData];

        props.getContainerSizeData(arrayData);

        props.setContainerData(arrayData);
    }

    const handleChangeContainerType = (value, el) => {
        let arrayData = [];
        let tempId = 1;
        let tempCode = 'GP';

        props.dataContainerType.forEach(element => {
            if (value === element.name) {
                tempId = element.id;
                tempCode = element.code;
            }
        });

        props.containerData.map((data) => {
            if (el.sequence === data.sequence) {
                data.containerTypeName = value;
                data.containerTypeId = tempId;
                data.containerTypeCode = tempCode;
            }

            return data;
        });

        arrayData = [...props.containerData];

        props.getContainerSizeData(arrayData);

        props.setContainerData(arrayData);
    }

    const handleChangeAmount = (value, el) => {
        let arrayData = [];

        if (value > 0) {
            props.containerData.map((data) => {
                if (el.sequence === data.sequence) {
                    data.amount = parseInt(value);
                }

                return data;
            });
        }

        arrayData = [...props.containerData];

        props.getContainerSizeData(arrayData);

        props.setContainerData(arrayData);
    }

    const mainContainer = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    padding: '16px 32px',
                    flexDirection: 'column',
                    borderRadius: '8px',
                    background: '#FAFAFA',
                    marginTop: '-13px',
                }}
            >
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Stack spacing={'16px'} direction="row">
                            <Input
                                label={'Place of Receipt'}
                                value={props.placeOfReceiptCode}
                                message={props.placeOfReceipt ? '' : 'This Field is Required'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.placeOfReceipt}
                                type='text'
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => cariCity1('delete')}
                                                disabled={props.isApproved}
                                                hidden={props.placeOfReceipt && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => cariCity1('search')}
                                                disabled={props.isApproved}
                                                hidden={props.isApproved}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" className={props.placeOfReceipt ? 'mt-3' : 'mt-1'} hidden={props.portType === 'AIR'}>
                            <Input
                                label={'Port of Loading'}
                                value={props.portOfLoadingCode}
                                disabled
                                inputBaseProps={{
                                    readOnly: true
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.portOfLoading}
                                type='text'
                                disabled
                                formControlProps={{ sx: { width: '100%', } }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => cariSeaport1('delete')}
                                                disabled={props.isApproved}
                                                hidden={props.portOfLoading && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => cariSeaport1('search')}
                                                hidden={props.isApproved}
                                                disabled={(props.isApproved === true || (props.portType !== 'SEA' && props.portType !== 'DOM')) ? true : false}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" className={props.portType === 'DOM' ? 'mt-3' : props.placeOfReceipt ? 'mt-3' : 'mt-1'} hidden={props.portType === 'SEA'}>
                            <Input
                                label={'Airport Departure'}
                                value={props.airportDepartureCode}
                                disabled
                                inputBaseProps={{
                                    readOnly: true
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.airportDeparture}
                                type='text'
                                disabled
                                formControlProps={{ sx: { width: '100%', } }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => cariAirport1('delete')}
                                                disabled={props.isApproved}
                                                hidden={props.airportDeparture && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => cariAirport1('search')}
                                                hidden={props.isApproved}
                                                disabled={(props.isApproved === true || (props.portType !== 'AIR' && props.portType !== 'DOM')) ? true : false}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Stack spacing={'16px'} direction="row">
                            <Input
                                label={'Place of Delivery'}
                                value={props.placeOfDeliveryCode}
                                message={props.placeOfDelivery ? '' : 'This Field is Required'}
                                disabled
                                inputBaseProps={{
                                    readOnly: true
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.placeOfDelivery}
                                type='text'
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => cariCity2('delete')}
                                                disabled={props.isApproved}
                                                hidden={props.placeOfDelivery && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => cariCity2('search')}
                                                disabled={props.isApproved}
                                                hidden={props.isApproved}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" className={props.placeOfDelivery ? 'mt-3' : 'mt-1'} hidden={props.portType === 'AIR'}>
                            <Input
                                label={'Port of Discharge'}
                                value={props.portOfDischargeCode}
                                disabled
                                inputBaseProps={{
                                    readOnly: true
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.portOfDischarge}
                                type='text'
                                disabled
                                formControlProps={{ sx: { width: '100%', } }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => cariSeaport2('delete')}
                                                disabled={props.isApproved}
                                                hidden={props.portOfDischarge && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => cariSeaport2('search')}
                                                hidden={props.isApproved}
                                                disabled={(props.isApproved === true || (props.portType !== 'SEA' && props.portType !== 'DOM')) ? true : false}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" className={props.portType === 'DOM' ? 'mt-3' : props.placeOfDelivery ? 'mt-3' : 'mt-1'} hidden={props.portType === 'SEA'}>
                            <Input
                                label={'Airport Destination'}
                                value={props.airportDestinationCode}
                                disabled
                                inputBaseProps={{
                                    readOnly: true
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.airportDestination}
                                type='text'
                                disabled
                                formControlProps={{ sx: { width: '100%', } }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => cariAirport2('delete')}
                                                disabled={props.isApproved}
                                                hidden={props.airportDestination && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => cariAirport2('search')}
                                                hidden={props.isApproved}
                                                disabled={(props.isApproved === true || (props.portType !== 'AIR' && props.portType !== 'DOM')) ? true : false}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <Input
                    label={'Final Destination'}
                    value={props.finalDestination}
                    onChange={(e) => props.setFinalDestination(e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    disabled={props.isApproved}
                />

                <div className='mt-3'>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <CustomDatePicker
                                label={'ETD'}
                                value={props.ETD}
                                type={'date'}
                                onChange={props.setETD}
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                                inputBaseProps={{
                                    inputProps: {
                                        // max: props.ETA,
                                    }
                                }}
                                disabled={props.isApproved}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <CustomDatePicker
                                label={'ETA'}
                                value={props.ETA}
                                type={'date'}
                                onChange={props.setETA}
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                                inputBaseProps={{
                                    inputProps: {
                                        // min: props.ETD,
                                    }
                                }}
                                disabled={props.isApproved}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div className='mt-3'>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    label={'Agent Code'}
                                    value={props.idAgent}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.agentName}
                                    type='text'
                                    label={'Agent Name'}
                                    disabled
                                    formControlProps={{ sx: { width: '100%', } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => cariAgent('delete')}
                                                    disabled={props.isApproved}
                                                    hidden={props.agentName && !props.isApproved ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => cariAgent('search')}
                                                    disabled={props.isApproved}
                                                    hidden={props.isApproved}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    label={'EMKL Code'}
                                    value={props.idEMKL}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.EMKLName}
                                    type='text'
                                    label={'EMKL Name'}
                                    disabled
                                    formControlProps={{ sx: { width: '100%', } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => cariEMKL('delete')}
                                                    disabled={props.isApproved}
                                                    hidden={props.EMKLName && !props.isApproved ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => cariEMKL('search')}
                                                    disabled={props.isApproved}
                                                    hidden={props.isApproved}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>

                <div className='mt-3'>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    label={'Depo Code'}
                                    value={props.idDepo}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.depoName}
                                    type='text'
                                    label={'Depo Name'}
                                    disabled
                                    formControlProps={{ sx: { width: '100%', } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => cariDepo('delete')}
                                                    disabled={props.isApproved}
                                                    hidden={props.depoName && !props.isApproved ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => cariDepo('search')}
                                                    disabled={props.isApproved}
                                                    hidden={props.isApproved}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" className='mt-3'>
                                <Input
                                    label={'Carrier Code'}
                                    value={props.idCarrier}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.carrierName}
                                    type='text'
                                    label={'Carrier Name'}
                                    disabled
                                    formControlProps={{ sx: { width: '100%', } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => cariCarrier('delete')}
                                                    disabled={props.isApproved}
                                                    hidden={props.carrierName && !props.isApproved ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => cariCarrier('search')}
                                                    disabled={props.isApproved}
                                                    hidden={props.isApproved}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" className='mt-3'>
                                <Selection
                                    id='0'
                                    label={'Service Type'}
                                    placeholder={'Select Service Type'}
                                    value={props.serviceType}
                                    disabled={props.isApproved}
                                    onChange={(e) => props.setServiceType(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '200px'
                                        }
                                    }}
                                >
                                    <MenuItem sx={{ fontSize: '12px' }} id={1} value={'CC'}>CY-CY</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} id={2} value={'DC'}>DOOR-CY</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} id={3} value={'CD'}>CY-DOOR</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} id={4} value={'DD'}>DOOR-DOOR</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} id={5} value={'None'}>None</MenuItem>
                                </Selection>

                                <FormControl sx={{ marginTop: '24px' }}>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Freight
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="variant-option"
                                        name="variant"
                                        value={props.freight}
                                        sx={{
                                            gap: '12px'
                                        }}
                                        disabled={props.isApproved}
                                        onChange={(e) => props.setFreight(e.target.value)}
                                    >
                                        <FormControlLabel
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '20px',
                                            }}
                                            value={'C'}
                                            key={1}
                                            control={<Radio size='small' />}
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: '20px',
                                                    }}
                                                >
                                                    Collect
                                                </Typography>
                                            }
                                            disabled={props.isApproved}
                                        />

                                        <FormControlLabel
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '20px',
                                            }}
                                            value={'P'}
                                            key={2}
                                            control={<Radio size='small' />}
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: '20px',
                                                    }}
                                                >
                                                    Prepaid
                                                </Typography>
                                            }
                                            disabled={props.isApproved}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>

                            <FormControl sx={{ marginTop: '16px' }}>
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    Volume
                                </Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="variant-option"
                                    name="variant"
                                    sx={{
                                        marginTop: '4px',
                                        gap: '12px'
                                    }}
                                    value={props.shipmentVolume}
                                    onChange={(e) => handleVolume(e.target.value)}
                                    disabled={props.isApproved}
                                >
                                    <FormControlLabel
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                        }}
                                        value={'FCL'}
                                        key={1}
                                        control={<Radio size='small' />}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                FCL
                                            </Typography>
                                        }
                                        disabled={props.isApproved}
                                    />

                                    <FormControlLabel
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                        }}
                                        value={'LCL'}
                                        key={2}
                                        control={<Radio size='small' />}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                LCL
                                            </Typography>
                                        }
                                        disabled={props.isApproved}
                                    />

                                    <FormControlLabel
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                        }}
                                        value={'Others'}
                                        key={3}
                                        control={<Radio size='small' />}
                                        label={
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                Others
                                            </Typography>
                                        }
                                        disabled={props.isApproved}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <Box component="div" className='mt-1'>
                                <div className='mt-1'>
                                    <Stack spacing={'16px'} direction='row' hidden={props.shipmentVolume !== 'LCL' ? false : true}>
                                        <Input
                                            label={'20'}
                                            value={props.shipmentVolume20}
                                            disabled
                                            type={'text'}
                                        />

                                        <Input
                                            label={'40'}
                                            value={props.shipmentVolume40}
                                            disabled
                                            type={'text'}
                                        />

                                        <Input
                                            label={'45'}
                                            value={props.shipmentVolume45}
                                            disabled
                                            type={'text'}
                                        />

                                        <Input
                                            label={'All'}
                                            value={props.shipmentVolumeAll}
                                            disabled
                                            type={'text'}
                                        />
                                    </Stack>

                                    <Stack
                                        spacing={'16px'}
                                        direction='row'
                                        hidden={props.shipmentVolume !== 'FCL' ? false : true}
                                        className={props.shipmentVolume !== 'FCL' && props.shipmentVolume !== 'LCL' ? 'mt-3' : null}
                                    >
                                        <Input
                                            label={'CBM'}
                                            type='number'
                                            value={props.shipmentVolumeCBM}
                                            onChange={(e) => calculateVolumeCBM(e.target.value)}
                                            disabled={props.isApproved}
                                        />

                                        <Input
                                            label={'KGS'}
                                            type='number'
                                            value={props.shipmentVolumeKGS}
                                            onChange={(e) => props.setShipmentVolumeKGS(e.target.value)}
                                            disabled={props.isApproved}
                                        />

                                        <Input
                                            label={'Collie'}
                                            type='number'
                                            value={props.shipmentVolumeCollie}
                                            onChange={(e) => props.setShipmentVolumeCollie(e.target.value)}
                                            disabled={props.isApproved}
                                        />
                                    </Stack>
                                </div>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row" hidden={props.portType === 'AIR'}>
                                <Input
                                    label={'Vessel Code'}
                                    value={props.vesselId}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.vessel}
                                    type='text'
                                    label={'Vessel Name'}
                                    disabled
                                    formControlProps={{ sx: { width: '100%', } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => cariVessel('delete')}
                                                    disabled={props.isApproved}
                                                    hidden={props.vessel && !props.isApproved ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => cariVessel('search')}
                                                    hidden={props.isApproved}
                                                    disabled={(props.isApproved === true || (props.portType !== 'SEA' && props.portType !== 'DOM')) ? true : false}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Input
                                    label={'Voyage No'}
                                    value={props.voyageNo}
                                    disabled={(props.isApproved === true || (props.portType !== 'SEA' && props.portType !== 'DOM')) ? true : false}
                                    onChange={(e) => props.setVoyageNo(e.target.value)}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" className={props.portType === 'DOM' ? 'mt-3' : ''} hidden={props.portType === 'SEA'}>
                                <Input
                                    label={'Airline Code'}
                                    value={props.airlineId}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true
                                    }}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.airline}
                                    type='text'
                                    label={'Airline Name'}
                                    disabled
                                    formControlProps={{ sx: { width: '100%', } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => cariAirline('delete')}
                                                    disabled={props.isApproved}
                                                    hidden={props.airline && !props.isApproved ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => cariAirline('search')}
                                                    hidden={props.isApproved}
                                                    disabled={(props.isApproved === true || (props.portType !== 'AIR' && props.portType !== 'DOM')) ? true : false}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Input
                                    label={'Flight No'}
                                    value={props.flightNo}
                                    disabled={(props.isApproved === true || (props.portType !== 'AIR' && props.portType !== 'DOM')) ? true : false}
                                    onChange={(e) => props.setFlightNo(e.target.value)}
                                />
                            </Stack>

                            <div className='mt-3'>
                                <CustomDatePicker
                                    label={'Stuffing Date'}
                                    type={'date'}
                                    value={props.stuffingDate}
                                    onChange={props.setStuffingDate}
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                    disabled={props.isApproved}
                                />

                                <Input
                                    label={'Place of Stuffing'}
                                    value={props.stuffingPlace}
                                    onChange={(e) => props.setStuffingPlace(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isApproved}
                                />

                                <Input
                                    label={'Stuffing Note'}
                                    value={props.stuffingNote}
                                    onChange={(e) => props.setStuffingNote(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 5,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                    disabled={props.isApproved}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className='mt-3'>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                            hidden={props.shipmentVolume !== 'LCL' ? false : true}
                        >
                            <Box component="div" className='mt-2'>
                                <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px' }}>
                                    <Table size='small' aria-label="a dense table" stickyHeader>
                                        <TableHead
                                            sx={{
                                                '& th:first-of-type': {
                                                    borderRadius: '12px 0 0'
                                                },
                                                '& th:last-child': {
                                                    borderRadius: '0 12px 0 0'
                                                }
                                            }}
                                        >
                                            <TableRow>
                                                <StyledTableCell>Container Size</StyledTableCell>
                                                <StyledTableCell>Container Type</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: 'left' }}>Amount</StyledTableCell>
                                                {
                                                    !props.isApproved ?
                                                        <StyledTableCell style={{ textAlign: 'center' }} >Action</StyledTableCell>
                                                        :
                                                        <></>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        {
                                            props.containerData?.length > 0 ?
                                                <TableBody>
                                                    {
                                                        props.containerData.map((el) => {
                                                            return (
                                                                <TableRow key={el.sequence} sx={{ backgroundColor: '#F2FAFF' }}>
                                                                    <StyledTableBodyCell>
                                                                        {
                                                                            !props.isApproved ?
                                                                                <Selection
                                                                                    fullWidth={true}
                                                                                    value={el.containerSize}
                                                                                    onChange={(e) => handleChangeContainerSize(e.target.value, el)}
                                                                                >
                                                                                    {
                                                                                        dataContainerSize.map((data, index) => (
                                                                                            <MenuItem sx={{ fontSize: '12px' }} id={index} key={index} value={data.name}>
                                                                                                {data.name}
                                                                                            </MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Selection>
                                                                                :
                                                                                <>
                                                                                    {el.containerSize}
                                                                                </>
                                                                        }
                                                                    </StyledTableBodyCell>
                                                                    <StyledTableBodyCell>
                                                                        {
                                                                            !props.isApproved ?
                                                                                <Selection
                                                                                    fullWidth={true}
                                                                                    value={el.containerTypeName}
                                                                                    onChange={(e) => handleChangeContainerType(e.target.value, el)}
                                                                                >
                                                                                    {
                                                                                        props.dataContainerType.map((data, index) => (
                                                                                            <MenuItem sx={{ fontSize: '12px' }} id={index} key={index} value={data.name}>
                                                                                                {data.name}
                                                                                            </MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Selection>
                                                                                :
                                                                                <>
                                                                                    {el.containerTypeName}
                                                                                </>
                                                                        }
                                                                    </StyledTableBodyCell>
                                                                    <StyledTableBodyCell style={{ textAlign: 'left' }}>
                                                                        {
                                                                            !props.isApproved ?
                                                                                <Input
                                                                                    value={el.amount}
                                                                                    onChange={(e) => handleChangeAmount(e.target.value, el)}
                                                                                    type='number'
                                                                                />
                                                                                :
                                                                                <>
                                                                                    {el.amount}
                                                                                </>
                                                                        }
                                                                    </StyledTableBodyCell>
                                                                    {
                                                                        !props.isApproved ?
                                                                            <StyledTableBodyCell style={{ textAlign: 'center' }}>
                                                                                <IconButton onClick={() => handleDeleteContainer(el)} hidden={props.isApproved}>
                                                                                    <Icon
                                                                                        iconName={'delete'}
                                                                                        svgIconProps={{
                                                                                            fontSize: 'small',
                                                                                            htmlColor: '#0087C2',
                                                                                            sx: {
                                                                                                height: '18px',
                                                                                                width: '18px'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </StyledTableBodyCell>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                                :
                                                <TableBody
                                                    sx={{
                                                        '& td:last-child': {
                                                            borderRadius: '0 0 12px 12px'
                                                        }
                                                    }}
                                                >
                                                    <TableRow>
                                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center', backgroundColor: '#F2FAFF' }}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Primary-One, #083A50)',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: '12px',
                                                                    fontstyle: 'normal',
                                                                    fontWeight: '500',
                                                                    lineHeight: 'normal'
                                                                }}
                                                            >
                                                                Data Empty
                                                            </Typography>
                                                        </StyledTableBodyCell>
                                                    </TableRow>
                                                </TableBody>
                                        }
                                    </Table>
                                </TableContainer>

                                <div className='mt-3'>
                                    {ButtonComponent.AddButton(handleAddContainer, '', false, props.isApproved)}
                                </div>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xl={props.shipmentVolume !== 'LCL' ? 6 : 12}
                            lg={props.shipmentVolume !== 'LCL' ? 6 : 12}
                            sm={props.shipmentVolume !== 'LCL' ? 6 : 12}
                            xs={12}
                        >
                            <Grid
                                container
                                spacing={'16px'}
                            >
                                <Grid
                                    item
                                    xl={2}
                                    lg={2}
                                    sm={2}
                                    xs={12}
                                >
                                    <FormControl>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: '19.6px',
                                            }}
                                        >
                                            Credit Term
                                        </Typography>
                                        <RadioGroup
                                            row
                                            sx={{
                                                marginTop: '4px',
                                                gap: '4px'
                                            }}
                                            aria-labelledby="variant-option"
                                            name="variant"
                                            value={props.creditTerm}
                                            onChange={(e) => props.setCreditTerm(e.target.value)}
                                            disabled={props.isApproved}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                }}
                                                value={true}
                                                key={1}
                                                control={<Radio size='small' />}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: '20px',
                                                        }}
                                                    >
                                                        Yes
                                                    </Typography>
                                                }
                                                disabled={props.isApproved}
                                            />

                                            <FormControlLabel
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                }}
                                                value={false}
                                                key={2}
                                                control={<Radio size='small' />}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: '20px',
                                                        }}
                                                    >
                                                        No
                                                    </Typography>
                                                }
                                                disabled={props.isApproved}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    xl={10}
                                    lg={10}
                                    sm={10}
                                    xs={12}
                                >
                                    <Input
                                        label={'Remarks'}
                                        value={props.remarks}
                                        onChange={(e) => props.setRemarks(e.target.value)}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            rows: 4,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                        disabled={props.isApproved}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                props.isApproved ?
                    <fieldset disabled>
                        {mainContainer()}
                    </fieldset>
                    :
                    <fieldset>
                        {mainContainer()}
                    </fieldset>
            }

        </>
    )
}

export default TabDetails