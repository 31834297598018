import { Box, Typography, Grid, Button } from '@mui/material';
import React from 'react';

import { CustomDatePicker, Icon, PopUp } from '../../../../../components/ui';

const ModalPrintRecap = (props) => {
    const mainContainer = (
        <>
            <CustomDatePicker
                label={'Period'}
                fullWidth
                type={'month'}
                value={props.Period}
                onChange={props.setPeriod}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '12px !important'
                    }
                }}
            />
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#083A50',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={'Print Rekap'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <div style={{ padding: '6px 6px 16px' }}>
                    <Box display="flex">
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                marginTop: '3px !important',
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#D20C0C'
                            }}
                        >
                            *) Period based on Print Date
                        </Typography>
                    </Box>

                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #E4E8EC)',
                                        background: '#0087C2',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #E4E8EC)',
                                            background: '#0087C2',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => props.PrintRecap('pdf')}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#E4E8EC',
                                            sx: {
                                                height: '14px',
                                                width: '14px',
                                                marginTop: '1px'
                                            }
                                        }}
                                        iconName={'printer'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#E4E8EC',
                                        }}
                                    >
                                        Print
                                    </Typography>
                                </Button>

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #0087C2)',
                                        background: '#B3E3FF',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #0087C2)',
                                            background: '#B3E3FF',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => props.PrintRecap('xls')}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '14px',
                                                width: '14px',
                                                marginTop: '1px'
                                            }
                                        }}
                                        iconName={'excel'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#0087C2',
                                        }}
                                    >
                                        Print Excel
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
}

export default ModalPrintRecap;