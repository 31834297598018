import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import TabCash from './TabCash';
import TabBank from './TabBank';

const TabsPaymentMethod = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    function checkMethod(list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].totalCashUSD !== undefined && list[i].totalCashIDR !== undefined) {
                if (list[i].totalCashUSD > 0 || list[i].totalCashIDR > 0) {
                    return 1;
                }
            }

            if (list[i].officialReceiptInvoiceBanks !== undefined) {
                if (list[i].officialReceiptInvoiceBanks.length > 0) {
                    return 2;
                }
            }
        };

        return 0;
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <TabContext value={props.tabValue} className='p-0'>
                    <Tabs
                        sx={{ width: '100%', height: 'fit-content', maxHeight: '32px !important' }}
                        variant="fullWidth"
                        value={props.tabValue}
                        onChange={handleChange}
                        className='p-0'
                        textColor="primary"
                        aria-label="full width tabs example"
                        TabIndicatorProps={{
                            sx: {
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                                backgroundColor: "#0087C2 !important",
                            },
                        }}
                        TabScrollButtonProps={{
                            sx: {
                                backgroundColor: "#0087C2 !important",
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                            },
                        }}
                    >
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="Cash"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "Cash" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Cash
                                </Typography>
                            }
                        />
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="Bank"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "Bank" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Bank
                                </Typography>
                            }
                        />
                    </Tabs>

                    {
                        props.tabValue === "Cash" && (
                            <TabPanel value="Cash" className='mt-1 p-0'>
                                <TabCash
                                    isApprovedAcc={props.isApprovedAcc}
                                    calculateTotalCash={props.calculateTotalCash}
                                    Printing={props.Printing}
                                    calculateTotalDifferent={props.calculateTotalDifferent}
                                    RateCount={props.RateCount}
                                    officialReceiptId={props.officialReceiptId}
                                    CashPaymentUSD={props.CashPaymentUSD}
                                    setCashPaymentUSD={props.setCashPaymentUSD}
                                    CashPaymentIDR={props.CashPaymentIDR}
                                    setCashPaymentIDR={props.setCashPaymentIDR}
                                    TotalCashPaymentIDR={props.TotalCashPaymentIDR}
                                    setTotalCashPaymentIDR={props.setTotalCashPaymentIDR}
                                    checkSelectedInvoice={props.checkSelectedInvoice}
                                    checkMethod={checkMethod}
                                    InvoiceList={props.InvoiceList}
                                    setInvoiceList={props.setInvoiceList}
                                    TotalCashUSD={props.TotalCashUSD}
                                    setTotalCashUSD={props.setTotalCashUSD}
                                    TotalCashIDR={props.TotalCashIDR}
                                    setTotalCashIDR={props.setTotalCashIDR}
                                    TotalCashInIDR={props.TotalCashInIDR}
                                    setTotalCashInIDR={props.setTotalCashInIDR}
                                />
                            </TabPanel>
                        )
                    }

                    {
                        props.tabValue === "Bank" && (
                            <TabPanel value="Bank" className='mt-1 p-0'>
                                <TabBank
                                    isApprovedAcc={props.isApprovedAcc}
                                    calculateTotalBank={props.calculateTotalBank}
                                    Printing={props.Printing}
                                    calculateTotalDifferent={props.calculateTotalDifferent}
                                    officialReceiptId={props.officialReceiptId}
                                    checkMethod={checkMethod}
                                    BankData={props.BankData}
                                    setBankData={props.setBankData}
                                    checkSelectedInvoice={props.checkSelectedInvoice}
                                    RateCount={props.RateCount}
                                    InvoiceList={props.InvoiceList}
                                    setInvoiceList={props.setInvoiceList}
                                    TotalBankUSD={props.TotalBankUSD}
                                    setTotalBankUSD={props.setTotalBankUSD}
                                    TotalBankIDR={props.TotalBankIDR}
                                    setTotalBankIDR={props.setTotalBankIDR}
                                    TotalBankInIDR={props.TotalBankInIDR}
                                    setTotalBankInIDR={props.setTotalBankInIDR}
                                    dueDateLast={props.dueDateLast}
                                    setDueDateLast={props.setDueDateLast}
                                    TempDiffUSD={props.TempDiffUSD}
                                    TempDiffIDR={props.TempDiffIDR}
                                />
                            </TabPanel>
                        )
                    }

                </TabContext>
            </Box>
        </>
    )
}

export default TabsPaymentMethod;