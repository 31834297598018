import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Grid, Backdrop, CardContent, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, showToast } from '../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const RekapPaymentPPh23Report = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = `${date.getFullYear()}-${date.getMonth() + 1}`

  const [isLoading, setIsLoading] = useState(true)

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Rekap Payment PPh 23 Report', link: '/Majura/RekapPaymentPPh23Report', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Rekap Payment PPh 23 Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAccess = () => {
    setIsLoading(true)
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);
            setIsLoading(false)
          }
        })
        .catch(function (error) {
          setIsLoading(false)
          console.error("error saat fetch", error);
          showToast({ type: 'error', message: 'You don`t have access to this page, ' + error });
          history('/Majura');
        });
    } else {
      setIsLoading(false)
      history('/Majura');
      showToast({ type: 'error', message: 'You don`t have access to this page.' });
    }
  };

  const handlePrint = (fileType) => {
    const payload = formik.values
    if (!payload?.period) {
      return showToast({ type: 'error', message: 'Please select period.' });
    }

    setIsLoading(true);

    let params = [];
    let date = new Date(payload.period).toLocaleDateString("sv-se").split('T')[0];

    params = [
      ...params,
      {
        "attr": "Date",
        "value": date
      },
    ]

    let reportLink = 'Reports/Finance/Print/Pph23/RekapPaymentPPh23Report/RekapPaymentPPh23Report/PrintRekapPaymentPph23?typeReport=' + fileType;
    if (fileType === 'pdf') {
      PrintPDF(setIsLoading, 'Rekap Payment Pph23', reportLink, params);
    }
    else {
      PrintExcel(setIsLoading, 'Rekap Payment Pph23', reportLink, params);
    }
  }

  const formik = useFormik({
    initialValues: {
      period: dateString,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf'))}
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Excel')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Rekap Payment PPh 23 Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <CustomDatePicker
                        label="Period"
                        name="period"
                        value={formik.values?.period || ''}
                        onChange={(e) => { formik.setFieldValue('period', e) }}
                        type={'month'}
                        formControlProps={{
                          sx: {
                            width: '100%',
                          }
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading &&
        (<Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <LoadingSpinner />
        </Backdrop>)
      }
    </>
  )
})

export default RekapPaymentPPh23Report