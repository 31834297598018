import { Box, Grid, FormControl, TextareaAutosize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import { Table } from 'react-bootstrap';
import { Input } from '../../../../components/ui';

const TabDescription = (props) => {
    const [description, setDescription] = useState(props.GoodDescription);

    function handleCopy() {
        if (props.containerData.length > 0) {
            let temp = '';
            if (props.GoodDescription !== '') {
                temp = props.GoodDescription + '\n';
            }
            props.containerData.forEach(element => {
                temp = temp + element.containerNo + '/' + element.containerSize + element.containerTypeCode + '/' + element.sealNo + '\n';
            });

            setDescription(temp);
            props.setGoodDescription(temp);
            props.setSIGoodDesc(temp);
        }
    }

    // Sync initial value from parent to local state
    useEffect(() => {
        setDescription(props.GoodDescription);
    }, [props]);

    // Debounce function to limit update frequency
    const sendValueToParent = (newValue) => {
        if (props.setGoodDescription) {
            props.setGoodDescription(newValue);
            props.setSIGoodDesc(newValue);
        }
    };

    const handleChange = (event) => {
        const newValue = event.target.value.toUpperCase();
        setDescription(newValue);

        clearTimeout(TabDescription.debounceTimeout);
        TabDescription.debounceTimeout = setTimeout(() => {
            sendValueToParent(newValue);
        }, 300);
    };

    const handleBlur = () => {
        sendValueToParent(description);
    };

    return (
        <>
            <Box component="div" sx={{ background: '#FAFAFA', marginTop: '12px' }}>
                {
                    props.jobType.portType !== 'AIR' ?
                        <>
                            <FormControl variant="standard" fullWidth style={{ alignItems: 'center', background: '#FAFAFA' }}>
                                <Table style={{ width: '1009px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', background: 'transparent !important' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '190px', borderBottom: 'none' }}>
                                                <label style={{ fontSize: '12.5px' }}>
                                                    Mark & Number
                                                </label>
                                            </td>

                                            <td style={{ width: '180px', borderBottom: 'none' }}>
                                                <label style={{ fontSize: '12.5px' }}>
                                                    Number & Type <br></br> Pakages
                                                </label>
                                            </td>

                                            <td style={{ width: '300px', borderBottom: 'none' }}>
                                                <label style={{ fontSize: '12.5px' }}>
                                                    Description of Goods
                                                </label>
                                            </td>

                                            <td style={{ width: '100px', borderBottom: 'none' }}>
                                                <label style={{ fontSize: '12.5px' }}>
                                                    Gross Weight
                                                </label>
                                            </td>

                                            <td style={{ borderBottom: 'none' }}>
                                                <label style={{ fontSize: '12.5px' }}>
                                                    Measurement
                                                </label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={5} style={{ borderBottom: 'none' }}>
                                                <label></label>
                                                <TextareaAutosize
                                                    aria-label="minimum height"
                                                    style={{
                                                        width: '1009px',
                                                        fontFamily: 'Tahoma',
                                                        fontSize: '12.5px',
                                                        textAlign: 'left',
                                                        backgroundColor: '#DCFFCA'
                                                    }}
                                                    minRows={10}
                                                    maxRows={10}
                                                    value={description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </FormControl>

                            <div className='mt-3'>
                                {ButtonComponent.CopyButton(handleCopy, 'Container')}
                            </div>
                        </>
                        :
                        <>
                            <Grid
                                container
                                spacing={'32px'}
                            >
                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    sm={4}
                                    xs={8}
                                >
                                    <Input
                                        label="No Of Pieces RCP"
                                        value={props.PiecesRCP}
                                        onChange={(e) => props.setPiecesRCP(e.target.value)}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Gross Weight"
                                        textAlign='right'
                                        value={props.GrossWeight}
                                        onChange={(e) => props.setGrossWeight(e.target.value)}
                                        type={'number'}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    sm={4}
                                    xs={8}
                                >
                                    <Input
                                        label="KG / Lb"
                                        value={props.KGLB}
                                        onChange={(e) => props.setKGLB(e.target.value)}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Chargeable Weight"
                                        textAlign='right'
                                        value={props.ChargeWeight}
                                        onChange={(e) => props.setChargeWeight(e.target.value)}
                                        type={'number'}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    sm={4}
                                    xs={8}
                                >
                                    <Input
                                        label="Rate / Charge"
                                        textAlign='right'
                                        value={props.ChargeRate}
                                        onChange={(e) => props.setChargeRate(e.target.value)}
                                        type={'number'}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Total"
                                        value={props.Total}
                                        onChange={(e) => props.setTotal(e.target.value)}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={'32px'}
                            >
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <Input
                                        label="Commodity"
                                        value={props.Commodity}
                                        onChange={(e) => props.setCommodity(e.target.value)}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <div style={{ marginTop: '16px' }}>
                                        {props.selectPackagingType(props.PackagingCode, props.setPackagingCode, null, 'Packaging Code')}
                                    </div>
                                </Grid>
                            </Grid>

                            <Input
                                label="Nature and Quantity of Goods (Incl. Dimensions or Volume)"
                                value={props.GoodNatureQuantity}
                                onChange={(e) => props.setGoodNatureQuantity(e.target.value)}
                                multiline
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    rows: 4,
                                    sx: {
                                        padding: '8px',
                                    },
                                }}
                            />

                            <Input
                                label="Shipping Mark - Side Mark"
                                value={props.Shipmark}
                                onChange={(e) => props.setShipmark(e.target.value)}
                                multiline
                                fullWidth
                                hidden={props.jobType.serviceType === 'Export' ? false : true}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    rows: 4,
                                    sx: {
                                        padding: '8px',
                                    },
                                }}
                            />
                        </>
                }
            </Box>
        </>
    )
}

export default TabDescription;