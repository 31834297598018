import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { MenuItem, Grid, Card, CardContent, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import PaymentRequestHeader from './Components/PaymentRequestHeader';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ButtonCreateEditPaymentRequest from './Buttons/ButtonCreateEditPaymentRequest';
import PaymentRequestBeingForPayment from './Components/PaymentRequestBeingForPayment';
import ApiService, { branchId, companyId, countryId, userId, userCode, username, isInfossNo } from '../../../services/ApiService';
import ModalApproveGeneral from './Modals/ModalApproveGeneral';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../components/ui';
import ButtonInfo from '../../../components/Components/ButtonInfo';
import { defaultDate, getDefaultDateFormat, nullDate, setDateFormat, updateDateFormat } from '../../../utils/date';
import { convertToNominal, newWindowConfig } from '../../../utils/masks.jsx';
import { PrintPDF } from '../../../utils/reusableFunction.jsx';

const CreateEditPaymentRequest = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataPR"));

    const { sJobTypeId, prId, eplId, isToDo, isContra, customerId, customerTypeId } = useParams();

    const [check, setCheck] = useState(0);

    const paramPaymentRequest = 'paymentRequest/paymentRequest';
    const paramShipmentOrder = 'shipmentOrder/shipmentOrder';
    const paramEPL = 'estimateProfitLoss/estimateProfitLoss';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const paramGL = 'generalLedger/generalLedger';
    const paramContact = "regContact/regContact";

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState([]);
    const [typeJobId, setTypeJobId] = useState('1');
    const [idJobType, setIdJobType] = useState(0);

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const paramVAT = 'vat/vat';
    const [VATData, setVATData] = useState([]);

    const history = useNavigate();

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    //PaymentRequest Variable
    const [rowStatus, setRowStatus] = useState('ACT');
    const [ticketId, setTicketId] = useState(0);
    const [PRNo, setPRNo] = useState(0);
    const [debetCredit, setDebetCredit] = useState('C');
    const [shipmentId, setShipmentId] = useState(0);
    const [shipmentNo, setShipmentNo] = useState('');
    const [EPLId, setEPLId] = useState(0);
    const [ET, setET] = useState('');
    const [reference, setReference] = useState('');
    const [PRStatus, setPRStatus] = useState(0);
    const [isGeneralPR, setIsGeneralPR] = useState(false);
    const [CustomerId, setCustomerId] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [CustomerTypeId, setCustomerTypeId] = useState(4);
    const [personalId, setPersonalId] = useState(0);
    const [personalName, setPersonalName] = useState('');
    const [PRContraStatus, setPRContraStatus] = useState('');
    const [PRContraNo, setPRContraNo] = useState(0);
    const [paidUSD, setPaidUSD] = useState(false);
    const [datePaidUSD, setDatePaidUSD] = useState('');
    const [paidIDR, setPaidIDR] = useState(false);
    const [datePaidIDR, setDatePaidIDR] = useState('');
    const [deleted, setDeleted] = useState(false);
    const [deletedOn, setDeletedOn] = useState('');
    const [approveOpr, setApproveOpr] = useState(false);
    const [approveOprOn, setApproveOprOn] = useState('');
    const [approveAcc, setApproveAcc] = useState(false);
    const [approveAccOn, setApproveAccOn] = useState('');
    const [printing, setPrinting] = useState(0);
    const [printedOn, setPrintedOn] = useState('');
    const [PRNo2, setPRNo2] = useState('');
    const [deletedRemarks, setDeletedRemarks] = useState('');
    const [isCostToCost, setIsCostToCost] = useState(false);
    const [uniqueKeyPR, setUniqueKeyPR] = useState('');
    const [packingListNo, setPackingListNo] = useState('');
    const [SICustomerNo, setSICustomerNo] = useState('');
    const [vendorDN, setVendorDN] = useState('');
    const [approved, setApproved] = useState(false);
    const [approvedOn, setApprovedOn] = useState('');
    const [approvedBy, setApprovedBy] = useState('');
    const [approvedRemarks, setApprovedRemarks] = useState('');
    const [approvedMarketing, setApprovedMarketing] = useState(false);
    const [approvedMarketingOn, setApprovedMarketingOn] = useState('');
    const [approvedMarketingBy, setApprovedMarketingBy] = useState('');
    const [PrincipleBy, setPrincipleBy] = useState(0);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateId, setExRateId] = useState(0);
    const [ExRateDate, setExRateDate] = useState(0);
    const [requester, setRequester] = useState('');
    const [originalPRId, setOriginalPRId] = useState(0);
    const [originalPRNo, setOriginalPRNo] = useState('');

    const [portType, setPortType] = useState('');
    const [serviceType, setServiceType] = useState('');

    const [isEdit, setIsEdit] = useState(false);
    const [isExport, setIsExport] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isSEA, setIsSEA] = useState(false);
    const [isAIR, setIsAIR] = useState(false);
    const [Customer, setCustomer] = useState('');
    const [PaymentRequestDetail, setPaymentRequestDetail] = useState([]);
    const [TotalPaymentUSD, setTotalPaymentUSD] = useState(0);
    const [TotalPaymentIDR, setTotalPaymentIDR] = useState(0);
    const [TotalPPNUSD, setTotalPPNUSD] = useState(0);
    const [TotalPPNIDR, setTotalPPNIDR] = useState(0);
    const [TotalPPNUSDReal, setTotalPPNUSDReal] = useState(0);
    const [TotalPPNIDRReal, setTotalPPNIDRReal] = useState(0);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isDraft, setIsDraft] = useState(true);
    const [isAllJob, setIsAllJob] = useState(false);
    const [isPosted, setIsPosted] = useState(false);

    const [jobTypeCode, setJobTypeCode] = useState('');

    const [volume20, setVolume20] = useState(0);
    const [volume40, setVolume40] = useState(0);
    const [volume45, setVolume45] = useState(0);
    const [volumeCBM, setVolumeCBM] = useState(0);
    const [isVolume20, setIsVolume20] = useState(false);
    const [isVolume40, setIsVolume40] = useState(false);
    const [isVolume45, setIsVolume45] = useState(false);
    const [isVolumeCBM, setIsVolumeCBM] = useState(false);

    const [bookingConfirmationId, setBookingConfirmationId] = useState(0);
    const [bookingConfirmationNumber, setBookingConfirmationNumber] = useState('');

    const [isViewOnly, setIsViewOnly] = useState(false);
    const [PVId, setPVId] = useState(0);

    const [containerSizeData, setContainerSizeData] = useState([]);

    const [isModalGPR, setIsModalGPR] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [isClosed, setIsClosed] = useState(false);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setYearPeriod] = useState(0);

    const [isAcc, setIsAcc] = useState(false);

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Payment Request', link: '/Majura/PaymentRequest', style: '' },
        { label: 'Create Payment Request', link: '/Majura/PaymentRequest/Create/' + sJobTypeId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Payment Request', link: '/Majura/PaymentRequest', style: '' },
        { label: 'Detail Payment Request', link: '/Majura/PaymentRequest/' + sJobTypeId + '/' + prId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        let id = prId ? prId : 0;
        let eta = defaultDate;
        let etd = defaultDate;
        let paidUSDDate = updateDateFormat(datePaidUSD, true);
        let paidIDRDate = updateDateFormat(datePaidIDR, true);
        let deletedDate = updateDateFormat(deletedOn, true);
        let approveOPRDate = updateDateFormat(approveOprOn, true);
        let approveAccDate = updateDateFormat(approveAccOn, true);
        let printedDate = updateDateFormat(printedOn, true);
        let approveDate = updateDateFormat(approvedOn, true);
        let approveMarketingDate = updateDateFormat(approvedMarketingOn, true);
        let exRateDate = updateDateFormat(ExRateDate, true);

        if (ET) {
            if (isExport === false) {
                etd = ET;
            }
            else if (isImport === false) {
                eta = ET;
            }
        }

        var data = {
            "paymentRequest": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": rowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": ticketId,
                "prNo": PRNo,
                "debetCredit": debetCredit,
                "shipmentId": shipmentId,
                "shipmentNo": shipmentNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "eplId": EPLId,
                "jobTypeId": parseInt(idJobType),
                "jobTypeCode": jobTypeCode,
                "etd": etd,
                "eta": eta,
                "reference": reference,
                "prStatus": PRStatus,
                "isGeneral": isGeneralPR,
                "customerId": parseInt(CustomerId),
                "customerName": customerName,
                "customerTypeId": parseInt(CustomerTypeId),
                "personalId": parseInt(personalId),
                "personalName": personalName,
                "paymentUSD": TotalPaymentUSD,
                "paymentIDR": TotalPaymentIDR,
                "prContraStatus": PRContraStatus,
                "prContraNo": PRContraNo,
                "paidUSD": paidUSD,
                "datePaidUSD": paidUSDDate,
                "paidIDR": paidIDR,
                "datePaidIDR": paidIDRDate,
                "deleted": deleted,
                "deletedOn": deletedDate,
                "approveOpr": approveOpr,
                "approveOprOn": approveOPRDate,
                "approveAcc": approveAcc,
                "approveAccOn": approveAccDate,
                "rate": RateReal,
                "exRateDate": exRateDate,
                "printing": printing,
                "printedOn": printedDate,
                "prNo2": PRNo2,
                "exRateId": ExRateId,
                "deletedRemarks": deletedRemarks,
                "isCostToCost": isCostToCost,
                "totalPpnUSD": TotalPPNUSDReal,
                "totalPpnIDR": TotalPPNIDRReal,
                "uniqueKeyPR": uniqueKeyPR,
                "packingListNo": packingListNo,
                "siCustomerNo": SICustomerNo,
                "jobOwnerId": PrincipleBy,
                "vendorDN": vendorDN,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "approved": approved,
                "approvedOn": approveDate,
                "approvedBy": approvedBy,
                "approvedRemarks": approvedRemarks,
                "approvedMarketing": approvedMarketing,
                "approvedMarketingOn": approveMarketingDate,
                "approvedMarketingBy": approvedMarketingBy,
                "pvId": PVId,
                "isPosted": isPosted,
                "requester": requester,

                "idLama": 0,
            },
            "paymentRequestDetails": PaymentRequestDetail,
        };

        localStorage.setItem(username + "-DataPR", JSON.stringify(data));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal();
        }
    }));

    useEffect(() => {
        localStorage.setItem('isClosedEPL', JSON.stringify(false))
        document.title = 'Payment Request - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleDocumentStatus(rowStatus, isApproved = false, isPosted = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else if (isPosted) {
            setDocumentStatus('PREVIEW');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getDataLocal = () => {
        if (prId) {
            setIsEdit(true);
        }

        let typeJob;
        let dataDetailPR = dataLocal.paymentRequestDetails;
        let dataPR = dataLocal.paymentRequest;

        if (dataPR.pvId !== 0) {
            setIsViewOnly(true);
        }

        setRowStatus(dataPR.rowStatus);
        setInfossNo(dataPR.noInfoss);
        handleDocumentStatus(dataPR.rowStatus, dataPR.approveAcc, dataPR.printing > 0)
        setBookingConfirmationId(dataPR.bookingId);
        setBookingConfirmationNumber(dataPR.bookingNo);
        setTicketId(dataPR.ticketId);
        setJobTypeCode(dataPR.jobTypeCode);
        setPRNo(dataPR.prNo);
        setDebetCredit(dataPR.debetCredit);
        setShipmentId(dataPR.shipmentId);
        setShipmentNo(dataPR.shipmentNo);
        setPRStatus(dataPR.prStatus);
        setIsGeneralPR(dataPR.isGeneral);
        setCustomerId(dataPR.customerId);
        setCustomerName(dataPR.customerName);
        setCustomerTypeId(dataPR.customerTypeId);
        setPersonalId(dataPR.personalId);
        setPersonalName(dataPR.personalName);
        setPRContraStatus(dataPR.prContraStatus);
        setPRContraNo(dataPR.prContraNo);
        setPaidUSD(dataPR.paidUSD);
        setPaidIDR(dataPR.paidIDR);
        setDeleted(dataPR.deleted);
        setApproveOpr(dataPR.approveOpr);
        setApproveAcc(dataPR.approveAcc);
        setIsPosted(dataPR.isPosted);
        if (isContra !== 'true') {
            setPRNo2(dataPR.prNo2);
            setReference(dataPR.reference);

            setPrinting(dataPR.printing);

            setDateFormat(dataPR.printedOn, setPrintedOn);
        }
        else {
            setOriginalPRId(dataPR.id);
            setOriginalPRNo(dataPR.prNo2);
        }
        setDeletedRemarks(dataPR.deletedRemarks);
        setIsCostToCost(dataPR.isCostToCost);
        setUniqueKeyPR(dataPR.uniqueKeyPR);
        setPackingListNo(dataPR.packingListNo);
        setSICustomerNo(dataPR.siCustomerNo);
        setVendorDN(dataPR.vendorDN);
        setApproved(dataPR.approved);
        setApprovedBy(dataPR.approvedBy);
        setApprovedRemarks(dataPR.approvedRemarks);
        setApprovedMarketing(dataPR.approvedMarketing);
        setApprovedMarketingBy(dataPR.approvedMarketingBy);
        setPrincipleBy(dataPR.jobOwnerId);

        let count = convertToNominal(dataPR.rate);
        setRate(count);
        setRateReal(dataPR.rate);
        setExRateId(dataPR.exRateId);
        setEPLId(dataPR.eplId);
        setRequester(dataPR.requester);
        setPVId(dataPR.pvId);

        if (dataPR.printing > 0 && isContra === 'false') {
            setIsViewOnly(true);
        }

        setVolume20(dataPR.shipVol20Amount);
        if (dataPR.shipVol20Amount > 0) {
            setIsVolume20(true);
        }

        setVolume40(dataPR.shipVol40Amount);
        if (dataPR.shipVol40Amount > 0) {
            setIsVolume40(true);
        }

        setVolume45(dataPR.shipVol45Amount);
        if (dataPR.shipVol45Amount > 0) {
            setIsVolume45(true);
        }

        setVolumeCBM(dataPR.shipVolCBMAmount);
        if (dataPR.shipVolCBMAmount > 0) {
            setIsVolumeCBM(true);
        }

        setDateFormat(dataPR.etd, setET);
        setDateFormat(dataPR.eta, setET);
        setDateFormat(dataPR.datePaidUSD, setDatePaidUSD);
        setDateFormat(dataPR.datePaidIDR, setDatePaidIDR);
        setDateFormat(dataPR.deletedOn, setDeletedOn);
        setDateFormat(dataPR.approveOprOn, setApproveOprOn);
        setDateFormat(dataPR.approveAccOn, setApproveAccOn);
        setDateFormat(dataPR.approvedOn, setApprovedOn);
        setDateFormat(dataPR.approvedMarketingOn, setApprovedMarketingOn);
        setDateFormat(dataPR.exRateDate, setExRateDate);

        setPaymentRequestDetail(dataDetailPR);

        calculateTotal(dataDetailPR);
        GetContainerSizeData(1, 100, dataPR.shipmentId);

        ApiService.PostById(paramRegJobType, dataPR.jobTypeId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                    typeJob = response.data.data.jobType;
                }
            })
            .then(() => {
                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })

        localStorage.removeItem(username + '-DataPR');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id, typeJob) => {
        let dataPRDetail = [];
        ApiService.OperationPostById(paramPaymentRequest, id)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);

                    if (response.data.data.paymentRequest.pvId) {
                        setIsViewOnly(true);
                    }

                    setRowStatus(response.data.data.paymentRequest.rowStatus);
                    setInfossNo(response.data.data.paymentRequest.noInfoss);
                    handleDocumentStatus(response.data.data.paymentRequest.rowStatus, response.data.data.paymentRequest.approveAcc, response.data.data.paymentRequest.printing > 0)
                    setBookingConfirmationId(response.data.data.paymentRequest.bookingId);
                    setBookingConfirmationNumber(response.data.data.paymentRequest.bookingNo);
                    setTicketId(response.data.data.paymentRequest.ticketId);
                    setPRNo(response.data.data.paymentRequest.prNo);
                    setJobTypeCode(response.data.data.paymentRequest.jobTypeCode);
                    setDebetCredit(response.data.data.paymentRequest.debetCredit);
                    setShipmentId(response.data.data.paymentRequest.shipmentId);
                    setShipmentNo(response.data.data.paymentRequest.shipmentNo);
                    setPRStatus(response.data.data.paymentRequest.prStatus);
                    setIsGeneralPR(response.data.data.paymentRequest.isGeneral);
                    setCustomerId(response.data.data.paymentRequest.customerId);
                    setCustomerName(response.data.data.paymentRequest.customerName);
                    setCustomerTypeId(response.data.data.paymentRequest.customerTypeId);
                    setPersonalId(response.data.data.paymentRequest.personalId);
                    setPersonalName(response.data.data.paymentRequest.personalName);
                    setPRContraStatus(response.data.data.paymentRequest.prContraStatus);
                    setPRContraNo(response.data.data.paymentRequest.prContraNo);
                    setPaidUSD(response.data.data.paymentRequest.paidUSD);
                    setPaidIDR(response.data.data.paymentRequest.paidIDR);
                    setDeleted(response.data.data.paymentRequest.deleted);
                    setApproveOpr(response.data.data.paymentRequest.approveOpr);
                    setApproveAcc(response.data.data.paymentRequest.approveAcc);
                    setIsPosted(response.data.data.paymentRequest.isPosted);

                    if (isContra !== 'true') {
                        setPRNo2(response.data.data.paymentRequest.prNo2);
                        setReference(response.data.data.paymentRequest.reference);

                        setPrinting(response.data.data.paymentRequest.printing);

                        setDateFormat(response.data.data.paymentRequest.printedOn, setPrintedOn);
                    }
                    else {
                        setOriginalPRId(response.data.data.paymentRequest.id);
                        setOriginalPRNo(response.data.data.paymentRequest.prNo2);
                        handleDocumentStatus(response.data.data.paymentRequest.rowStatus)
                    }

                    setDeletedRemarks(response.data.data.paymentRequest.deletedRemarks);
                    setIsCostToCost(response.data.data.paymentRequest.isCostToCost);
                    setUniqueKeyPR(response.data.data.paymentRequest.uniqueKeyPR);
                    setPackingListNo(response.data.data.paymentRequest.packingListNo);
                    setSICustomerNo(response.data.data.paymentRequest.siCustomerNo);
                    setVendorDN(response.data.data.paymentRequest.vendorDN);
                    setApproved(response.data.data.paymentRequest.approved);
                    setApprovedBy(response.data.data.paymentRequest.approvedBy);
                    setApprovedRemarks(response.data.data.paymentRequest.approvedRemarks);
                    setApprovedMarketing(response.data.data.paymentRequest.approvedMarketing);
                    setApprovedMarketingBy(response.data.data.paymentRequest.approvedMarketingBy);
                    setPrincipleBy(response.data.data.paymentRequest.jobOwnerId);
                    setPVId(response.data.data.paymentRequest.pvId);

                    let count = convertToNominal(response.data.data.paymentRequest.rate);
                    setRate(count);
                    setRateReal(response.data.data.paymentRequest.rate);
                    setExRateId(response.data.data.paymentRequest.exRateId);
                    setEPLId(response.data.data.paymentRequest.eplId);
                    setRequester(response.data.data.paymentRequest.requester);

                    setVolume20(response.data.data.paymentRequest.shipVol20Amount);
                    if (response.data.data.paymentRequest.shipVol20Amount > 0) {
                        setIsVolume20(true);
                    }

                    setVolume40(response.data.data.paymentRequest.shipVol40Amount);
                    if (response.data.data.paymentRequest.shipVol40Amount > 0) {
                        setIsVolume40(true);
                    }

                    setVolume45(response.data.data.paymentRequest.shipVol45Amount);
                    if (response.data.data.paymentRequest.shipVol45Amount > 0) {
                        setIsVolume45(true);
                    }

                    setVolumeCBM(response.data.data.paymentRequest.shipVolCBMAmount);
                    if (response.data.data.paymentRequest.shipVolCBMAmount > 0) {
                        setIsVolumeCBM(true);
                    }

                    if (response.data.data.paymentRequest.printing > 0 && isContra === 'false') {
                        setIsViewOnly(true);
                    }

                    if (typeJob.serviceType === 'Export') {
                        setDateFormat(response.data.data.paymentRequest.etd, setET);
                    }
                    else {
                        setDateFormat(response.data.data.paymentRequest.eta, setET);
                    }

                    setDateFormat(response.data.data.paymentRequest.datePaidUSD, setDatePaidUSD);
                    setDateFormat(response.data.data.paymentRequest.datePaidIDR, setDatePaidIDR);
                    setDateFormat(response.data.data.paymentRequest.deletedOn, setDeletedOn);
                    setDateFormat(response.data.data.paymentRequest.approveOprOn, setApproveOprOn);
                    setDateFormat(response.data.data.paymentRequest.approveAccOn, setApproveAccOn);
                    setDateFormat(response.data.data.paymentRequest.approvedOn, setApprovedOn);
                    setDateFormat(response.data.data.paymentRequest.approvedMarketingOn, setApprovedMarketingOn);
                    setDateFormat(response.data.data.paymentRequest.exRateDate, setExRateDate);

                    GetContainerSizeData(1, 100, response.data.data.paymentRequest.shipmentId);

                    dataPRDetail = response.data.data.paymentRequest.paymentRequestDetails;

                    dataPRDetail.forEach(el => {
                        let paidOn = updateDateFormat(el.paidOn);
                        if (paidOn === nullDate || paidOn === '') {
                            el.paidOn = defaultDate;
                        }

                        let deliveryDate = updateDateFormat(el.deliveryDate);
                        if (deliveryDate === nullDate || deliveryDate === '') {
                            el.deliveryDate = defaultDate;
                        }

                        let fakturDate = updateDateFormat(el.fakturDate);
                        if (fakturDate === nullDate || fakturDate === '') {
                            el.fakturDate = defaultDate;
                        }
                    });

                    setPaymentRequestDetail(dataPRDetail);
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + response.data.message });
                    Back();
                }
            })
            .then(() => {
                calculateTotal(dataPRDetail);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error.message });
            })
    };

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OPARE')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (prId) {
                                if (element.accessCode === 'UPD' && element.hidden === false) {
                                    checked = 1;
                                }
                                if (element.accessCode === 'ACD' && isContra === 'false') {
                                    setIsAcc(true);
                                }
                            }
                            else {
                                if (element.accessCode === 'CRE') {
                                    checked = 1;
                                }
                            }
                        });

                        setCheck(checked);

                        if (hasAccess) {
                            GetJobOwnerList(1, 20);
                        }
                    }
                    else {
                        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                    }
                })
                .catch(function (error) {

                    setIsLoadingBackDrop(false)
                    console.error('error saat fetch', error);
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobType = (id, eta, etd) => {
        let typeJob = '';
        let dataId = '';
        if (id === undefined) {
            dataId = sJobTypeId;
        }
        else {
            dataId = id;
        }
        setTypeJobId(dataId);

        ApiService.PostById(paramRegJobType, dataId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                    setJobTypeCode(response.data.data.jobType.code);
                    setServiceType(response.data.data.jobType.serviceType);
                    setPortType(response.data.data.jobType.portType);
                    typeJob = response.data.data.jobType;
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }
            })
            .then(() => {
                if (!eplId) {
                    if (prId) {
                        getData(prId, typeJob);
                    }
                    else {
                        if (!dataLocal) {
                            setIsLoadingBackDrop(false);
                            handleDocumentStatus('ACT', false);
                        }
                    }
                }
                else {
                    if (typeJob.serviceType === 'Export') {
                        setDateFormat(etd, setET);
                    }
                    else {
                        setDateFormat(eta, setET);
                    }
                    handleDocumentStatus('ACT', false);
                }
                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    function checkExportImport(jobType) {
        if (jobType.portType === 'SEA') {
            setCustomerTypeId('4');
            setCustomer('SSLine');
            setIsSEA(false);
            setIsAIR(true);
        }
        else if (jobType.portType === 'AIR') {
            setCustomerTypeId('5');
            setCustomer('IATA');
            setIsSEA(true);
            setIsAIR(false);
        }
        else {
            setCustomerTypeId('4');
            setCustomer('SSLine');
            setIsSEA(false);
            setIsAIR(false);
        }

        if (jobType.serviceType === 'Export') {
            setIsExport(false);
            setIsImport(true);
        }
        else if (jobType.serviceType === 'Import') {
            setIsExport(true);
            setIsImport(false);
        }
        else {
            setIsExport(false);
            setIsImport(false);
        }
    }

    const GetEPLData = (id) => {
        if (id !== null || id !== '') {
            // let params = [
            //     {
            //         "attr": "IsForEPL",
            //         "value": "1"
            //     },
            //     {
            //         "attr": "EPLClosingDate",
            //         "value": getDefaultDateFormat(new Date())
            //     }
            // ]

            let eplData = [];
            ApiService.OperationPostById(paramEPL, id)
                .then((response) => {
                    if (response.data.code === 200) {
                        eplData = response.data.data.estimateProfitLoss;
                    }
                    else {
                        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                    }
                })
                .then(() => {
                    if (eplData?.yearPeriod !== undefined) {
                        ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, getDefaultDateFormat(new Date()))
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let data = response.data.data.closingPeriod;

                                    if (eplData.yearPeriod === data.yearPeriod) {
                                        if (eplData.period === data.period) {
                                            let currentDate = new Date();
                                            let dayOfMonth = currentDate.getDate();
                                            let currentMonth = currentDate.getMonth();
                                            if (currentMonth > eplData.period) {
                                                if (dayOfMonth > 5) {
                                                    setIsClosed(true);
                                                    localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                                }
                                                else {
                                                    setIsClosed(false);
                                                    localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                                }
                                            }
                                            else {
                                                setIsClosed(false);
                                                localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                            }
                                        }
                                        else if (eplData.period < data.period) {
                                            setIsClosed(true);
                                            localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                        }
                                        else {
                                            setIsClosed(false);
                                            localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                        }
                                    }
                                    else {
                                        setIsClosed(true);
                                        localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                    }
                                }
                            })
                            .catch(function (error) {
                                showToast({ type: 'error', message: error.message });
                            })

                        setEPLId(eplData.id);
                        setTicketId(eplData.ticketId);
                        setShipmentId(eplData.shipmentId);
                        GetContainerSizeData(1, 100, eplData.shipmentId);
                        setShipmentNo(eplData.shipmentNo);
                        setPrincipleBy(eplData.jobOwnerId);
                        setRequester(eplData.requester);
                        setPeriod(eplData.period);
                        setYearPeriod(eplData.yearPeriod);

                        setBookingConfirmationId(eplData.bookingId);
                        setBookingConfirmationNumber(eplData.bookingNo);

                        setVolume20(eplData.shipVol20Amount);
                        if (eplData.shipVol20Amount > 0) {
                            setIsVolume20(true);
                        }

                        setVolume40(eplData.shipVol40Amount);
                        if (eplData.shipVol40Amount > 0) {
                            setIsVolume40(true);
                        }

                        setVolume45(eplData.shipVol45Amount);
                        if (eplData.shipVol45Amount > 0) {
                            setIsVolume45(true);
                        }

                        setVolumeCBM(eplData.shipVolCBMAmount);
                        if (eplData.shipVolCBMAmount > 0) {
                            setIsVolumeCBM(true);
                        }

                        GetDataCustomer();

                        GetJobType(eplData.jobTypeId, eplData.eta, eplData.etd);
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                })
        }
    };

    const GetDataCustomer = () => {
        let params = [
            { 'attr': 'contactId', 'value': '' + customerId },
            { 'attr': 'contactTypeId', 'value': '' + customerTypeId }
        ];
        ApiService.DynamicPostByPage(paramContact, 'ID', 1, 1, params, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setCustomerId(response.data.data.contact[0].contactId);
                    setCustomerName(response.data.data.contact[0].contactName);

                    let customerTo = [
                        { id: 2, code: '4', name: 'SSLine' },
                        { id: 3, code: '5', name: 'IATA' },
                        { id: 4, code: '6', name: 'EMKL' },
                        { id: 5, code: '2', name: 'Discount Shipper' },
                        { id: 6, code: '3', name: 'Discount Consignee' },
                        { id: 7, code: '7', name: 'Depo' },
                    ];

                    customerTo.forEach(element => {
                        if (element.code === customerTypeId) {
                            setCustomerTypeId(element.code);
                            setCustomer(element.name);
                        }
                    });

                    getDataDetail(eplId, response.data.data.contact[0].contactId);
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const getDataDetail = (eplId, id, isCostToCost = false) => {
        let params = [];
        params = [
            ...params,
            { 'attr': 'eplId', 'value': '' + eplId },
            { 'attr': 'customerId', 'value': '' + id },
            { 'attr': 'isIncome', 'value': '' + false },
            { 'attr': 'refId', 'value': '' + 0 },
        ];
        if (!isCostToCost) {
            params = [
                ...params,
                { 'attr': 'isCostToCost', 'value': '' + isCostToCost },
            ];
        }
        let arrayData = [];
        let dataArray = [];
        ApiService.OperationPostByDetail(paramEPL, 'SEARCH', 1, 200, params)
            .then((response) => {
                if (response.data.code === 200) {
                    arrayData = response.data.data.estimateProfitLossDetail;

                    let Sequence = 1;
                    let payReqId = 0;
                    let delivery = '';

                    let amount = 0;

                    if (prId !== undefined || prId !== null) {
                        payReqId = prId;

                        if (payReqId === undefined) {
                            payReqId = 0;
                        }
                    }
                    else {
                        payReqId = 0;
                    }

                    if (arrayData.length > 0) {
                        setIsGeneralPR(false);

                        for (let i = 0; i < arrayData.length; i++) {
                            if (arrayData[i].isIncome !== true && arrayData[i].rowStatus === 'ACT' && arrayData[i].isIgnoreItem === false) {
                                if (arrayData[i].amountCrr === 0) {
                                    amount = parseFloat(arrayData[i].amountUSD);
                                }
                                else if (arrayData[i].amountCrr === 1) {
                                    amount = parseFloat(arrayData[i].amountIDR);
                                }

                                if (arrayData[i].deliveryDate !== '0001-01-01T00:00:00') {
                                    delivery = arrayData[i].deliveryDate;
                                }
                                else {
                                    delivery = '1785-12-06T10:51:29.426Z';
                                }

                                let data = {
                                    "countryId": countryId,
                                    "companyId": companyId,
                                    "branchId": branchId,
                                    "userId": userId,
                                    "userCode": userCode,

                                    "rowStatus": "ACT",
                                    "prId": payReqId,
                                    "sequence": Sequence,
                                    "debetCredit": 'C',
                                    "accountId": arrayData[i].accountId,
                                    "accountCode": arrayData[i].accountCode,
                                    "accountName": arrayData[i].accountName,
                                    "coaCode": arrayData[i].coaCode,
                                    "coaName": arrayData[i].coaName,
                                    "description": arrayData[i].description,
                                    "type": arrayData[i].type,
                                    "containerSize": arrayData[i].containerSize,
                                    "formatContainer": arrayData[i].formatContainer,
                                    "containerTypeId": arrayData[i].containerTypeId,
                                    "containerTypeCode": arrayData[i].containerTypeCode,
                                    "containerTypeName": arrayData[i].containerTypeName,
                                    "isContainer": arrayData[i].isContainer,
                                    "codingQuantity": arrayData[i].codingQuantity,
                                    "quantity": parseFloat(arrayData[i].quantity),
                                    "perQty": parseFloat(arrayData[i].perQty),
                                    "amount": parseFloat(amount),
                                    "amountCrr": arrayData[i].amountCrr,
                                    "paid": false,
                                    "paidOn": '1785-12-06T10:51:29.426Z',
                                    "paidPV": false,
                                    "eplDetailId": arrayData[i].id,
                                    "statusMemo": arrayData[i].statusMemo,
                                    "memoNo": arrayData[i].memoNo,
                                    "isCostToCost": arrayData[i].isCostToCost,
                                    "isPpn": false,
                                    "persenPpn": 0,
                                    "fakturNo": '',
                                    "fakturDate": '1785-12-06T10:51:29.426Z',
                                    "isCostTrucking": arrayData[i].isCostTrucking,
                                    "kendaraanId": arrayData[i].kendaraanId,
                                    "kendaraanNopol": arrayData[i].kendaraanNopol,
                                    "employeeId": arrayData[i].employeeId,
                                    "employeeName": arrayData[i].employeeName,
                                    "mrgId": arrayData[i].mrgId,
                                    "deliveryDate": delivery,
                                    "originalUsd": parseFloat(arrayData[i].originalUsd),
                                    "originalRate": parseFloat(arrayData[i].originalRate),

                                    "idLama": 0,
                                }

                                dataArray = [...dataArray, data];

                                Sequence = parseInt(Sequence) + parseInt(1);
                            }
                        }
                        setPaymentRequestDetail(dataArray);

                        calculateTotal(dataArray);
                    }
                    else {
                        let isClosedEPL = JSON.parse(localStorage.getItem('isClosedEPL'));
                        if (isClosedEPL === true) {
                            setIsGeneralPR(true);
                        }
                        else {
                            showToast({ type: 'error', message: 'EPL Still Open, Please Input Detail on EPL!' });
                        }

                        setPaymentRequestDetail(dataArray);

                        calculateTotal(dataArray);
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                props.setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const GetExRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);

                    let count = convertToNominal(response.data.data.exchangeRate.exRate1);
                    setRate(count);
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setExRateDate(getDefaultDateFormat(response.data.data.exchangeRate.exRateDate));

                    GetCurrencyFormat();
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const GetCurrencyFormat = () => {
        let formatUSD = convertToNominal(0);
        let formatIDR = convertToNominal(0);
        setTotalPPNUSD(formatUSD);
        setTotalPPNIDR(formatIDR);

        GetVATData(1, 20);
    }

    //Function for Principle By
    const GetJobOwnerList = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobOwnerList(response.data.data.jobOwner);

                    GetExRate();
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const selectPrincipleBy = () => (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                value={PrincipleBy}
                fullWidth={true}
                placeholder={'Select Principle By'}
                onChange={(e) => setPrincipleBy(e.target.value)}
                disabled
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetVATData = (pageNumber, pageSize) => {
        let arrayData = [{ "id": 0, "value": 0.00 }];
        ApiService.PostByPage(paramVAT, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    for (let i = 0; i < response.data.data.vat.length; i++) {
                        arrayData = [...arrayData, response.data.data.vat[i]];
                    }
                    setVATData(arrayData);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        if (eplId) {
                            GetEPLData(eplId);
                        }
                        else {
                            GetJobType();
                        }
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const selectVATData = (Vat, setVat, isDisabled) => (
        <>
            <Selection
                id='0'
                label={'Include VAT'}
                fullWidth={true}
                placeholder={'Select VAT'}
                value={Vat}
                onChange={(e) => setVat(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px'
                    }
                }}
                disabled={isDisabled}
            >
                {
                    VATData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.value}%</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetContainerSizeData = (pageNumber, pageSize, shipmentId, filter) => {
        let params = [];
        params = [
            {
                'attr': 'shipmentId',
                'value': '' + shipmentId,
                'isDistinct': true
            },
            {
                'attr': 'containerTypeId',
                'value': '',
                'isDistinct': true
            },
            {
                'attr': 'containerSize',
                'value': '',
                'isDistinct': true
            }
        ]
        ApiService.ShipmentSeaContainerPostBySearch(paramShipmentOrder, pageNumber, pageSize, params, filter)
            .then((response) => {
                if (response.data.code === 200) {
                    setContainerSizeData(response.data.data.shipmentOrderSeaContainer);
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsAllJob(false);
            setIsDraft(true);
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationPR() {
        if (titleConfirmation === 'Print') {
            checkDataCondition('Print');
        }
        else if (titleConfirmation === 'Approve Accounting Manager') {
            ApproveAccManagerData();
        }
        setIsModalConfirmation(false);
    }

    const checkDataCondition = (isAction) => {
        if (prId) {
            setIsLoadingBackDrop(true);
            ApiService.OperationPostById(paramPaymentRequest, prId)
                .then((response) => {
                    if (response.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        let data = response.data.data.paymentRequest;

                        const handleCheckPrint = () => {
                            if (isDraft === false) {
                                let check = 0;
                                if (data.isGeneral === true && data.approved === false) {
                                    check = 1;
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'General PR Need To Be Approved First!' });
                                }

                                if (check === 0) {
                                    PrintPR();
                                }
                            }
                            else {
                                let reportLink = 'Transactions/Operation/PaymentRequestReport/PaymentRequestReport/PrintPaymentRequest?Id=' + prId
                                    + '&isDraft=' + isDraft + '&isAllJob=' + isAllJob;
                                PrintPDF(setIsLoadingBackDrop, 'Payment Request', reportLink, [], true);
                            }
                        }

                        if (isAction === 'Save') {
                            if (data.approveAcc) {
                                setIsLoadingBackDrop(true);
                                showToast({ type: 'error', message: 'Payment Request Already Been Approved!' });
                                setTimeout(
                                    function () {
                                        setIsLoadingBackDrop(false);
                                        history('/Majura/PaymentRequest/' + false + '/' + sJobTypeId + '/' + prId, { replace: true });
                                        window.location.reload();
                                    },
                                    1250);
                            }
                            else {
                                if (data.printing > 0) {
                                    getData(prId, jobType);
                                    showToast({ type: 'error', message: 'Payment Request Already Printed!' });
                                } else {
                                    Save(false);
                                }
                            }
                        }
                        else if (isAction === 'Print') {
                            if (data.printing === 0) {
                                Save(true);
                            }
                            else {
                                getData(prId, jobType);
                                handleCheckPrint();
                            }
                        }
                        else if (isAction === 'CheckPrint') {
                            handleCheckPrint();
                        }
                        else if (isAction === 'PrintPDF') {
                            setTitleConfirmation('Print')
                            setTitleModalConfirmation('Payment Request');
                            handleClickConfirmation();
                        }
                        else if (isAction === 'Approve Gen') {
                            if (data.approveAcc === true) {
                                setIsLoadingBackDrop(true);
                                showToast({ type: 'error', message: 'Payment Request Already Been Approved!' });
                                setTimeout(
                                    function () {
                                        setIsLoadingBackDrop(false);
                                        history('/Majura/PaymentRequest/' + false + '/' + sJobTypeId + '/' + prId, { replace: true });
                                        window.location.reload();
                                    },
                                    1250);
                            }
                            else {
                                if (data.isGeneral === true) {
                                    if (data.approved === false) {
                                        handleClickGPR();
                                    }
                                    else {
                                        getData(prId, jobType);
                                        showToast({ type: 'error', message: 'General Payment Request Already Been Approved!' });
                                    }
                                }
                                else {
                                    getData(prId, jobType);
                                    showToast({ type: 'error', message: 'Only For General Payment Request!' });
                                }
                            }
                        }
                        else if (isAction === 'Approve Acc') {
                            if (data.approveAcc === false) {
                                if (data.printing > 0) {
                                    setTitleConfirmation('Approve Accounting Manager')
                                    setTitleModalConfirmation('Payment Request');
                                    handleClickConfirmation();
                                }
                                else {
                                    showToast({ type: 'error', message: 'Please Print Payment Request First!' });
                                }
                            }
                            else {
                                setIsLoadingBackDrop(true);
                                showToast({ type: 'error', message: 'Payment Request Already Been Approved!' });
                                setTimeout(
                                    function () {
                                        setIsLoadingBackDrop(false);
                                        history('/Majura/PaymentRequest/' + false + '/' + sJobTypeId + '/' + prId, { replace: true });
                                        window.location.reload();
                                    },
                                    1250);
                            }
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Error When Read Data!, ' + response.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Read Data!, ' + error });
                })
        }
        else if (isAction === 'Save') {
            Save();
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    function Save(isPrint = false) {
        if (isLoadingBackDrop) return
        setIsLoadingBackDrop(true);

        let id = prId ? prId : 0;
        let eta = defaultDate;
        let etd = defaultDate;
        let paidUSDDate = updateDateFormat(datePaidUSD, true);
        let paidIDRDate = updateDateFormat(datePaidIDR, true);
        let deletedDate = updateDateFormat(deletedOn, true);
        let approveOPRDate = updateDateFormat(approveOprOn, true);
        let approveAccDate = updateDateFormat(approveAccOn, true);
        let printedDate = updateDateFormat(printedOn, true);
        let approveDate = updateDateFormat(approvedOn, true);
        let approveMarketingDate = updateDateFormat(approvedMarketingOn, true);
        let exRateDate = updateDateFormat(ExRateDate, true);

        if (ET) {
            if (isExport === false) {
                etd = ET;
            }
            else if (isImport === false) {
                eta = ET;
            }
        }

        let pDetail = PaymentRequestDetail;

        pDetail.forEach(element => {
            element.fakturDate = updateDateFormat(element.fakturDate, true);
            element.deliveryDate = updateDateFormat(element.deliveryDate, true);
            element.paidOn = updateDateFormat(element.paidOn, true);
        });

        var data = {
            "paymentRequest": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": rowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": ticketId,
                "prNo": PRNo,
                "debetCredit": debetCredit,
                "shipmentId": shipmentId,
                "shipmentNo": shipmentNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "eplId": EPLId,
                "jobTypeId": parseInt(idJobType),
                "jobTypeCode": jobTypeCode,
                "etd": etd,
                "eta": eta,
                "reference": reference,
                "prStatus": PRStatus,
                "isGeneral": isGeneralPR,
                "customerId": parseInt(CustomerId),
                "customerName": customerName,
                "customerTypeId": parseInt(CustomerTypeId),
                "personalId": parseInt(personalId),
                "originalPRId": originalPRId,
                "originalPRNo": originalPRNo,
                "personalName": personalName,
                "paymentUSD": TotalPaymentUSD,
                "paymentIDR": TotalPaymentIDR,
                "prContraStatus": PRContraStatus,
                "prContraNo": PRContraNo,
                "paidUSD": paidUSD,
                "datePaidUSD": paidUSDDate,
                "paidIDR": paidIDR,
                "datePaidIDR": paidIDRDate,
                "deleted": deleted,
                "deletedOn": deletedDate,
                "approveOpr": approveOpr,
                "approveOprOn": approveOPRDate,
                "approveAcc": approveAcc,
                "approveAccOn": approveAccDate,
                "rate": RateReal,
                "exRateDate": exRateDate,
                "printing": printing,
                "printedOn": printedDate,
                "prNo2": PRNo2,
                "exRateId": ExRateId,
                "deletedRemarks": deletedRemarks,
                "isCostToCost": isCostToCost,
                "totalPpnUSD": TotalPPNUSDReal,
                "totalPpnIDR": TotalPPNIDRReal,
                "uniqueKeyPR": uniqueKeyPR,
                "packingListNo": packingListNo,
                "siCustomerNo": SICustomerNo,
                "jobOwnerId": PrincipleBy,
                "vendorDN": vendorDN,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "approved": approved,
                "approvedOn": approveDate,
                "approvedBy": approvedBy,
                "approvedRemarks": approvedRemarks,
                "approvedMarketing": approvedMarketing,
                "approvedMarketingOn": approveMarketingDate,
                "approvedMarketingBy": approvedMarketingBy,
                "requester": requester,

                "idLama": 0,
            },
            "paymentRequestDetails": pDetail,
        };

        let errorMessage = undefined;
        if (data.paymentRequestDetails.length === 0) {
            errorMessage = 'Please Add Payment Request Detail!';
        }
        else if (data.paymentRequest.noInfoss === '' && isInfossNo) {
            errorMessage = 'Please Fill Infoss Number!';
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(data, paramPaymentRequest, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (id === 0) {
                            ApiService.CreateOperation(paramPaymentRequest, data).then((res) => {
                                if (res.data.code === 200) {
                                    setIsLoadingBackDrop(false);

                                    message = 'Create Data Success'
                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Majura/PaymentRequest/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                        else {
                            if (isContra === undefined) {
                                ApiService.EditOperation(paramPaymentRequest, data).then((res) => {
                                    if (res.data.code === 200) {
                                        if (!isPrint) {
                                            setIsLoadingBackDrop(false);
                                            message = 'Update Data Success';
                                            showToast({ type: 'success', message: message });

                                            setTimeout(
                                                function () {
                                                    history('/Majura/PaymentRequest/' + sJobTypeId + '/' + prId, { replace: true });
                                                    window.location.reload();
                                                },
                                                1250);
                                        }
                                        else {
                                            checkDataCondition('CheckPrint');
                                        }
                                    }
                                    else {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: res.data.message });
                                    }
                                });
                            }
                            else {
                                var oldData = {};
                                let isContra = '';
                                let etd = '';
                                let eta = '';
                                let paidUSDDate = '';
                                let paidIDRDate = '';
                                let deletedDate = '';
                                let approveOPRDate = '';
                                let approveAccDate = '';
                                let printedDate = '';
                                let approveDate = '';
                                let approveMarketingDate = '';

                                ApiService.OperationPostById(paramPaymentRequest, id)
                                    .then((response) => {
                                        if (response.data.code === 200) {
                                            if (response.data.data.paymentRequest.printing > 0) {
                                                isContra = 'Edit';
                                            }

                                            etd = updateDateFormat(response.data.data.paymentRequest.etd, true);
                                            eta = updateDateFormat(response.data.data.paymentRequest.eta, true);
                                            paidUSDDate = updateDateFormat(response.data.data.paymentRequest.datePaidUSD, true);
                                            paidIDRDate = updateDateFormat(response.data.data.paymentRequest.datePaidIDR, true);
                                            deletedDate = updateDateFormat(response.data.data.paymentRequest.deletedOn, true);
                                            approveOPRDate = updateDateFormat(response.data.data.paymentRequest.approveOprOn, true);
                                            approveAccDate = updateDateFormat(response.data.data.paymentRequest.approveAccOn, true);
                                            printedDate = updateDateFormat(response.data.data.paymentRequest.printedOn, true);
                                            approveDate = updateDateFormat(response.data.data.paymentRequest.approvedOn, true);
                                            approveMarketingDate = updateDateFormat(response.data.data.paymentRequest.approvedMarketingOn, true);

                                            let pDetail = response.data.data.paymentRequest.paymentRequestDetails;

                                            pDetail.forEach(element => {
                                                element.fakturDate = updateDateFormat(element.fakturDate, true);
                                                element.deliveryDate = updateDateFormat(element.deliveryDate, true);
                                                element.paidOn = updateDateFormat(element.paidOn, true);
                                            });

                                            oldData = {
                                                "paymentRequest": {
                                                    "countryId": countryId,
                                                    "companyId": companyId,
                                                    "branchId": branchId,
                                                    "userId": userId,
                                                    "userCode": userCode,

                                                    "rowStatus": response.data.data.paymentRequest.rowStatus,
                                                    "id": id,
                                                    "ticketId": response.data.data.paymentRequest.ticketId,
                                                    "prNo": response.data.data.paymentRequest.prNo,
                                                    "debetCredit": response.data.data.paymentRequest.debetCredit,
                                                    "shipmentId": response.data.data.paymentRequest.shipmentId,
                                                    "shipmentNo": response.data.data.paymentRequest.shipmentNo,
                                                    "bookingId": response.data.data.paymentRequest.bookingId,
                                                    "bookingNo": response.data.data.paymentRequest.bookingNo,
                                                    "eplId": response.data.data.paymentRequest.eplId,
                                                    "jobTypeId": response.data.data.paymentRequest.jobTypeId,
                                                    "etd": etd,
                                                    "eta": eta,
                                                    "reference": response.data.data.paymentRequest.reference,
                                                    "prStatus": response.data.data.paymentRequest.prStatus,
                                                    "isGeneral": response.data.data.paymentRequest.isGeneral,
                                                    "customerId": response.data.data.paymentRequest.customerId,
                                                    "customerName": response.data.data.paymentRequest.customerName,
                                                    "customerTypeId": response.data.data.paymentRequest.customerTypeId,
                                                    "personalId": response.data.data.paymentRequest.personalId,
                                                    "personalName": response.data.data.paymentRequest.personalName,
                                                    "paymentUSD": response.data.data.paymentRequest.paymentUSD,
                                                    "paymentIDR": response.data.data.paymentRequest.paymentIDR,
                                                    "prContraStatus": isContra,
                                                    "prContraNo": response.data.data.paymentRequest.prContraNo,
                                                    "paidUSD": response.data.data.paymentRequest.paidUSD,
                                                    "datePaidUSD": paidUSDDate,
                                                    "paidIDR": response.data.data.paymentRequest.paidIDR,
                                                    "datePaidIDR": paidIDRDate,
                                                    "originalPRId": response.data.data.paymentRequest.originalPRId,
                                                    "originalPRNo": response.data.data.paymentRequest.originalPRNo,
                                                    "deleted": response.data.data.paymentRequest.deleted,
                                                    "deletedOn": deletedDate,
                                                    "approveOpr": response.data.data.paymentRequest.approveOpr,
                                                    "approveOprOn": approveOPRDate,
                                                    "approveAcc": response.data.data.paymentRequest.approveAcc,
                                                    "approveAccOn": approveAccDate,
                                                    "rate": response.data.data.paymentRequest.rate,
                                                    "exRateDate": response.data.data.paymentRequest.exRateDate,
                                                    "printing": response.data.data.paymentRequest.printing,
                                                    "printedOn": printedDate,
                                                    "prNo2": response.data.data.paymentRequest.prNo2,
                                                    "exRateId": response.data.data.paymentRequest.exRateId,
                                                    "deletedRemarks": response.data.data.paymentRequest.deletedRemarks,
                                                    "isCostToCost": response.data.data.paymentRequest.isCostToCost,
                                                    "totalPpnUSD": response.data.data.paymentRequest.totalPpnUSD,
                                                    "totalPpnIDR": response.data.data.paymentRequest.totalPpnIDR,
                                                    "uniqueKeyPR": response.data.data.paymentRequest.uniqueKeyPR,
                                                    "packingListNo": response.data.data.paymentRequest.packingListNo,
                                                    "siCustomerNo": response.data.data.paymentRequest.siCustomerNo,
                                                    "jobOwnerId": response.data.data.paymentRequest.jobOwnerId,
                                                    "vendorDN": response.data.data.paymentRequest.vendorDN,
                                                    "approved": response.data.data.paymentRequest.approved,
                                                    "approvedOn": approveDate,
                                                    "approvedBy": response.data.data.paymentRequest.approvedBy,
                                                    "approvedRemarks": response.data.data.paymentRequest.approvedRemarks,
                                                    "approvedMarketing": response.data.data.paymentRequest.approvedMarketing,
                                                    "approvedMarketingOn": approveMarketingDate,
                                                    "approvedMarketingBy": response.data.data.paymentRequest.approvedMarketingBy,
                                                    "requester": response.data.data.paymentRequest.requester,

                                                    "idLama": 0,
                                                },
                                                "paymentRequestDetails": pDetail,
                                            }

                                            ApiService.EditOperation(paramPaymentRequest, oldData).then((res) => {
                                                if (res.data.code === 200) {
                                                    var dataGL = {
                                                        "generalLedger": {
                                                            "source": "PR",
                                                            "refId": res.data.data.Id,
                                                            "methodPost": "AUTO",
                                                            "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
                                                        }
                                                    }

                                                    ApiService.CreateFinAcc(paramGL, dataGL).then((res) => {
                                                        let message = '';
                                                        message = res.data.message;
                                                        if (res.data.code === 200) {
                                                            ApiService.CreateOperation(paramPaymentRequest, data).then((res) => {
                                                                if (res.data.code === 200) {
                                                                    setIsLoadingBackDrop(false);

                                                                    message = 'Create New Data Success'
                                                                    showToast({ type: 'success', message: message });

                                                                    setTimeout(
                                                                        function () {
                                                                            history('/Majura/PaymentRequest/' + sJobTypeId + '/' + res.data.data.Id);
                                                                            window.location.reload();
                                                                        },
                                                                        1250);
                                                                }
                                                                else {
                                                                    setIsLoadingBackDrop(false);
                                                                    showToast({ type: 'error', message: res.data.message });
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            showToast({ type: 'error', message: message });
                                                        }
                                                    });
                                                }
                                                else {
                                                    setIsLoadingBackDrop(false);

                                                    message = 'Updated Data Contra Failed';
                                                    showToast({ type: 'error', message: message })
                                                }
                                            });
                                        }
                                    })
                                    .catch(function (error) {
                                        setIsLoadingBackDrop(false);

                                        message = 'Updated Data Contra Failed';
                                        showToast({ type: 'error', message: message })
                                    })
                            }
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    function Back() {
        if (!isToDo) {
            history('/Majura/PaymentRequest');
        }
        else {
            history('/Majura');
            window.location.reload();
        }
    }

    const ApproveAccManagerData = () => {
        ApiService.ApproveOperation(paramPaymentRequest, prId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                Back();
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    }

    function PrintPR() {
        setIsLoadingBackDrop(true);
        ApiService.OperationUpdateStatusPrint(paramPaymentRequest, prId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                if (printing === 0 && isDraft !== true) {
                    // CreateGL(prId);
                }

                let count = parseInt(printing) + parseInt(1);
                setPrinting(count);
                let reportLink = 'Transactions/Operation/PaymentRequestReport/PaymentRequestReport/PrintPaymentRequest?Id=' + prId
                    + '&isDraft=' + isDraft + '&isAllJob=' + isAllJob;
                PrintPDF(setIsLoadingBackDrop, 'Payment Request', reportLink, [], true);
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    }

    // const CreateGL = (id) => {
    //     var data = {
    //         "generalLedger": {
    //             "source": "PR",
    //             "refId": id,
    //             "methodPost": "AUTO",
    //             "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
    //         }
    //     }

    //     ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //         let message = '';
    //         message = res.data.message;
    //         if (res.data.code !== 200) {
    //             showToast({ type: 'error', message: message });
    //         }
    //     });
    // }

    function AddNew() {
        window.open('/Majura/PaymentRequest/Create/' + sJobTypeId, newWindowConfig);
    }

    const handleClickGPR = () => {
        if (isModalGPR === false) {
            setIsModalGPR(true);
        }
        else {
            setIsModalGPR(false);
        }
    }

    const ApproveGenPRData = (isApproveGPR, remarksGPR) => {
        setIsLoadingBackDrop(true);
        let data = {
            "paymentRequest": {
                "id": prId,
                "approved": isApproveGPR,
                "approvedRemarks": remarksGPR
            }
        }

        ApiService.ApproveGeneralOperation(paramPaymentRequest, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                setIsLoadingBackDrop(false);
                handleClickGPR();
                if (isApproveGPR === false) {
                    message = 'Reject General PR Success';
                }
                else {
                    message = 'Approve General PR Success';
                }

                showToast({ type: 'success', message: message });
                setTimeout(
                    function () {
                        history('/Majura/PaymentRequest/' + sJobTypeId + '/' + prId, { replace: true });
                        window.location.reload();
                    },
                    1250);
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    }

    function ApproveMKT() {

    }

    function calculateTotal(result) {
        let totalUSD = 0;
        let totalIDR = 0;
        let totalTaxUSD = 0;
        let totalTaxIDR = 0;

        for (let i = 0; i < result.length; i++) {
            if (result[i].amountCrr === 0) {
                totalUSD = parseFloat(totalUSD) + parseFloat(result[i].amount);

                if (result[i].isPpn === true) {
                    totalTaxUSD = parseFloat(totalTaxUSD) + (parseFloat(result[i].amount) * parseFloat(result[i].persenPpn) / parseFloat(100));
                }
            }
            else if (result[i].amountCrr === 1) {
                totalIDR = parseFloat(totalIDR) + parseFloat(result[i].amount);

                if (result[i].isPpn === true) {
                    totalTaxIDR = parseFloat(totalTaxIDR) + (parseFloat(result[i].amount) * parseFloat(result[i].persenPpn) / parseFloat(100));
                }
            }
        }

        setTotalPaymentUSD(totalUSD);
        setTotalPaymentIDR(totalIDR);
        setTotalPPNUSDReal(totalTaxUSD);
        setTotalPPNIDRReal(totalTaxIDR);
        setTotalPPNUSD(convertToNominal(totalTaxUSD));
        setTotalPPNIDR(convertToNominal(totalTaxIDR));
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditPaymentRequest
                Save={() => checkDataCondition('Save')}
                Back={Back}
                ApproveAccManager={() => checkDataCondition('Approve Acc')}
                PrintPR={() => checkDataCondition('PrintPDF')}
                AddNew={AddNew}
                ApproveGeneralPR={() => checkDataCondition('Approve Gen')}
                ApproveMKT={ApproveMKT}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isViewOnly={isViewOnly}
                isPosted={printing > 0}
                isGeneral={isGeneralPR}
                isEdit={isEdit}
                isContra={isContra}
                documentStatus={documentStatus}
                isAcc={isAcc}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            prId && isContra !== 'true' ?
                                'Payment Request - ' + jobType.name + ' (' + reference + ')'
                                :
                                'Payment Request - ' + jobType.name + ' (New Document)'
                        }
                        BreadcrumbsItems={prId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={ticketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <fieldset>
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <PaymentRequestHeader
                                    infossNo={infossNo}
                                    setInfossNo={setInfossNo}
                                    isExport={isExport}
                                    isImport={isImport}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    isSEA={isSEA}
                                    isAIR={isAIR}
                                    isEdit={isEdit}
                                    eplId={eplId}
                                    Customer={Customer}
                                    setCustomer={setCustomer}
                                    jobType={jobType}
                                    typeJobId={typeJobId}
                                    isClosed={isClosed}
                                    setIsClosed={setIsClosed}
                                    selectPrincipleBy={selectPrincipleBy}
                                    prId={prId}
                                    calculateTotal={calculateTotal}
                                    isContra={isContra}

                                    isGeneralPR={isGeneralPR}
                                    setIsGeneralPR={setIsGeneralPR}
                                    isCostToCost={isCostToCost}
                                    setIsCostToCost={setIsCostToCost}
                                    PRNo={PRNo2}
                                    reference={reference}
                                    setRequester={setRequester}
                                    ET={ET}
                                    setET={setET}
                                    setPrincipleBy={setPrincipleBy}
                                    Rate={Rate}
                                    ExRateDate={ExRateDate}
                                    setTicketId={setTicketId}
                                    printing={printing}
                                    printedOn={printedOn}
                                    shipmentId={shipmentId}
                                    setShipmentId={setShipmentId}
                                    shipmentNo={shipmentNo}
                                    setShipmentNo={setShipmentNo}
                                    EPLId={EPLId}
                                    setEPLId={setEPLId}
                                    period={period}
                                    setPeriod={setPeriod}
                                    yearPeriod={yearPeriod}
                                    setYearPeriod={setYearPeriod}
                                    vendorDN={vendorDN}
                                    setVendorDN={setVendorDN}
                                    customerTypeId={CustomerTypeId}
                                    setCustomerTypeId={setCustomerTypeId}
                                    customerId={CustomerId}
                                    setCustomerId={setCustomerId}
                                    customerName={customerName}
                                    setCustomerName={setCustomerName}
                                    personalId={personalId}
                                    setPersonalId={setPersonalId}
                                    personalName={personalName}
                                    setPersonalName={setPersonalName}

                                    volume20={volume20}
                                    setVolume20={setVolume20}
                                    volume40={volume40}
                                    setVolume40={setVolume40}
                                    volume45={volume45}
                                    setVolume45={setVolume45}
                                    volumeCBM={volumeCBM}
                                    setVolumeCBM={setVolumeCBM}
                                    isVolume20={isVolume20}
                                    setIsVolume20={setIsVolume20}
                                    isVolume40={isVolume40}
                                    setIsVolume40={setIsVolume40}
                                    isVolume45={isVolume45}
                                    setIsVolume45={setIsVolume45}
                                    isVolumeCBM={isVolumeCBM}
                                    setIsVolumeCBM={setIsVolumeCBM}

                                    PaymentRequestDetail={PaymentRequestDetail}
                                    setPaymentRequestDetail={setPaymentRequestDetail}

                                    bookingConfirmationId={bookingConfirmationId}
                                    setBookingConfirmationId={setBookingConfirmationId}
                                    bookingConfirmationNumber={bookingConfirmationNumber}
                                    setBookingConfirmationNumber={setBookingConfirmationNumber}
                                    GetContainerSizeData={GetContainerSizeData}
                                    getDataDetail={getDataDetail}
                                    isViewOnly={isViewOnly}
                                    documentStatus={documentStatus}
                                />
                            </CardContent>
                        </Card>

                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <PaymentRequestBeingForPayment
                                    isExport={isExport}
                                    isImport={isImport}
                                    selectVATData={selectVATData}
                                    VATData={VATData}
                                    isGeneralPR={isGeneralPR}
                                    setIsGeneralPR={setIsGeneralPR}
                                    isCostToCost={isCostToCost}
                                    isSEA={isSEA}
                                    isAIR={isAIR}
                                    CustomerId={CustomerId}
                                    EPLId={EPLId}
                                    isClosed={isClosed}
                                    Customer={Customer}
                                    prId={prId}
                                    jobType={jobType}
                                    isEdit={isEdit}
                                    PaymentRequestDetail={PaymentRequestDetail}
                                    setPaymentRequestDetail={setPaymentRequestDetail}
                                    calculateTotal={calculateTotal}
                                    isContra={isContra}
                                    isViewOnly={isViewOnly}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}

                                    volume20={volume20}
                                    setVolume20={setVolume20}
                                    volume40={volume40}
                                    setVolume40={setVolume40}
                                    volume45={volume45}
                                    setVolume45={setVolume45}
                                    volumeCBM={volumeCBM}
                                    setVolumeCBM={setVolumeCBM}
                                    isVolume20={isVolume20}
                                    setIsVolume20={setIsVolume20}
                                    isVolume40={isVolume40}
                                    setIsVolume40={setIsVolume40}
                                    isVolume45={isVolume45}
                                    setIsVolume45={setIsVolume45}
                                    isVolumeCBM={isVolumeCBM}
                                    setIsVolumeCBM={setIsVolumeCBM}

                                    TotalPaymentUSD={TotalPaymentUSD}
                                    TotalPaymentIDR={TotalPaymentIDR}
                                    TotalPPNUSD={TotalPPNUSD}
                                    TotalPPNIDR={TotalPPNIDR}
                                    paidUSD={paidUSD}
                                    setPaidUSD={setPaidUSD}
                                    paidIDR={paidIDR}
                                    setPaidIDR={setPaidIDR}
                                    containerSizeData={containerSizeData}

                                    serviceType={serviceType}
                                    portType={portType}
                                    documentStatus={documentStatus}
                                    isAcc={isAcc}
                                />
                            </CardContent>
                        </Card>
                    </fieldset>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationPR}

                            isDraft={isDraft}
                            setIsDraft={setIsDraft}
                            isAllJob={isAllJob}
                            setIsAllJob={setIsAllJob}
                        />
                    )
                }

                {
                    isModalGPR && (
                        <ModalApproveGeneral
                            isModal={isModalGPR}
                            handleClick={handleClickGPR}
                            ApproveGenPRData={ApproveGenPRData}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditPaymentRequest;