import { Box, Typography, Grid, Button } from '@mui/material';
import React, {
    memo,
    // useState 
} from 'react';
import {
    CustomDatePicker, Icon, PopUp,
    // TickBox 
} from '../../../../components/ui';

const ModalAddRestore = memo(({ titleModal, isModal, handleClick, restoreData, restoreTo, setRestoreTo, lastMonth, startMonth }) => {
    // const [isChecked, setIsChecked] = useState(true);
    const isChecked = true;

    const mainContainer = (
        <>
            <CustomDatePicker
                fullWidth
                type='month'
                value={restoreTo}
                label='Restore To'
                variant='standard'
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setRestoreTo(e)}
                formControlProps={{ sx: { width: '100%' } }}
                customDateProps={{
                    minDate: `${new Date(startMonth)}`,
                    maxDate: `${new Date(lastMonth)}`,
                }}
            />

            {/* <div style={{ marginTop: '24px' }}> // Will Be Commented Until Further Notice
                <TickBox
                    size={'small'}
                    label={
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #001A4F)',
                                fontFamily: 'Nunito',
                                fontSize: '14px !important',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '20px',
                                userSelect: 'none',
                            }}
                        >
                            I agree that login access for other users is restricted until I finish the process and manually re-enable it via this menu
                        </Typography>
                    }
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                />
            </div> */}
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#083A50',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={titleModal}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '24px 6px 16px' }}>
                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #0087C2)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #0087C2)',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleClick}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '1px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#0087C2',
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #0087C2)',
                                        background: !isChecked ? '#EAEAEA' : 'var(--Blue-Button-Primary-1, #0087C2)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #0087C2)',
                                            backgroundColor: !isChecked ? '#EAEAEA' : 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={restoreData}
                                    disabled={!isChecked}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: !isChecked ? '#A3A3A3' : '#E4E8EC',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'documents'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '1px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: !isChecked ? '#A3A3A3' : '#E4E8EC',
                                        }}
                                    >
                                        Save
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default memo(ModalAddRestore);