import { Backdrop, Box, Grid, Card, CardContent } from '@mui/material'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useState } from 'react';
import { companyName } from '../../../../services/ApiService'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner'
import ButtonBalanceSheet from './Buttons/ButtonBalanceSheet'
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation'
import HeaderInfo from '../../../../components/Components/HeaderInfo'
import CustomToastContainer, { CustomDatePicker, Input } from '../../../../components/ui'
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const BalanceSheet = forwardRef((props, ref) => {
    const [period, setPeriod] = useState(new Date());
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const labelPeriod = "Period";

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Balance Sheet (Standard) Report', link: '/Majura/BalanceSheetReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {
            initializeView();
        }
    }));

    useEffect(() => {
        document.title = 'Balance Sheet Report - ' + JSON.parse(localStorage.getItem('branchName'));
        initializeView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function initializeView() {
        setIsLoadingPrint(true);
        getDefaultMonth();
        setTimeout(function () { setIsLoadingPrint(false); }, 2000);
    }

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRC() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport(false, "pdf");
        }
        else if (titleConfirmation === 'Print PDF Explanation') {
            PrintReport(true, "pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport(false, "xls")
        }
        else if (titleConfirmation === 'Print Excel Explanation') {
            PrintReport(true, "xls")
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintDataExplanation = () => {
        setTitleConfirmation('Print PDF Explanation')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintExcelData = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintExcelExplanation = () => {
        setTitleConfirmation('Print Excel Explanation')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintReport = (type, code) => {
        setIsLoadingPrint(true);
        const date = new Date(period).getMonth();
        const month = date + 1;
        const year = new Date(period).getFullYear();
        let params = [
            {
                "attr": "period",
                "value": "" + month
            },
            {
                "attr": "yearPeriod",
                "value": "" + year
            },
            {
                "attr": "isExplanation",
                "value": "" + type
            }
        ]

        let reportLink = 'Reports/Finance/Print/FinanceReport/BalanceSheetReport/BalanceSheetReport/PrintBalanceSheetStandard?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(setIsLoadingPrint, 'Balance Sheet (Standard)', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'Balance Sheet (Standard)', reportLink, params);
        }
    };

    const ButtonComponents = () => {
        return (
            <ButtonBalanceSheet
                cetak={PrintData}
                excel={PrintExcelData}
                cetak2={PrintDataExplanation}
                excel2={PrintExcelExplanation}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Balance Sheet (Standard) Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Input
                                            label="Company"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={companyName || ''}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                            disabled
                                        />

                                        <CustomDatePicker
                                            label={labelPeriod}
                                            name="period"
                                            value={period}
                                            onChange={setPeriod}
                                            type={'month'}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important',
                                                }
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRC}
                    />
                )
            }

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
}
)
export default BalanceSheet