import { Grid, MenuItem, Stack } from "@mui/material";
import { Selection, CustomDatePicker } from "../ui";

const EtaEtdDateRangeSelector = ({
  isRangeSelected,
  handleRangeTypeChanged,
  dataRanges,
  eta,
  onEtaChanged,
  etd,
  onEtdChanged
}) => (
  <>
    <Grid
      item
      xl={2}
      lg={2}
      sm={2}
      xs={12}
    >
      <div>
        <Selection
          id='0'
          label={'ETA/ETD Range'}
          fullWidth={true}
          placeholder={'Select ETA/ETD Range'}
          value={isRangeSelected}
          onChange={(e) => handleRangeTypeChanged(e.target.value)}
        >
          {
            dataRanges.map((data) => (
              <MenuItem id={data.id} key={data.id} value={data.value}>
                {data.name}
              </MenuItem>
            ))
          }
        </Selection>
      </div>
    </Grid>
    <Grid
      item
      xl={4}
      lg={4}
      sm={4}
      xs={12}
    >
      <Stack spacing={'24px'} direction="row" hidden={!isRangeSelected}>
        <CustomDatePicker
          label = 'Date From'
          value={etd}
          onChange={(e) => onEtdChanged(e)}
          type={'date'}
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
        />
        
        <CustomDatePicker
          label = 'Date To'
          value={eta}
          onChange={(e) => onEtaChanged(e)}
          type={'date'}
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
        />
      </Stack>
    </Grid>
  </>
);

export default EtaEtdDateRangeSelector;
