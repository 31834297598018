import {
    Grid, IconButton, InputAdornment, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses
} from '@mui/material';
import { branchId, companyId, countryId, formatCurrency } from '../../../../services/ApiService';
import ModalAccount from '../ModalEPL/ModalAccount'
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';
import { styled } from '@mui/material/styles';
import React from 'react';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { CustomDatePicker, Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';
import { convertToNumeric } from '../../../../utils/masks.jsx';
import { defaultDate } from '../../../../utils/date.jsx';

const ModalTrucking = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontstyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    document.onkeyup = escapeFunction;
    function escapeFunction(e) {
        var keyCode = e.keyCode;
        var valid = (keyCode === 27 || e.key === 'Escape');

        if (valid) {
            if (e.keyCode === 27 || e.key === 'Escape') {
                props.handleModalDetail();
                props.clearForm();
            }
        }
    }

    const containerSizeDataAll = [
        { id: 1, name: 'All', hidden: props.isContainer },
    ];

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const CostToCostData = [
        { id: 1, code: false, name: 'No' },
        { id: 2, code: true, name: 'Yes' },
    ];

    const DataTrucking = [
        { id: 1, code: '308', desc: '', amount: 0, disabled: true },
        { id: 2, code: '309', desc: '', amount: 0, disabled: true },
        { id: 3, code: '310', desc: 'BIAYA UMUM', amount: 0, disabled: false },
    ]

    const saveData = () => {
        let amount_IDR = 0;
        let amount_USD = 0;

        let check = 0;

        if (parseFloat(convertToNumeric(props.Amount)) <= 0) {
            check = 1;
        }
        else if (parseInt(props.QtyUnit) <= 0) {
            check = 1;
        }
        else if (parseFloat(convertToNumeric(props.PerUnitCost)) <= 0) {
            check = 1;
        }
        else if (props.accountId !== 0) {
            check = 1;
        }

        if (check === 1) {
            if (props.Currency === 0) {
                amount_USD = parseFloat(convertToNumeric(props.Amount));
            }
            else {
                amount_IDR = parseFloat(convertToNumeric(props.Amount));
            }

            let CTI = props.contactType
            let II = props.isIncome

            const custId = props.contactId

            let Sequence = 1
            if (props.isAdd) {
                if (props.dataDetail.length > 0) {
                    let data = props.dataDetail.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    Sequence = data.sequence + 1;
                }
            }
            else {
                Sequence = props.sequence
            }

            const payload2 = {
                "rowStatus": "ACT",
                "id": 0,
                "eplId": props.eplId,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "sequence": Sequence,
                "isIncome": II,
                "customerId": custId,
                "customerTypeId": CTI,
                "customerName": props.contactName,
                "accountId": props.accountId,
                "accountCode": props.accountCode,
                "accountName": props.accountName,
                "coaCode": props.COACode,
                "coaName": props.COAName,
                "description": props.Desc,
                "codingQuantity": false,
                "type": '0',
                "quantity": props.QtyUnit ? parseFloat(props.QtyUnit) : parseFloat(0),
                "perQty": convertToNumeric(props.PerUnitCost),
                "amountCrr": parseInt(props.Currency),
                "amountUSD": parseFloat(amount_USD),
                "amountIDR": parseFloat(amount_IDR),
                "sign": props.sign,
                "dataFrom": false,
                "memoNo": 0,
                "statusMemo": false,
                "createdMemoOn": defaultDate,
                "paidPR": false,
                "isSplitIncCost": props.SplitAccount,
                "isContainer": props.isContainer,
                "containerSize": props.containerSize,
                "formatContainer": props.containerFormat,
                "containerTypeId": props.containerTypeId,
                "containerTypeCode": props.containerTypeCode,
                "containerTypeName": props.containerTypeName,
                "idLama": 0,
                "isCostToCost": props.CostToCost,
                "truck_detail_id": 0,
                "isCostTrucking": props.CostTrucking,
                "isAdditional": false,
                "isIgnoreItem": false,
                "kendaraanId": 0,
                "kendaraanNopol": "",
                "employeeId": 0,
                "employeeName": "",
                "mrgId": 0,
                "refId": 0,
                "deliveryDate": defaultDate,
                "originalUsd": convertToNumeric(props.OriginalUSD),
                "originalRate": convertToNumeric(props.OriginalRate),
                "bookingDetailId": 0,
                "user": "",
                "estimateProfitLossDetailStorages": []
            }

            if (props.isAdd) {
                if (II === true && CTI === 2) {
                    props.setIncShipperList([...props.IncShipperList, payload2])
                } else if (II === true && CTI === 1) {
                    props.setIncAgentList([...props.IncAgentList, payload2])
                } else if (II === true && CTI === 3) {
                    props.setIncConsigneeList([...props.IncConsigneeList, payload2])
                } else if (II === false && CTI === 4) {
                    props.setCostSSLineList([...props.CostSSLineList, payload2])
                } else if (II === false && CTI === 5) {
                    props.setCostIATAList([...props.CostIATAList, payload2])
                } else if (II === false && CTI === 6) {
                    props.setCostEMKLList([...props.CostEMKLList, payload2])
                } else if (II === false && CTI === 7) {
                    props.setCostDepoList([...props.CostDepoList, payload2])
                } else if (II === false && CTI === 2) {
                    props.setCostDiscountShipperList([...props.CostDiscountShipperList, payload2])
                    console.log(props.CostDiscountShipperList)
                } else if (II === false && CTI === 3) {
                    props.setCostDiscountConsigneeList([...props.CostDiscountConsigneeList, payload2])
                } else if (II === false && CTI === 1) {
                    props.setCostAgentList([...props.CostAgentList, payload2])
                } else {
                    props.setCostTruckingList([...props.CostTruckingList, payload2])
                }

                props.setDataDetail([...props.dataDetail, payload2])
                props.countSum([...props.dataDetail, payload2])
                props.countTotalData([...props.dataDetail, payload2])
                props.clearForm();
            }
            else {
                const newArr = props.dataDetail.slice()
                newArr.forEach(el => {
                    if (el.sequence === Sequence) {
                        el.eplId = payload2.accountId
                        el.isIncome = payload2.isIncome
                        el.customerId = payload2.customerId
                        el.customerName = payload2.customerName
                        el.customerTypeId = payload2.customerTypeId
                        el.accountId = payload2.accountId
                        el.accountCode = payload2.accountCode
                        el.accountName = payload2.accountName
                        el.coaCode = payload2.coaCode
                        el.coaName = payload2.coaName
                        el.description = payload2.description
                        el.formatContainer = payload2.formatContainer
                        el.containerTypeId = payload2.containerTypeId
                        el.containerTypeCode = payload2.containerTypeCode
                        el.containerTypeName = payload2.containerTypeName
                        el.quantity = payload2.quantity
                        el.perQty = payload2.perQty
                        el.amountUSD = payload2.amountUSD
                        el.amountIDR = payload2.amountIDR
                        el.isSplitIncCost = payload2.isSplitIncCost
                        el.isCostToCost = payload2.isCostToCost
                        el.isCostTrucking = payload2.isCostTrucking
                        el.originalUsd = payload2.originalUsd
                        el.originalRate = payload2.originalRate
                        el.amountCrr = payload2.amountCrr
                        el.sign = payload2.sign
                    }
                })
                props.setDataDetail(newArr)
                props.countSum(newArr)
                props.countTotalData(newArr)
                let data2 = newArr.filter(el => el.customerTypeId === CTI && el.isIncome === II)
                if (II === true && CTI === 2) {
                    props.setIncShipperList(data2)
                } else if (II === true && CTI === 3) {
                    props.setIncConsigneeList(data2)
                } else if (II === true && CTI === 1) {
                    props.setIncAgentList(data2)
                } else if (II === false && CTI === 4) {
                    props.setCostSSLineList(data2)
                } else if (II === false && CTI === 5) {
                    props.setCostIATAList(data2)
                } else if (II === false && CTI === 6) {
                    props.setCostEMKLList(data2)
                } else if (II === false && CTI === 7) {
                    props.setCostDepoList(data2)
                } else if (II === false && (CTI === 2)) {
                    props.setCostDiscountShipperList(data2)
                } else if (II === false && (CTI === 3)) {
                    props.setCostDiscountConsigneeList(data2)
                } else if (II === false && CTI === 1) {
                    props.setCostAgentList(data2)
                } else {
                    props.setCostTruckingList(data2)
                }

                props.clearForm();
            }

            if (props.isDummy === true) {
                showToast({ type: 'warning', message: 'There Still Dummy Container On Shipment Order! Please Update The Container!' });
            }

            props.handleModalDetail();
        }
        else {
            showToast({ type: 'error', message: 'Please Input Important Data!' });
        }
    }

    function handleQuantityUnit(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setQtyUnit(value);
            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.PerUnitCost)).toFixed(5)));
        }
    }

    function handleContainerSize(value) {
        props.setContainerFormat(value);
        if (props.isContainer) {
            props.containerSizeData.forEach(element => {
                if (element.formatContainer === value) {
                    props.setContainerSize(element.containerSize);
                    props.setContainerTypeId(element.containerTypeId);
                    props.setContainerTypeCode(element.containerTypeCode);
                    props.setContainerTypeName(element.containerTypeName);
                    handleQuantityUnit(element.totalContainer);
                }
            });
        }
        else {
            handleQuantityUnit(0);
            props.setContainerSize('All');
            props.setContainerTypeId(0);
            props.setContainerTypeCode('');
            props.setContainerTypeName('');
            props.setIsOthers(false);
        }
    }

    const mainContainer = (
        <div>
            <Input
                label={'Payment To'}
                value={props.contact}
                disabled
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                fullWidth
                inputBaseProps={{
                    sx: {
                        marginTop: '3px !important',
                        display: 'flex',
                        height: '41px',
                        padding: '0px 12px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        'borderRadius': '8px',
                        border: '1.5px solid var(--Grey-Outline-2, #989898)',
                        background: '#F2F2F2',
                    },
                }}
                formLabelProps={{
                    sx: {
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#083A50 !important',
                        textAlign: 'left'
                    }
                }}
            />

            <div style={{ marginTop: '16px' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <Input
                            label={'Vendor Id'}
                            value={props.contactId}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    display: 'flex',
                                    height: '41px',
                                    padding: '0px 12px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    'borderRadius': '8px',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    background: '#F2F2F2',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => props.handleModalContact('delete')}
                                            hidden={props.contactId ? false : true}
                                            disabled={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => { props.handleModalContact('search') }}
                                            disabled={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={8}
                        lg={8}
                        sm={8}
                        xs={12}
                    >
                        <Input
                            label={'Vendor Name'}
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.contactName}
                            type='text'
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            disabled
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '16px' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <CustomDatePicker
                            label={'Delivery Date'}
                            value={props.DueDate}
                            type={'date'}
                            disabled={props.isViewOnly}
                            onChange={props.setDueDate}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >

                        <Input
                            label={'No. Pol. Truck'}
                            value={props.accountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    display: 'flex',
                                    height: '41px',
                                    padding: '0px 12px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    'borderRadius': '8px',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    background: '#F2F2F2',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => props.handleModalAccount('delete')}
                                            disabled={props.isViewOnly}
                                            hidden={props.accountCode ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => props.handleModalAccount('search')}
                                            disabled={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <Input
                            label={'Driver Truck'}
                            value={props.accountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    display: 'flex',
                                    height: '41px',
                                    padding: '0px 12px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    'borderRadius': '8px',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    background: '#F2F2F2',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => props.handleModalAccount('delete')}
                                            disabled={props.isViewOnly}
                                            hidden={props.accountCode ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => props.handleModalAccount('search')}
                                            disabled={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '16px' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Container Size'}
                            value={props.containerFormat}
                            fullWidth={true}
                            placeholder={'Select Container Size'}
                            onChange={(e) => handleContainerSize(e.target.value)}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                            selectBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    // background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(88.4deg, #0087C2 0%, #00D1FF 100%)',
                                    'borderRadius': '8px',
                                    textAlign: 'left',
                                    '&.Mui-focused': {
                                        // border: '2px solid #0049FF',
                                        'borderRadius': '8px',
                                    }
                                },
                            }}
                            selectInnerStyle={{
                                padding: '0px 32px 0px 0px !important',
                                style: {
                                    // border: '1px solid var(--Grey-Outline-2, #989898)',
                                    'borderRadius': '8px',
                                }
                            }}
                            disabled={!props.isContainer || props.isViewOnly}
                        >
                            {
                                props.isContainer ?
                                    props.containerSizeData.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                    ))
                                    :
                                    containerSizeDataAll.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                    ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label={'Destination Code'}
                            value={props.accountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    display: 'flex',
                                    height: '41px',
                                    padding: '0px 12px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    'borderRadius': '8px',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    background: '#F2F2F2',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => props.handleModalAccount('delete')}
                                            disabled={props.isViewOnly}
                                            hidden={props.accountCode ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => props.handleModalAccount('search')}
                                            disabled={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <Input
                label={'Destination Detail'}
                value={props.description}
                onChange={(e) => props.setDesc(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        marginTop: '3px !important',
                        display: 'flex',
                        padding: '8px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        'borderRadius': '8px',
                        border: '1.5px solid var(--Grey-Outline-2, #989898)',
                        background: '#F2F2F2',
                    },
                }}
                formLabelProps={{
                    sx: {
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#083A50 !important',
                        textAlign: 'left'
                    }
                }}
                disabled={props.isViewOnly}
            />

            <div className='mt-3'>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Currency'}
                            fullWidth={true}
                            placeholder={'Select Currency'}
                            value={props.Currency}
                            onChange={(e) => props.setCurrency(e.target.value)}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                            selectBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    // background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(88.4deg, #0087C2 0%, #00D1FF 100%)',
                                    'borderRadius': '8px',
                                    textAlign: 'left',
                                    '&.Mui-focused': {
                                        // border: '2px solid #0049FF',
                                        'borderRadius': '8px',
                                    }
                                },
                            }}
                            selectInnerStyle={{
                                padding: '0px 32px 0px 0px !important',
                                style: {
                                    // border: '1px solid var(--Grey-Outline-2, #989898)',
                                    'borderRadius': '8px',
                                }
                            }}
                            disabled={props.isViewOnly}
                        >
                            {
                                BankTypeData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                    </Grid>

                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Additional'}
                            fullWidth={true}
                            placeholder={'Select Currency'}
                            value={true}
                            disabled
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                            selectBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    // background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(88.4deg, #0087C2 0%, #00D1FF 100%)',
                                    'borderRadius': '8px',
                                    textAlign: 'left',
                                    '&.Mui-focused': {
                                        // border: '2px solid #0049FF',
                                        'borderRadius': '8px',
                                    }
                                },
                            }}
                            selectInnerStyle={{
                                padding: '0px 32px 0px 0px !important',
                                style: {
                                    // border: '1px solid var(--Grey-Outline-2, #989898)',
                                    'borderRadius': '8px',
                                }
                            }}
                        >
                            {
                                CostToCostData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '32px' }}>
                <TableContainer sx={{ 'borderRadius': '12px', background: '#FFF' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Code</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Description</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Amount</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                DataTrucking.map((el) => {
                                    return (
                                        <TableRow
                                            key={el.id}
                                            hover={true}
                                        >
                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.code}</StyledTableBodyCell>

                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>
                                                <Input
                                                    value={el.desc}
                                                    fullWidth
                                                    // onChange={handleInputChange}
                                                    disabled={el.disabled}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        sx: {
                                                            marginTop: '3px !important',
                                                            display: 'flex',
                                                            height: '41px',
                                                            padding: '0px 12px',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            alignSelf: 'stretch',
                                                            'borderRadius': '8px',
                                                            border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                                            background: '#F2F2F2',
                                                        },
                                                    }}
                                                    formLabelProps={{
                                                        sx: {
                                                            fontFamily: 'Nunito',
                                                            fontSize: '16px',
                                                            fontWeight: '700',
                                                            color: '#083A50 !important',
                                                            textAlign: 'left'
                                                        }
                                                    }}
                                                />
                                            </StyledTableBodyCell>

                                            <StyledTableBodyCell sx={{ textAlign: 'right' }}>
                                                <Input
                                                    textAlign='right'
                                                    fullWidth
                                                    value={el.amount}
                                                    disabled={el.disabled}
                                                    // onChange={(e) => handleQuantityUnit(e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        sx: {
                                                            marginTop: '3px !important',
                                                            display: 'flex',
                                                            height: '41px',
                                                            padding: '0px 12px',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            alignSelf: 'stretch',
                                                            'borderRadius': '8px',
                                                            border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                                            background: '#F2F2F2',
                                                        },
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                    formLabelProps={{
                                                        sx: {
                                                            fontFamily: 'Nunito',
                                                            fontSize: '16px',
                                                            fontWeight: '700',
                                                            color: '#083A50 !important',
                                                            textAlign: "right !important",
                                                            "& label": {
                                                                left: "inherit !important",
                                                                right: "0rem !important",
                                                                transformOrigin: "right !important",
                                                            },
                                                        }
                                                    }}
                                                />
                                            </StyledTableBodyCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )

    const handleCancel = () => {
        props.handleModalDetail()
        props.setIsContainer(false)
        props.setContainerSize('All');
    }

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'delivery'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Trucking Detail'}
                isOpen={props.showDetail}
                onClose={handleCancel}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(saveData, false, ((props.isViewOnly || props.isReadOnly === true) || props.refId !== 0 ? true : false))}
                    </div>
                </div>
            </PopUp>

            {
                props.showModalAccount && (
                    <ModalAccount
                        show={props.showModalAccount}
                        containerSizeData={props.containerSizeData}
                        handleModalAccount={props.handleModalAccount}
                        setSelectedData={(e) => props.setSelectedAccount(e)}
                        SelectedData={props.SelectedAccount}
                        setDesc={props.setDesc}
                        setAccountId={props.setAccountId}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                        setIsContainer={props.setIsContainer}
                        isAdd={props.isAdd}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}

                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}

                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}

                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}

                        setCOACode={props.setCOACode}
                        setCOAName={props.setCOAName}

                        containerSize={props.containerSize}
                        setContainerSize={props.setContainerSize}
                        containerTypeId={props.containerTypeId}
                        setContainerTypeId={props.setContainerTypeId}
                        containerTypeCode={props.containerTypeCode}
                        setContainerTypeCode={props.setContainerTypeCode}
                        containerTypeName={props.containerTypeName}
                        setContainerTypeName={props.setContainerTypeName}
                        containerFormat={props.containerFormat}
                        setContainerFormat={props.setContainerFormat}
                        setQtyUnit={props.setQtyUnit}
                        data={props.dataAccount}
                        isLoading={props.isLoadingAccount}
                        dataMap={props.dataMapAccount}
                        columnData={props.columnDataAccount}
                        totalRows={props.totalRowsAccount}
                        getData={props.getDataAccount}
                        handleQuantityUnit={handleQuantityUnit}
                        setIsOthers={props.setIsOthers}
                    />
                )
            }
        </>
    );
}

export default React.memo(ModalTrucking)