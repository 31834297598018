import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { Icon, Input, PopUp } from '../../../../components/ui';

const MemoizedButton = React.memo(({ onClick, icon, label, sx, variant }) => (
    <Button
        size='small'
        variant={variant}
        sx={sx}
        onClick={() => onClick()}
    >
        <Icon
            svgIconProps={icon.svgIconProps}
            iconName={icon.iconName}
        />
        <Typography sx={label.sx}>{label.text}</Typography>
    </Button>
));

const ModalApproveGeneral = React.memo(({ ApproveGenInvData, handleClick, isModal, isContra, isViewOnly }) => {
    const [localRemarks, setLocalRemarks] = useState("");

    const handleApproveClick = useCallback((localRemarks) => {
        ApproveGenInvData(localRemarks);
    }, [ApproveGenInvData]);

    const handleCancelClick = useCallback(() => {
        handleClick();
    }, [handleClick]);

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={'Approval General Invoice'}
                isOpen={isModal}
                onClose={() => handleCancelClick()}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xs'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '4px 12px 0px' }}>
                    <Input
                        label={'Remarks'}
                        value={localRemarks}
                        onChange={(e) => setLocalRemarks(e.target.value)}
                        multiline
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            rows: 5,
                            sx: {
                                padding: '8px',
                            },
                        }}
                        disabled={isContra || isViewOnly === true ? true : false}
                    />

                    <Box display="flex" alignItems="center" className='mt-4'>
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <MemoizedButton
                                    variant="outlined"
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                        '&:hover': {
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => handleApproveClick(localRemarks)}
                                    icon={{
                                        iconName: 'checklist',
                                        svgIconProps: {
                                            htmlColor: '#E4E8EC',
                                            sx: {
                                                height: '16px',
                                                width: '16px',
                                            }
                                        }
                                    }}
                                    label={{
                                        text: 'Approve',
                                        sx: {
                                            fontFamily: 'Nunito',
                                            marginTop: '2px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#E4E8EC',
                                        }
                                    }}
                                />
                                <MemoizedButton
                                    variant="outlined"
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: 'rgba(210, 12, 12, 0.25)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #D20C0C)',
                                            background: 'rgba(210, 12, 12, 0.25)',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => handleCancelClick()}
                                    icon={{
                                        iconName: 'close',
                                        svgIconProps: {
                                            htmlColor: '#D20C0C',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }
                                    }}
                                    label={{
                                        text: 'Cancel',
                                        sx: {
                                            fontFamily: 'Nunito',
                                            marginTop: '2px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#D20C0C',
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default React.memo(ModalApproveGeneral);