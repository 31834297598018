import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box } from '@mui/material';

import ButtonCheckPaymentRequest from './Buttons/ButtonCheckPaymentRequest';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ApiService from '../../../services/ApiService';
import ModalApproveGeneral from './Modals/ModalApproveGeneral';
import TableCheck from '../../../components/Tables/TableCheck';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';

const ListPaymentRequest = forwardRef((props, ref) => {
    const { isCheckType } = useParams();
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramPaymentRequest = 'paymentRequest/paymentRequest';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(0);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const [jobType, setJobType] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);

    const [dataJobType, setDataJobType] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isDraft, setIsDraft] = useState(true);
    const [isAllJob, setIsAllJob] = useState(false);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    const [isModalGPR, setIsModalGPR] = useState(false);
    const [dataCheck, setDataCheck] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Payment Request', link: '/Majura/PaymentRequest' },
        { label: 'Approve Payment Request', link: '/Majura/PaymentRequest/' + isCheckType, style: { color: '#0087C2', fontWeight: '700' } }
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        getDataAccess();
        handleStart(isCheckType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStart = (isCheckType) => {
        if (isCheckType === 'MultiApprove') {
            document.title = 'Approve Payment Request - ' + JSON.parse(localStorage.getItem('branchName'));
        }
        else {
            history('/Majura/PaymentRequest', { replace: true });
        }
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }
        let params = [];

        params = [...params, { 'attr': 'jobTypeId', 'value': '' + jobType }];

        ApiService.ListOperationDynamicPostByPage(paramPaymentRequest, 'SEAAPP', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.paymentRequests
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error when fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OPARE')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.userAccess;
                        setDataRegUserAccess(data);

                        if (hasAccess) {
                            GetDataJobType();
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error when fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getData2 = (pageNumber, pageSize, filters, jobType) => {
        setIsLoading(true);
        let params = [];

        params = [...params, { 'attr': 'jobTypeId', 'value': '' + jobType }];

        ApiService.ListOperationDynamicPostByPage(paramPaymentRequest, 'SEAAPP', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.paymentRequests
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    setIsLoading(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    setIsLoading(false);
                }

                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false);
                console.error('error when fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    getData(0, 50, []);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error when fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleChange = (e) => {
        localStorage.setItem("jobTypeId", JSON.stringify(e.target.value));
        emptyState();
        setJobType(e.target.value);
        getData2(0, 50, filter, e.target.value);
    };

    const handleReset = () => {
        emptyState();
        getData(0, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data!' });
        emptyState();
        getData(0, 50, []);
    }

    const ApproveGPR = (isApproveGPR, remarksGPR) => {
        MultiApprove('APG', isApproveGPR, remarksGPR);
    }

    const MultiApprove = (code, approved, remarks) => {
        let data = '';
        setIsModalGPR(false);
        setIsLoadingBackDrop(true);

        if (dataCheck.length > 0) {
            dataCheck.forEach(el => {
                if (el.id === dataCheck[dataCheck.length - 1].id) {
                    data = data + code + ':' + el.id;
                }
                else {
                    data = data + code + ':' + el.id + ',';
                }
            });

            const payload = {
                "paymentRequest": {
                    "processedData": data,
                    "approved": approved,
                    "approvedRemarks": remarks
                }
            };

            ApiService.OperationCustomPut(paramPaymentRequest + '/MultiApproval', payload)
                .then((res) => {
                    if (res.data.code === 200) {
                        showToast({ type: 'success', message: 'Processing Payment Request Success!' });

                        setTimeout(
                            function () {
                                setDataCheck([]);
                                setIsLoadingBackDrop(false);
                                getData(numPage, rowsCount, filter);
                            },
                            1250);
                    }
                    else {
                        setDataCheck([]);
                        setIsLoadingBackDrop(false);
                        getData(numPage, rowsCount, filter);
                        showToast({ type: 'error', message: res.data.message });
                    }
                })
                .catch((err) => {
                    setDataCheck([]);
                    setIsLoadingBackDrop(false);
                    getData(numPage, rowsCount, filter);
                    showToast({ type: 'error', message: 'Processing Payment Request Failed!, ' + err });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
    }

    function ConfirmationPR() {
        if (titleConfirmation === 'Approve Accounting Manager') {
            MultiApprove('APP');
        }
        else if (titleConfirmation === 'Open Approval') {
            MultiApprove('UPP');
        }
        setIsModalConfirmation(false);
    }

    const ApproveAccManager = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Approve Accounting Manager')
            setTitleModalConfirmation('Payment Request');
            handleClickConfirmation();
        }
    };

    const UnApproveAccManager = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Open Approval')
            setTitleModalConfirmation('Payment Request');
            handleClickConfirmation();
        }
    };

    const handleClickGPR = () => {
        if (isModalGPR === false) {
            setIsModalGPR(true);
        }
        else {
            setIsModalGPR(false);
        }
    }

    const ApproveGenPR = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            handleClickGPR();
        }
    };

    const emptyState = () => {
        setNumPage(0);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({})
        setSortArr([])
        setDataCheck([]);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <ButtonCheckPaymentRequest
                DataRegUserAccess={DataRegUserAccess}
                ReloadData={ReloadData}
                ApproveAccManager={ApproveAccManager}
                ApproveGenPR={ApproveGenPR}
                UnApproveAccManager={UnApproveAccManager}
            />
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(0);
        setRowsCount(value);
        getData(0, value, filter);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={'Approve Payment Request'}
                    isJobType={true}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                    jobTypeId={jobType}
                    handleSelect={handleChange}
                    dataJobType={dataJobType}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableCheck
                                            uniqueKey={'id'}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            dataCheck={dataCheck}
                                            setDataCheck={setDataCheck}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                            goTo={goTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationPR}

                        isDraft={isDraft}
                        setIsDraft={setIsDraft}
                        isAllJob={isAllJob}
                        setIsAllJob={setIsAllJob}
                    />
                )
            }

            {
                isModalGPR && (
                    <ModalApproveGeneral
                        isModal={isModalGPR}
                        handleClick={handleClickGPR}
                        ApproveGenPRData={ApproveGPR}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default ListPaymentRequest;