import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CustomDatePicker, Icon, Input, showToast } from '../../../../../components/ui'
import ApiService from '../../../../../services/ApiService';
import SelectDataCOA from '../../OfficialReceipt/Selects/SelectDataCOA';

const ReceiptPPh23AdjustmentForm = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCOA = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setDebitAccountCode('');
            props.setDebitAccountName('');
        }
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };
    return (
        <>
            <Typography
                sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                    marginTop: '8px',
                    marginBottom: '16px'
                }}
            >
                Adjustment
            </Typography>

            <Grid container columnSpacing={'24px'} rowSpacing={'24px'}>
                <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            textAlign: 'left !important',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            width: '100%',
                        }}
                    >
                        Adjustment Date
                    </Typography>
                </Grid>

                <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                    <CustomDatePicker
                        value={props.adjustmentDate}
                        onChange={props.setAdjustmentDate}
                        type={'date'}
                        hidden={props.isAdjustment ? true : false}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={7} />

                <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            textAlign: 'left !important',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            width: '100%',
                        }}
                    >
                        Debit
                    </Typography>
                </Grid>

                <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                    <Input
                        value={props.debitAccountCode}
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={7}>
                    <Input
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.debitAccountName}
                        type='text'
                        label={''}
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchCOA('delete')}
                                        disabled={props.isJobOwner}
                                        hidden={props.AccountName === ''}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton onClick={() => searchCOA('search')}>
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        disabled
                    />
                </Grid>
                <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            textAlign: 'left !important',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            width: '100%',
                        }}
                    >
                        Credit
                    </Typography>
                </Grid>

                <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                    <Input
                        value={props.creditAccountCode}
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={7}>
                    <Input
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.creditAccountName}
                        type='text'
                        label={''}
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                        }}
                        disabled
                    />
                </Grid>
            </Grid>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setAccountCode={props.setDebitAccountCode}
                        setAccountName={props.setDebitAccountName}
                    />
                )
            }
        </>
    )
}

export default ReceiptPPh23AdjustmentForm