import { Box, Typography, Grid, MenuItem, Stack, Button } from '@mui/material';
import React, { memo, useState } from 'react';
import { Icon, Input, PopUp, Selection } from '../../../../components/ui';

const ModalApproveGeneral = memo(({ isModal, handleClick, isContra, isViewOnly, ApproveGenPRData }) => {
    const [isApproveGPR, setIsApproveGPR] = useState(false);
    const [remarksGPR, setRemarksGPR] = useState('');
    
    const approveData = [
        { id: 1, code: true, name: 'Yes' },
        { id: 1, code: false, name: 'No' },
    ];

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Approval General Payment Request'}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '12px 12px 0px' }}>
                    <Stack direction={'column'} spacing={'16px'}>
                        <Selection
                            id='0'
                            label={'Approval'}
                            fullWidth={true}
                            placeholder={'Select Approval'}
                            value={isApproveGPR}
                            onChange={(e) => setIsApproveGPR(e.target.value)}
                            formControlProps={{
                                sx: {
                                    marginTop: '16px'
                                }
                            }}
                            disabled={(isContra || isViewOnly === true ? true : false)}
                        >
                            {
                                approveData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Input
                            label={'Remarks'}
                            value={remarksGPR}
                            onChange={(e) => setRemarksGPR(e.target.value)}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                rows: 5,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                            disabled={isContra || isViewOnly === true ? true : false}
                        />
                    </Stack>

                    <Box display="flex" alignItems="center" className='mt-4'>
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                        '&:hover': {
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => ApproveGenPRData(isApproveGPR, remarksGPR)}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#E4E8EC',
                                            sx: {
                                                height: '14px',
                                                width: '14px',
                                            }
                                        }}
                                        iconName={'checklist'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#E4E8EC',
                                        }}
                                    >
                                        Approve
                                    </Typography>
                                </Button>

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: 'rgba(210, 12, 12, 0.25)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #D20C0C)',
                                            background: 'rgba(210, 12, 12, 0.25)',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleClick}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#D20C0C',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#D20C0C',
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default memo(ModalApproveGeneral);