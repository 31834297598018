import React, { forwardRef, useEffect, useState } from 'react'
import { Backdrop, Box, Card, CardContent, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';

import ModalSearchCustomer from './components/ModalSearchCustomer';
import ModalSearchPeriod from './components/ModalSearchPeriod';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const CustomerInvoiceReport = forwardRef(() => {
  const date = new Date()

  const [isLoading, setIsLoading] = useState(false)

  const [contactTypeList, setContactTypeList] = useState([])

  const [activeModal, setActiveModal] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedPeriod, setSelectedPeriod] = useState({})

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Customer Invoices', link: '/Majura/CustomerInvoiceReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Customer Invoices - ' + JSON.parse(localStorage.getItem('branchName'));

    getAdditionalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resCT] = await Promise.all([
        ApiService.PostByPage('contactType/contactType', 1, 999, []),
      ])

      if (resCT?.data?.code === 200) {
        const tempData = resCT.data.data.contactType
        const filterData = tempData.filter((data) => ['Agent', 'Shipper', 'Consignee'].includes(data.name))
        const mapData = filterData.map((ct) => {
          return { value: ct.id, label: ct.name }
        })

        formik.setFieldValue('contactType', mapData[0].value)

        setContactTypeList(mapData)
      } else {
        errorToast('contact type')
      }

    } catch (error) {
      errorToast('additional')
    }
    setIsLoading(false)
  }

  const handlePrint = async (fileType) => {
    const payload = formik.values
    if (payload.customPeriod === 'closingPeriod' && !selectedPeriod?.id) {
      return showToast({ type: 'error', message: 'Plase select closing period.' })
    }

    if (payload.customer !== 'all' && !selectedCustomer?.id) {
      return showToast({ type: 'error', message: 'Plase select custom customer.' })
    }

    setIsLoading(true)

    try {
      const params = [
        {
          attr: 'startDate',
          value: payload.customPeriod === 'custom' ? payload.startTransaction : payload.startPeriod
        },
        {
          attr: 'endDate',
          value: payload.customPeriod === 'custom' ? payload.endTransaction : payload.endPeriod
        },
        {
          attr: 'customerTypeId',
          value: String(payload.contactType)
        },
        {
          attr: 'customerId',
          value: String(selectedCustomer?.id || 0)
        },
        {
          attr: 'isRecap',
          value: String(payload.reportType === 'recapitulation'? true : false)
        }
      ]

      let reportLink = 'Reports/Finance/Print/ControlReport/CustomerInvoiceReport/CustomerInvoiceReport/PrintCustomerInvoice?typeReport=' + fileType;
      if (fileType === 'pdf') {
        PrintPDF(setIsLoading, 'Customer Invoice', reportLink, params);
      }
      else {
        PrintExcel(setIsLoading, 'Customer Invoice', reportLink, params);
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Can\'t Print Customer Invoice, ' + error });
    }

    setIsLoading(false)
  }

  const searchModal = (target) => {
    setActiveModal(target)
  }

  const handleSelectPeriod = (data) => {
    setSelectedPeriod(data)
    formik.setFieldValue('period', data.period)
    formik.setFieldValue('year', data.yearPeriod)

    const startPeriod = new Date(data.beginningPeriod)
    const endPeriod = new Date(data.endDatePeriod)
    formik.setFieldValue('startPeriod', startPeriod.toLocaleDateString("sv-se").split('T')[0])
    formik.setFieldValue('endPeriod', endPeriod.toLocaleDateString("sv-se").split('T')[0])
  }

  const resetPeriod = () => {
    formik.setFieldValue('year', '')
    formik.setFieldValue('period', '')
    formik.setFieldValue('endPeriod', '')
    formik.setFieldValue('startPeriod', '')
    formik.setFieldValue('endTransaction', date.toLocaleDateString("sv-se").split('T')[0])
    formik.setFieldValue('startTransaction', date.toLocaleDateString("sv-se").split('T')[0])
  }

  const formik = useFormik({
    initialValues: {
      customCompany: 'company',
      customPeriod: 'closingPeriod',
      period: '',
      year: '',
      startPeriod: '',
      endPeriod: '',
      startTransaction: date.toLocaleDateString("sv-se").split('T')[0],
      endTransaction: date.toLocaleDateString("sv-se").split('T')[0],
      contactType: '',
      customer: 'all',
      reportType: 'recapitulation'
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {ButtonComponent.PrintButton(() => handlePrint('pdf'), 'PDF')}
            {ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Print Excel')}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          title={'Customer Invoices'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div style={{ marginTop: '24px' }}>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                  <CardContent>
                    <Stack spacing={'16px'} direction={'row'}>
                      <Selection
                        id='0'
                        fullWidth={true}
                        label="Custom Company"
                        value={formik.values.customCompany}
                        placeholder={'Select Custom Company'}
                        onChange={(e) => formik.setFieldValue('customCompany', e.target.value)}
                      >
                        <MenuItem sx={{ fontSize: '12px' }} value={'company'}>Per Company</MenuItem>
                        <MenuItem sx={{ fontSize: '12px' }} value={'regional'}>Per Regional</MenuItem>
                      </Selection>

                      <Selection
                        id='0'
                        fullWidth={true}
                        label="Report Type"
                        value={formik.values.reportType}
                        placeholder={'Select Report Type'}
                        onChange={(e) => formik.setFieldValue('reportType', e.target.value)}
                      >
                        <MenuItem sx={{ fontSize: '12px' }} value={'recapitulation'}>Recapitulation</MenuItem>
                        <MenuItem sx={{ fontSize: '12px' }} value={'detail'}>Detail</MenuItem>
                      </Selection>
                    </Stack>
                  </CardContent>
                </Card>

                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px', marginTop: "32px" }}>
                  <CardContent>
                    <Stack spacing={'16px'}>
                      <div>
                        <Typography
                          sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                          }}
                        >
                          Custom Period
                        </Typography>

                        <RadioGroup
                          row
                          value={formik.values.customPeriod}
                          onChange={(e) => {
                            formik.setFieldValue('customPeriod', e.target.value)
                            resetPeriod()
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            control={<Radio size='small' />}
                            key={0}
                            value={'closingPeriod'}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Per Closing Period
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            control={<Radio size='small' />}
                            key={1}
                            value={'custom'}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Custom Date
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      {
                        formik.values.customPeriod === 'closingPeriod' ?
                          <div>
                            <Stack spacing={'16px'} direction={'row'}>
                              <Input
                                readOnly
                                label="Period"
                                disabled
                                value={formik.values?.period || ''}
                              />

                              <Input
                                label="Year"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={formik.values?.year || ''}
                                type='text'
                                disabled
                                formControlProps={{
                                  sx: {
                                    width: '100%',
                                  }
                                }}
                                inputBaseProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => searchModal('Period')}
                                        hidden={formik.values.customPeriod === 'custom'}
                                      >
                                        <Icon
                                          iconName={'search'}
                                          svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                              height: '12px',
                                              width: '12px'
                                            }
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Stack>

                            <Stack spacing={'16px'} direction={'row'} sx={{ marginTop: '16px' }}>
                              <CustomDatePicker
                                label="Beginning Period"
                                value={formik.values?.startPeriod || ''}
                                onChange={(e) => formik.setFieldValue('startPeriod', e)}
                                type={'date'}
                                disabled
                                formControlProps={{
                                  sx: {
                                    width: '100%',
                                  }
                                }}
                              />

                              <CustomDatePicker
                                label="Ending Period"
                                value={formik.values?.endPeriod || ''}
                                onChange={(e) => formik.setFieldValue('endPeriod', e)}
                                type={'date'}
                                disabled
                                formControlProps={{
                                  sx: {
                                    width: '100%',
                                  }
                                }}
                              />
                            </Stack>
                          </div>
                          :
                          <Stack direction={'row'} spacing={'16px'}>
                            <CustomDatePicker
                              label="Transaction Start"
                              value={formik.values?.startTransaction || ''}
                              onChange={(e) => formik.setFieldValue('startTransaction', e)}
                              type={'date'}
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                }
                              }}
                            />

                            <CustomDatePicker
                              label="Transaction End"
                              value={formik.values?.endTransaction || ''}
                              onChange={(e) => formik.setFieldValue('endTransaction', e)}
                              type={'date'}
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                }
                              }}
                            />
                          </Stack>
                      }
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                  <CardContent>
                    <Selection
                      id='0'
                      fullWidth={true}
                      label="Invoice (AR)"
                      value={formik.values.contactType}
                      placeholder={'Select Invoice (AR)'}
                      onChange={(e) => {
                        formik.setFieldValue('contactType', e.target.value)
                        setSelectedCustomer({})
                      }}
                    >
                      {
                        contactTypeList.map((ct) => (
                          <MenuItem sx={{ fontSize: '12px' }} key={ct.value} value={ct.value}>{ct.label}</MenuItem>
                        ))
                      }
                    </Selection>

                    <RadioGroup
                      row
                      sx={{ marginTop: '16px !important' }}
                      value={formik.values.customer}
                      onChange={(e) => {
                        formik.setFieldValue('customer', e.target.value)
                        setSelectedCustomer({})
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          color: 'var(--Blue-Primary-1, #083A50)',
                          fontFamily: 'Nunito',
                          fontSize: '12px !important',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                        }}
                        control={<Radio size='small' />}
                        key={0}
                        value={'all'}
                        label={
                          <Typography
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: '20px',
                            }}
                          >
                            All Per Customer
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        sx={{
                          color: 'var(--Blue-Primary-1, #083A50)',
                          fontFamily: 'Nunito',
                          fontSize: '12px !important',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                        }}
                        control={<Radio size='small' />}
                        key={1}
                        value={'custom'}
                        label={
                          <Typography
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: '20px',
                            }}
                          >
                            Custom Per Customer
                          </Typography>
                        }
                      />
                    </RadioGroup>

                    <Stack spacing={'16px'} direction={'row'} sx={{ marginTop: '16px' }}>
                      <Input
                        readOnly
                        placeholder='Code'
                        value={selectedCustomer?.code || ''}
                        disabled
                      />

                      <Input
                        variant="standard"
                        placeholder='Search Customer'
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={selectedCustomer?.contactName || ''}
                        type='text'
                        formControlProps={{
                          sx: {
                            width: '100%',
                          }
                        }}
                        inputBaseProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => searchModal('Customer')}
                                hidden={formik.values.customer === 'all' || !formik.values.contactType}
                              >
                                <Icon
                                  iconName={'search'}
                                  svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                      height: '12px',
                                      width: '12px'
                                    }
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        disabled
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal === 'Customer' && (
        <ModalSearchCustomer
          isModal={activeModal === 'Customer'}
          activeModal={activeModal}
          toggleModal={searchModal}
          dataSelected={selectedCustomer}
          onSelect={setSelectedCustomer}
          errorToast={errorToast}
          formValue={formik.values}
          contactTypeList={contactTypeList}
        />
      )}

      {activeModal === 'Period' && (
        <ModalSearchPeriod
          isModal={activeModal === 'Period'}
          activeModal={activeModal}
          toggleModal={searchModal}
          dataSelected={selectedPeriod}
          onSelect={handleSelectPeriod}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </Box>
  )
})

export default CustomerInvoiceReport