import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, Stack, InputAdornment, IconButton, Card, CardContent } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonBalanceBankAdvanceView from './Buttons/ButtonBalanceBankAdvanceView';
import SelectDataBank from './Selects/SelectDataBank';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const BalanceBankAdvanceView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [DateNow, setDateNow] = useState('');
    const [BankId, setBankId] = useState(0);
    const [BankCode, setBankCode] = useState(0);
    const [BankInt, setBankInt] = useState('');
    const [BankName, setBankName] = useState('');

    const [titleModalBank, setTitleModalBank] = useState('');
    const [isModalBank, setIsModalBank] = useState(false);

    const [isLoadingBank, setIsLoadingBank] = useState(false);
    const [dataBank, setDataBank] = useState([]);
    const [SelectedDataBank, setSelectedDataBank] = useState({});
    const [totalRowsBank, setTotalRowsBank] = useState(50);
    const [dataMapBank, setDataMapBank] = useState([])
    const [columnDataBank, setColumnDataBank] = useState([])

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Balance Bank Advance Report', link: '/Majura/BalanceBankAdvanceListReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Balance Bank Advance Report - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDateNow);
    }

    function handleDateNow(value) {
        setDateNow(value);
    }

    const handleClickBank = () => {
        if (isModalBank === false) {
            setIsModalBank(true);
            getDataBank(1, 50);
        }
        else {
            setIsModalBank(false);
        }
    };

    function searchBank(value) {
        if (value === 'search') {
            setTitleModalBank('Bank');
            handleClickBank();
        }
        else {
            setBankId(0);
            setBankCode('');
            setBankInt('');
            setBankName('');
        }
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDateNow();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getDataBank = (pageNumber, pageSize, filters) => {
        setIsLoadingBank(true);
        let param = 'bank/bank';
        let params = [{ "attr": "isWithCOA", "value": "1" }];

        ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataBank({})
                    setDataBank(response.data.data.bank);

                    let temp = response.data.data.bank
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapBank(indexed)
                    setColumnDataBank(response.data.data.columns)
                    setTotalRowsBank(response.data.totalRowCount)

                    setIsLoadingBank(false)
                }
                setIsLoadingBank(false)
            })
            .catch(function (error) {
                setIsLoadingBank(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Failed to fetch data!, ' + error })
            })
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationBBA() {
        if (titleConfirmation === 'Print PDF') {
            PrintPDFData();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (BankId === 0) {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Bank!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('Balance Bank Advance Report');
            handleClickConfirmation();
        }
    };

    const PrintExcelData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Balance Bank Advance Report');
            handleClickConfirmation();
        }
    }

    function PrintPDFData() {
        setIsLoadingBackDrop(true);

        let reportLink = 'Reports/Finance/Print/Cashier/BalanceBankAdvanceListReport/BalanceBankAdvanceListReport/PrintBalanceBankAdvanceList?typeReport=pdf&tanggal=' + DateNow + '&bankId=' + BankId;
        PrintPDF(setIsLoadingBackDrop, 'Balance Bank Advance', reportLink);
    }

    function PrintExl() {
        setIsLoadingBackDrop(true);

        let reportLink = 'Reports/Finance/Print/Cashier/BalanceBankAdvanceListReport/BalanceBankAdvanceListReport/PrintBalanceBankAdvanceList?typeReport=xls&tanggal=' + DateNow + '&bankId=' + BankId;
        PrintExcel(setIsLoadingBackDrop, 'Balance Bank Advance', reportLink);
    }

    const ButtonComponents = () => {
        return (
            <ButtonBalanceBankAdvanceView
                PrintData={PrintData}
                PrintExcel={PrintExcelData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Balance Bank Advance Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={8}
                                lg={8}
                                sm={8}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'32px'}
                                        >
                                            <Grid
                                                item
                                                xl={8}
                                                lg={8}
                                                sm={8}
                                                xs={12}
                                            >
                                                <Stack spacing={'16px'} direction="row">
                                                    <Input
                                                        label='Bank'
                                                        value={BankCode}
                                                        inputBaseProps={{
                                                            readOnly: true,
                                                        }}
                                                        disabled
                                                    />

                                                    <Input
                                                        value={BankInt}
                                                        inputBaseProps={{
                                                            sx: {
                                                                marginTop: '23px !important'
                                                            },
                                                            readOnly: true,
                                                        }}
                                                        disabled
                                                    />

                                                    <Input
                                                        variant="standard"
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        value={BankName}
                                                        type='text'
                                                        disabled
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                        inputBaseProps={{
                                                            sx: {
                                                                marginTop: '23px !important'
                                                            },
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => searchBank('delete')}
                                                                        hidden={BankName ? false : true}
                                                                    >
                                                                        <Icon
                                                                            iconName={'close'}
                                                                            svgIconProps={{
                                                                                fontSize: 'small',
                                                                                htmlColor: '#0087C2',
                                                                                sx: {
                                                                                    height: '12px',
                                                                                    width: '12px'
                                                                                }
                                                                            }}
                                                                        />
                                                                    </IconButton>

                                                                    <IconButton
                                                                        onClick={() => searchBank('search')}
                                                                        size='small'
                                                                    >
                                                                        <Icon
                                                                            iconName={'search'}
                                                                            svgIconProps={{
                                                                                fontSize: 'small',
                                                                                htmlColor: '#0087C2',
                                                                                sx: {
                                                                                    height: '12px',
                                                                                    width: '12px'
                                                                                }
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                sm={4}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label='As Per Date'
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={DateNow}
                                                    onChange={(e) => handleDateNow(e)}
                                                    type='date'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalBank && (
                    <SelectDataBank
                        isModal={isModalBank}
                        setIsModal={setIsModalBank}
                        handleClick={handleClickBank}
                        titleModal={titleModalBank}

                        getData={getDataBank}
                        isLoading={isLoadingBank}
                        totalRows={totalRowsBank}
                        setTotalRows={setTotalRowsBank}
                        SelectedData={SelectedDataBank}
                        setSelectedData={setSelectedDataBank}
                        data={dataBank}
                        columnData={columnDataBank}
                        dataMap={dataMapBank}

                        setBankId={setBankId}
                        setBankCode={setBankCode}
                        setBankInt={setBankInt}
                        setBankName={setBankName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationBBA}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default BalanceBankAdvanceView