import { Grid, Box, Card, CardContent, MenuItem } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import ButtonDailyClosingProcess from './Buttons/ButtonDailyClosingProcess';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import { getDefaultDateFormat } from '../../../../utils/date';
import CustomToastContainer, { CustomDatePicker, Selection, showToast } from '../../../../components/ui';

const DailyClosingProcess = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramDailyClosing = 'ClosingBalance/ClosingBalance';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [companyType, setCompanyType] = useState('C');
    const [paymentType, setPaymentType] = useState('Cash');
    const [endingDate, setEndingDate] = useState('');
    const [perDate, setPerDate] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Daily Closing', link: '/Majura/DailyClosing', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const paymentTypeData = [
        { id: 1, code: 1, name: 'Cash' },
        { id: 2, code: 2, name: 'Bank' },
    ];

    const companyTypeData = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Regional Company' },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Daily Closing - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        getData('Cash');
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getData = (paymentType) => {
        setIsLoadingBackDrop(true);
        let param = paramDailyClosing;

        if (paymentType === 'Cash') {
            param = paramDailyClosing + '/LastCloseCashRead';
        }
        else {
            param = paramDailyClosing + '/LastCloseBankRead';
        }

        ApiService.GetDataFinance(param)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.dailyClosing[0];

                    let EndingDate = '';
                    if (data.closingDate !== null) {
                        EndingDate = new Date(data.closingDate);
                    }
                    
                    var dateEnding = EndingDate.toLocaleDateString("sv-se").split('T')[0];
                    setIsDisabled(EndingDate.toLocaleTimeString("sv-se").split(':')[2] === '00');
                    setEndingDate(dateEnding);

                    setPerDate(getDefaultDateFormat(new Date()));

                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCM() {
        if (titleConfirmation === 'Process') {
            ProcessData();
        }
        setIsModalConfirmation(false);
    }

    const Process = () => {
        setTitleConfirmation('Process')
        setTitleModalConfirmation('Daily Closing');
        handleClickConfirmation();
    };

    function ProcessData() {
        setIsLoadingBackDrop(true);
        let message = '';
        let param = paramDailyClosing;

        if (paymentType === 'Cash') {
            param = paramDailyClosing + '/Daily/Create/Cash';
        }
        else {
            param = paramDailyClosing + '/Daily/Create/Bank';
        }
        ApiService.GetDataFinance(param)
            .then((res) => {
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    message = 'Process Data Success';
                    showToast({ type: 'success', message: message });
                    setTimeout(function () { window.location.reload(); }, 1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: res.data.message });
                }
            });
    }

    function handlePaymentType(value) {
        setPaymentType(value);
        getData(value);
    }

    const ButtonComponents = () => {
        return (
            <ButtonDailyClosingProcess
                Process={Process}
                isDisabled={isDisabled}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0}
                direction="column">
                <HeaderInfo
                    title={'Daily Closing'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div className="mt-3">
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xl={12}
                                lg={12}
                                sm={12}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'48px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={'48px'}
                                                >
                                                    <Grid
                                                        item
                                                        xl={6}
                                                        lg={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <Selection
                                                            id='0'
                                                            label={'Payment Type'}
                                                            value={paymentType}
                                                            fullWidth={true}
                                                            placeholder={'Select Payment Type'}
                                                            onChange={(e) => handlePaymentType(e.target.value)}
                                                        >
                                                            {
                                                                paymentTypeData.map((data) => (
                                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.name}>
                                                                        {data.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Selection>

                                                        <CustomDatePicker
                                                            label={'Ending Date Closing'}
                                                            value={endingDate}
                                                            type={'date'}
                                                            onChange={setEndingDate}
                                                            formControlProps={{
                                                                sx: {
                                                                    width: '100%',
                                                                    marginTop: '24px !important'
                                                                }
                                                            }}
                                                            disabled
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xl={6}
                                                        lg={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <Selection
                                                            id='0'
                                                            label={'Company Type'}
                                                            value={companyType}
                                                            fullWidth={true}
                                                            placeholder={'Select Company Type'}
                                                            onChange={(e) => setCompanyType(e.target.value)}
                                                            disabled
                                                        >
                                                            {
                                                                companyTypeData.map((data) => (
                                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.code}>
                                                                        {data.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Selection>

                                                        <CustomDatePicker
                                                            label={'As Per Date'}
                                                            value={perDate}
                                                            type={'date'}
                                                            onChange={setPerDate}
                                                            formControlProps={{
                                                                sx: {
                                                                    width: '100%',
                                                                    marginTop: '24px !important'
                                                                }
                                                            }}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCM}
                    />
                )
            }
        </Box>
    )
})

export default DailyClosingProcess